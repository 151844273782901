export function isLoadingtags(state = false, action) {
  if (action.type === 'LOADING_TAGS') {
    return true;
  }
  if (action.type === 'NO_LOADING_TAGS') {
    return false;
  }

  return state;
}
