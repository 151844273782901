import styled, { css } from 'styled-components';
import { style as teamSettings } from '../../../../../style';
import { hexToRgbA } from '../../../../user/components/FieldPlaceholder/FieldPlaceholder.styles';

const defaultTransition = {
  transition: 'all 0.33s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const HourSelectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-evenly;

  padding-right: ${({ marginRight }) => marginRight ? marginRight : ''};
`;

export const HourSelectTitle = styled.h3`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);

  width: 100%;
  text-align: center;

  align-self: ${({ titleLeft }) => titleLeft && 'flex-start'};
  margin-right: ${({ marginRight }) => marginRight ? '10px' : ''};
`;

export const HourSelectLabel = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  max-height: 105px;

  span.disabledHour{
    opacity: 0.4;
  }

  span.disabledHour:hover {
    background: none !important;
    cursor: not-allowed;
  }
`;

export const HourSelectValues = styled.span`
  padding: 4px 8px;
  margin: 0;
  font-size: 18px;
  color: #333333;
  cursor: pointer;

  &:hover {
    background-color: ${hexToRgbA(teamSettings.inpuStyle.colorInput, 0.25)};
  }
`;

export const HourSelectArea = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 5px;
  height: ${({ isActive }) => (isActive ? '105px' : '38px')};
  padding: 4px 8px;
  z-index: 3;
  transition: height 0.33s;

  &::after {
    content: '';
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    ${defaultTransition}
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #fff;

      & {
        transform: scale(1.15, 1.15);
      }

      &::after {
        opacity: 1;
      }
    `}
`;

export const HourSelectOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
`;

export const HourSelectList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0px;
  overflow-y: ${({ isActive }) => (isActive ? 'scroll' : 'hidden')};
  height: ${({ isActive }) => (isActive ? '105px' : '30px')};
  max-height: 105px;
  transition: height 0.33s;
  margin: 0;
  z-index: 4;

  & + & {
    margin-left: ${({ isActive }) => (isActive ? '0px' : '20px')};
  }

  background-color: ${({ isActive }) => isActive && '#fff'};
`;

export const HourSelectItem = styled.li`
  padding: 4px 8px;
  margin: 0;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: ${hexToRgbA(teamSettings.inpuStyle.colorInput, 0.25)};
  }

  ${({ currentlySelected }) =>
    currentlySelected &&
    css`
      background-color: ${hexToRgbA(teamSettings.inpuStyle.colorInput, 0.5)};
    `}
`;
