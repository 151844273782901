import styled from 'styled-components';
import BaseIcon from './BaseIcon';
import { style as teamStyle } from '../../../../../../style';

export const DownloadIconStyle = styled(BaseIcon)`
  width: 15px;
  height: 15px;
  margin-right: 8px;
  fill: #ffffff;
  stroke: ${teamStyle.inpuStyle.colorInput};
`;
