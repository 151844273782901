import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { style } from '../../style';
import HistoricalApi from '../../Logicas/HistoricalApi';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import ComponentListUser from './components/ComponentListUser';
import ComponentListMerit from './components/ComponentListMerit';
import ComponentListCourse from './components/ComponentListCourse';
import ComponentListPublish from './components/ComponentListPublish';
import { TextForm, DatePickerForm } from '../utils_form/FormComponents';

import IcoPontos from '../utils/img/ic-pontos.svg';

import './historyStyle.css';
import DashboardButton from '../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import DialogHistoricalEmail from '../publication/dialog/DialogHistoricalEmail/DialogHistoricalEmail';

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  timePicker: {
    pickerHeaderColor: style.inpuStyle.colorInput,
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput,
    pickerSelectColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});
class HistoryList extends Component {
  constructor() {
    super();
    this.state = {
      listSectionName: [
        'Lista de Usuários',
        'Publicações',
        'Cursos',
        'Merit Money'
      ],
      sectionActive: 0,
      searchvalue: '',
      startDate: new Date(),
      endDate: new Date(),
      mimDate: new Date('2018-01-01T00:00:00')
    };

    this.timeout = 0;
    this.getSearchDate = this.getSearchDate.bind(this);
    this.getSearchValue = this.getSearchValue.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.getSelectSection = this.getSelectSection.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
  }

  handleChangeStart(value, date) {
    this.setState({
      startDate: date
    });
    this.getSearchDate(date, this.state.endDate);
  }

  handleChangeEnd(value, date) {
    this.setState({
      endDate: date
    });
    this.getSearchDate(this.state.startDate, date);
  }

  downloadReport(state, props) {
    const sectionName = document.querySelector(
      '.body-details-first-historical .active'
    ).innerText;

    props.getXlsxReport(
      sectionName,
      state.sectionActive,
      1,
      state.searchvalue,
      state.startDate,
      state.endDate
    );
  }

  getSearchDate(startDate, endDate) {
    if (startDate < endDate) {
      switch (this.state.sectionActive) {
        case 0:
          return this.props.getListUsersHistory(1, this.state.searchvalue);
        case 1:
          return this.props.getListPublishHistory(
            1,
            this.state.searchvalue,
            startDate,
            endDate
          );
        case 2:
          return this.props.getListCourseHistory(
            1,
            this.state.searchvalue,
            startDate,
            endDate
          );
        case 3:
          return this.props.getListMeritHistory(
            1,
            this.state.searchvalue,
            startDate,
            endDate
          );
      }
    }
  }

  componentWillMount() {
    this.props.getListUsersHistory(1, '');
  }

  getSelectSection(index) {
    this.setState({
      sectionActive: index,
      searchvalue: '',
      startDate: '',
      endDate: ''
    });
    this.props.reset();
    switch (index) {
      case 0:
        return this.props.getListUsersHistory(1, '');
      case 1:
        return this.props.getListPublishHistory(1, '');
      case 2:
        return this.props.getListCourseHistory(1, '');
      case 3:
        return this.props.getListMeritHistory(1, '');
    }
  }

  getSearchValue({ target: { value } }) {
    this.setState({ searchvalue: value });
    if (value !== this.state.searchvalue) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        switch (this.state.sectionActive) {
          case 0:
            return this.props.getListUsersHistory(1, value);
          case 1:
            return this.props.getListPublishHistory(
              1,
              value,
              this.state.startDate,
              this.state.endDate
            );
          case 2:
            return this.props.getListCourseHistory(
              1,
              value,
              this.state.startDate,
              this.state.endDate
            );
          case 3:
            return this.props.getListMeritHistory(
              1,
              value,
              this.state.startDate,
              this.state.endDate
            );
        }
      }, 1500);
    }
  }

  openHistoricalEmailDialog() {
    const { sectionActive, startDate, endDate } = this.state;

    switch (sectionActive) {
      case 0:
        return this.props.openHistoricalEmailDialog(
          'users_history.xlsx',
          startDate,
          endDate
        );
      case 1:
        return this.props.openHistoricalEmailDialog(
          'canvas_publications_history.xlsx',
          startDate,
          endDate
        );
      case 2:
        return this.props.openHistoricalEmailDialog(
          'courses_history.xlsx',
          startDate,
          endDate
        );
      case 3:
        return this.props.openHistoricalEmailDialog(
          'manual_scorings_history.xlsx',
          startDate,
          endDate
        );
    }
  }

  render() {
    const teamName = style.CurrencyName.name.split('/');
    const { isLoading } = this.props;
    const { startDate, endDate, sectionActive } = this.state;
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div>
          <div className="container">
            <div className="header-child-title">
              <div className="title-historical">
                <p>Histórico de {teamName[1]}</p>
              </div>
              <div className="filter-historical">
                <div className="filter-historical-date">
                  {this.state.sectionActive !== 0 && (
                    <>
                      <div className="date-first">
                        <DatePickerForm
                          name="start_date"
                          label="Data inicial"
                          minDate={this.state.mimDate}
                          selected={this.state.startDate}
                          onChange={this.handleChangeStart}
                        />
                      </div>
                      <div className="second-date">
                        <DatePickerForm
                          name="end_date"
                          label="Data Final"
                          selected={this.state.endDate}
                          minDate={this.state.startDate}
                          onChange={this.handleChangeEnd}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="filter-search">
                  <TextForm
                    type="text"
                    rowsMax={1}
                    hintText="Pesquisar"
                    value={this.state.searchvalue}
                    onChange={this.getSearchValue}
                  />
                </div>
              </div>
            </div>
            <div className="header-section-list">
              <div className="header-title-history">
                <ul className="body-details-first-historical">
                  {this.state.listSectionName &&
                    this.state.listSectionName.map((nameSection, index) => (
                      <li key={index}>
                        <div
                          className={
                            this.state.sectionActive === index
                              ? 'menu-item-details active'
                              : 'menu-item-details'
                          }
                          style={{ borderColor: style.inpuStyle.colorInput }}
                          onClick={() => this.getSelectSection(index)}
                        >
                          {nameSection}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="header-full-score">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() =>
                    this.getSelectSection(this.state.sectionActive)
                  }
                >
                  <path
                    d="M19 8L15 12H18C18 15.31 15.31 18 12 18C10.99 18 10.03 17.75 9.2 17.3L7.74 18.76C8.97 19.54 10.43 20 12 20C16.42 20 20 16.42 20 12H23L19 8ZM6 12C6 8.69 8.69 6 12 6C13.01 6 13.97 6.25 14.8 6.7L16.26 5.24C15.03 4.46 13.57 4 12 4C7.58 4 4 7.58 4 12H1L5 16L9 12H6Z"
                    fill={style.inpuStyle.colorInput}
                  />
                </svg>
                <p>{teamName[1]} Totais</p>
                <p>
                  {this.props.list.historical_list.total_score && this.props.list.historical_list.total_score.toLocaleString('pt-BR')}{' '}
                  {this.props.list.historical_list.total_score > 0
                    ? teamName[1]
                    : teamName[0]}
                </p>
                <img src={IcoPontos} alt="moeda de pontos" />
                {/*  <button
                  className="download-report-btn"
                  onClick={() => this.openHistoricalEmailDialog()}
                  style={{ backgroundColor: style.inpuStyle.colorInput }}
                >
                  <div className="download-report-btn-txt">
                    Baixar histórico
                  </div>
                </button> */}
                <DashboardButton
                  isActive={isLoading}
                  disabled={
                    sectionActive === 0
                      ? false
                      : !startDate || !endDate
                      ? true
                      : false
                  }
                  label={isLoading ? 'Obtendo...' : 'Obter histórico'}
                  bothDatesRequired={
                    sectionActive === 0
                      ? true
                      : !startDate || !endDate
                      ? false
                      : true
                  }
                  onClick={() => this.openHistoricalEmailDialog()}
                />
              </div>
            </div>
          </div>
          <div className="body-historical">
            {this.state.sectionActive === 0 && (
              <ComponentListUser
                listUser={this.props.list.historical_list}
                filterName={this.state.searchvalue}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            )}
            {this.state.sectionActive === 1 && (
              <ComponentListPublish
                listPublish={this.props.list.historical_list}
                filterTitle={this.state.searchvalue}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            )}
            {this.state.sectionActive === 2 && (
              <ComponentListCourse
                listCourse={this.props.list.historical_list}
                filterTitle={this.state.searchvalue}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            )}
            {this.state.sectionActive === 3 && (
              <ComponentListMerit
                listMerit={this.props.list.historical_list}
                filterName={this.state.searchvalue}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              />
            )}
          </div>
        </div>
        <DialogHistoricalEmail />
      </MuiThemeProvider>
    );
  }
}

let HistoryListContainer = reduxForm({
  form: 'historical'
})(HistoryList);

const mapStateToProps = (state) => {
  return {
    list: state.historical_points,
    isLoading: state.dialog_historical_email.requesting_historical
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListUsersHistory: (page, name) => {
      dispatch(HistoricalApi.getListUsersHistory(page, name));
    },
    getListPublishHistory: (page, title, startDate, endDate) => {
      dispatch(
        HistoricalApi.getListPublishHistory(page, title, startDate, endDate)
      );
    },
    getXlsxReport: (
      sectionName,
      sectionActive,
      page,
      searchvalue,
      startDate,
      endDate
    ) => {
      dispatch(
        HistoricalApi.getXlsxReport(
          sectionName,
          sectionActive,
          page,
          searchvalue,
          startDate,
          endDate
        )
      );
    },
    getListCourseHistory: (page, title, startDate, endDate) => {
      dispatch(
        HistoricalApi.getListCourseHistory(page, title, startDate, endDate)
      );
    },
    getListMeritHistory: (page, name, startDate, endDate) => {
      dispatch(
        HistoricalApi.getListMeritHistory(page, name, startDate, endDate)
      );
    },
    openHistoricalEmailDialog: (kind, startDate, endDate) => {
      dispatch({
        type: 'OPEN_HISTORICAL_EMAIL_MODAL',
        payload: { kind, startDate, endDate }
      });
    }
  };
};

HistoryListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryListContainer);

export default HistoryListContainer;
