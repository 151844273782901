import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEdit
} from '../utils/Table';
import { BodyTable } from '../utils/BodyTable';
import ComponentHeader from '../utils/ComponenteHeader';
import { browserHistory } from 'react-router';
import NotificationApi from '../../Logicas/NotificationApi';
import Avatar from 'material-ui/Avatar';
import DialogCreateNotification from './DialogNotification';
require('./notificationlist.css');
class NotificationList extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentWillMount() {
    this.props.getNotifications(1);
  }
  render() {
    return (
      <div>
        <ComponentHeader
          onClick={this.props.showDialog}
          actions={[]}
          name="Notificações"
          nameaction="Criar notificação"
        />
        <BodyTable>
          <Table>
            <TableHead>
              <TableHeadRow>Id</TableHeadRow>
              <TableHeadRow>Titulo</TableHeadRow>
              <TableHeadRow>Mensagem</TableHeadRow>
              <TableHeadRow>Enviado por</TableHeadRow>
              <TableHeadRow>Recebido por</TableHeadRow>
            </TableHead>
            <TableBody>
              {Array.isArray(this.props.notification_list)
                ? this.props.notification_list.map(function(notification) {
                    return (
                      <TableRow>
                        <TableData>{notification.id}</TableData>
                        <TableData>{notification.title}</TableData>
                        <TableData>
                          <div className="column-row-text">
                            {notification.description.length > 40
                              ? notification.description.slice(0, 40) + '...'
                              : notification.description}
                          </div>
                        </TableData>
                        <TableData>
                          <div className="column-row-table">
                            <Avatar src={notification.user.image.url} />
                            {notification.user.name}
                          </div>
                        </TableData>
                        <TableData>
                          {notification.user_to_send !== null ? (
                            <div className="column-row-table">
                              <Avatar src={notification.user.image.url} />
                              {notification.user_to_send.name}
                            </div>
                          ) : (
                            <div className="column-row-table">
                              Enviado para uma tag
                            </div>
                          )}
                        </TableData>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
          <DialogCreateNotification visible={this.props.alert} />
        </BodyTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notification_list: state.notification_list,
    alert: state.dialog_push
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (page) => {
      dispatch(NotificationApi.getNotifications(page));
    },
    showDialog: () => {
      dispatch({ type: 'SHOW_DIALOG_PUSH' });
    }
  };
};
const NotificationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationList);

export default NotificationListContainer;
