const defaultState = {
  app_list: [],
  app_edit: {}
};

export function application(state = defaultState, action) {
  if (action.type === 'GET_APP_LIST') {
    return { ...state, app_list: action.app_list };
  } else if (action.type === 'GET_EDIT_APP') {
    return { ...state, app_edit: action.app_edit };
  }
  return state;
}
