import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Modal from 'react-awesome-modal';
import Dropzone from 'react-dropzone';
import ExitDialog from '../../../utils/img/exit-dialog.png';
import { style } from '../../../../style';
require('./dialogimport.css');
export class DialogImport extends Component {
  constructor() {
    super();
    this.state = { width: '40%', height: '90%' };
    this.showDragArea = this.showDragArea.bind(this);
  }
  redirect() {
    browserHistory.push('/user/list');
  }
  showDragArea() {
    this.setState({ height: '20%', drag: true });
  }
  render() {
    return (
      <Modal width="1200px" height="90%" visible={true}>
        <div className="header-exit">
          <img
            onClick={this.redirect}
            className="img-exit-import"
            src={ExitDialog}
            alt="Botão para fechar modal"
          />
        </div>
        <Dropzone
          onDragLeave={() => this.setState({ drag: false })}
          onDragOver={() => this.setState({ drag: true })}
          onDrop={() => this.setState({ drag: false })}
          disableClick={true}
          className="drag-area"
        >
          {this.state.drag && this.props.file.length === 0 ? (
            <Drop
              onDropFile={this.props.onDropFile}
              visible={this.state.drag}
            />
          ) : (
            <DescriptionImport
              onDropFile={this.props.onDropFile}
              downloadSpreadsheetModel={this.props.downloadSpreadsheetModel}
            />
          )}
        </Dropzone>
      </Modal>
    );
  }
}

class DescriptionImport extends Component {
  constructor() {
    super();
    this.sendFile = this.sendFile.bind(this);
  }
  sendFile(value) {
    this.props.onDropFile(value);
  }
  render() {
    return (
      <div>
        <div className="container-header">
          <label className="main-label-import">
            Importar planilha de usuários
          </label>
          <label className="secondary-label-import">
            Importe um arquivo .xlsx preenchido de acordo com as instruções
            abaixo:
          </label>
        </div>

        <div className="description-body-text">
          {style.teamName.name === 'Time Cibra' && (
            <>
              <p className="title-label">MATRÍCULA*:</p>
              <ul className="list-label">
                <li className="text-label">
                  - Preencha com o número de matrícula do usuário.
                </li>
              </ul>
            </>
          )}

          <p className="title-label">NOME*:</p>
          <ul className="list-label">
            <li className="text-label">
              - Preencha com o nome completo do usuário.
            </li>
          </ul>

          <p className="title-label">LOGIN*:</p>
          <ul className="list-label">
            <li className="text-label">- Preencha com o login do usuário.</li>
            <li className="text-label">
              - Sugestão: CPF (somente números, sem ponto) ou e-mail.
            </li>
          </ul>

          <p className="title-label">SETOR*:</p>
          <ul className="list-label">
            <li className="text-label">
              - Preencha com o nome do setor do usuário.
            </li>
            <li className="text-label">
              - Esse campo alimenta as opções de setor no painel administrativo,
              que, por sua vez, organiza o item de menu Time.
            </li>
          </ul>

          <p className="title-label">ANIVERSÁRIO:</p>
          <ul className="list-label">
            <li className="text-label">
              - Preencha com data de aniversário do usuário, seguindo o padrão
              DD/MM/AAAA.
            </li>
          </ul>

          <p className="title-label">SENHA*:</p>
          <ul className="list-label">
            <li className="text-label">
              - As senhas poderão ser iguais para todos os usuários, pois no
              momento de importação, o usuário receberá um e-mail com uma senha
              randômica, criada pelo sistema automaticamente.
            </li>
          </ul>

          <p className="title-label">E-MAIL*:</p>
          <ul className="list-label">
            <li className="text-label">
              - Preencha com o e-mail do usuário, seguindo o padrão: xxx@xxx.com
              ou xxx@xxx.com.br.
            </li>
          </ul>

          <p className="title-label">TELEFONE:</p>
          <ul className="list-label">
            <li className="text-label">
              - Preencha com o telefone do usuário, seguindo o padrão:
              (DDD)99999-9999.
            </li>
          </ul>

          <p className="title-label">DATA DE ADMISSÃO:</p>
          <ul className="list-label">
            <li className="text-label">
              - Preencha com data de admissão do usuário, seguindo o padrão
              DD/MM/AAAA.
            </li>
          </ul>

          <p className="title-label">GÊNERO:</p>
          <ul className="list-label">
            <li className="text-label">
              - Preencha apenas com uma das opções disponíveis: Mulher cis;
              Mulher trans; Homem cis; Homen trans; Não binário; Prefere não se
              identificar ou Outros.
            </li>
          </ul>

          {style.teamName.name === 'Time Cibra' ? (
            <>
              <p className="title-label">PERMISSÕES*:</p>
              <ul className="list-label">
                <li className="text-label">
                  - Da coluna M a O, fazer marcação com um X (maiúsculo),
                  referente à permissão a qual o usuário pertence.
                </li>
              </ul>

              <p className="title-label">TAGS*:</p>
              <ul className="list-label">
                <li className="text-label">
                  - Da coluna Q em diante fazer marcação com X (maiúsculo), com
                  as tags às quais o usuário pertence.
                </li>
              </ul>
            </>
          ) : (
            <>
              <p className="title-label">PERMISSÕES*:</p>
              <ul className="list-label">
                <li className="text-label">
                  - Da coluna L a N, fazer marcação com um X (maiúsculo),
                  referente à permissão a qual o usuário pertence.
                </li>
              </ul>

              <p className="title-label">TAGS*:</p>
              <ul className="list-label">
                <li className="text-label">
                  - Da coluna P em diante fazer marcação com X (maiúsculo), com
                  as tags às quais o usuário pertence.
                </li>
              </ul>
            </>
          )}

          <p className="text-title-color">Observações:</p>
          <ul className="list-label">
            <li className="text-label-regular">
              Os campos com “*” são de preenchimento obrigatório;
            </li>
            <li className="text-label-regular">
              O título das colunas deve permanecer em caixa alta;
            </li>
            <li className="text-label-regular">
              Importe em um arquivo .xls seguindo o modelo de planilha
              disponível abaixo.
            </li>
          </ul>
        </div>

        <div className="modal-actions-import">
          <a className="drop-zone-link">
            <label
              className="label-text-strong"
              onClick={() => this.props.downloadSpreadsheetModel()}
            >
              Baixar modelo da planilha
            </label>
          </a>
          <Dropzone onDrop={this.sendFile} className="drop-zone-input">
            <div className="label-text-strong">
              Arraste ou selecione o arquivo{' '}
              <p className="text-label-description">
                (planilha anexa ao e-mail)
              </p>
            </div>
          </Dropzone>
        </div>
      </div>
    );
  }
}

class Drop extends Component {
  constructor() {
    super();
    this.sendFile = this.sendFile.bind(this);
  }
  sendFile(value) {
    this.props.onDropFile(value);
  }
  render() {
    return (
      <Dropzone
        onDrop={this.sendFile}
        className="container-drop"
        onDragOver={this.showDragArea}
        disableClick={true}
        visible={this.props.visible}
      >
        <div className="main-label-import">
          Solte aqui o arquivo da planilha
        </div>
      </Dropzone>
    );
  }
}
