import styled from "styled-components";

import { style } from '../../style';


export const ResultsPage = styled.div`
    width: 80vw;
    margin: 0 auto;
    padding: 4rem 0 0 0;

    color: #808080;
    font-size: 16px;
    font-family: Rubik;
` 

export const ViewContainer = styled.div`
    display: flex;
    flex-direction: column;

    h3{
        font-weight: 500;
        font-size: 12px;
        color: #808080;
    }
`

export const ViewContent = styled.div`
    margin-top: 2rem;

    display: flex;
    gap: 2rem;
`

export const ViewOption = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;

    font-size: 16px;

    cursor: pointer;
    color: ${(props) => props.activeFilter ? style.inpuStyle.colorInput : '#808080'} ;
    >svg circle{
        fill: ${(props) => props.activeFilter ? style.inpuStyle.colorInput : ''} ;
    }

    &:hover{
        color: ${style.inpuStyle.colorInput};
        >svg circle{
            fill: ${style.inpuStyle.colorInput} ;
        }
    }

    >span.withBorderLeft{
        display: flex;
        align-items: center;
        padding-left: 2rem;
        height: 1.5rem;
        border-left: 2px solid #E4E4E5;
    }
`

export const PeriodAndOptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;

    padding-bottom: 4rem;
    border-bottom: 1.5px solid #E4E4E5;;

    h3{
        font-weight: 500;
        font-size: 12px;
        color: #808080;
    }
`

export const PeriodContainer = styled.div`
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: initial;
    gap: 0.5rem;
`

export const OptionsContainer = styled.div`
    margin-top: 4rem;
    margin-right: 2rem;

    display: flex;
    flex-direction: column;
    align-items: initial;

    /* gap: 1rem; */
`

export const InfoMoodSurveyContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

export const InfoContent = styled.div`
    display: flex;
    align-items: center;
    
    gap: 0.5rem;

    >span{
        font-weight: 600;
        color: #4B4E5C;
    }
`

export const OptionSurveysContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;

    margin-left: ${(props) => props.withoutSpan ? '-0.3rem' : ''};
`

export const OptionSurvey = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 75px;

    >img{
        width: 4rem;
        height: 4rem;

        border: 3px solid transparent;
        border-radius: 100px;
    }

    >span.option-survey{
        color: ${style.inpuStyle.colorInput};
        width: 9rem;
        font-size: 14px;
    }

    >span{
        width: 75px;
        text-align: center;
    }

    &.visualization-by-response{
        cursor: pointer;
        filter: ${(props) => props.isActiveByFilter ? "" : "grayscale(1)"};
        >img{
            border-color: ${(props) => props.isActiveByFilter ? "#E4E4E5" : ""};
        }
        
    }

    &:not(.visualization-by-response){
        filter: ${(props) => props.isInactive ? "grayscale(1)" : "none"};
    }
`

export const AnonymousContainer = styled.div`
    margin-top: 4rem;

    max-height: 0;
    transition: max-height 0.75s linear;
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 4rem;
`

export const ImageAndDescriptionAnonymous = styled.div`
    display: flex;
    align-items: center;
    justify-content: initial;
    gap: 2rem;

    margin-left: 0.5rem;
    font-size: 14px;

`

export const TableContainer = styled.div`
    >div.container{
        min-width: 109%;
        margin-left: -5rem;
    }
    th{
        font-weight: 500;
        font-size: 12px;
        color: #808080;
    }
`

export const TableRow = styled.tr`
    font-family: Rubik;
    height: 8rem;
    font-weight: 400;

    &:nth-child(even){
        background-color: #F4F6FB;
    }
`

export const TableCol = styled.td`
    font-size:14px;
    font-weight: 400;
    color: #4B4E5C;

    padding: ${(props) => props.withoutPadding ? '0 !important' : ''};
`

export const UserImageAndName = styled.div`
    >img{
        width: 5rem;
        height: 5rem;
        border-radius: 100px;
    }

    display: flex;
    align-items: center;
    gap: 2rem;
    margin-left: 1rem;

    span{
        color: #808080;
        font-weight: 400;

        display: flex;
        align-items: center;
        text-align: center;
    }


`

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 3rem;

    margin-top: ${(props) => props.displayAnonymous ? "0" : "-2rem"};
    transition: margin-top 0.5s linear;
`

export const SearchComponent = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  margin-right: 0rem;
  max-width: 48rem;

  > svg{
    position: absolute;
    path{
        stroke: ${style.inpuStyle.colorInput};
    }
  }

  .magnifier{
    right: 10px;
    margin-bottom: 10px;
  }
`

export const InputSearch = styled.input`
    font-family: Rubik;
    height: 40px;
    border: none;
    width: 100%;

    border: 1px solid #E4E4E5;
    padding: 10px 45px 10px 10px;
    font-size: 16px;
    margin-bottom: 10px;

    color: #808080;

    &::placeholder{
      color: #808080;
    }

    &:focus {
      border: none;
      outline-style: solid;
      outline-color: ${style.inpuStyle.colorInput};
      outline-width: 1px;
    }

    &:hover {
      /* border: ${(props) => props.isDisabled ? '1px solid #E4E4E5' : 'none'}; */
      outline: 1px solid ${style.inpuStyle.colorInput};
    }
    
    "@media (max-width: 767px)"{
      margin: 0;
      width: 300px;
    }
`