import React, { Component } from 'react';

import * as S from './FieldPlaceholder.styles';

export class FieldPlaceholder extends Component {
  render() {
    const { height } = this.props;
    return (
      <S.FieldPlaceholderContainer height={height}>
        <S.FieldPlaceholderLoader />
      </S.FieldPlaceholderContainer>
    );
  }
}

export default FieldPlaceholder;
