import API from '../restservice/api.instance';

export const getToken = async () => {
  const token = await window.grecaptcha.getResponse();
  return token;
};

export const loadReCaptcha = (siteKey) => {
  const script = document.createElement('script');
  script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);

  const recaptchaInterval = setInterval(() => {
    if (!window.grecaptcha || !window.grecaptcha.execute) {
      return;
    }
    clearInterval(recaptchaInterval);

    const widgetId = window.grecaptcha.render(
      document.getElementById('widgetLogin'),
      {
        sitekey: siteKey
      }
    );
    window.grecaptcha.reset(widgetId);
  });
};

export const validateRecaptcha = async (token) => {
  const { data } = await API.get(
    `/google_captcha/validate_token?response=${token}`
  );
  return data;
};
