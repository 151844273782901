import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  TextFieldForm,
  LongTextFieldTitle
} from '../../utils_form/FormComponents';
import { required, minValue0 } from '../../validation/FormValidation';
import { MuiThemeProvider } from 'material-ui/styles';
import ModuleApi from '../../../Logicas/ModuleApi';
import IcoRemove from '../../utils/img/ic-excluir.svg';
import { DialogYesNo } from '../../utils/DailogComponentes';
import DialogApi from '../../../Logicas/DialogApi';
import './cardmodule.css';

class EditModuleForm extends Component {
  constructor() {
    super();
    this.state = { dirty_form: false };
    this.editModuleName = this.editModuleName.bind(this);
    this.editModuleScore = this.editModuleScore.bind(this);
    this.editModuleDescription = this.editModuleDescription.bind(this);
    this.requestSave = this.requestSave.bind(this);
  }
  componentDidMount() {
    this.setState({
      name: this.props.module.name,
      description: this.props.module.description,
      score: this.props.module.score
    });
  }

  editModuleName(value, kind) {
    if (value.target.value.length > 0) {
      this.setState({
        name: value.target.value,
        dirty_form: !this.state.dirty_form
      });
      this.requestSave();
    }
  }
  editModuleScore(value, kind) {
    if (value.target.value.length > 0) {
      this.setState({
        score: value.target.value,
        dirty_form: !this.state.dirty_form
      });
      this.requestSave();
    }
  }
  editModuleDescription(value, kind) {
    if (value.target.value.length > 0) {
      this.setState({
        description: value.target.value,
        dirty_form: !this.state.dirty_form
      });
      this.requestSave();
    }
  }

  requestSave() {
    setTimeout(() => {
      if (this.props.enable_form_after_callback) {
        this.props.enableEdit();
        this.props.UpdateModule(this.state, this.props.module.id);
      }
    }, 2000);
  }

  render() {
    const { handleSubmit } = this.props;
    let inputStyle = {
      'font-size': '24px',
      'font-weight': 'bold',
      width: '200px'
    };
    let fieldStyle = { width: '200px' };

    return (
      <MuiThemeProvider>
        <form className="form-edit-module-course">
          <div className="header-course-module">
            <div className="label-course-module">
              <TextFieldForm
                type="text"
                onChange={this.editModuleName}
                validations={[required]}
                textareaStyle={inputStyle}
                style={fieldStyle}
                name="name"
                floatingLabelText="Nome do módulo"
              />
            </div>
            <div className="label-course-module">
              <TextFieldForm
                type="number"
                validations={[required, minValue0]}
                onChange={this.editModuleScore}
                textareaStyle={inputStyle}
                style={fieldStyle}
                name="score"
                floatingLabelText="Pontuação"
              />
            </div>
            <div className="label-course-module">
              <LongTextFieldTitle
                type="text"
                validations={[required]}
                onChange={this.editModuleDescription}
                textareaStyle={inputStyle}
                style={fieldStyle}
                fullWidth={false}
                name="description"
                floatingLabelText="Descrição"
              />
            </div>
          </div>
          <div
            onClick={this.props.deleteShowDialogModule.bind(
              this,
              this.props.module.id
            )}
            className="btn-remove-module"
          >
            <img style={{ width: '20px', height: '20px' }} src={IcoRemove} alt="remover"/>
          </div>
        </form>
        <DialogYesNo
          yesclick={this.props.deleteModule.bind(
            this,
            this.props.alert.id_elemente,
            this.props.module.course_id
          )}
          noclick={this.props.closeDialog}
          onClick={this.props.closeDialog}
          visible={this.props.alert.visible}
          message="Tem certeza que deseja remover este módulo ?"
        />
      </MuiThemeProvider>
    );
  }
}

let EditModuleContainer = reduxForm({})(EditModuleForm);
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateModule: (values, id) => dispatch(ModuleApi.pathModule(values, id)),
    enableEdit: () => {
      dispatch({ type: 'DISABLE_SUBMITION' });
    },
    deleteShowDialogModule: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deleteModule: (values, id_course, id) => {
      dispatch(ModuleApi.deleteModule(values, id_course));
    }
  };
};

EditModuleContainer = connect(
  (state, props) => ({
    initialValues: props.module,
    alert: state.dialog,
    enableReinitialize: true,
    form: `edit_module${props.module.id}`,
    enable_form_after_callback: state.enable_form_after_callback
  }),
  mapDispatchToProps
)(EditModuleContainer);
export default EditModuleContainer;
