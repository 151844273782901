import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import GalleryApi from '../../../Logicas/GalleryApi';
import GalleryCard from '../GalleryCard/GalleryCard';
import GalleryButton from '../Components/GalleryButton';
import ArrowBack from '../../repository/Components/ArrowBack';
import ComponentHeader from '../../utils/ComponenteHeader';
import DialogCreateGallery from '../DialogCreateGallery/DialogCreateGallery';
import IcoExternal from '../../utils/img/ico-external.png';

import * as S from './GalleryList.style';

class GalleryList extends Component {
  constructor() {
    super();
    this.state = {
      showDialog: false
    };
  }

  componentDidMount() {
    if (!this.props.galleries) {
      browserHistory.push('/files/folders');
      return null;
    }
    this.props.getGalleries(1);
  }

  hideModal = () => {
    this.setState({ showDialog: false });
  };

  render() {
    const {
      showGalleryForm,
      galleries,
      pagination,
      loading,
      getMoreGalleries
    } = this.props;
    return (
      <>
        <ComponentHeader
          onClick={() => showGalleryForm()}
          actions={[]}
          name="Galerias"
          nameaction="Criar galeria +"
        />
        <div onClick={() => browserHistory.push('/files/folders')}>
          <ArrowBack />
        </div>
        <S.ListItemsGallery className="container">
          {galleries &&
            galleries.map((gallery) => {
              return (
                <GalleryCard
                  key={gallery.id}
                  item={gallery}
                  cover={
                    gallery.cover !== null ? gallery.cover.url : IcoExternal
                  }
                />
              );
            })}
        </S.ListItemsGallery>
        {pagination > 1 && (
          <GalleryButton
            onClick={() => getMoreGalleries(pagination)}
            label={loading ? 'Carregando...' : 'Carregar galerias'}
            disabled={loading}
            pagination={true}
          />
        )}
        <DialogCreateGallery />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    galleries: state.gallery.galleriesList,
    pagination: state.gallery.pagination,
    loading: state.gallery.loading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGalleries: (page) => {
      dispatch(GalleryApi.getGalleries(page));
    },
    getMoreGalleries: (page) => {
      dispatch(GalleryApi.getMoreGalleries(page));
    },
    showGalleryForm: () => {
      dispatch({ type: 'SHOW_GALLERY_FORM' });
    }
  };
};
const GalleryListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryList);

export default GalleryListContainer;
