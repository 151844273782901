const initialState = {
  eventEdit: {
    event_tags: []
  },
  eventsList: false,
  editRecurrency: {
    visible: false,
    options: {}
  }
};

export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_EDIT_RECURRENCY_DIALOG':
      return {
        ...state,
        editRecurrency: {
          ...state.editRecurrency,
          visible: true,
          options: action.options
        }
      };
    case 'CLOSE_EDIT_RECURRENCY_DIALOG':
      return {
        ...state,
        editRecurrency: {
          ...state.editRecurrency,
          visible: false,
          options: {}
        }
      };
    case 'EVENT_RESET':
      return {
        ...state,
        eventEdit: {
          event_tags: []
        }
      };
    case 'EVENT_EDIT':
      return { ...state, eventEdit: action.event_ };
    case 'EVENT_LIST':
      return {
        ...state,
        eventsList: action.events
      };
    case 'EVENT_TAGS_UPDATE':
      return {
        ...state,
        eventEdit: {
          ...state.eventEdit,
          event_tags: action.eventTags
        }
      };
    default:
      return state;
  }
}
