import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { formValueSelector, change, reduxForm, Field } from 'redux-form';
import Modal from 'react-awesome-modal';

import { getMuiTheme, MuiThemeProvider } from 'material-ui/styles';

import IcoExit from '../../../utils/img/exit-dialog.png';
import IconDelete from '../../../utils/img/ic-delete.png';

import DashboardButton from '../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import { AutoCompleteForm } from '../../../utils_form/FormComponents';
import { TextFieldForm } from '../../../utils_form/FormComponents';
import { TextFieldDefault } from '../../../utils/FormsComponents';
import ToolsButton from '../../../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import { email } from '../../../validation/FormValidation';
import { style } from '../../../../style';

import TagApi from '../../../../Logicas/TagApi';
import UserApi from '../../../../Logicas/UserApi';

import * as S from './DialogTagAssignmentBySpreadsheet.styles';

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

export class DialogTagAssignmentBySpreadsheet extends Component {
  constructor() {
    super();
    this.state = {
      file: null
    };
    this.sendReport = this.sendReport.bind(this);
  }

  validFile(file) {
    switch (file.type) {
      case '.xls':
        return true;

      case '.xlsx':
        return true;

      case 'application/vnd.ms-excel':
        return true;

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return true;

      case 'application/vnd.oasis.opendocument.spreadsheet':
        return true;

      case 'application/vnd.ms-excel.sheet.macroenabled.12':
        return true;

      case '.application/vnd.ms-excel.sheet.macroenabled.12':
        return true;

      default:
        return false;
    }
  }

  tagsCanBeAssignable = () => {
    if (!this.state.file || !this.props.email || !this.props.tags_user)
      return false;
    return true;
  };

  sendReport() {
    const { tagAssignmentViaSpreadsheet, email, tags_user } = this.props;
    const { file } = this.state;

    tagAssignmentViaSpreadsheet(file, email, tags_user);
  }

  zeroEmail = (target) => {
    if (target.nodeName === 'IMG') {
      this.props.dispatch(
        change('dialogTagAssignmentBySpreadsheetForm', 'email', '')
      );
    }
  };

  clearOnCloseDialogButton = () => {
    this.setState({ file: null });
    this.props.dispatch(
      change('dialogTagAssignmentBySpreadsheetForm', 'tags_user', '')
    );
    this.props.dispatch(
      change('dialogTagAssignmentBySpreadsheetForm', 'email', '')
    );
  };

  render() {
    const { showDialog, closeDialog, apiMessage, isLoading } = this.props;

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div
          className="react-awesome-modal"
          style={{
            visibility: showDialog ? 'visible' : 'hidden'
          }}
        >
          {!!apiMessage ? (
            <Modal
              effect="fadeInUp"
              width="420px"
              height="300px"
              visible={showDialog && !!apiMessage}
            >
              <S.ContainerDialog>
                <S.ContainerTitle>
                  <h1>Atribuição concluída</h1>
                  <S.CloseButton
                    src={IcoExit}
                    onClick={() => {
                      this.clearOnCloseDialogButton();
                      closeDialog();
                    }}
                  />
                </S.ContainerTitle>

                <S.ContainerMessage>{apiMessage}</S.ContainerMessage>

                <S.ContainerButton>
                  <ToolsButton
                    label="Voltar"
                    onClick={() => {
                      this.clearOnCloseDialogButton();
                      closeDialog();
                    }}
                  />
                </S.ContainerButton>
              </S.ContainerDialog>
            </Modal>
          ) : (
            <Modal
              effect="fadeInUp"
              width="500px"
              height="620px"
              visible={showDialog}
            >
              <S.ContainerDialog>
                <S.ContainerTitle>
                  <h1>Atribuir tag via planilha</h1>
                  <S.CloseButton
                    src={IcoExit}
                    onClick={() => {
                      this.clearOnCloseDialogButton();
                      closeDialog();
                    }}
                  />
                </S.ContainerTitle>

                <S.Section>
                  <h3>Planilha</h3>

                  <Dropzone
                    onDrop={(value) => {
                      if (this.validFile(value[0])) {
                        this.setState({ file: value[0] });
                      } else {
                        this.props.dispatchFormatError();
                      }
                    }}
                    className="drop-zone-input"
                  >
                    <DashboardButton label="Importar" onClick={() => {}} />
                  </Dropzone>

                  <S.ContainerSpreadsheet>
                    <span>
                      {this.state.file && this.state.file.name
                        ? this.state.file.name
                        : 'Nenhum arquivo selecionado'}
                    </span>
                    <S.CloseButton
                      style={{ position: 'initial' }}
                      src={IcoExit}
                      onClick={() => this.setState({ file: null })}
                    />
                  </S.ContainerSpreadsheet>
                </S.Section>

                <S.Section>
                  <h3 style={{ paddingBottom: '0' }}>Dados da importação</h3>

                  <S.FieldSection>
                    <AutoCompleteForm
                      id="tags_user"
                      name="tags_user"
                      isLoading={this.props.isLoadingTags}
                      label="Tags"
                      selectInputWidth="100%"
                      getTagAutoComplete={() => {}}
                      infiniteScroll={{
                        searchByRequest: true,
                        loadMore: (page, search) =>
                          this.props.getTags(page, search)
                      }}
                    />
                  </S.FieldSection>

                  <S.FieldSection>
                    <Field
                      name="responsible"
                      label="Responsável"
                      type="text"
                      style={{ width: '100%' }}
                      component={TextFieldDefault}
                      disabled={true}
                    />
                  </S.FieldSection>

                  <S.FieldSection>
                    <div className="cancel-edit" style={{ width: '100%' }}>
                      {this.props.email && this.props.email !== '' ? (
                        <img
                          style={{
                            marginLeft: '72%',
                            bottom: '5px',
                            zIndex: '3'
                          }}
                          src={IconDelete}
                          alt=""
                          onClick={(e) => this.zeroEmail(e.target)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <TextFieldForm
                      name="email"
                      validations={[email]}
                      floatingLabelText="E-mail"
                      type="email"
                      style={{ width: '100%' }}
                    />
                  </S.FieldSection>
                </S.Section>

                <S.Info>
                  Informe um e-mail válido para envio do relatório com
                  informações sobre esta ação.
                </S.Info>

                <S.ContainerButton>
                  <ToolsButton
                    disabled={!this.tagsCanBeAssignable() || isLoading}
                    isDisabled={!this.tagsCanBeAssignable() || isLoading}
                    label="Atribuir tag"
                    onClick={!isLoading ? this.sendReport : () => {}}
                  />
                </S.ContainerButton>
              </S.ContainerDialog>
            </Modal>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

DialogTagAssignmentBySpreadsheet = reduxForm({
  form: 'dialogTagAssignmentBySpreadsheetForm',
  enableReinitialize: true
})(DialogTagAssignmentBySpreadsheet);

const selector = formValueSelector('dialogTagAssignmentBySpreadsheetForm');

const mapStateToProps = (state) => ({
  showDialog: state.dialog_spreadsheet_tag_assignment.visible,
  apiMessage: state.dialog_spreadsheet_tag_assignment.apiMessage,
  isLoading: state.dialog_spreadsheet_tag_assignment.isLoading,
  isLoadingTags: state.isLoadingtags,
  responsible: selector(state, 'responsible'),
  initialValues: { responsible: localStorage.getItem('UserName') },
  tags_user: selector(state, 'tags_user'),
  email: selector(state, 'email')
});

const mapDispatchToProps = (dispatch) => {
  return {
    openDialog: () =>
      dispatch({
        type: 'OPEN_DIALOG_SPREADSHEET_TAG_ASSIGNMENT_USERS'
      }),
    closeDialog: () =>
      dispatch({
        type: 'CLOSE_DIALOG_SPREADSHEET_TAG_ASSIGNMENT_USERS'
      }),
    getTags: async (page, search) => {
      const res = await dispatch(TagApi.getTagsUserV2(page, search));
      return res;
    },
    tagAssignmentViaSpreadsheet: (file, email, tags_user) =>
      dispatch(
        UserApi.tagAssignmentToUsersViaSpreadsheet(file, email, tags_user)
      ),
    dispatchFormatError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Formato de arquivo enviado inválido.'
        }
      });
    }
  };
};

const DialogTagAssignmentBySpreadsheetForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogTagAssignmentBySpreadsheet);

export default DialogTagAssignmentBySpreadsheetForm;
