import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { formValueSelector } from 'redux-form';

import TagApi from '../../Logicas/TagApi';
import PostApi from '../../Logicas/PostApi';
import DialogApi from '../../Logicas/DialogApi';
import UserApi from '../../Logicas/UserApi';

import { Body } from '../utils/BodyComponente';
import ButtonDefault from '../utils/FormsComponents';
import { dateValue, required } from '../validation/FormValidation';
import ComponentHeader from '../utils/ComponenteHeader';
import { TextFieldTitle } from '../utils/FormsComponents';
import { TextFieldForm, DatePickerForm } from '../utils_form/FormComponents';
import { AutoCompleteForm } from '../utils_form/FormComponents';
import { ButtonDefaultNoTimer } from '../utils/FormsComponents';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { MuiThemeProvider } from 'material-ui/styles';
import { Toggle } from 'material-ui';
import 'react-datepicker/dist/react-datepicker.css';
import { style } from '../../style';
const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});
require('./survey.css');

class SurveyCreate extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      not_score_after: new Date(),
      publish: true,
      options: [
        <div className="col-md-4">
          <TextFieldForm
            name="option_0"
            rows={1}
            validations={[required]}
            id={`option_1`}
            floatingLabelText={`Opção 1`}
            type="text"
          />
        </div>
      ],
      quizValidate: false
    };
  }

  handleChange = (date) => {
    this.setState({
      startDate: date
    });
  };

  handleNotScoreAfter = (date) =>{
    this.setState({
      not_score_after: date
    });
  }

  componentWillMount() {
    this.props.getTags();
    this.props.getUserRoles(localStorage.getItem('Userid'));
  }

  saveEnquete = (values) => {
    this.props.CreateSurvey(
      values,
      this.state.publish,
      this.state.quizValidate
    );
  };

  insertOption = () => {
    var itens = this.state.options;
    if (itens.length < 5) {
      itens.push(
        <div className="col-md-4">
          <TextFieldForm
            name={`option_${this.state.options.length}`}
            rows={1}
            validations={[required]}
            id={`option_${this.state.options.length}`}
            floatingLabelText={`Opção ${this.state.options.length + 1}`}
            type="text"
          />
        </div>
      );
      this.setState({ itens: itens });
    }
  };

  render() {
    const styles = {
      block: {
        maxWidth: 250
      },
      toggle: {
        marginBottom: 16
      },
      thumbOff: {
        backgroundColor: '#c9c7c7'
      },
      trackOff: {
        backgroundColor: '#c9c7c7'
      },
      thumbSwitched: {
        backgroundColor: style.inpuStyle.colorInput
      },
      trackSwitched: {
        backgroundColor: style.inpuStyle.colorInput
      },
      labelStyle: {
        color: 'red'
      }
    };
    const { handleSubmit, submitting } = this.props;
    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let roleAdmin = roles.filter(function(role) {
      return role.name === 'admin';
    });
    let roleManager = roles.filter(function(role) {
      return role.name === 'manager';
    });
    const tagspeaker = JSON.parse(localStorage.getItem('tagspeaker'));
    const tags = tagspeaker.map((tag) => tag.name);
    return (
      <form onSubmit={handleSubmit(this.saveEnquete)}>
        <div className="Container-header">
          <ComponentHeader
            disabled={submitting}
            actions={[]}
            name="Nova Enquete"
            nameaction="Publicar"
            onClick={handleSubmit(this.saveEnquete)}
          ></ComponentHeader>
        </div>
        <Body>
          <MuiThemeProvider muiTheme={muiTheme}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="enable-quiz">
                    <div className="quiz-title">
                      <p>Habilitar multiplas escolhas</p>
                    </div>
                    <div className="quiz-toggle">
                      <Toggle
                        thumbStyle={styles.thumbOff}
                        trackStyle={styles.trackOff}
                        thumbSwitchedStyle={styles.thumbSwitched}
                        trackSwitchedStyle={styles.trackSwitched}
                        labelStyle={styles.labelStyle}
                        defaultToggled={this.state.quizValidate}
                        onToggle={() =>
                          this.setState({
                            quizValidate: !this.state.quizValidate
                          })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <Field
                      name="title"
                      rows={1}
                      validate={[required]}
                      id="name"
                      label="Digite a pergunta"
                      component={TextFieldTitle}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-options">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <AutoCompleteForm
                      name="tags"
                      isLoading={this.props.isLoadingTags}
                      validations={[required]}
                      label="Segmentação*"
                      getTagAutoComplete={()=>{}}
                      tags={roleAdmin[0] || roleManager[0] ? this.props.listTagName : tags}
                      infiniteScroll={
                        roleAdmin[0] || roleManager[0] ?
                        {
                          searchByRequest: true,
                          loadMore: (page, search) => this.props.getTags(page, search)
                        }:{
                          searchByRequest: false,
                          loadMore: () => {}
                        }
                    }
                    />
                  </div>
                  {this.props.userRoles !== undefined &&
                  this.props.userRoles !== 'speaker' ? (
                    <div className="col-md-2">
                      <TextFieldForm
                        name="score"
                        rows={1}
                        validations={[required]}
                        id={`option_${this.state.options.length}`}
                        floatingLabelText={`Pontuação`}
                        type="number"
                        style={{ width: '100px'}}
                        textareaStyle={{width:'120px'}}
                      />
                    </div>
                  ) : (
                    undefined
                  )}
                  <div className="col-md-3">
                    <label className="label-chindren-date" style={{borderBottom:'1px solid #3B3B3B4D'}}>
                      <DatePickerForm
                        name="end_date"
                        label="Prazo para respostas*"
                        validations={[required,dateValue]}
                        floatingLabelText={`date`}
                        selected={this.state.startDate}
                        onChange={this.handleChange}
                        isSurveyCreate={true}
                      />
                    </label>
                    <div>
                      <span className="note-schedule">
                        Validade da postagem
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label className="label-chindren-date" style={{borderBottom:'1px solid #3B3B3B4D'}}>
                      <DatePickerForm
                        name="not_score_after"
                        label="Prazo para gamificação"
                        floatingLabelText={`date`}
                        selected={this.state.not_score_after}
                        onChange={this.handleNotScoreAfter}
                        maxDate={this.state.startDate}
                        isSurveyCreate={true}
                      />
                    </label>
                    <div>
                      <span className="note-schedule">
                        Validade da gamificação
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-options">
              <div className="container">
                <div className="row">
                  {this.state.options.map((e) => {
                    return e;
                  })}
                </div>
              </div>
            </div>
            <div className="form-buttons">
              <ButtonDefaultNoTimer
                label="Adicionar opção"
                onClick={this.insertOption}
                type="button"
              >
                Adicionar opção{' '}
              </ButtonDefaultNoTimer>
              <ButtonDefaultNoTimer
                label="Remover opção"
                onClick={() =>
                  this.setState({
                    options:
                      this.state.options.length > 1
                        ? this.state.options.slice(
                            0,
                            this.state.options.length - 1
                          )
                        : [this.state.options[0]]
                  })
                }
                type="button"
              >
                {' '}
                Remover opção{' '}
              </ButtonDefaultNoTimer>
            </div>
          </MuiThemeProvider>
        </Body>
      </form>
    );
  }
}
SurveyCreate = reduxForm({
  form: 'EnqueteCreate'
})(SurveyCreate);

const selector = formValueSelector('EnqueteCreate');

const mapStateToProps = (state) => {
  return {
    erroalert: state.erroalert,
    tags_selected: selector(state, 'tags'),
    userRoles: state.user.userRoles,
    listTagName: state.tagsWithPagination.tags,
    isLoadingTags: state.tagsWithPagination.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTags: async (page, search) => {
      const res = await dispatch(TagApi.getTagsUserV2(page, search));
      return res;
    },
    closeDialogErro: () => {
      dispatch(DialogApi.closeDialogErro());
    },
    CreateSurvey: (values, publish, quiz) => {
      dispatch(PostApi.saveSurvery(values, publish, quiz));
    },
    getUserRoles: (id) => {
      dispatch(UserApi.getUserRoles(id));
    }
  };
};
const SurveyCreateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyCreate);

export default SurveyCreateContainer;
