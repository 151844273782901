import React, { Component } from 'react';

import './CourseLongStyles.css';

class ComponentContent extends Component {
  render() {
    return (
      <div className="component-content-submodule">
        <div className="text-title-submodule">
          <p>TIPO DE MÓDULO</p>
        </div>
        <div className="body-content-submodule">
          <div
            className="card-content-submodule"
            onClick={this.props.sendContentText}
          >
            <div
              className={
                this.props.contentsSubmodule.length > 0 &&
                this.props.contentsSubmodule[0].kind_content === 'quiz'
                  ? 'img-submodule desactive-img-submodule'
                  : 'img-submodule'
              }
            >
              <svg
                id={
                  this.props.contentsSubmodule.length > 0 &&
                  this.props.contentsSubmodule[0].kind_content === 'quiz'
                    ? 'img-submodule-svg desactive-img-submodule-svg'
                    : 'img-submodule-svg'
                }
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z"
                  fill="#828282"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 5.77778V4H20V5.77778H4ZM14.6667 7.5558H4V9.33358H14.6667V7.5558ZM14.6667 14.6669H4V16.4446H14.6667V14.6669ZM20 12.8888H4V11.111H20V12.8888ZM4 19.9999H20V18.2221H4V19.9999Z"
                  fill="white"
                />
              </svg>
            </div>
            <p>Texto</p>
          </div>
          <div
            className="card-content-submodule"
            onClick={this.props.sendContentMovie}
          >
            <div
              className={
                this.props.contentsSubmodule.length > 0 &&
                this.props.contentsSubmodule[0].kind_content === 'quiz'
                  ? 'img-submodule desactive-img-submodule'
                  : 'img-submodule'
              }
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 7.5V28.5L28.5 18L12 7.5Z" fill="#828282" />
              </svg>
            </div>
            <p>Video</p>
          </div>
          <div
            className="card-content-submodule"
            onClick={this.props.sendContentQuiz}
          >
            <div
              className={
                (this.props.contentsSubmodule.length > 0 &&
                  this.props.contentsSubmodule[0].kind_content === 'quiz') ||
                this.props.contentsSubmodule.length > 0
                  ? 'img-submodule desactive-img-submodule'
                  : 'img-submodule'
              }
            >
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29 4.5H8C6.335 4.5 5 5.85 5 7.5V28.5C5 30.15 6.335 31.5 8 31.5H29C30.665 31.5 32 30.15 32 28.5V7.5C32 5.85 30.665 4.5 29 4.5ZM15.5 25.5L8 18L10.115 15.885L15.5 21.255L26.885 9.87L29 12L15.5 25.5Z"
                  fill="#828282"
                />
              </svg>
            </div>
            <p>Quiz</p>
          </div>
          <div
            className="card-content-submodule"
            onClick={this.props.sendContentImage}
          >
            <div
              className={
                this.props.contentsSubmodule.length > 0 &&
                this.props.contentsSubmodule[0].kind_content === 'quiz'
                  ? 'img-submodule desactive-img-submodule'
                  : 'img-submodule'
              }
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.5 28.5V7.5C31.5 5.85 30.15 4.5 28.5 4.5H7.5C5.85 4.5 4.5 5.85 4.5 7.5V28.5C4.5 30.15 5.85 31.5 7.5 31.5H28.5C30.15 31.5 31.5 30.15 31.5 28.5ZM12.75 20.25L16.5 24.765L21.75 18L28.5 27H7.5L12.75 20.25Z"
                  fill="#828282"
                />
              </svg>
            </div>
            <p>Imagem</p>
          </div>
          <div
            className="card-content-submodule"
            onClick={this.props.sendContentLink}
          >
            <div
              className={
                this.props.contentsSubmodule.length > 0 &&
                this.props.contentsSubmodule[0].kind_content === 'quiz'
                  ? 'img-submodule desactive-img-submodule'
                  : 'img-submodule'
              }
            >
              <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="38" height="38" rx="4" fill="#828282"/>
                <path d="M16.9435 19.9336C17.373 20.5077 17.9209 20.9827 18.5501 21.3265C19.1793 21.6703 19.875 21.8747 20.5902 21.9259C21.3053 21.9771 22.0231 21.8739 22.6949 21.6233C23.3666 21.3728 23.9767 20.9806 24.4835 20.4736L27.4835 17.4736C28.3943 16.5305 28.8983 15.2675 28.8869 13.9566C28.8755 12.6456 28.3497 11.3915 27.4226 10.4645C26.4956 9.53743 25.2415 9.01158 23.9305 9.00019C22.6196 8.9888 21.3566 9.49277 20.4135 10.4036L18.6935 12.1136" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20.9436 17.9336C20.5141 17.3594 19.9662 16.8844 19.337 16.5406C18.7078 16.1969 18.0121 15.9925 17.2969 15.9412C16.5818 15.89 15.864 15.9932 15.1922 16.2438C14.5205 16.4944 13.9104 16.8865 13.4036 17.3936L10.4036 20.3936C9.49277 21.3366 8.9888 22.5996 9.00019 23.9106C9.01158 25.2216 9.53743 26.4756 10.4645 27.4027C11.3915 28.3297 12.6456 28.8556 13.9566 28.8669C15.2675 28.8783 16.5305 28.3744 17.4736 27.4636L19.1836 25.7536" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>Link</p>
          </div>
          <div
            className="card-content-submodule"
            onClick={this.props.sendContentFile}
          >
            <div
              className={
                this.props.contentsSubmodule.length > 0 &&
                this.props.contentsSubmodule[0].kind_content === 'quiz'
                  ? 'img-submodule desactive-img-submodule'
                  : 'img-submodule'
              }
            >
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="38" height="38" rx="4" fill="#828282"/>
                <path d="M20 9H13C12.4696 9 11.9609 9.21071 11.5858 9.58579C11.2107 9.96086 11 10.4696 11 11V27C11 27.5304 11.2107 28.0391 11.5858 28.4142C11.9609 28.7893 12.4696 29 13 29H25C25.5304 29 26.0391 28.7893 26.4142 28.4142C26.7893 28.0391 27 27.5304 27 27V16L20 9Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20 9V16H27" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>Arquivos</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ComponentContent;
