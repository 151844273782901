import React, { Component } from 'react';
import IcoPontos from '../../utils/img/ic-pontos.svg';

require('../academystyle.css');

export default class HeaderModule extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card-details-module">
            <div className="row">
              <div className="col-md-4">
                <label className="module-title">{this.props.modulename}</label>
              </div>
              <div
                style={{
                  marginRight: '0px'
                }}
                className="col-md-4"
              >
                <img
                  style={{
                    marginTop: '-10px',
                    marginRight: '10px'
                  }}
                  src={IcoPontos}
                  alt="pontuação"
                />
                <label className="score-text">{this.props.score}</label>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <label className="module-description">
                  {this.props.description}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
