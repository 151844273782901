import styled from "styled-components";
import { style } from "../../../../../style";


export const ActionsModuleContainer = styled.div`
  border-top: 2px solid #ececec;
  padding: 4rem 0;
`

export const ActionsModuleContent = styled.div`
  display: flex;
  padding: 5rem 6rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.75rem;

  border-radius: 16px;
  border: 2px solid #E4E4E5;
  max-width: 70%;
  margin: 0 auto;

`

export const ChooseOptionContainer = styled.div`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  border-radius: 8px;
  background: rgba(228, 228, 229, 0.25);

  h2{
    color: #4B4E5C;
    font-size: 1.75rem;
    font-family: Rubik;
    font-weight: 500;
    text-align: start;
    width: 100%;
  }
`

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;

`

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 0 3rem;

  &:hover{
    cursor: ${(props) => !props.isInactive ? 'pointer' : ''};

    .ImageContainer{
      background-color: ${(props) => !props.isInactive ? style.inpuStyle.colorInput: ''};
    }

    svg{
      path{
        stroke: ${(props) => !props.isInactive ? 'white' : ''};
      }
    }

    span{
      color: ${(props) => !props.isInactive ? style.inpuStyle.colorInput : ''};
    }
  }

  .ImageContainer{
    display: flex;
    width: 9rem;
    height: 9rem;
    justify-content: center;
    align-items: center;

    background-color: ${(props) => props.isActive && !props.isInactive ? style.inpuStyle.colorInput : '#E4E4E5'};
    border-radius: 8px;

    svg{
      path{
        stroke: ${(props) => props.isActive && !props.isInactive ? 'white' : ''};
      }
    }

  }

  span{
    color: ${(props) => props.isActive && !props.isInactive ? style.inpuStyle.colorInput : '#808080'};
    text-align: center;
    font-size: 2rem;
    font-family: Rubik;
    font-weight: 500;
  }
`

export const RelatorioContainer = styled.div`
  max-width: 63rem;

  display: flex;
  padding: 0 3.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
  align-self: stretch;

  margin: 0 auto;

  >h2{
    color: #4B4E5C;
    font-size: 1.75rem;
    font-family: Rubik;
    font-weight: 500;
    text-align: start;
    width: 100%;
  }

  >span{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: #808080;
    font-size: 1.5rem;
    font-family: Rubik;
    line-height: 27px;
  }

  >button{
    margin: 0;
    margin-left: auto;
  }

  .span-error{
    color: red;
    position: absolute;
    margin-top: -2rem;
    padding: 0.5rem;
    font-size: 12px;
    font-family: Rubik;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 5rem;
  padding: 0.8rem 0px 0.8rem 1.5rem;

  outline: none;
  border: ${(props) => props.withError ? '1px' : '2px'} solid ${(props) => props.withError ? 'red' : '#E4E4E5'};
  border-radius: 4px;

  color: #808080;
  font-size: 1.5rem;
`

export const MessageContainer = styled.div`
  max-width: 63rem;
  min-width: 63rem;

  display: flex;
  padding: 0 3.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
  align-self: stretch;

  margin: 0 auto;

  >h2{
    color: #4B4E5C;
    font-size: 1.75rem;
    font-family: Rubik;
    font-weight: 500;
    text-align: start;
    width: 100%;
  }

  >button{
    margin: 0;
    margin-left: auto;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #ccc;
    outline: none;
    cursor: pointer;
    padding: 2px;
    background-clip: content-box;
  }

  /* Estiliza o rótulo quando o input radio estiver marcado */
  input[type="radio"]:checked {
    background-color: ${style.inpuStyle.colorInput};
    border-color: ${style.inpuStyle.colorInput};
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 1rem;

  .first-line{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    >span{
      color: #808080;
      font-size: 1.5rem;
      font-family: Rubik;
      line-height: 16.125px;
      letter-spacing: 0.08px;
    }
  }
`

export const Textarea = styled.textarea`
  width: 100%;
  height: 12rem;
  padding: 0.8rem 0px 0.8rem 1.5rem;

  outline: none;
  border: ${(props) => props.withError ? '1px' : '2px'} solid ${(props) => props.withError ? 'red' : '#E4E4E5'};
  border-radius: 4px;

  color: #808080;
  font-size: 1.5rem;

  white-space: pre-wrap;
`
export const CertificadoContainer = styled.div`
  width: 100%;

  display: flex;
  padding: 0 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  margin: 0 auto;

  >h2{
    color: #4B4E5C;
    font-size: 1.75rem;
    font-family: Rubik;
    font-weight: 500;
    text-align: start;
    width: 100%;
  }

`

export const SearchUserContainer = styled.div`
  display: flex;
  width: 60%;
  gap: 2rem;
`
export const ContainerSearch = styled.div`
  display: flex;
  grid-area: search;
  position: relative;
  flex: 1;
  align-items: center;
  max-width: 400px;
  width: 80%;

  > svg {
    position: absolute;
  }

  .magnifier {
    right: 10px;
    bottom: 10px;
    opacity: 0.8;
  }
`;

export const TableContainer = styled.div`
  /* background-color: red; */
  width: 100%;

  > div {
    padding: 0;
  }


  .table-row{
    border-radius: 4px;
  }

  .table-row:hover{
    background-color: white;
  }

  .table-row:nth-child(even){
    background-color: #F4F6FB;
  }
`

export const TableWarning = styled.div`
  width: 100%;
  margin: 1rem auto -1rem;
  height: 72px;
  background-color: rgba(228, 228, 229, 0.25);
  color: #808080;

  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const AvatarContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: start;
`
export const ActionsCertificateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  >div{
    cursor: pointer;

    >svg{
      path{
        stroke: #808080;
      }
    }

    >svg:hover{
      path{
        stroke: ${style.inpuStyle.colorInput};
      }
    }
  }
`
