const initialState = {
  visible: false,
  startDate: '',
  endDate: '',
  kind: '',
  requesting_historical: false,
  historical_success: false,
  success_message: ''
};

export function dialog_historical_email(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_HISTORICAL_EMAIL_MODAL':
      return {
        ...state,
        visible: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        kind: action.payload.kind
      };
    case 'HISTORICAL_REQUEST_START':
      return {
        ...state,
        requesting_historical: true
      };
    case 'HISTORICAL_REQUEST_SUCCESS':
      return {
        ...state,
        requesting_historical: false,
        historical_success: true,
        success_message: action.payload.message
      };
    case 'CLOSE_HISTORICAL_EMAIL_MODAL':
      return {
        ...state,
        visible: false,
        startDate: '',
        endDate: '',
        kind: '',
        requesting_historical: false,
        historical_success: false,
        success_message: ''
      };
    default:
      return state;
  }
}
