import moment from 'moment';
import momentTz from 'moment-timezone';

export const required = (value) =>
  value ? undefined || value.length <= 0 : 'Campo obrigatório';

const formats = [
  '.webm',
  '.mkv',
  '.flv',
  '.vob',
  '.ogv',
  '.ogg',
  '.drc',
  '.gif',
  '.gifv',
  '.mng',
  '.avi',
  '.mov',
  '.qt',
  '.wmv',
  '.yuv',
  '.rm',
  '.rmvb',
  '.asf',
  '.amv',
  '.mp4',
  '.m4p',
  '.m4v',
  '.mpg',
  '.mp2',
  '.mpeg',
  '.mpe',
  '.mpg',
  '.mpeg',
  '.m2v',
  '.m4v',
  '.svi',
  '.3gp',
  '.3g2',
  '.mxf',
  '.roq',
  '.nsv',
  '.flv',
  '.f4v',
  '.f4p',
  '.mov',
  '.MOV',
  '.f4a',
  '.f4b',
  'video/mp4',
  'video/quicktime'
];

const formatsImageOrGif = ['image/jpeg', 'image/png', 'image/gif'];

const formatsImage = ['image/jpeg', 'image/png'];

export const maxLength = (max) => (value) =>
  value && value.length > max
    ? 'Quantidade de caracteres acima do permitido '
    : undefined;

var validUrl = require('valid-url');

export const validurl = (value) =>
  value && !validUrl.isUri(value) ? 'A url informada não é valida' : undefined;

export const maxLength190 = maxLength(190);

export const maxLength300 = maxLength(300);

export const maxLength255 = maxLength(255);

export const maxLengthTitle = maxLength(170);

export const maxLengthDescription = maxLength(1024);

export const minLength = (min) => (value) =>
  value && value.length < min
    ? `A senha deve ter no minímo ${min} caracteres`
    : undefined;

export const minLength6 = minLength(6);

export const number = (value) =>
  value && isNaN(Number(value)) ? 'Deve ser um número' : undefined;

export const minValue = (min) => (value) =>
  value && value < 0 ? `Pontuação deve ser maior ou igual a ${min}` : undefined;
export const minValue0 = minValue(0);
export const minValue1 = minValue(1);

export const maxValue = (max) => (value) =>
  value && value > 100
    ? `Pontuação deve ser menor ou igual a ${max}`
    : undefined;

export const maxValue100 = maxValue(100);

export const minProductsQty = (min) => (value) =>
  value && value < 0
    ? `Quantidade deve ser maior ou igual a ${min}`
    : undefined;
export const minProductsQty0 = minProductsQty(0);
export const minProductsQty1 = minProductsQty(1);

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'E-mail inválido'
    : undefined;

export const tooYoung = (value) =>
  value && value < 13
    ? 'You do not meet the minimum age requirement!'
    : undefined;

export const aol = (value) =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?'
    : undefined;

export const alphaNumeric = (value) =>
  value && /[^a-zA-Z\s]/i.test(value)
    ? 'Use somente caracteres alfanuméricos'
    : undefined;

export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

export const validAge = (value) => {
  const birthDay = moment(value, 'DD/MM/YYYY');
  const minimumAge = moment().subtract(13, 'years');
  const maximumAge = moment().subtract(120, 'years');

  if (birthDay.isBefore(maximumAge)) {
    return 'Idade maior que a permitida';
  } else if (birthDay.isAfter(minimumAge)) {
    return 'Idade menor que a permitida';
  }
};

export const ValidVideo = (value) => {
  if (formats.includes(value)) {
    return true;
  } else {
    return false;
  }
};

export const ValidImage = (value) => {
  if (formatsImage.includes(value)) {
    return true;
  } else {
    return false;
  }
};

export const ValidImageOrGif = (value) => {
  if (formatsImageOrGif.includes(value)) {
    return true;
  } else {
    return false;
  }
};

export const parseValue = (value) => {
  if (typeof value === 'string' && value !== value.replace(/[^0-9]/g, '')) {
    return 'A aplicação só trata numeros inteiros.';
  }
};

export const dateValue = (value) => {
  let dateCurrent = new Date();
  if (
    momentTz.tz(value, 'Europe/Berlin').format('DD/MM/YYYY') ===
    momentTz.tz(dateCurrent, 'Europe/Berlin').format('DD/MM/YYYY')
  ) {
    return 'Data inválida para publicação.';
  }
};

export const doesContainHttp = (url) =>
  ['http://', 'https://'].some(
    (validProtocol) => url !== null && url.startsWith(validProtocol)
  )
    ? true
    : false;

export const isValidToolLink = (linkUrl) =>
  !doesContainHttp(linkUrl)
    ? 'A url informada não contém http:// ou https://, então será adicionado https:// ao início da mesma'
    : undefined;

export const validateCNPJ = (value) => {
  const cnpj = value.replace(/[^\d]+/g, '');
  if (cnpj.length !== 14) return 'CNPJ inválido';
  let totalLength = cnpj.length - 2;
  const cnpjWithoutDigits = cnpj.substring(0, totalLength);
  const verificationDigits = cnpj.substring(totalLength);
  let sum = 0;
  let pos = totalLength - 7;
  for (let i = totalLength; i >= 1; i--) {
    sum += cnpjWithoutDigits.charAt(totalLength - i) * pos--;
    if (pos < 2) pos = 9;
  }
  const result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(verificationDigits.charAt(0))) return 'CNPJ inválido';
  totalLength += 1;
  const updatedCnpjWithoutDigits = cnpj.substring(0, totalLength);
  sum = 0;
  pos = totalLength - 7;
  for (let i = totalLength; i >= 1; i--) {
    sum += updatedCnpjWithoutDigits.charAt(totalLength - i) * pos--;
    if (pos < 2) pos = 9;
  }
  const newResult = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (newResult !== parseInt(verificationDigits.charAt(1)))
    return 'CNPJ inválido';
  return undefined;
};

export function formatCNPJ(value) {
  value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
  value = value.slice(0, 14); // Limita a 14 caracteres numéricos
  value = value.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca o ponto após os dois primeiros dígitos
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca o ponto após os cinco primeiros dígitos
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca a barra após os oito primeiros dígitos
  value = value.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca o hífen após os doze primeiros dígitos
  return value;
}
