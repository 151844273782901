const initialState = {
  open: false,
  postId: 0
};

const specialPostDialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_SPECIAL_POST_DIALOG':
      return {
        ...state,
        open: true,
        postId: action.postId
      };
    case 'CLOSE_SPECIAL_POST_DIALOG':
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};

export default specialPostDialogReducer;
