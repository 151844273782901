import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Player } from 'video-react';
import { v1 as uuidv1 } from 'uuid';
import styled from 'styled-components';

import { style } from '../../../../style';
import ReactHtmlParser from 'react-html-parser';

import { ReactComponent as DownloadIcon } from './icons/downloadIcon.svg';
import { ReactComponent as FilePdf } from './icons/filePdf.svg';
import { ReactComponent as FileJpeg } from './icons/fileJpeg.svg';
import { ReactComponent as FileDoc } from './icons/fileDoc.svg';
import { ReactComponent as FilePpt } from './icons/filePpt.svg';
import { ReactComponent as FilePrezi } from './icons/filePrezi.svg';
import { ReactComponent as FileXls } from './icons/fileXls.svg';

import './CourseLongStyles.css';

const ContentFile = styled.div`
  height: 60px;
  width: 90%;
  margin-bottom: ${({withMarginBottom})=>(withMarginBottom && '20px')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .first-line{
    display:flex;
    align-items:center;
  }

  svg{
    #Rectangle_Copy_5{
      fill: ${style.inpuStyle.colorInput};
    }
  }

  a{
    cursor: pointer;
  }
`;

class PreviewContent extends Component {
  constructor() {
    super();
    this.state = { subModuleSelected: 0, moduleActiveNow: 0 };
  }

  componentDidMount() {
    this.setState({
      moduleActiveNow: this.props.moduleActiveNow,
      courseDetails: this.props.courseDetails.submodules
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.moduleActiveNow !== this.state.moduleActiveNow) {
      this.setState({ subModuleSelected: 0 });
      this.setState({ moduleActiveNow: nextProps.moduleActiveNow });
      this.setState({ courseDetails: nextProps.courseDetails.submodules });
    }
  }

  returnIconFromFormatFile(format) {
    const formatsXls = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.oasis.opendocument.spreadsheet',
      '.xls',
      '.xlsm',
      '.csv',
      '.xml',
      'text/csv',
      'application/vnd.ms-excel.sheet.macroenabled.12',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'unknow'
    ];
    const formatsDoc = [
      '.txt',
      '.odt',
      'application/vnd.oasis.opendocument.text',
      'text/plain',
      '.rtf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      '.docx',
      '.doc',
      'application/msword',
      'text/html',
      'application/rtf'
    ];
    const formatsPpt = [
      '.ppt',
      '.pptx',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint',
      'application/vnd.oasis.opendocument.presentation',
      '.form'
    ];
    const formatsJpg = ['image/png', 'image/jpeg', 'image/gif', '.jpg'];
    const formatsPrezi = ['.prezi'];
    if (formatsXls.includes(format)) {
      return <FileXls />;
    } else if (formatsDoc.includes(format)) {
      return <FileDoc />;
    } else if (formatsPpt.includes(format)) {
      return <FilePpt />;
    } else if (formatsJpg.includes(format)) {
      return <FileJpeg />;
    } else if (formatsPrezi.includes(format)) {
      return <FilePrezi />;
    } else return <FilePdf />;
    //   case 'application/pdf':
  }

  render() {
    return (
      <div
        className="container-preview-content"
        style={{
          backgroundImage: `linear-gradient(45deg, #ccd2e0 0%, ${style.inpuStyle.colorInput} 100%)`
        }}
      >
        {this.props.moduleActive && (
          <div>
            <div className="header-preview-content">
              <p>{this.props.moduleActive.name}</p>
            </div>
            {this.props.moduleActive.submodules &&
              this.props.moduleActive.submodules.length > 0 && (
                <div className="body-preview-content">
                  <div className="card-preview-content">
                    <div className="header-card-preview">
                      <div
                        className="arrow-card-submodule left"
                        onClick={() =>
                          this.state.subModuleSelected > 0 &&
                          this.setState({
                            subModuleSelected: this.state.subModuleSelected - 1
                          })
                        }
                      >
                        <svg
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 1L1 6.5782L7 12"
                            stroke={style.inpuStyle.colorInput}
                            stroke-width="1.44"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <p>
                        {
                          this.props.moduleActive.submodules[
                            this.state.subModuleSelected
                          ].name
                        }
                      </p>
                      <div
                        className="arrow-card-submodule right"
                        onClick={() =>
                          this.state.subModuleSelected <
                            this.props.moduleActive.submodules.length - 1 &&
                          this.setState({
                            subModuleSelected: this.state.subModuleSelected + 1
                          })
                        }
                      >
                        <svg
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7 6.5782L1 12"
                            stroke={style.inpuStyle.colorInput}
                            stroke-width="1.44"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    {this.props.moduleActive.submodules[
                      this.state.subModuleSelected
                    ].contents.map((contents, index) => {
                      switch (contents.kind_content) {
                        case 'quiz':
                          return (
                            <div className="body-card-preview" key={uuidv1()}>
                              <div className="preview-title-quiz">
                                <p>{contents.question_text}</p>
                              </div>
                              <div className="body-answers-content">
                                {contents.answers &&
                                  contents.answers.map((answer) => {
                                    return (
                                      <div
                                        className={
                                          answer.is_correct
                                            ? 'content-answers-preview active-content-answers-preview'
                                            : 'content-answers-preview'
                                        }
                                        key={answer.id}
                                      >
                                        <p>{answer.answer_text}</p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        case 'text':
                          return (
                            <div className="body-card-preview" key={uuidv1()}>
                              <div className="preview-description-text">
                                {ReactHtmlParser(contents.text_content)}
                              </div>
                            </div>
                          );
                        case 'image':
                          if (contents.image === undefined) {
                            return null;
                          }
                          return (
                            <div className="body-card-preview" key={uuidv1()}>
                              <img
                                className="image-preview-card"
                                src={contents.image.url}
                                alt="Imagem de preview"
                              />
                            </div>
                          );
                        case 'video':
                          if (contents.video === undefined) {
                            return null;
                          }
                          return (
                            <div className="body-card-preview" key={uuidv1()}>
                              <div className="image-preview-card-video">
                                <Player src={contents.video.url} />
                              </div>
                            </div>
                          );
                        case 'link':
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <div
                                className="btn-add-component"
                                style={{
                                  backgroundColor: `${style.inpuStyle.colorInput}`,
                                  borderRadius: '5px',
                                  width: '100px',
                                  paddingLeft: '15px',
                                  paddingRight: '15px',
                                  height: '40px'
                                }}
                              >
                                <a
                                  style={{ marginTop: '20px' }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={contents.linkcontent}
                                >
                                  {' '}
                                  <div
                                    className="btn-add-component-label"
                                    style={{
                                      height: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      marginTop: '0px',
                                      color: 'white',
                                      fontWeight: '300',
                                      fontSize: '16px'
                                    }}
                                  >
                                    {' '}
                                    {contents.title.length > 30
                                      ? contents.title.slice(0, 30) + '...'
                                      : contents.title}
                                  </div>
                                </a>
                              </div>
                            </div>
                          );
                        case 'file':
                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '5px'
                              }}
                            >
                              {contents.content_files.map((file, index) => (
                                <ContentFile key={index} withMarginBottom={index !== contents.content_files.length - 1}>
                                  <div className="first-line">
                                    {this.returnIconFromFormatFile(
                                      file.format_file
                                    )}
                                    <span
                                      title={file.name_file}
                                      style={{
                                        fontFamily: 'SofiaPro',
                                        fontSize: '12px',
                                        color: '#7d86a1',
                                        marginLeft: '10px'
                                      }}
                                    >
                                      {file.name_file.length > 30
                                        ? `${file.name_file.slice(0, 30)} ...`
                                        : file.name_file}
                                    </span>
                                  </div>
                                  <a
                                    href={file.file.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={file.name_file}
                                  >
                                    <DownloadIcon />
                                  </a>
                                </ContentFile>
                              ))}
                            </div>
                          );
                      }
                    })}
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    moduleActiveNow: state.course.moduleActive
  };
};

const PreviewContentContainer = connect(mapStateToProps, {})(PreviewContent);

export default PreviewContentContainer;
