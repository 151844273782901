const editForm = (props) => {
  if (props.editpostform.elements === undefined) {
    return;
  }

  let formTitle = { title: props.editpostform.title };

  let quizTitleName = [];
  let quizTitleValue = [];
  let quizAnswerId = [];
  let quizAnswerContent = [];

  let surveyTitleName = [];
  let surveyTitleValue = [];
  let surveyAnswerId = [];
  let surveyAnswerContent = [];

  let questionId = [];
  let questionValue = [];

  props.editpostform.elements.forEach((element) => {
    switch (element.kind) {
      case 'quiz':
        quizTitleName.push(`quizTitle_${element.id}`.trim());
        quizTitleValue.push(element.text.trim());

        element.content_survey.forEach((item) => {
          quizAnswerId.push(`quizAnswer_${item.id}`.trim());
          quizAnswerContent.push(item.title.trim());
        });
        break;

      case 'survey':
        surveyTitleName.push(`optionTitle_${element.id}`.trim());
        surveyTitleValue.push(element.text.trim());

        element.content_survey.forEach((item) => {
          surveyAnswerId.push(`optionAnswer_${item.id}`.trim());
          surveyAnswerContent.push(item.title.trim());
        });
        break;

      case 'open_question':
        questionId.push(`questionTitle_${element.id}`);
        questionValue.push(element.content_open_question.question);

      default:
        break;
    }
  });

  let quizTitleFields = {};
  quizTitleName.forEach((k, i) => (quizTitleFields[k] = quizTitleValue[i]));

  let quizAnswerFields = {};
  quizAnswerId.forEach((k, i) => (quizAnswerFields[k] = quizAnswerContent[i]));

  let surveyTitleFields = {};
  surveyTitleName.forEach(
    (k, i) => (surveyTitleFields[k] = surveyTitleValue[i])
  );

  let surveyAnswerFields = {};
  surveyAnswerId.forEach(
    (k, i) => (surveyAnswerFields[k] = surveyAnswerContent[i])
  );

  let questionFields = {};
  questionId.forEach((k, i) => (questionFields[k] = questionValue[i]));

  const defaultFieldsValues = Object.assign(
    formTitle,
    quizTitleFields,
    quizAnswerFields,
    surveyTitleFields,
    surveyAnswerFields,
    questionFields
  );

  props.initialize(defaultFieldsValues);
};

export default editForm;
