export function dialogprogress(
  state = { visible: false, percentagem: 0 },
  action
) {
  if (action.type === 'CLOSE_ALERT') {
    return action.alert;
  }
  if (action.type === 'OPEN_DIALOG_PROGRESS') {
    return action.alert;
  }

  if (action.type === 'SHOW_PROGRESS') {
    return action.alert;
  }
  return state;
}
