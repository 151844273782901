import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import CourseApi from '../../../Logicas/CourseApi';

import CourseSearchPreview from './CourseSearchPreview';
import ComponentHeader from '../../utils/ComponenteHeader';

import { ReactComponent as IconActive } from "./img/iconActive.svg";
import { ReactComponent as IconDraft } from "./img/iconDraft.svg";

import * as S from "./CourseResultsStyle";

require('./courselist.css');

class CourseResults extends Component {
  constructor() {
    super();
    this.state = {
    };
    this.redirectNewCourse = this.redirectNewCourse.bind(this);
    this.editCourseRedirect = this.editCourseRedirect.bind(this);
  }

  componentWillMount() {
    this.props.clearCourseSearch();
    this.props.setCourseSearchNotActive();
  }

  redirectNewCourse() {
    this.props.redirectNewCourse();
    browserHistory.push('/course/create');
  }

  editCourseRedirect(id, typeCourse) {
    localStorage.setItem('IdCourseEdit', id);
    this.props.getHeaderCourseDetails('Iniciados');
    this.props.getUsersCourse(id,'not_finished');
    if (typeCourse === 'long') {
      this.props.editCourseV2(id);
    } else {
      this.props.editCourse(id);
    }
  }

  render() {
    return (
      <React.Fragment>
        <ComponentHeader
          actions={[]}
          name={""}
          nameaction="Novo Curso"
          onClick={this.redirectNewCourse}
          functionSearch={(search)=>this.props.getCoursesBySearch(1,search)}
          clearSearch={this.props.clearCourseSearch}
          setActiveSearch={this.props.setCourseSearchActive}
          setNotActiveSearch={this.props.setCourseSearchNotActive}
          course_search_active={this.props.course_search_active}
        />

        {(this.props.course_search_active) && 
          <CourseSearchPreview 
            coursesAvailable={this.props.list_search_available}
            coursesNotAvailable={this.props.list_search_not_available} 
            editCourse={this.editCourseRedirect}
            functionSearchAll={()=>this.props.getAllCoursesBySearch(1, this.props.search_actual)}
          />
        }

        <S.ContainerSearch>
          <S.ContentSearch> 
            <S.HeaderTable>
              <span>Resultados da pesquisa</span>
              <span>Status</span>
            </S.HeaderTable>
              <S.BodyTable>
                <S.ContainerType>
                  <div className="header-title">
                    <IconActive/>
                    <span>Ativos</span>
                  </div>
                  {
                    this.props.course_all_list_search !== undefined && 
                      this.props.course_all_list_search.length > 0 &&
                      this.props.course_all_list_search.filter(course => course.available === true).length > 0 ?
                      this.props.course_all_list_search.filter(course => course.available === true).map(courseAvaiable => (
                        <S.MiniCardCourse 
                          index={courseAvaiable.id} 
                          onClick={()=>this.editCourseRedirect(courseAvaiable.id, courseAvaiable.kind)}
                        >
                          <S.ContainerImageMiniCard>
                              <S.ImageMiniCard src={courseAvaiable.image.url}/>
                          </S.ContainerImageMiniCard>

                          <S.ContainerTexts>
                              <h4 title={courseAvaiable.title}>
                                  {courseAvaiable.title.length > 80 ? 
                                    `${courseAvaiable.title.slice(0,80)}...` 
                                      : courseAvaiable.title}
                              </h4>
                              <span title={courseAvaiable.description}>
                                  {courseAvaiable.description.length > 100 ? 
                                    `${courseAvaiable.title.slice(0,100)}...` 
                                    : courseAvaiable.description}
                              </span>
                          </S.ContainerTexts>

                          <S.ContainerStatus>
                            <IconActive/>
                          </S.ContainerStatus>
                        </S.MiniCardCourse>
                      ))
                      : (
                        <span className="results-not-found">0 resultados correspondem à sua busca, em cursos ativos.</span>
                      )
                  }
                </S.ContainerType>

                <S.ContainerType>
                  <div className="header-title">
                    <IconDraft/>
                    <span>Em rascunho</span>
                  </div>
                  {
                    this.props.course_all_list_search !== undefined && 
                      this.props.course_all_list_search.length > 0 &&
                      this.props.course_all_list_search.filter(course => course.available === false).length > 0 ?
                      this.props.course_all_list_search.filter(course => course.available === false).map(courseNotAvaiable => (
                        <S.MiniCardCourse 
                          index={courseNotAvaiable.id} 
                          onClick={()=>this.editCourseRedirect(courseNotAvaiable.id, courseNotAvaiable.kind)}
                        >
                          <S.ContainerImageMiniCard>
                              <S.ImageMiniCard src={courseNotAvaiable.image.url} className='image-not-active'/>
                          </S.ContainerImageMiniCard>

                          <S.ContainerTexts>
                              <h4 title={courseNotAvaiable.title}>
                                  {courseNotAvaiable.title.length > 80 ? 
                                    `${courseNotAvaiable.title.slice(0,80)}...` 
                                    : courseNotAvaiable.title}
                              </h4>
                              <span title={courseNotAvaiable.description}>
                                  {courseNotAvaiable.description.length > 100 ? 
                                    `${courseNotAvaiable.title.slice(0,100)}...` 
                                    : courseNotAvaiable.description}
                              </span>
                          </S.ContainerTexts>

                          <S.ContainerStatus>
                            <IconDraft/>
                          </S.ContainerStatus>
                        </S.MiniCardCourse>
                      ))
                      : (
                        <span className="results-not-found">0 resultados correspondem à sua busca, em cursos em rascunho.</span>
                      )
                  }
                </S.ContainerType>
              </S.BodyTable>
          </S.ContentSearch>
        </S.ContainerSearch>
      
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list_search_available: state.course.course_list_search_available.course,
    list_search_not_available: state.course.course_list_search_not_available.course,
    course_all_list_search: state.course.course_all_list_search.course,
    course_search_active: state.course.course_search_active,
    search_actual: state.course.search_actual,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCourses: (page,avaiable) => {
      dispatch(CourseApi.getCourses(page,avaiable));
    },
    getCoursesBySearch: (page, search) => {
      dispatch(CourseApi.getCoursesBySearch(page, search));
    },
    getAllCoursesBySearch: (page, search) => {
      dispatch(CourseApi.getAllCoursesBySearch(page, search));
    },
    setCourseSearchActive: () => {
      dispatch({ type: 'SET_COURSE_SEARCH_ACTIVE' });
    },
    setCourseSearchNotActive: () => {
      dispatch({ type: 'SET_COURSE_SEARCH_NOT_ACTIVE' });
    },
    clearCourseSearch: () => {
      dispatch({ type: 'CLEAR_COURSE_SEARCH' });
    },
    editCourse: (id) => {
      dispatch(CourseApi.editCourse(id));
    },
    editCourseV2: (id) => {
      dispatch(CourseApi.editCourseV2(id, true));
    },
    getHeaderCourseDetails: (titleName) => {
      dispatch(CourseApi.getHeaderCourseDetails(titleName));
    },
    getUsersCourse:(idCourse,kind,page,per_page) =>{
      dispatch(CourseApi.getUsersCourse(idCourse,kind,page,per_page));
    },
    redirectNewCourse: () => {
      dispatch({ type: 'EDIT_COURSE', course: {} });
    }
  };
};
const CourseResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseResults);

export default CourseResultsContainer;
