import React from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { Field } from 'redux-form';
import { DatePicker } from 'material-ui';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { style } from '../../style';
import { locale } from 'yargs';

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: style.inpuStyle.colorInput
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        cursor: 'default',
        borderBottom: 'none !important',
        outline: 'none !important',
        '&:hover': {
          cursor: 'default !important',
          borderBottom: 'none !important',
          outline: 'none !important'
        }
      }
    },
    MuiInput: {
      input: {
        fontFamily: 'Rubik',
        fontSize: '1.75rem'
      }
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Rubik',
        fontSize: '1.75rem'
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        fontSize: '1.75rem'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: '1.75rem',
        backgroundColor: 'red'
      }
    },
    MuiPickersYear: {
      root: {
        fontFamily: 'Rubik',
        fontSize: '1.75rem'
      }
    },
    MuiPickersMonth: {
      root: {
        fontFamily: 'Rubik',
        fontSize: '1.75rem'
      }
    },
    CustomInputBase: {
      root: {
        '&:hover': {
          borderBottom: 'none !important',
          outline: 'none !important',
        }
      }
    }
  }
});

export const MonthPicker = ({ input: { value, onChange } }) => {
  const handleChange = (date) => {
    const newDate = !!date ? new Date(date) : new Date();
    onChange(format(newDate, 'MMM/yyyy'));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <div>
        <ThemeProvider theme={customTheme}>
          <KeyboardDatePicker
            views={['year', 'month']}
            format="MMM/yyyy"
            value={value || null}
            onChange={handleChange}
            cancelLabel="Sair"
            okLabel="Selecionar"
            inputProps={{ readOnly: true }}
            placeholder='Escolha uma data'
          />
        </ThemeProvider>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export class DatePickerForm extends DatePicker {
  render() {
    return <Field component={MonthPicker} input={{value: this.props.selected, onChange:this.props.onChange}}/>;
  }
}
