import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import DialogSegmentation from '../../../../content/SegmentationContent';
import IcoExit from '../../../utils/img/exit-dialog.png';

export class DialogGeneral extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal visible={this.props.visible} width="740px" height="645px">
        <div onClick={this.props.handler} className="body-row-exit">
          <img style={{ height: '12px', width: '12px' }} src={IcoExit} alt="fechar"/>
        </div>
        <DialogSegmentation />
      </Modal>
    );
  }
}

export default DialogGeneral;
