const initialState = {
  get: false,
  post: false,
  delete: false,
  patch: false
};

export const requestError = (state = initialState, action) => {
  if (action.type === 'REQUEST_ERROR_GET') {
    return {
      ...state,
      get: action.payload
    };
  }

  return state;
};
