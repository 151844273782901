const initialState = {
  page: null,
  productList: [],
  historyList: {
    dispatchedProducts: {
      products: [],
      pagination: {}
    },
    notDispatchedProducts: {
      products: [],
      pagination: {}
    }
  },
  editProduct: {},
  productToDispatch: {
    isModalOpen: false,
    cartId: '',
    productData: {},
    userData: {}
  }
};

export function storeList(state = initialState, action) {
  switch (action.type) {
    case 'GET_STORE':
      return {
        ...state,
        productList: action.products,
        page: action.page
      };

    case 'GET_PRODUCTS_DISPATCHED':
      return {
        ...state,
        historyList: {
          ...state.historyList,
          dispatchedProducts: action.payload
        }
      };

    case 'GET_PRODUCTS_NOT_DISPATCHED':
      return {
        ...state,
        historyList: {
          ...state.historyList,
          notDispatchedProducts: action.payload
        }
      };

    case 'EDIT_PRODUCT':
      return { ...state, editProduct: action.product };

    case 'SET_PRODUCT_TO_DISPATCH':
      const requestedProduct = state.historyList.notDispatchedProducts.products.find(
        (product) => product.cart_id === action.payload.cart_id
      );
      return {
        ...state,
        productToDispatch: {
          ...state.productToDispatch,
          cartId: action.payload.cart_id,
          productData: requestedProduct.product,
          userData: requestedProduct.user
        }
      };

    case 'DISPATCH_DIALOG_OPEN':
      return {
        ...state,
        productToDispatch: {
          ...state.productToDispatch,
          isModalOpen: true
        }
      };

    case 'DISPATCH_DIALOG_CLOSE':
      return {
        ...state,
        productToDispatch: {
          ...state.productToDispatch,
          isModalOpen: false,
          productData: {},
          userData: {}
        }
      };

    default:
      return state;
  }
}
