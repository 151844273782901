import React from 'react';
import { DownloadIconStyle } from './DownloadIcon.styles';
import { style as teamStyle } from '../../../../../../style';

const stopColor = `${teamStyle.inpuStyle.colorInput}`;

const DownloadIcon = () => {
  return (
    <DownloadIconStyle viewBox="0 0 32 32" >
      <g id="download">
        <path
          id="Vector"
          d="M28 20V25.3333C28 26.0406 27.719 26.7189 27.219 27.219C26.7189 27.719 26.0406 28 25.3333 28H6.66667C5.95942 28 5.28115 27.719 4.78105 27.219C4.28095 26.7189 4 26.0406 4 25.3333V20"
          stroke="url(#paint0_linear)"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_2"
          d="M9.33301 13.3333L15.9997 19.9999L22.6663 13.3333"
          stroke="url(#paint1_linear)"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Vector_3"
          d="M16 20V4"
          stroke="url(#paint2_linear)"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="28"
          y1="20"
          x2="8.8"
          y2="34.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={stopColor} />
          <stop offset="1" stop-color={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="22.6663"
          y1="13.3333"
          x2="9.33301"
          y2="19.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={stopColor} />
          <stop offset="1" stop-color={stopColor} />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="17"
          y1="4"
          x2="15.7503"
          y2="4.01953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={stopColor} />
          <stop offset="1" stop-color={stopColor} />
        </linearGradient>
      </defs>
    </DownloadIconStyle>
  );
};

export default DownloadIcon;
