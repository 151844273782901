export const dateMask = (val) => {
  if (!val) {
    return val;
  }

  const date = val.replace(/[^\d]/g, '');

  if (date.length <= 2) {
    return date;
  }

  if (date.length <= 4) {
    return `${date.slice(0, 2)}/${date.slice(2)}`;
  }

  return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4, 8)}`;
};

export const phoneMask = (val) => {
  if (!val) {
    return val;
  }

  const phone = val.replace(/[^\d]/g, '');

  if (phone.length <= 2) {
    return `(${phone.slice(0, 2)}`;
  }

  if (phone.length <= 6) {
    return `(${phone.slice(0, 2)})${phone.slice(2, 6)}`;
  }

  if (phone.length <= 10) {
    return `(${phone.slice(0, 2)})${phone.slice(2, 6)}-${phone.slice(6, 10)}`;
  }

  return `(${phone.slice(0, 2)})${phone.slice(2, 7)}-${phone.slice(7, 11)}`;
};

export const cepMask = (val) => {
  if (!val) {
    return val;
  }

  const phone = val.replace(/[^\d]/g, '');

  if (phone.length <= 5) {
    return `${phone.slice(0, 5)}`;
  }

  return `${phone.slice(0, 5)}-${phone.slice(5, 8)}`;

}
