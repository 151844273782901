import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectorApi from '../../../../Logicas/SectorApi';
import SearchIcon from './SearchIcon';
import IcoEdit from '../../../utils/img/ic-editar.svg';
import RemoveIcon from '../../../utils/img/ic-excluir.svg';

import * as S from './SectorSearch.style';

export class SectorSearch extends Component {
  constructor() {
    super();
    this.state = {
      filteredSectors: [],
      isFocus: false
    };
  }

  filterSectors = (sectorName) => {
    const sectorsList = this.props.list;
    let filteredSectors = [];

    filteredSectors = sectorsList.filter((sector) => {
      return sector.name_sector.toLowerCase().search(sectorName) !== -1;
    });

    this.setState({ filteredSectors });
  };

  setFocusTrue = () => {
    this.setState({ filteredSectors: this.props.list });
    this.setState({ isFocus: true });
  };

  setFocusFalse = () => {
    this.setState({ isFocus: false });
  };

  render() {
    return (
      <>
        <S.InputWrapper isFocus={this.state.isFocus}>
          <S.Input
            onChange={(e) => this.filterSectors(e.target.value)}
            onFocus={this.setFocusTrue}
            isFocus={this.state.isFocus}
            placeholder="Busque pelo nome do setor"
          />
          <S.Button isFocus={this.state.isFocus}>
            <SearchIcon />
          </S.Button>
          <S.SectorWrapper isFocus={this.state.isFocus}>
            <S.SectorList>
              {this.props.loadingSector && (
                <S.NoResults>
                  <S.NoResultsText>Carregando setores...</S.NoResultsText>
                </S.NoResults>
              )}
              {this.state.filteredSectors.length > 0 ? (
                this.state.filteredSectors.map((sector) => (
                  <S.SectorListItem key={sector.id_sector}>
                    <S.SectorName>{sector.name_sector}</S.SectorName>
                    <div>
                      <S.SectorEdit
                        src={IcoEdit}
                        alt="Editar setor"
                        onClick={() => {
                          this.props.editSector(sector.id_sector);
                        }}
                      />
                      <S.SectorEdit
                        src={RemoveIcon}
                        alt="Apagar setor"
                        onClick={() => {
                          this.props.deleteSector(
                            sector.id_sector,
                            sector.associated_users
                          );
                        }}
                      />
                    </div>
                  </S.SectorListItem>
                ))
              ) : (
                <S.NoResults>
                  <S.NoResultsText>Sem resultados</S.NoResultsText>
                </S.NoResults>
              )}
            </S.SectorList>
          </S.SectorWrapper>
        </S.InputWrapper>
        <S.SectorOverlay
          isFocus={this.state.isFocus}
          onClick={this.setFocusFalse}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.sectorReducer.sectorList,
    alert: state.dialog,
    erroalert: state.erroalert,
    loadingSectors: state.sectorReducer.loadingSectors
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSectorList: () => {
      dispatch(SectorApi.getSectorUser());
    },
    openDialog: () => {
      dispatch({ type: 'SHOW_MODAL_INSERT_TAG' });
    },
    openDialogDelete: () => {
      dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
    }
  };
};
const SectorSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectorSearch);

export default SectorSearchContainer;
