import React, { Component } from 'react';

import * as S from './ToolsButton.styles';

export class ToolsButton extends Component {
  render() {
    return (
      <S.ButtonBody
        className="btn-click"
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        isDisabled={this.props.disabled}
        type="button"
        style={this.props.style}
      >
        <S.ButtonLabel>{this.props.label}</S.ButtonLabel>
      </S.ButtonBody>
    );
  }
}

export default ToolsButton;
