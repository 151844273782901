import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Modal from 'react-awesome-modal';
import { MuiThemeProvider } from 'material-ui/styles';
import ErroImg from '../../../utils/img/ic-erro@3x.png';
import {
  Table,
  TableBody,
  TableHeaderColumn,
  TableRow
} from 'material-ui/Table';
import ExitDialog from '../../../utils/img/exit-dialog.png';

require('./dialogerrors.css');

export default class DialogErrors extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <MuiThemeProvider>
        <Modal width="40%" height="70%" visible={this.props.visible}>
          <div className="header-exit">
            <img
              onClick={this.props.clear}
              className="img-exit-import"
              src={ExitDialog}
              alt="fechar"
            />
          </div>
          <div className="modal-header-validation">
            <img style={{ width: '190px', height: '139px' }} src={ErroImg} alt="ícone erro"/>
            <div className="main-label-import">Erro na importação</div>
            <div className="secondary-label-import">
              A planilha não foi importada. Corrija os erros abaixo e reenvie a
              planilha.{' '}
            </div>
            <Table height="200px">
              <TableBody displayRowCheckbox={false}>
                {Array.isArray(this.props.errors)
                  ? this.props.errors.map(function(error, index) {
                      return (
                        <TableRow key={index}>
                          <TableHeaderColumn>
                            <p className='error-description'>
                              - {error}
                            </p>
                          </TableHeaderColumn>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </div>
        </Modal>
      </MuiThemeProvider>
    );
  }
}
