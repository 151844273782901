import styled from 'styled-components';

export const ToggleGroup = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 48px;
  margin-top: 24px;
`;

export const ToggleContainer = styled.div``;
