import styled from 'styled-components';
import { style } from '../../../../style';

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px 64px 0px;
  overflow: auto;
  position: relative;
`;

export const ModalTitle = styled.h5`
  font-family: Rubik, sans-serif;
  font-size: 18px;

  & > span {
    color: ${style.inpuStyle.colorInput};
  }
`;

export const QuestionContent = styled.div`
  width: 100%;
  // background: blue;

  >span{
    font-family: Rubik;
    font-size: 14px;
    opacity: 0.7;
  }
`

export const AnswersContainer = styled.div`
    // min-height: 100px;
    width: 100%;
    margin-top: 30px;
    // background: blue;

    padding-bottom: 30px;
    border-bottom: 1px solid #E4E4E5;

    >span{
      font-family: Rubik;
        font-size: 14px;
        opacity: 0.7;
    }

`

export const AnswerContent = styled.div`
    width: 100%;
    min-height: 20px;
    padding: 5px;
    border-radius: 5px;
    background: #E6EAF3;

    font-family: Rubik;
    font-size: 16px;

    display: flex;
    align-items: center;

    >span{
        width: 90%;
        height: 100%;
        // background: green;

        display: flex;
        align-items: center;
    }

    .percentage{
        width: 10%;
        height: 100%;
        // background: blue;
        color: ${style.inpuStyle.colorInput};

        display: flex;
        align-items: center;
    }
`

export const UserList = styled.ul`
  // height: calc(100% - 32px);
  min-height: 20px;
  margin-top: 16px;
  padding-inline-start: 0;
`;

export const UserName = styled.li`
  padding: 4px 8px;
  margin: 0;
  font-family: Rubik, sans-serif;
  font-size: 14px;

  &:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
`;

export const CloseButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 0;
  appearance: none !important;
  background-color: transparent;
  border: 0;

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.5px;
    height: 20px;
    background-color: #d8d8d8;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    content: '';
  }

  &:after {
    transform: rotate(-45deg) translate(-50%, -50%);
  }

  &:hover {
    filter: brightness(0.66);
  }
`;

export const Button = styled.button`
  background-color: #fff;
  border: 0;
  cursor: pointer;
  pointer-events: none;
  font-size: 24px;
  height: 40px;
  width: 50px;
  z-index: 2;
  vertical-align: top;

  &:focus {
    outline: none;
  }
`;

export const Input = styled.input.attrs({ type: 'text' })`
  width: 100%;
  height: 40px;
  border: 0;
  padding: 5px;
  background-color: #fff;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  z-index: 2;
  vertical-align: top;

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 0.66;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  margin-top:30px;
  margin-bottom: 30px;
`;
