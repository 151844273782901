import React, { Component } from 'react';
import { style } from '../../../style';
import styled from 'styled-components';

const ButtonBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-left: 30px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 15px;
  transition: 0.15s all;
  background-color: transparent;
`;

const ButtonText = styled.div`
  height: 18px;
  margin-left: 16px;
  margin-bottom: 6px;
  font-family: Rubik;
  font-size: 15px;
  text-align: center;
  vertical-align: text-top;
  color: #808080;

  ${ButtonBody}:hover & {
    color: ${style.inpuStyle.colorInput};
  }
`;

const ArrowIcon = styled.svg`
  width: 16px;
  fill: #808080;

  ${ButtonBody}:hover & {
    fill: ${style.inpuStyle.colorInput};
  }
`;

export class BackButton extends Component {
  render() {
    return (
      <ButtonBody>
        <ArrowIcon
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
        </ArrowIcon>
        <ButtonText>Voltar</ButtonText>
      </ButtonBody>
    );
  }
}

export default BackButton;
