export function load_content_requisition(state = false, action) {
  if (action.type === 'SUBMITTING_CONTENT') {
    return { visible: true, id: action.id };
  }
  if (action.type === 'NO_SUBMITTING_CONTENT') {
    return { visible: false, id: action.id };
  }

  return { visible: false };
}
