import React, { Component } from 'react';
import EditContentText from './EditContentText';
import EditContentImage from './EditContentImage';
import EditContentVideo from './EditContentVideo';
import EditContentQuiz from './EditContentQuiz';
export default class EditContent extends Component {
  constructor() {
    super();
    this.state = { courses: [], content: null };
  }

  render() {
    return (
      <div>
        {this.props.content.kindcontent === 'text' ? (
          <EditContentText content={this.props.content} />
        ) : (
          undefined
        )}
        {this.props.content.kindcontent === 'image' ? (
          <EditContentImage content={this.props.content} />
        ) : (
          undefined
        )}
        {this.props.content.kindcontent === 'video' ? (
          <EditContentVideo content={this.props.content} />
        ) : (
          undefined
        )}
        {this.props.content.kindcontent === 'quiz' ? (
          <EditContentQuiz content={this.props.content} />
        ) : (
          undefined
        )}
      </div>
    );
  }
}
