import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEditAcessFile,
  RemoveEditAcess
} from '../utils/Table';
import { connect } from 'react-redux';
import { BodyTable } from '../utils/BodyTable';
import ComponentHeader from '../utils/ComponenteHeader';
import moment from 'moment-timezone';
import { browserHistory } from 'react-router';
import RepositorieApi from '../../Logicas/RepositoryApi';
import { DialogYesNo } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import ArrowBack from './Components/ArrowBack';
import IcoFolder from '../utils/img/ic-pasta.png';

class FileListRepository extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { list } = this.props;
    if (!list || (list.repositories && !list.repositories.length))
      browserHistory.push('/files/folders');
  }

  render() {
    const { parent_id, folder_id, folder_name } = this.props.list;

    return (
      <div>
        <ComponentHeader
          actions={[]}
          name="Arquivos"
          nameaction="Adicionar arquivo"
          onClick={function() {
            browserHistory.push('/file/create');
          }}
          namesecondaction="Adicionar pasta"
          secondOnClick={function() {
            browserHistory.push({
              pathname: '/folder/create',
              state: { parent_id: folder_id }
            });
          }}
        />
        <div className="container">
          {folder_name && (
            <div className="row" style={{ marginLeft: 30, marginTop: 15 }}>
              <div style={{ marginRight: 5 }}>
                <img src={IcoFolder} alt="ícone pasta" />
              </div>
              <div className="col-md-11">
                <div>
                  {folder_name.length > 80
                    ? `${folder_name.slice(0, 80)}...`
                    : folder_name}
                </div>
              </div>
            </div>
          )}

          <div
            onClick={() =>
              parent_id
                ? this.props.getFiles(parent_id)
                : browserHistory.push('/files/folders')
            }
          >
            <ArrowBack />
          </div>
          <BodyTable>
            <Table>
              <TableHead>
                <TableHeadRow>
                  <label>Id{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Nome{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Formato{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Criado em{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Criado por{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Ações{this.state.oderico}</label>
                </TableHeadRow>
              </TableHead>
              <TableBody>
                {Array.isArray(this.props.list.subfolders)
                  ? this.props.list.subfolders.map(
                      function(folder, i) {
                        return (
                          <TableRow value={i}>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {folder.id}
                              </div>
                            </TableData>
                            <TableData>
                              <div className="row" style={{ margin: 0 }}>
                                <img
                                  style={{
                                    marginTop: '4px',
                                    marginRight: 10
                                  }}
                                  src={IcoFolder}
                                  alt="ícone pasta"
                                />
                                <div style={{ marginTop: '10px' }}>
                                  {folder.name_folder.length > 80
                                    ? `${folder.name_folder.slice(0, 80)}...`
                                    : folder.name_folder}
                                </div>
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>Pasta</div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {moment
                                  .tz(folder.created_at, 'Europe/Berlin')
                                  .format('DD/MM/YYYY')}
                              </div>
                            </TableData>
                            <TableData>
                              {folder.user !== undefined ? (
                                <div style={{ marginTop: '10px' }}>
                                  {folder.user.name}
                                </div>
                              ) : (
                                undefined
                              )}
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                <RemoveEditAcess
                                  Delete={this.props.deleteShowDialogFolder.bind(
                                    this,
                                    folder.id
                                  )}
                                  Show={this.props.getFiles.bind(
                                    this,
                                    folder.id
                                  )}
                                  Edit={this.props.editFolder.bind(
                                    this,
                                    folder.id
                                  )}
                                />
                              </div>
                            </TableData>
                          </TableRow>
                        );
                      }.bind(this)
                    )
                  : undefined}
                {Array.isArray(this.props.list.files)
                  ? this.props.list.files.map(
                      function(file, i) {
                        return (
                          <TableRow value={i}>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>{file.id}</div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {file.name_file.length > 80
                                  ? `${file.name_file.slice(0, 80)}...`
                                  : file.name_file}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {file.format_file}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {moment
                                  .tz(file.created_at, 'Europe/Berlin')
                                  .format('DD/MM/YYYY')}
                              </div>
                            </TableData>
                            <TableData>
                              {file.user !== undefined ? (
                                <div style={{ marginTop: '10px' }}>
                                  {file.user.name}
                                </div>
                              ) : (
                                undefined
                              )}
                            </TableData>
                            <TableData>
                              {file.file ? (
                                file.file.url !== null ? (
                                  <div style={{ marginTop: '10px' }}>
                                    <RemoveEditAcessFile
                                      Delete={this.props.deleteShowDialogFile.bind(
                                        this,
                                        file.id
                                      )}
                                      Edit={this.props.Edit.bind(
                                        this,
                                        folder_id,
                                        file.id
                                      )}
                                      filelink={file.file.url}
                                    />
                                  </div>
                                ) : (
                                  <div style={{ marginTop: '10px' }}>
                                    <RemoveEditAcessFile
                                      Delete={this.props.deleteShowDialogFile.bind(
                                        this,
                                        file.id
                                      )}
                                      Edit={this.props.Edit.bind(
                                        this,
                                        folder_id,
                                        file.id
                                      )}
                                      filelink={file.link}
                                    />
                                  </div>
                                )
                              ) : (
                                undefined
                              )}
                            </TableData>
                          </TableRow>
                        );
                      }.bind(this)
                    )
                  : undefined}
              </TableBody>
            </Table>
          </BodyTable>
        </div>
        <DialogYesNo
          yesclick={
            this.props.alert.is_folder
              ? this.props.deleteFolder.bind(
                  this,
                  this.props.alert.id_elemente,
                  true,
                  folder_id
                )
              : this.props.Delete.bind(
                  this,
                  folder_id,
                  this.props.alert.id_elemente
                )
          }
          noclick={this.props.closeDialog}
          onClick={this.props.closeDialog}
          visible={this.props.alert.visible}
          message="Tem certeza que deseja remover este arquivo ?"
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    list: state.repositories,
    alert: state.dialog,
    pagination: state.pagination
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    Edit: (values, idfile, id) => {
      dispatch(RepositorieApi.getEditFiles(values, idfile));
    },
    Delete: (folder, file) => {
      dispatch(RepositorieApi.deleteFile(folder, file));
    },
    deleteShowDialogFile: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    getFolders: (page, per_page) => {
      dispatch(RepositorieApi.getFolders(page, per_page));
    },
    editFolder: (id, values) => {
      dispatch(RepositorieApi.editFolder(id));
    },
    getFiles: (id) => {
      dispatch(RepositorieApi.getFiles(id));
    },
    deleteShowDialogFolder: (values, id) => {
      dispatch(DialogApi.deleteAlert(values, undefined, undefined, true));
    },
    deleteFolder: (values, is_subfolder, parent_id) => {
      dispatch(RepositorieApi.deleteFolder(values, is_subfolder, parent_id));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    }
  };
};
const FoldersListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileListRepository);

export default FoldersListContainer;
