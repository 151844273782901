import styled from 'styled-components';

const fontFamily = { fontFamily: 'Rubik, sans-serif' };

export const TagsComponentHeader = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
`;

export const TagsComponentHeaderTitle = styled.div``;

export const TagsComponentHeaderTitleValue = styled.h1`
  ${fontFamily};
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
  padding: 20px;
`;

export const TagsComponentHeaderButtons = styled.div`
  display: flex;
  align-items: center;

  .btn-click:hover {
    font-size: inherit;
  }
`;

export const TagsContentBody = styled.div``;

export const TagsContentBodySection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &:not(:first-of-type) {
    margin-top: 48px;
  }
`;
