import React, { Component } from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import IconEmoji from '../img/ic-emoji.png'

import * as S from './EmojiPicker.styles';

const i18nEmoji = {
  search: 'Pesquisa',
  clear: 'Limpar',
  notfound: 'Nenhum emoji encontrado',
  skintext: 'Escolha o seu tom de pele padrão',
  categories: {
    search: 'Resultados da busca',
    recent: 'Usados frequentemente',
    smileys: 'Carinhas e Emoções',
    people: 'Pessoas & Corpos',
    nature: 'Animais & Natureza',
    foods: 'Comidas & Bebidas',
    activity: 'Atividades',
    places: 'Viagem & Lugares',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    custom: 'Personalizadas'
  },
  categorieslabel: 'Categorias de emoji',
  skintones: {
    1: 'Tom de pele padrão',
    2: 'Tom de pele claro',
    3: 'Tom de pele médio-claro',
    4: 'Tom de pele médio',
    5: 'Tom de pele médio escuro',
    6: 'Tom de pele escuro'
  }
};

class EmojiPicker extends Component {
  constructor() {
    super();
    this.state = {
      selectorActive: false
    };
  }

  showEmojiSelector = () => {
    !this.props.blockEmojiPicker &&
      this.setState(() => ({
        selectorActive: !this.state.selectorActive
      }));
  };

  addEmoji = (event) => {
    const element = this.props.element;
    let emoji = event.native;
    this.insertAtCursor(element, emoji);
  };

  insertAtCursor = (textArea, emoji) => {
    const propToChange = this.props.propToChange;
    const textField = document.querySelector(textArea).previousElementSibling;
    const strPos = this.props.cursorPosition;

    const front = textField.value.substring(0, strPos);
    const back = textField.value.substring(strPos, textField.value.length);
    textField.value = front + emoji + back;

    this.props.parentProps.dispatch(
      this.props.parentProps.change(propToChange, textField.value)
    );
  };

  render() {
    const emojiSelectorStyles = {
      position: 'absolute',
      transition: '.15s all ease',
      opacity: this.state.selectorActive ? '1' : '0',
      transformOrigin: 'top left',
      top: '80px',
      zIndex: 999
    };

    return (
      <>
        <S.SelectorContainer>
          <S.EmojiButton
            selectorActive={this.state.selectorActive}
            onClick={this.showEmojiSelector}
          >
            <img role="img" aria-label="Rosto sorridente" style={{marginTop:"17px", height:"25px"}} src={IconEmoji}>
            </img>
          </S.EmojiButton>
          {this.state.selectorActive && 
            <Picker
              showPreview={false}
              showSkinTones={false}
              i18n={i18nEmoji}
              style={emojiSelectorStyles}
              onSelect={this.addEmoji}
              exclude={['search', 'recent']}
            />
          }
        </S.SelectorContainer>
        <S.SelectorOverlay
          selectorActive={this.state.selectorActive}
          onClick={() => this.setState({ selectorActive: false })}
        />
      </>
    );
  }
}

export default EmojiPicker;
