export function post_comments(state = { data: [] }, action) {
  if (action.type === 'GET_COMMENTS') {
    if (!action.reset) {
      if (action.data.length >= 1) {
        action.data.map(function(comments) {
          state.data.push(comments);
        });
      } else {
        state.data = [];
      }
    } else {
      state.data = action.data;
    }

    return {
      data: state.data,
      id_post: action.post_id,
      visible: action.visible
    };
  }

  if (action.type === 'DELETE_COMMENTS') {
    let comments = [];
    state.data.map(function(comment) {
      if (comment.id !== action.id) {
        comments.push(comment);
      }
    });
    return { data: comments, id_post: action.post_id, visible: action.visible };
  }

  return state;
}
