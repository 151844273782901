import React, { Component } from 'react';

import * as S from './styles';

export class SelectedTagsList extends Component {
  render() {
    return (
      <S.TagsList>
        {this.props.selectedTags.map((tag) => (
          <S.TagItem key={tag.id}>{tag.name}</S.TagItem>
        ))}
      </S.TagsList>
    );
  }
}

export default SelectedTagsList;
