import styled from 'styled-components';

export const AnswersContainer = styled.section`
  width: 100%;
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;

  & > div:not(:last-of-type) {
    margin-bottom: 32px;
    padding-bottom: 32px;
    // border-bottom: 1px solid #d1d4e3;
  }
`;
