import React, { Component } from 'react';
import Chip from 'material-ui/Chip';
import { connect } from 'react-redux';
import PostApi from '../../Logicas/PostApi';
import { browserHistory } from 'react-router';

import DialogApi from '../../Logicas/DialogApi';
import * as PostApiAsync from '../../Logicas/PostApiAsync';
import UserApi from '../../Logicas/UserApi';

import DetailsDraw from './DetailsDraw';
import DownloadPostData from '../_shared/DownloadPostData/DownloadPostData';
import { BodyTable } from '../utils/BodyTable';
import ComponentHeader from '../utils/ComponenteHeader';
import { DialogYesNo } from '../utils/DailogComponentes';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEdit
} from '../utils/Table';
import Pagination from '../utils/AutoPagination/Pagination';
import Loader from '../utils/AutoPagination/Loader';

import not_img from '../utils/img/ico-external.png';
import { FooterPagination } from '../utils/Pagination';
import { style } from '../../style';

class PostListDraw extends Component {
  constructor() {
    super();
    this.redirectNewPost = this.redirectNewPost.bind(this);
  }

  redirectNewPost() {
    const { setTypeDraw, openDrawModal, getTypeForm } = this.props;
    setTypeDraw();
    openDrawModal();
    getTypeForm(false);
    browserHistory.push('/post/create');
  }

  componentDidMount() {
    !this.props.list.publications && this.props.getPostList('draw', 'raffle');
  }

  componentWillMount() {
    if(sessionStorage.getItem("@Noz-hasMoodSurvey") === null)
      this.props.getMoodSurveyFromProfileAPI();
    else
      this.props.setMoodSurveyFromState(
        sessionStorage.getItem("@Noz-hasMoodSurvey") === "true" ? true : false
      )
  }

  render() {
    const { listRequestsActive } = this.props;
    if (this.props.list) {
      return (
        <div>
          <ComponentHeader
            onClick={this.redirectNewPost}
            tooltip={
              <p>
                Atualizar publicação
                <br />
              </p>
            }
            actions={
              this.props.hasMoodSurveyActive
                ? [
                    {
                      name: 'Enquetes',
                      action: this.props.getSurveyPublication
                    },
                    {
                      name: 'Formulários',
                      action: () => this.props.getPostList('form', '')
                    },
                    {
                      name: 'Publicações',
                      action: () => this.props.getPostList('publication', '')
                    },
                    {
                      name: 'Termômetro de humor',
                      action: () => this.props.getMoodSurveys(),
                      style: { width: '11rem', textAlign: 'center' }
                    },
                    {
                      name: 'Rascunhos',
                      action: this.props.getDraftPublication
                    }
                  ]
                : [
                    {
                      name: 'Enquetes',
                      action: this.props.getSurveyPublication
                    },
                    {
                      name: 'Formulários',
                      action: () => this.props.getPostList('form', '')
                    },
                    {
                      name: 'Publicações',
                      action: () => this.props.getPostList('publication', '')
                    },
                    {
                      name: 'Rascunhos',
                      action: this.props.getDraftPublication
                    }
                  ]
            }
            name="Sorteios"
            nameaction="Novo sorteio"
            withMoodSurvey
            enablesubmit={listRequestsActive.length > 0}
          />
          <DownloadPostData kind="publication" />
          <BodyTable>
            <Table>
              <TableHead>
                <TableHeadRow>Id</TableHeadRow>
                <TableHeadRow>Publicação</TableHeadRow>
                <TableHeadRow></TableHeadRow>
                <TableHeadRow>Tags</TableHeadRow>
                <TableHeadRow></TableHeadRow>
                <TableHeadRow>Curtidas</TableHeadRow>
                <TableHeadRow>Visualizações</TableHeadRow>
                <TableHeadRow>Data</TableHeadRow>
                <TableHeadRow>Ações</TableHeadRow>
              </TableHead>

              <TableBody>
                {this.props.list.publications &&
                  this.props.list.publications.map(
                    function(publication, i) {
                      return (
                        <TableRow key={i} onClik={this.props.editUser}>
                          <TableData>
                            <div
                              style={{
                                marginTop: '30px'
                              }}
                            >
                              {publication.id}
                            </div>
                          </TableData>
                          <TableData>
                            <div
                              style={{
                                marginTop: '30px'
                              }}
                            >
                              {publication.title.length > 30
                                ? publication.title.slice(0, 30) + '...'
                                : publication.title}
                            </div>
                          </TableData>
                          <TableData>
                            <div>
                              {publication.cover ? (
                                publication.cover.kind === 'video' ? (
                                  <video
                                    src={publication.cover.url}
                                    style={{
                                      width: '100px',
                                      height: '60px',
                                      'object-fit': 'cover'
                                    }}
                                    width="100%"
                                    preload
                                    loop
                                    muted
                                    controls
                                    controlsList="nodownload"
                                    alt="conteúdo de vídeo"
                                  />
                                ) : (
                                  <img
                                    src={
                                      publication.cover.url
                                        ? publication.cover.url
                                        : not_img
                                    }
                                    style={{
                                      width: '100px',
                                      height: '60px',
                                      'object-fit': 'cover'
                                    }}
                                    alt="Capa da publicação"
                                  />
                                )
                              ) : (
                                <img
                                  src={not_img}
                                  style={{
                                    width: '100px',
                                    height: '60px',
                                    'object-fit': 'cover'
                                  }}
                                  alt="Ícone de publicação sem capa"
                                />
                              )}
                            </div>
                          </TableData>
                          <TableData>
                            <div className="tags">
                              <Chip>
                                {publication.tags.length > 0
                                  ? publication.tags[0].name.length > 30
                                    ? publication.tags[0].name.substring(
                                        0,
                                        20
                                      ) + '...'
                                    : publication.tags[0].name
                                  : null}
                              </Chip>
                              {publication.tags.length > 1 && (
                                <Chip>{publication.tags.length - 1}</Chip>
                              )}
                            </div>
                          </TableData>
                          <TableData>
                            <div
                              style={{
                                marginTop: '15px'
                              }}
                            >
                              <Chip> {publication.tag_main}</Chip>
                            </div>
                          </TableData>
                          <TableData>
                            <div
                              style={{
                                marginTop: '30px'
                              }}
                            >
                              {publication.likes}
                            </div>
                          </TableData>
                          <TableData>
                            <div
                              style={{
                                marginTop: '30px'
                              }}
                            >
                              {publication.visualization}
                            </div>
                          </TableData>

                          <TableData>
                            <div
                              style={{
                                marginTop: '30px'
                              }}
                            >
                              {publication.created_at === 'Invalid date'
                                ? 'Aguardando publicação'
                                : publication.created_at}
                            </div>
                            <br />
                            <label>{publication.user_name}</label>
                          </TableData>
                          <TableData>
                            <div
                              style={{
                                marginTop: '20px'
                              }}
                            >
                              <RemoveEdit
                                Delete={this.props.deleteShowDialogPublication.bind(
                                  this,
                                  publication.id,
                                  publication.is_publication
                                )}
                                Edition={() => {
                                  this.props.editPost(
                                    publication.id,
                                    publication.is_publication
                                  );
                                  this.props.setNotificationShow();
                                }}
                                edit={false}
                                View={this.props.getDetailsDraw.bind(
                                  this,
                                  publication.id,
                                  publication.is_publication
                                )}
                                enablesubmit={listRequestsActive.length > 0}
                              />
                            </div>
                          </TableData>
                        </TableRow>
                      );
                    }.bind(this)
                  )}
              </TableBody>
            </Table>
            {this.props.list.size > 0 && (
              <footer className="footer-pagination">
                <FooterPagination
                  activePage={this.props.page}
                  totalItemsCount={this.props.list.size}
                  handlePageChange={(page) =>
                    this.props.getMorePublications(page)
                  }
                />
              </footer>
            )}
          </BodyTable>
          <Loader loading={this.props.loading} />
          <DialogYesNo
            yesclick={this.props.deletePost.bind(
              this,
              this.props.alert.id_elemente,
              this.props.alert.is_publication
            )}
            noclick={this.props.closeDialog}
            onClick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message="Tem certeza que deseja remover esta publicação ?"
          />
          {this.props.showDrawResults && <DetailsDraw />}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.post.list,
    page: state.post.page,
    alert: state.dialog,
    pagination: state.post.pagination,
    listRequestsActive: state.post.listRequestsActive,
    loading: state.progress_indeterminate,
    showDrawResults: state.draw.showDrawResults,
    hasMoodSurveyActive: state.user.hasMoodSurveyActive,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPostList: (kind, raffle) => {
      dispatch(PostApi.getPostListV2(kind, raffle));
    },
    getMorePublications: (page, kind, raffle) => {
      dispatch(PostApiAsync.getMorePublications(page, '', 'raffle'));
    },
    deleteShowDialogPublication: (id, values) => {
      dispatch(DialogApi.deleteAlert(id));
    },
    editPost: (id) => {
      dispatch(PostApi.editPost(id));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deletePost: (id, values) => {
      dispatch(PostApi.deletePost(id, values, true));
    },
    getDraftPublication: () => {
      dispatch(PostApi.getPostListDraft());
    },
    getSurveyPublication: () => {
      dispatch(PostApi.getSurveyList(1));
    },
    getMoodSurveys: (page = 1, active) => {
      dispatch(PostApi.getMoodSurveys(page, active));
    },
    setTypeDraw: () => {
      dispatch({ type: 'GET_TYPE_DRAW', isDrawType: true });
    },
    getTypeForm: (isForm) => {
      dispatch(PostApi.getTypeForm(isForm));
    },
    getDetailsDraw: (idContent, values) => {
      dispatch(PostApi.getDetailsDraw(idContent, values));
    },
    openDrawModal: () => {
      dispatch({ type: 'DRAW_MODAL_OPEN' });
    },
    setNotificationShow: () => {
      dispatch({ type: 'ENABLE_NOTIFICATION_SHOW' });
    },
    getMoodSurveyFromProfileAPI: () => {
      dispatch(UserApi.getMoodSurveyFromProfileAPI());
    },
    setMoodSurveyFromState: (hasMoodSurveyActive) => {
      dispatch({
        type: 'SET_MOOD_SURVEY_ACTIVE',
        hasMoodSurveyActive,
      });
    }
  };
};
const PostListDrawContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostListDraw);

export default PostListDrawContainer;
