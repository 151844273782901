import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData
} from '../utils/Table';
import { BodyTable } from '../utils/BodyTable';
import { connect } from 'react-redux';
import TagApi from '../../Logicas/TagApi';
import { Toggle } from 'material-ui';
import IcoEdit from '../utils/img/ic-editar-gray.svg';
import { FooterPagination } from '../utils/Pagination';
import DialogTagForm from '../utils/DailogComponentes';
import { DialogAlert } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import TagsHeader from './Components/TagsHeader/TagsHeader';
require('./tag.css');

class TagList extends Component {
  constructor() {
    super();
    this.state = {
      init: 0,
      activePage: 1,
      arrayToggleAux: []
    };
  }

  componentWillMount() {
    this.props.getTags(1, '', 'available');
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.list) !== JSON.stringify(prevProps.list)) {
      this.setState({arrayToggleAux: []});
    }
  }

  render() {
    const styles = {
      block: {
        maxWidth: 250
      },
      toggle: {
        marginBottom: 16
      },
      thumbOff: {
        backgroundColor: '#ffffff'
      },
      trackOff: {
        backgroundColor: '#FF3232'
      },
      thumbSwitched: {
        backgroundColor: '#ffffff'
      },
      trackSwitched: {
        backgroundColor: '#2E63FB'
      },
      labelStyle: {
        color: 'red'
      }
    };
    const {
      tagMergeModalOpen: { isOpen, message },
      closeMergeModal
    } = this.props;
    return (
      <div>
        <TagsHeader />
        <div
          style={{
            'border-top': 'solid 1px #cccccc',
            'border-bottom': 'solid 1px #cccccc'
          }}
        >
          <div
            className="body-card"
            style={{
              width: '90%',
              padding: '0',
              height: '75px',
              marginLeft: 'auto',
              marginRight: 'auto',
              border: 'none',
              minWidth: '200px'
            }}
          >
            <div
              className="card-product-column"
              style={{ fontSize: '18px', fontWeight: '600' }}
            >
              Id
            </div>
            <div
              className="card-product-column"
              style={{ fontSize: '18px', fontWeight: '600', minWidth: '190px' }}
            >
              Tag
            </div>
            <div
              className="card-product-column"
              style={{ fontSize: '18px', fontWeight: '600', minWidth: '190px' }}
            >
              Nome
            </div>
            <div
              className="card-product-column"
              style={{ fontSize: '18px', fontWeight: '600' }}
            >
              Usuários
            </div>
            <div
              className="card-product-column"
              style={{ fontSize: '18px', fontWeight: '600' }}
            >
              Status
            </div>
            <div
              className="card-product-column"
              style={{ fontSize: '18px', fontWeight: '600' }}
            >
              Ações
            </div>
          </div>
        </div>
        <BodyTable isDispatchProducts={true}>
          {/* <Table> */}
          {/* <TableHead>
              <TableHeadRow>
                <label>Id</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Tag</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Nome exibido</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Usuários Associados</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Status</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Ação</label>
              </TableHeadRow>
            </TableHead> */}

          {/* <TableBody> */}
          <section>
            <div
              className="tab__waiting-confirmation"
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              {this.props.list.length > 0 ? (
                this.props.list.map((tag) => {
                  const stateTag = this.state.arrayToggleAux.find(
                    (object) => object.id === tag.id
                  );
                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: '100%',
                        margin: 'auto'
                      }}
                      className="tableRow"
                    >
                      <div
                        className="body-card"
                        style={{
                          width: '90%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          margin: '0',
                          border: 'none'
                        }}
                      >
                        <div className="card-product-column">
                          <div>{tag.id}</div>
                        </div>
                        <div
                          className="card-product-column"
                          style={{ minWidth: '200px' }}
                        >
                          <div>
                            {tag.name} <br />
                            {tag.description !== 'undefined'
                              ? tag.description
                              : undefined}
                          </div>
                        </div>
                        <div
                          className="card-product-column"
                          style={{ minWidth: '200px' }}
                        >
                          <div>{'#' + tag.name}</div>
                        </div>
                        <div className="card-product-column">
                          <div>{tag.users_tag}</div>
                        </div>
                        <div className="card-product-column">
                          <div
                            className={
                              !!stateTag
                                ? stateTag.flag === false
                                  ? 'div-toogle-tagList div-toogle-tagList-inactive'
                                  : 'div-toogle-tagList div-toogle-tagList-active'
                                : tag.valide
                                ? 'div-toogle-tagList div-toogle-tagList-active'
                                : 'div-toogle-tagList div-toogle-tagList-inactive'
                            }
                          >
                            <Toggle
                              thumbStyle={styles.thumbOff}
                              trackStyle={styles.trackOff}
                              thumbSwitchedStyle={styles.thumbSwitched}
                              trackSwitchedStyle={styles.trackSwitched}
                              labelStyle={styles.labelStyle}
                              toggled={!!stateTag ? stateTag.flag : tag.valide}
                              onToggle={(event, value) => {
                                this.props.setTagValide(
                                  tag.id,
                                  tag.valide,
                                  this.props.paginationTag.current_page
                                );
                                let actualArray = this.state.arrayToggleAux;

                                if (!!stateTag) {
                                  const newArray = actualArray.map((tagAux) => {
                                    if (tagAux.id === tag.id) {
                                      return {
                                        id: tag.id,
                                        flag: value
                                      };
                                    }
                                    return tagAux;
                                  });
                                  this.setState({
                                    arrayToggleAux: newArray
                                  });

                                } else {
                                  actualArray.push({
                                    id: tag.id,
                                    flag: value
                                  });
                                  this.setState({
                                    arrayToggleAux: actualArray
                                  });
                                }
                                this.setState();
                              }}
                            />
                          </div>
                        </div>
                        <div className="card-product-column">
                          <div>
                            <img
                              onClick={() => {
                                this.props.getTagEdit(tag.id);
                              }}
                              className="img-edit"
                              src={IcoEdit}
                              alt="Ícone de edição"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="row"></div>
              )}
            </div>
          </section>
          {/* <>
              {Array.isArray(this.props.list)
                ? this.props.list
                    .slice(this.state.init, this.state.init + 10)
                    .map(
                      function(tag, index) {
                        return (
                          <TableRow value={index}>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>{tag.id}</div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {tag.name}
                                <br />
                                {tag.description !== 'undefined'
                                  ? tag.description
                                  : undefined}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {'#' + tag.name}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {tag.users_tag}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                <Toggle
                                  thumbStyle={styles.thumbOff}
                                  trackStyle={styles.trackOff}
                                  thumbSwitchedStyle={styles.thumbSwitched}
                                  trackSwitchedStyle={styles.trackSwitched}
                                  labelStyle={styles.labelStyle}
                                  defaultToggled={tag.valide}
                                  onToggle={(event, index, values) =>
                                    this.props.setTagValide(
                                      tag.id,
                                      tag.valide,
                                      this.props.paginationTag.current_page
                                    )
                                  }
                                />
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                <img
                                  onClick={() => {
                                    this.props.getTagEdit(tag.id);
                                  }}
                                  className="img-edit"
                                  src={IcoEdit}
                                  alt="Ícone de edição"
                                />
                              </div>
                            </TableData>
                          </TableRow>
                        );
                      }.bind(this)
                    )
                : undefined}
              </> */}
          {/* </TableBody> */}
          {/* </Table> */}
          <FooterPagination
            activePage={this.props.paginationTag.current_page}
            totalItemsCount={this.props.paginationTag.total_count}
            handlePageChange={(pageNumber) =>
              this.props.getTags(pageNumber, this.props.search, this.props.available)
            }
          />
        </BodyTable>
        <DialogTagForm
          visible={this.props.alert.visible}
          createTag={this.props.createTag}
        />
        <DialogAlert
          closeDialog={this.props.closeDialog}
          message={this.props.erroalert.message}
          visible={this.props.erroalert.visible}
          createTag={this.props.createTag}
        />
        <DialogAlert
          closeDialog={closeMergeModal}
          visible={isOpen}
          message={message}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.tag.tagList,
    search: state.tag.search,
    available: state.tag.available,
    paginationTag: state.tag.pagination,
    alert: state.dialog,
    pagination: state.pagination,
    erroalert: state.erroalert,
    tagMergeModalOpen: state.tag.tagMergeModalOpen
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTags: (page, search, available) => {
      dispatch(TagApi.getTagsV2(page, search, available));
    },
    setTagValide: (id, valide, page) => {
      dispatch(TagApi.setTagAvaiable(id, valide, page));
    },
    getTagEdit: (id) => {
      dispatch(TagApi.getTagEdit(id));
    },
    createTag: (values) => {
      dispatch(TagApi.createTag(values));
    },
    openDialog: () => {
      dispatch(DialogApi.openDialog());
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    closeMergeModal: () => {
      dispatch({ type: 'TAG_MERGE_MODAL_CLOSE' });
    }
  };
};
const TagListContainer = connect(mapStateToProps, mapDispatchToProps)(TagList);

export default TagListContainer;
