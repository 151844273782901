import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn,
  TableHeaderColumn
} from 'material-ui/Table';
import moment from 'moment';

class CourseList extends Component {
  render() {
    return (
      <div className="publication-list-dash">
        <div>
          <div className="header-publication-list">
            <div className="header-publication-list-description">
              <label className="main-label-header-publication-list">
                {this.props.label}
              </label>
              <label className="secondary-label-header-publication-list">
                {this.props.description}
              </label>
            </div>
          </div>
          <Table height="300px">
            <TableBody displayRowCheckbox={false}>
              <TableRow displayRowCheckbox={false}>
                <TableHeaderColumn displayRowCheckbox={false}>
                  Usuário
                </TableHeaderColumn>
                <TableHeaderColumn displayRowCheckbox={false}>
                  Pontuação adquirida
                </TableHeaderColumn>
                <TableHeaderColumn displayRowCheckbox={false}>
                  Quando
                </TableHeaderColumn>
              </TableRow>
              {Array.isArray(this.props.courses_finished)
                ? this.props.courses_finished.map(function(user) {
                    let hour = moment(user.conclusion_date).fromNow();
                    return (
                      <TableRow displayBorder={false}>
                        <TableRowColumn>
                          <label className="label-title-list">
                            {user.name}
                          </label>
                        </TableRowColumn>
                        <TableRowColumn>
                          <label className="label-title-secondary">
                            {user.score.toLocaleString("pt-BR")}
                          </label>
                        </TableRowColumn>
                        <TableRowColumn>
                          <label className="label-title-secondary">
                            {hour}
                          </label>
                        </TableRowColumn>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
export default CourseList;
