import React, { Component } from 'react';
import {
  RadialBarChart,
  RadialBar,
  Legend
} from 'recharts';
import PlaceHolder from '../PlaceHolder/PlaceHolder';

require('./tagpublication.css');

class TagGraphc extends Component {
  render() {
    let data = [];

    if (this.props.tag_use) {
      this.props.tag_use.map(function(tag, index) {
        var color = '';

        switch (index) {
          case 0:
            color = '#ff0026';
            break;
          case 1:
            color = '#ff7800';
            break;
          case 2:
            color = '#ffb200';
            break;
          case 3:
            color = '#00ff43';
            break;
          case 4:
            color = '#00afff';
            break;
        }

        data.push({
          name: tag.name.length > 20 ? tag.name.slice(0, 10) + '...' : tag.name,
          uv: tag.number_of_occurrences,
          pv: 2400,
          fill: color
        });
      });
    }
    const style = {
      top: 250,
      left: -10,
      lineHeight: '24px'
    };

    return (
      <div className="tag-publication-body">
        <div className="header-tag-dash">
          <label className="main-label-tag-dash">Tags em destaque</label>
          <label className="secondary-label-tag-dash">
            Tags mais usadas no período
          </label>
        </div>
        <div>
          {data.length === 0 ? (
            <PlaceHolder />
          ) : (
            <RadialBarChart
              width={500}
              height={300}
              cx={200}
              cy={150}
              innerRadius={30}
              outerRadius={150}
              barSize={18}
              data={data}
              startAngle={-90}
              endAngle={270}
            >
              <RadialBar
                label={{ position: 'insideStart', fill: '#fff' }}
                background
                clockWise={true}
                dataKey="uv"
              />
              <Legend
                iconSize={10}
                width={220}
                height={140}
                layout="vertical"
                verticalAlign="middle"
                wrapperStyle={style}
              />
            </RadialBarChart>
          )}
        </div>
      </div>
    );
  }
}
export default TagGraphc;
