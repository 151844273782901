import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { MuiThemeProvider } from 'material-ui/styles';
import teamSettings from '../../utils/TeamsSettings/generalSettings';
import CalendarApi from '../../../Logicas/CalendarApi';
import ToolsButton from '../../Tools/ToolsList/Components/ToolsButton/ToolsButton';

import { ReactComponent as IconPrevious } from './icons/arrow_back.svg';
import { ReactComponent as IconNext } from './icons/arrow_next.svg';
import { ReactComponent as IconSearch } from '../../academy/course/img/iconSearchSmall.svg';
import { style } from '../../../style';
import { getMuiTheme } from 'material-ui/styles';
import { DatePickerForm } from '../../utils_form/MonthPicker';

const { hasEvents } = teamSettings();
require('./headerlist.css');

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  timePicker: {
    pickerHeaderColor: style.inpuStyle.colorInput,
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput,
    pickerSelectColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

const ButtonNextOrPrevious = styled.div`
  cursor: pointer;
  &:hover {
    svg > path {
      stroke: ${style.inpuStyle.colorInput};
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  grid-area: search;
  position: relative;
  flex: 1;
  align-items: center;
  max-width: 400px;

  > svg {
    position: absolute;
  }

  .magnifier {
    right: 10px;
    bottom: 10px;
    opacity: 0.8;
  }
`;

export const InputSearch = styled.input`
  font-family: Rubik;
  height: 40px;
  width: 100%;

  /* border: 1px solid #E4E4E5; */
  border: 2px solid #e4e4e5;
  border-radius: 4px;
  padding: 10px 40px 10px 10px;
  font-size: 16px;

  color: #808080;

  &::placeholder {
    color: #808080;
  }

  &:focus {
    border: none;
    outline-style: solid;
    outline-color: ${style.inpuStyle.colorInput};
    outline-width: 1px;
  }

  &:hover {
    border: ${(props) => (props.isDisabled ? '1px solid #E4E4E5' : 'none')};
    outline-style: solid;
    outline-color: ${style.inpuStyle.colorInput};
    outline-width: ${(props) => (props.isDisabled ? '0px' : '1px')};
  }
`;

class HeaderCalendarList extends Component {
  constructor() {
    super();
    this.state = {
      searchValue: '',
      date: null
    };
  }

  delayedQuery = debounce((inputValue) => {
    const { getThisMonthEvents, getEventByName } = this.props;

    if (inputValue) {
      this.setState({ date: null });
      getEventByName(inputValue);
    } else {
      getThisMonthEvents();
    }
  }, 1500);

  handleOnChange = (searchInputValue) => {
    const inputValue = searchInputValue.target.value;

    if (inputValue.trim() !== '') {
      this.setState({ searchValue: inputValue }, this.delayedQuery(inputValue));
    } else {
      this.setState({ searchValue: '' }, this.delayedQuery());
    }
  };

  render() {
    const month = moment(this.props.month_in_list).format('MMMM');

    const year = moment(this.props.month_in_list)
      .startOf('year')
      .format('yyyy');

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div
          className="container"
          style={{ marginBottom: '4rem', padding: '0 4rem' }}
        >
          <div className="header-actions">
            <div className="actions-calendar-title">
              <label className="calendar-header-title">
                {hasEvents.value}{' '}
              </label>
              <ButtonNextOrPrevious
                onClick={this.props.getPreviousMonth.bind(
                  this,
                  this.props.month_in_list
                )}
              >
                <IconPrevious />
              </ButtonNextOrPrevious>
              <div
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#4B4E5C',
                  textAlign: 'center'
                }}
              >
                {month.charAt(0).toUpperCase() + month.slice(1)}/{year}
              </div>
              <ButtonNextOrPrevious
                onClick={this.props.getNextMonth.bind(
                  this,
                  this.props.month_in_list
                )}
              >
                <IconNext />
              </ButtonNextOrPrevious>
            </div>
          </div>
          <div className="header-actions-row-2">
            <DatePickerForm
              id="date"
              name="date"
              selected={this.state.date}
              onChange={(date) => {
                this.setState({ date, searchValue: '' });
                this.props.getRandomMonth(new Date(date));
              }}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '2rem'
              }}
            >
              <ContainerSearch>
                <InputSearch
                  value={this.state.searchValue}
                  onChange={this.handleOnChange}
                  placeholder="Busque por eventos"
                />
                <IconSearch className="magnifier" />
              </ContainerSearch>
              <ToolsButton
                onClick={this.props.createNewEvent}
                label="Criar evento"
              />
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

let HeaderCalendarListContainer = reduxForm({
  form: 'HeaderCalendarList'
})(HeaderCalendarList);

const mapStateToProps = (state) => {
  return {
    event_list: state.eventsReducer.eventsList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRandomMonth: (date) => {
      dispatch(CalendarApi.getThisMonthEvents(date, 'get_random_month'));
    },
    getThisMonthEvents: () => dispatch(CalendarApi.getThisMonthEvents()),
    getEventByName: (searchValue) =>
      dispatch(CalendarApi.getEventByName(searchValue)),
    createNewEvent: () => dispatch(CalendarApi.createNewEvent())
  };
};

HeaderCalendarListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderCalendarListContainer);

export default HeaderCalendarListContainer;
