const initialState = {
  user_list: {
    pages: 0,
    size: 0,
    users: []
  },
  pagination: {
    current_page: 0,
    next_page: 1,
    total_count: 0,
  },
  actualfilter: '',
  userRoles: '',
  userEdit: {},
  userListName: [],
  userStorage: {},
  userTagAssociation: 0,
  customFields: {
    customQuestions: [],
    usersAnswers: [],
    isModalOpen: false,
    isLoading: false,
    editQuestion: {
      id: '',
      title: '',
      placeholder: ''
    }
  },
  selectedUsers: {
    users: []
  },
  userFilter: {
    selectedFilter: '',
    filterValue: '',
    birthdayMonth: '',
    nameSearch: ''
  },
  teamBrands: {
    availableBrands: [],
    pagination: {
      current_page: 0,
      next_page: '',
      previous_page: '',
      total_pages: 0,
      total_count: 0
    },
    isLoading: false
  },
  birthdayMessage: {
    showBirthdayMessageModal: false,
    birthdayMessageBody: '',
    updatingBirthdayMessage: false
  },
  listStates:[],
  listCities:[],
  stateSelected:{},
  citySelected:{},
  allowRequestCity:true,
  hasMoodSurveyActive: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_STORAGE':
      return {
        ...state,
        userStorage: action.userStorage
      };
    case 'GET_USER_LIST':
      return {
        ...state,
        user_list:
          action.pagination && action.pagination.current_page > 1
            ? [...state.user_list, ...action.user_list]
            : action.user_list,
        pagination: action.pagination,
        actualfilter: action.actualfilter
      };
    case 'RESET_USER_LIST':
      return {
        ...state,
        user_list: [],
        pagination: {
          current_page: 0,
          next_page: 1,
          total_count: 0,
        } ,
        actualfilter: ''
      };
    case 'GET_USER_LIST_NAME':
      return {
        ...state,
        userListName: action.userListName
      };
    case 'GET_USER_ROLES':
      return {
        ...state,
        userRoles: action.userRoles
      };
    case 'EDIT_USER':
      const newUserEdit = action.userEdit;
      if(newUserEdit.permission_user === 'Manager')
        newUserEdit.permission_user = 'Editor de Conteúdo';
      return {
        ...state,
        userEdit: newUserEdit
      };
    case 'EDIT_USER_RESET':
      return {
        ...state,
        userEdit: {}
      };
    case 'USER_TAG_ASSOCIATION_GET':
      return {
        ...state,
        userTagAssociation: action.userTagAssociation
      };
    case 'USER_TAG_ASSOCIATION_RESET':
      return {
        ...state,
        userTagAssociation: 0
      };
    case 'USER_SELECTED_TOGGLE':
      const doesContainUser = state.selectedUsers.users.includes(
        action.selectedUser
      );

      if (!doesContainUser) {
        const newSelectedUsers = [
          ...state.selectedUsers.users,
          action.selectedUser
        ];
        return {
          ...state,
          selectedUsers: {
            ...state.selectedUsers,
            users: newSelectedUsers
          }
        };
      } else {
        const newSelectedUsers = state.selectedUsers.users.filter(
          (user) => user.id !== action.selectedUser.id
        );
        return {
          ...state,
          selectedUsers: {
            ...state.selectedUsers,
            users: newSelectedUsers
          }
        };
      }
    case 'USER_SELECTED_ALL':
      const {
        selectedUsers: { users: currentlySelectedUsers }
      } = state;
      const {
        user_list: { users: currentlyListedUsers }
      } = state;

      const allSelectedusers = [
        ...currentlySelectedUsers,
        ...currentlyListedUsers
      ];

      const uniqueSelecTedUsers = allSelectedusers.filter(
        (user, index, usersArray) => usersArray.indexOf(user) === index
      );

      return {
        ...state,
        selectedUsers: {
          ...state.selectedUsers,
          users: uniqueSelecTedUsers
        }
      };
    case 'USER_SELECTED_NONE':
      return {
        ...state,
        selectedUsers: {
          ...state.selectedUsers,
          users: []
        }
      };
    case 'USER_FILTER_CREATE':
      return {
        ...state,
        userFilter: action.userFilter
      };
    case 'USER_FILTER_CLEAR':
      return {
        ...state,
        userFilter: {
          selectedFilter: '',
          filterValue: '',
          birthdayMonth: '',
          nameSearch: ''
        }
      };
    case 'CUSTOM_FIELDS_QUESTIONS_GET':
      const customQuestions = action.customQuestions.map((question) => {
        question.active = false;
        if (question.title === state.customFields.editQuestion.title) {
          question.active = true;
        }

        return question;
      });
      return {
        ...state,
        customFields: {
          ...state.customFields,
          customQuestions
        }
      };
    case 'CUSTOM_FIELDS_QUESTIONS_EDIT':
      const editQuestion = state.customFields.customQuestions.find(
        (question) => question.id === action.id
      );

      return {
        ...state,
        customFields: {
          ...state.customFields,
          editQuestion
        }
      };
    case 'CUSTOM_FIELDS_QUESTION_ACTIVE':
      const containActiveField = state.customFields.customQuestions.map(
        (question, k) => {
          question.active = false;
          if (question.id === action.id) {
            question.active = true;
          }

          return question;
        }
      );

      return {
        ...state,
        customFields: {
          ...state.customFields,
          customQuestions: containActiveField
        }
      };
    case 'CUSTOM_FIELDS_QUESTION_UPDATE':
      const updatedFields = state.customFields.customQuestions.map(
        (question, w) => {
          if (question.id === action.updatedQuestion.id) {
            question = action.updatedQuestion;
            question.active = true;
          }

          return question;
        }
      );

      return {
        ...state,
        customFields: {
          ...state.customFields,
          customQuestions: updatedFields
        }
      };
    case 'CUSTOM_FIELDS_QUESTIONS_LOADING_START':
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isLoading: true
        }
      };
    case 'CUSTOM_FIELDS_QUESTIONS_LOADING_FINISH':
      return {
        ...state,
        customFields: {
          ...state.customFields,
          isLoading: false
        }
      };
    case 'SET_AVAILABLE_BRANDS':
      return {
        ...state,
        teamBrands: action.teamBrands
      };
    case 'FETCH_MORE_BRANDS':
      const {
        payload: { newBrands, pagination }
      } = action;

      const moreAvailableBrands = [
        ...state.teamBrands.availableBrands,
        ...newBrands
      ];

      return {
        ...state,
        teamBrands: {
          ...state.teamBrands,
          availableBrands: moreAvailableBrands,
          pagination
        }
      };
    case 'LOADING_BRANDS_START':
      return {
        ...state,
        teamBrands: {
          ...state.teamBrands,
          isLoading: true
        }
      };
    case 'LOADING_BRANDS_FINISH':
      return {
        ...state,
        teamBrands: {
          ...state.teamBrands,
          isLoading: false
        }
      };
    case 'TOGGLE_SELECTED_BRAND':
      const doesContainBrand = state.userEdit.brands.find(
        (brand) => brand.id === action.id
      );

      if (!doesContainBrand) {
        const addedBrand = state.teamBrands.availableBrands.find(
          (brand) => brand.id === action.id
        );
        const newSelectedBrands = [...state.userEdit.brands, addedBrand];

        return {
          ...state,
          userEdit: {
            ...state.userEdit,
            brands: newSelectedBrands
          }
        };
      } else {
        const newSelectedBrands = state.userEdit.brands.filter(
          (brand) => brand.id !== action.id
        );

        return {
          ...state,
          userEdit: {
            ...state.userEdit,
            brands: newSelectedBrands
          }
        };
      }
    case 'BIRTHDAY_MESSAGE_MODAL_SHOW':
      return {
        ...state,
        birthdayMessage: {
          ...state.birthdayMessage,
          showBirthdayMessageModal: true
        }
      };
    case 'BIRTHDAY_MESSAGE_MODAL_HIDE':
      return {
        ...state,
        birthdayMessage: {
          ...state.birthdayMessage,
          showBirthdayMessageModal: false
        }
      };
    case 'BIRTHDAY_MESSAGE_BODY_GET':
      return {
        ...state,
        birthdayMessage: {
          ...state.birthdayMessage,
          birthdayMessageBody: action.birthdayMessageBody
        }
      };
    case 'BIRTHDAY_MESSAGE_UPDATE_START':
      return {
        ...state,
        birthdayMessage: {
          ...state.birthdayMessage,
          updatingBirthdayMessage: true
        }
      };
    case 'BIRTHDAY_MESSAGE_UPDATE_FINISH':
      return {
        ...state,
        birthdayMessage: {
          ...state.birthdayMessage,
          updatingBirthdayMessage: false
        }
      };
    case 'UPDATE_LIST_STATES':
      return{
        ...state,
        listStates: action.states
      };
    case 'UPDATE_LIST_CITIES':
      return{
        ...state,
        listCities: action.cities
      };
    case 'UPDATE_STATE':
      return{
        ...state,
        stateSelected: action.state
      };
    case 'UPDATE_CITY':
      return{
        ...state,
        citySelected: action.city
      };
    case 'ALLOW_REQUEST_CITY':
      return{
        ...state,
        allowRequestCity: action.allowRequestCity
      };
    case 'SET_MOOD_SURVEY_ACTIVE':
      return{
        ...state,
        hasMoodSurveyActive: action.hasMoodSurveyActive
      }
    default:
      return state;
  }
};

export default userReducer;
