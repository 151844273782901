import React, { Component } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Modal from 'react-awesome-modal';
import SectorApi from '../../Logicas/SectorApi';
import DialogApi from '../../Logicas/DialogApi';
import { required } from '../validation/FormValidation';

import ButtonDefault, {
  ButtonDisable,
  TextFieldDefault
} from '../utils/FormsComponents';

import IcoExit from '../utils/img/exit-dialog.png';
import '../tag/tag.css';

export class SaveSector extends Component {
  constructor() {
    super();
    this.saveSector = this.saveSector.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  saveSector(values) {
    if (this.props.patchSector && this.props.sectorId !== 0) {
      this.props.patchSectorUser(this.props.sectorId, values);
    } else {
      this.props.createSectorUser(values);
    }
  }

  closeDialog() {
    this.props.closeDialogSave();
    this.props.closeDialogConfirm();
  }

  render() {
    const { handleSubmit, valid, confirmSave } = this.props;
    return (
      <Modal
        visible={this.props.visible}
        width="30%"
        height="35%"
        effect="fadeInUp"
      >
        <div className="container-modal-schedule">
          <div className="exit-modal-draw">
            <img
              onClick={this.closeDialog}
              className="btn-exit"
              src={IcoExit}
              alt="ícone fechar"
            />
          </div>
          <div className="body-draw-confirm">
            {confirmSave ? (
              <>
                <div className="label-dialog-tag">
                  Setor {this.props.patchSector ? 'Atualizado' : 'Salvo'}
                </div>
                <label className="label-confirm-button">
                  <ButtonDefault onClick={this.closeDialog} label="Ok" />
                </label>
              </>
            ) : (
              <>
                <div className="label-dialog-tag">
                  {this.props.patchSector ? 'Editar' : 'Novo'} Setor
                </div>
                <label className="label-title">
                  <Field
                    name="name_sector"
                    validate={[required]}
                    id="name"
                    label="Nome do Setor"
                    component={TextFieldDefault}
                    type="text"
                  />
                </label>
                <label className="label-confirm-button">
                  {valid ? (
                    <ButtonDefault
                      onClick={handleSubmit(this.saveSector)}
                      label="Salvar"
                    />
                  ) : (
                    <ButtonDisable label="Salvar" />
                  )}
                </label>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
let SaveSectorContainer = reduxForm({ form: 'createSectors' })(SaveSector);

const mapStateToProps = (state) => {
  return {
    confirmSave: state.dialog.visible,
    visible: state.show_modal_insert_users_tag
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSectorUser: (values) => {
      dispatch(SectorApi.createSectorUser(values));
    },
    patchSectorUser: (id, values) => {
      dispatch(SectorApi.patchSectorUser(id, values));
    },
    closeDialogConfirm: () => {
      dispatch(DialogApi.closeDialog());
    },
    closeDialogSave: () => {
      dispatch({ type: 'HIDE_MODAL_INSERT_TAG' });
    }
  };
};

SaveSectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveSectorContainer);

export default SaveSectorContainer;
