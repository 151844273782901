import React, { Component } from 'react';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';

const config = {
  toolbar: {
    items: [
      'bold',
      'italic',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent'
    ]
  }
};

class RichTextEditor extends Component {
  render() {
    return (
      <CKEditor
        editor={ClassicEditor}
        config={config}
        onChange={this.props.onChange}
        data={this.props.data}
      />
    );
  }
}

export default RichTextEditor;
