export function submitting(state = true, action) {
  if (action.type === 'SUBMITTING') {
    return false;
  }
  if (action.type === 'NO_SUBMITTING') {
    return true;
  }

  return state;
}
