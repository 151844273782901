import styled from 'styled-components';
import { style } from '../../style';

export const ContainerDrop = styled.div`
  background-color: #f4f6fb;
  width: ${(props) => (props.isVideoUploadByModal ? '450px' : '592px')};
  height: ${(props) => (props.isVideoUploadByModal ? '100px' : '350px')};
  cursor: ${(props) => props.isVideoUploadByModal && 'pointer'};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: rgba(125, 134, 161, 1);
    font-size: 16px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    max-width: 450px;
  }
`;

export const DropHover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 101px;
  height: 88px;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 4px;
  background: var(--cinza, #f4f6fb);
  box-shadow: 0px 0px 8px 2px #33394740;

  > p {
    font-size: 18px !important;
    margin-bottom: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const VideoUploaded = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
    color: #2e63fb;
  }

  span {
    text-decoration: underline;
    color: #2e63fb;
  }
`;
