import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import ImageIcon from '../../img/ic-imagem_3x.png';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Dropzone from 'react-dropzone';
import VideoIcon from '../../img/ic-video.png';
import CarouselIcon from '../../img/ic-carousel.png';
import LinkIcon from '../../img/ic-link.png';
import { TextFieldDefault, LongTextLink } from '../../../utils/FormsComponents';
import { style } from '../../../../style';
import { required } from '../../../validation/FormValidation';
import IcoLinkConfirm from '../../img/ic-add-link.png';
import PostApi from '../../../../Logicas/PostApi';
import IcoExit from '../../../utils/img/exit-dialog.png';
import { canAddFiles } from '../../../utils/canAddFiles';
import ToolsButton from '../../../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import DashboardButton from '../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import { ReactComponent as IconUpload } from '../../../CreateOrEditPost/icons/upload.svg';
import { ReactComponent as IconVideoUploaded } from '../../../CreateOrEditPost/icons/videoUpload.svg';

require('./dialogcanvas.css');
class DialogEditMidia extends Component {
  constructor() {
    super();
    this.state = {
      videoKind: 'link',
      inputValue: '',
      videoEmbededUrl: '',
      videoFile: [],
      errorMessage: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveLink = this.saveLink.bind(this);
    this.isGif = this.isGif.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onEmbededVideoLink = this.onEmbededVideoLink.bind(this);
    this.handleVideoUpload = this.onEmbededVideoLink.bind(this);
    this.onUploadVideo = this.onUploadVideo.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  closeDialog() {}

  isGif(file) {
    return file.type === 'image/gif';
  }

  onOptionChange = (e) => {
    this.setState({ videoKind: e.target.value });
  };

  onClear = () => {
    this.setState({ videoFile: [] });
    this.setState({ inputValue: '' });
    this.props.closeDialogUploadFile();
  };

  onEmbededVideoLinkAdd = () => {
    this.state.inputValue !== '' &&
      this.saveContentEmbededVideoLink(this.state.inputValue);
  };

  onEmbededVideoLink = () => {
    this.setState({ inputValue: this.state.inputValue });
    this.props.closeDialogUploadFile();
    this.onEmbededVideoLinkAdd();
  };

  onDrop = (acceptedFiles) => {
    this.setState({
      videoFile: acceptedFiles.filter((file) => file.type === 'video/mp4')
    });

    if (this.state.videoFile === 0) {
      this.setState({
        errorMessage: 'Apenas arquivos de vídeo são permitidos.'
      });
    } else {
      this.setState({ errorMessage: '' });
      this.setState({ inputValue: '' });
    }
  };

  onUploadVideo = () => {
    if (this.state.videoFile[0] && this.state.videoFile.length > 0) {
      this.handleChange(this.state.videoFile);
    }
    if (this.state.inputValue !== '') {
      this.saveContentEmbededVideoLink(this.state.inputValue);
    }
  };

  async handleChange(e) {
    if (e[0] !== undefined && e !== null) {
      const { canAddAllFiles } =
        e[0].type === 'video/mp4'
          ? { canAddAllFiles: true }
          : await canAddFiles(e);
      let canAddAllGifs = true;
      e.map((file) => {
        if (this.isGif(file) && file.size / (1024 * 1024).toFixed(2) > 20)
          canAddAllGifs = false;
      });
      if (canAddAllFiles && canAddAllGifs) {
        try {
          let closeDialog = true;
          if (this.props.alert.kind === 'image') {
            this.props.EditImage(
              this.props.idcanvas,
              this.props.alert.id,
              e,
              this.props.index
            );
          }
          if (this.props.alert.kind === 'carousel') {
            if (e.length + this.props.amountOfImages > 10) {
              this.setState({ erro: true });
              closeDialog = false;
              setTimeout(() => {
                this.setState({ erro: undefined });
              }, 3000);
            } else {
              this.props.AddImageToCarousel(
                this.props.idcanvas,
                this.props.alert.id,
                e
              );
            }
          }

          if (this.props.alert.kind === 'video') {
            this.props.EditVideoV2(
              this.props.idcanvas,
              this.props.alert.id,
              e,
              style.teamName.name
            );
          }

          if (closeDialog) this.props.closeDialogUploadFile();
        } catch (error) {}
      } else {
        this.props.closeDialogUploadFile();
        if (!canAddAllFiles) this.props.dispatchImageError();
        else if (!canAddAllGifs) this.props.dispatchGifError();
      }
    }
  }

  saveLink(values) {
    this.props.addLink(values.name_button, values.link_button);
    this.props.setPublication('link', values);
    this.props.InsertComponentLink(values);
    this.props.closeDialogUploadFile();
  }

  saveContentEmbededVideoLink(values) {
    this.props.addEmbededVideoLink();
    this.props.setPublication('embeded_video_link', values);
    this.props.InsertComponentVideoEmbeded(values);
    this.props.closeDialogUploadFile();
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    if (this.props.alert.kind === undefined) {
      return null;
    }
    if (this.props.alert.kind !== 'link' && this.props.alert.kind !== 'video') {
      return (
        <Modal visible={this.props.alert.visible} width="50%" height="50%">
          <div
            onClick={this.props.closeDialogUploadFile}
            className="body-row-exit"
          >
            <img
              style={{ height: '12px', width: '12px' }}
              src={IcoExit}
              alt="fechar"
            />
          </div>
          <div
            style={{ width: '100%', height: '90%' }}
            className="dropzone-class"
          >
            {(this.state.erro === undefined &&
              this.props.amountOfImages < 10) ||
            this.props.amountOfImages === undefined ? (
              <Dropzone
                className="dropzone-class-border"
                onDrop={this.handleChange}
              >
                {this.props.alert.kind === 'image' && (
                  <div className="dropzone-class">
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={ImageIcon}
                      alt="ícone de imagem"
                    />
                    <div className="label-dialog-main">
                      <label className="label-dialog-main-select">
                        Clique aqui
                      </label>{' '}
                      ou arraste uma imagem para adicionar um conteúdo Imagem
                    </div>
                  </div>
                )}

                {this.props.alert.kind === 'carousel' && (
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={CarouselIcon}
                        alt="ícone de carrossel"
                      />
                    </div>
                    <div style={{ marginTop: '10px' }} className="col-md-8">
                      <div className="label-dialog-main">
                        <label className="label-dialog-main-select">
                          Clique aqui
                        </label>{' '}
                        ou arraste ao menos duas e até dez imagens para criar um
                        carousel
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            ) : (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="erro-images">
                      Limite de imagens excedido, máximo de dez imagens
                      permitidas
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      );
    }
    if (this.props.alert.kind === 'link') {
      return (
        <Modal visible={true} width="613px" height="158px">
          <form onSubmit={handleSubmit(this.saveLink)}>
            <div className="row">
              <div
                style={{ marginTop: '54px', marginLeft: '20px' }}
                className="col-md-2"
              >
                <img
                  style={{ width: '50px', height: '50px' }}
                  src={LinkIcon}
                  alt="ícone de link"
                />
              </div>
              <div className="col-md-8">
                <Field
                  name="name_button"
                  rows={1}
                  validate={[required]}
                  id="name"
                  label="Texto do botão*"
                  component={TextFieldDefault}
                  type="text"
                />
              </div>
              <div
                style={{ marginTop: '20px' }}
                onClick={this.props.closeDialogUploadFile}
                className="col-md-1"
              >
                <img
                  style={{ height: '12px', width: '12px' }}
                  src={IcoExit}
                  alt="fechar"
                />
              </div>
            </div>
            <div style={{ marginTop: '-50px' }} className="row">
              <div style={{ marginLeft: '20px' }} className="col-md-2"></div>
              <div className="col-md-6">
                <Field
                  name="link_button"
                  rowsMax={1}
                  rows={1}
                  validate={[required]}
                  id="name"
                  label="https://*"
                  component={LongTextLink}
                  type="text"
                />
              </div>
              <div className="col-md-2 img-button-click">
                <button className="img-button-click">
                  {' '}
                  <img src={IcoLinkConfirm} alt="confirmar" />{' '}
                </button>
              </div>
            </div>
          </form>
        </Modal>
      );
    }
    if (this.props.alert.kind === 'video') {
      return (
        <Modal width="550px" height="350px" visible={this.props.alert.visible}>
          <div className="modal_container">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <h4 className="modal_title">
                Selecione como você irá inserir o vídeo
              </h4>

              <div className="modal_container_select">
                <div className="modal_select">
                  <input
                    type="radio"
                    name="Upload de arquivo"
                    value="link"
                    onChange={this.onOptionChange}
                    checked={this.state.videoKind === 'link'}
                  />
                  <label className="modal_select_label">
                    Upload de arquivo
                  </label>
                </div>

                <div className="modal_select">
                  <input
                    disabled
                    type="radio"
                    name="Link do Youtube"
                    value="embeded_video_link"
                    onChange={this.onOptionChange}
                    checked={this.state.videoKind === 'embeded_video_link'}
                  />
                  <label className="modal_select_label">Link do Youtube</label>
                </div>
              </div>
            </div>

            {this.state.videoKind === 'embeded_video_link' ? (
              <input
                className="modal_link_input"
                type="text"
                value={this.state.inputValue}
                placeholder="http://www..."
                onChange={(e) => {
                  this.setState({ inputValue: e.target.value });
                  this.setState({ videoFile: [] });
                }}
              />
            ) : (
              <div className="dropzone_container">
                {this.state.videoFile[0] && this.state.videoFile.length > 0 ? (
                  <div className="dropzone_content">
                    <IconVideoUploaded />
                    <p className="dropzone_text">
                      {this.state.videoFile[0].name}
                    </p>
                  </div>
                ) : (
                  <Dropzone
                    className="dropzone_content"
                    onDrop={this.onDrop}
                    accept=".mp4"
                  >
                    <IconUpload />
                    <p className="dropzone_text">
                      <span className="dropzone_mark_text">Clique</span> ou
                      arraste um vídeo
                    </p>
                  </Dropzone>
                )}
              </div>
            )}

            <div className="modal_container_buttons">
              <DashboardButton
                withoutMargin
                label={'Cancelar'}
                onClick={this.onClear}
                style={{ width: '100px' }}
              />
              <ToolsButton
                disabled={!this.state.videoFile[0]}
                isDisabled={!this.state.videoFile[0]}
                label="Continuar"
                onClick={this.onUploadVideo}
                style={{ width: '100px' }}
              />
            </div>
          </div>
        </Modal>
      );
    }
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'createlink'
})(DialogEditMidia);

const mapDispatchToProps = (dispatch) => {
  return {
    sendFiles: (files) => {
      dispatch({ type: 'UPLOAD_FILES', files: files });
    },
    addLink: (link, name) => {
      dispatch({ type: 'ADD_LINK', link: { name: name, link: link } });
    },
    addEmbededVideoLink: (link, name) => {
      dispatch({
        type: 'ADD_EMBEDED_VIDEO_LINK',
        link: { name: name, link: link }
      });
    },
    setPublication: (kind, value) =>
      dispatch({ type: 'formPost', kind: kind, value: value }),
    EditImage: (idcanvas, idimage, image, index) =>
      dispatch(PostApi.PatchContentImage(idcanvas, idimage, image, index)),
    closeDialogUploadFile: () => {
      dispatch({ type: 'CLOSE_DIALOG_FILE_MIDIA', alert: { visible: false } });
    },
    AddImageToCarousel: (idcanvas, idimage, image) => {
      dispatch(PostApi.AddImageToCarousel(idcanvas, idimage, image));
    },
    EditVideo: (idcanvas, idimage, videos) => {
      dispatch(PostApi.PatchContentVideo(idcanvas, idimage, videos));
    },
    EditVideoV2: (idcanvas, idimage, value, teamName) => {
      dispatch(PostApi.PatchContentVideoV2(idcanvas, idimage, value, teamName));
    },
    dispatchImageError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Não foi possível carregar a imagem',
          submessage:
            'O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.'
        }
      });
    },
    dispatchGifError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:
            'Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.'
        }
      });
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    alert: state.dialogeditmidia
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
