import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import { MuiThemeProvider } from 'material-ui/styles';

import CourseApi from '../../../../Logicas/CourseApi';
import { required } from '../../../validation/FormValidation';
import NotificationApi from '../../../../Logicas/NotificationApi';

import ListModules from './ListModules';
import CreateModules from './CreateModules';
import PreviewContent from './PreviewContent';
import TableCourseDetails from '../TableCourseDetails';
import CourseCreateInitial from '../course_initial_form/CanvasFormInitial';
import ButtonDefault, {
  ButtonDialogTag,
  ButtonDisable,
  DatePickerForm,
  TextFieldDefault
} from '../../../utils/FormsComponents';
import DashboardButton from '../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import ToolsButton from '../../../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import ProgressToast from '../../../_shared/ProgressToast/ProgressToast';

import IcoExit from '../../../utils/img/exit-dialog.png';
import './CourseLongStyles.css';
import MenuButton from '../../../Menu/Components/MenuButton/MenuButton';
import { BodyTable } from '../../../utils/BodyTable';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeadRow
} from '../../../utils/Table';
import IcoEdit from '../../../utils/img/ic-editar.svg';
import RemoveIcon from '../../../utils/img/ic-excluir.svg';
import UserIcon from '../../../utils/img/ic-user-circle.png';

import { Field, reduxForm } from 'redux-form';
import { TableRow } from 'material-ui/Table';
import { FooterPagination } from '../../../utils/Pagination';
import DialogReportEmail from '../../../publication/dialog/DialogReportEmail/DialogReportEmail';
import { DialogVouchers } from '../../../utils/dialogs/DialogVouchers';
import Zoom from 'react-reveal/Zoom';

import { style } from '../../../../style';
import { ActionsModule } from './ActionsModule/ActionsModule';

class ContainerModules extends Component {
  constructor() {
    super();
    this.state = {
      moduleActiveEdit: {},
      selectedEditActive: 'Geral',
      message: '',
      courseDetails: {},
      showModal: {
        show: false,
        type: ''
      },
      init: 0,
      activePage: 1,
      voucherId: null,
      quantity: 0
    };

    this.handleModal = this.handleModal.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.showUsersVoucher = this.showUsersVoucher.bind(this);
  }

  componentDidMount() {
    if (!this.props.courseDetails.id) {
      // this.props.initialSearchByUsers();
      // this.props.getUsersCourse(
      //   localStorage.getItem('IdCourseEdit'),
      //   'not_finished'
      // );
      this.props.editCourseV2(localStorage.getItem('IdCourseEdit'));
    }
    if (this.props.courseDetails.module_courses) {
      this.setState({
        moduleActiveEdit: this.props.courseDetails.module_courses[
          this.props.moduleActive
        ]
      });
    }

    if (
      style.teamName.name === 'Pintou Parceria Suvinil' ||
      style.teamName.name === 'sample'
    ) {
      this.props.getVouchers(
        localStorage.getItem('IdCourseEdit'),
        this.state.activePage
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      moduleActiveEdit: nextProps.courseDetails.module_courses
        ? nextProps.courseDetails.module_courses[nextProps.moduleActive]
        : null
    });
    this.setState({ courseDetails: nextProps.courseDetails });
  }

  handleModal(show, type, voucherId) {
    const { reset } = this.props;

    this.setState({
      showModal: {
        show,
        type
      }
    });

    if (voucherId) {
      this.setState({
        voucherId
      });
    }

    reset();
  }

  async handleAddItem(e) {
    e.preventDefault();

    const { reset } = this.props;

    await this.props.addVoucher(e.target);
    this.setState({
      showModal: {
        show: false,
        type: ''
      }
    });

    reset();
  }

  async handleEditItem(e) {
    e.preventDefault();

    const { reset } = this.props;

    await this.props.editVoucher(e.target, this.state.voucherId);
    this.setState({
      showModal: {
        show: false,
        type: ''
      }
    });

    reset();
  }

  async handleRemoveItem(id) {
    await this.props.removeVoucher(this.props.courseDetails.id, id);
  }

  showUsersVoucher(voucherId, quantity) {
    this.setState({ quantity });
    this.props.viewUsersVoucher(voucherId, this.props.courseDetails.id);
  }

  onDateKeyUp(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    const len = input.value.length;

    if (len !== 1 || len !== 3) {
      if (e.charCode == 47) {
        e.preventDefault();
      }
    }

    if (len === 2) {
      input.value += '/';
    }

    if (len === 5) {
      input.value += '/';
    }
  }

  render() {
    const {
      courseDetails,
      usersCourse,
      usersNotification,
      headerName,
      getHeaderCourseDetails,
      openReportEmailDialog,
      getUsersCourse,
      sendNotificationUsersV2,
      searchingCourseUsers,
      report_success,
      loading
    } = this.props;

    !!report_success && openReportEmailDialog();

    return (
      <MuiThemeProvider>
        <div className="body-container">
          <div className="container-selected-edit">
            <p
              className={
                this.state.selectedEditActive === 'Geral'
                  ? 'selected-edit active-edit'
                  : 'selected-edit'
              }
              style={{ borderColor: style.inpuStyle.colorInput }}
              onClick={() => this.setState({ selectedEditActive: 'Geral' })}
            >
              Geral
            </p>
            {courseDetails.modality !== 'presential' && (
              <p
                className={
                  this.state.selectedEditActive === 'Módulos'
                    ? 'selected-edit active-edit'
                    : 'selected-edit'
                }
                style={{ borderColor: style.inpuStyle.colorInput }}
                onClick={() => this.setState({ selectedEditActive: 'Módulos' })}
              >
                Módulos
              </p>
            )}
            <p
              className={
                this.state.selectedEditActive === 'Ações'
                  ? 'selected-edit active-edit'
                  : 'selected-edit'
              }
              style={{ borderColor: style.inpuStyle.colorInput }}
              onClick={() => this.setState({ selectedEditActive: 'Ações' })}
            >
              Ações
            </p>
            {courseDetails.modality === 'presential' && (
              <p
                className={
                  this.state.selectedEditActive === 'Validação'
                    ? 'selected-edit active-edit'
                    : 'selected-edit'
                }
                style={{ borderColor: style.inpuStyle.colorInput }}
                onClick={() =>
                  this.setState({ selectedEditActive: 'Validação' })
                }
              >
                Validação
              </p>
            )}

            {this.state.selectedEditActive === 'Validação' && (
              <div class="right">
                <MenuButton
                  label="Novo código"
                  onClick={() => this.handleModal(true, 'add')}
                />
              </div>
            )}
          </div>
          <div>
            {this.state.selectedEditActive === 'Geral' ? (
              <div className="container-course-long">
                <CourseCreateInitial editCourse={true} />
              </div>
            ) : this.state.selectedEditActive === 'Módulos' ? (
              <div className="container-course-long">
                <div className="body-list-modules">
                  <ListModules
                    moduleActive={this.props.moduleActive}
                    idCourseModules={courseDetails.id}
                    listModulesCourse={courseDetails.module_courses}
                  />
                </div>
                <div className="body-create-modules">
                  {courseDetails.module_courses && (
                    <CreateModules
                      moduleActiveEdit={this.state.moduleActiveEdit}
                      courseIdActiveEdit={courseDetails.id}
                      courseDetails={this.state.courseDetails}
                    />
                  )}
                </div>
                <div className="body-preview-subModules">
                  <PreviewContent
                    moduleActive={this.state.moduleActiveEdit}
                    courseIdActiveEdit={courseDetails.id}
                    courseDetails={this.state.courseDetails}
                  />
                </div>
              </div>
            ) : this.state.selectedEditActive === 'Usuários' ? (
              <div className="container-course-long">
                <div className="card-details-body">
                  <span className="title-header-details">Usuários</span>
                  <div
                    className="header-details"
                    style={{ marginBottom: '0px' }}
                  >
                    <div>
                      <div className="header-title-course">
                        <ul className="body-details-first">
                          <li>
                            <div
                              className={
                                headerName === 'Iniciados'
                                  ? 'menu-item-details active'
                                  : 'menu-item-details'
                              }
                              style={{
                                borderColor: style.inpuStyle.colorInput
                              }}
                              onClick={() => {
                                getHeaderCourseDetails('Iniciados');
                                getUsersCourse(
                                  localStorage.getItem('IdCourseEdit'),
                                  'not_finished'
                                );
                              }}
                            >
                              Iniciados
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                headerName === 'Não iniciados'
                                  ? 'menu-item-details active'
                                  : 'menu-item-details'
                              }
                              style={{
                                borderColor: style.inpuStyle.colorInput
                              }}
                              onClick={() => {
                                getHeaderCourseDetails('Não iniciados');
                                getUsersCourse(
                                  localStorage.getItem('IdCourseEdit'),
                                  'not_initiated'
                                );
                              }}
                            >
                              Não iniciados
                            </div>
                          </li>
                          <li>
                            <div
                              className={
                                headerName === 'Concluídos'
                                  ? 'menu-item-details active'
                                  : 'menu-item-details'
                              }
                              style={{
                                borderColor: style.inpuStyle.colorInput
                              }}
                              onClick={() => {
                                getHeaderCourseDetails('Concluídos');
                                getUsersCourse(
                                  localStorage.getItem('IdCourseEdit'),
                                  'finished'
                                );
                              }}
                            >
                              Concluídos
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div id="subheader-buttons">
                      <DashboardButton
                        label="Receber relatório"
                        onClick={() => {
                          openReportEmailDialog('courseHistory');
                        }}
                        withoutMargin={true}
                      />
                      <ToolsButton
                        onClick={
                          usersCourse.pagination &&
                          usersCourse.pagination.total_count === 0
                            ? () =>
                                this.props.openErrorDialog(
                                  'Não é possível enviar mensagem, pois a lista de usuários está vazia.'
                                )
                            : () => this.props.showDialogMsg()
                        }
                        label="Enviar mensagem"
                        disabled={
                          headerName !== 'Não iniciados' &&
                          headerName !== 'Iniciados'
                        }
                      />
                    </div>
                  </div>
                  <div className="body-details">
                    <div className="header-title-course">
                      {headerName === 'Iniciados' && (
                        <div className="numbers-subtitle">
                          <img
                            src={UserIcon}
                            alt="user"
                            style={{ marginRight: '5px' }}
                          />
                          {usersCourse.pagination !== undefined &&
                          usersCourse.pagination.total_count !== undefined
                            ? usersCourse.pagination.total_count
                            : '0'}{' '}
                          usuário(s) iniciaram o curso
                        </div>
                      )}
                      {headerName === 'Não iniciados' && (
                        <div className="numbers-subtitle">
                          <img
                            src={UserIcon}
                            alt="user"
                            style={{ marginRight: '5px' }}
                          />
                          {usersCourse.pagination !== undefined &&
                          usersCourse.pagination.total_count !== undefined
                            ? usersCourse.pagination.total_count
                            : '0'}{' '}
                          usuário(s) não iniciaram o curso
                        </div>
                      )}
                      {headerName === 'Concluídos' && (
                        <div className="numbers-subtitle">
                          <img
                            src={UserIcon}
                            alt="user"
                            style={{ marginRight: '5px' }}
                          />
                          {usersCourse.pagination !== undefined &&
                          usersCourse.pagination.total_count !== undefined
                            ? usersCourse.pagination.total_count
                            : '0'}{' '}
                          usuário(s) concluíram o curso
                        </div>
                      )}
                    </div>
                    <div>
                      <div
                        style={{
                          width: '95%',
                          borderTop: '2px solid #E4E4E5',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          marginBottom: '30px',
                          marginTop: '20px'
                        }}
                      />

                      <TableCourseDetails
                        elements={courseDetails}
                        usersCourse={usersCourse}
                      />
                    </div>
                  </div>
                </div>
                <Modal width="50%" height="45%" visible={this.props.visible}>
                  <div className="send-notification-course">
                    <div className="notification-img-close">
                      <img
                        onClick={() => this.props.closeDialogNotification()}
                        className="btn-exit"
                        src={IcoExit}
                        alt="fechar"
                      />
                    </div>
                    {this.props.showModal ? (
                      <div>
                        <header className="notification-title">
                          Enviar notificação
                        </header>
                        <div className="notification-body-msg">
                          <p className="notificaiton-msg-description">
                            Mensagem
                          </p>
                          <input
                            className="msg-notification-course-input"
                            type="text"
                            placeholder="Seu curso está te esperando!"
                            value={this.state.message}
                            onChange={(event) =>
                              this.setState({ message: event.target.value })
                            }
                            validate={[required]}
                          />
                        </div>
                        <div className="notification-btn-msg">
                          {this.state.message.length > 0 ? (
                            <ButtonDefault
                              onClick={() => {
                                getUsersCourse(
                                  localStorage.getItem('IdCourseEdit'),
                                  headerName === 'Iniciados'
                                    ? 'not_finished'
                                    : headerName === 'Não iniciados'
                                    ? 'not_initiated'
                                    : 'finished',
                                  1,
                                  usersCourse.pagination.total_count,
                                  true,
                                  this.state.message
                                );
                              }}
                              label="Enviar"
                            />
                          ) : (
                            <ButtonDisable label="Enviar" />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <header className="notification-title">
                          Mensagem enviada!
                        </header>
                        <div className="notification-btn-msg">
                          <ButtonDefault
                            onClick={() => this.props.closeDialogNotification()}
                            label="OK"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Modal>
              </div>
            ) : this.state.selectedEditActive === 'Ações' ? (
              <ActionsModule
                sendCourseReport={(email) =>
                  this.props.sendCourseProgressReport(courseDetails.id, email)
                }
                sendNotificationReport={(usersKind, message) =>
                  sendNotificationUsersV2(
                    courseDetails.id,
                    usersKind === 'initiated'
                      ? 'not_finished'
                      : usersKind === 'not_initiated'
                      ? 'not_initiated'
                      : 'finished',
                    message
                  )
                }
                searchUserCertificate={(search, page) =>
                  this.props.getUserCertificate(
                    courseDetails.id,
                    search,
                    page,
                    10
                  )
                }
                loading={loading}
                usersCertificate={this.props.usersCertificate}
                resetUsersCertificate={this.props.resetUsersCertificate}
                resendUserCertificate={(userId) =>
                  this.props.resendUserCertificate(courseDetails.id, userId)
                }
                downloadUserCertificate={(userId) =>
                  this.props.downloadUserCertificate(
                    courseDetails.id,
                    userId,
                    courseDetails.title
                  )
                }
              />
            ) : (
              <BodyTable>
                <Table>
                  <TableHead>
                    <TableHeadRow>
                      <label>Nome do código</label>
                    </TableHeadRow>
                    <TableHeadRow>
                      <label>Código</label>
                    </TableHeadRow>
                    <TableHeadRow>
                      <label>Expiração</label>
                    </TableHeadRow>
                    <TableHeadRow>
                      <label>Quantidade</label>
                    </TableHeadRow>
                    <TableHeadRow>
                      <label>Criado por:</label>
                    </TableHeadRow>
                    <TableHeadRow>
                      <label>Ações</label>
                    </TableHeadRow>
                  </TableHead>

                  <TableBody>
                    {this.props.vouchers.vouchers &&
                      this.props.vouchers.vouchers
                        .slice(this.state.init, this.state.init + 10)
                        .map(
                          ({ id, name, code, expires_at, quantity, owner }) => (
                            <TableRow>
                              <TableData>{name}</TableData>
                              <TableData>{code}</TableData>
                              <TableData>
                                {new Date(expires_at).toLocaleDateString(
                                  'pt-br',
                                  {
                                    timeZone: 'UTC'
                                  }
                                )}
                              </TableData>
                              <TableData>
                                <span
                                  className="quantity"
                                  onClick={() =>
                                    this.showUsersVoucher(id, quantity)
                                  }
                                >
                                  {quantity}
                                </span>
                              </TableData>
                              <TableData>{owner && owner.name}</TableData>
                              <TableData>
                                <div>
                                  <img
                                    onClick={() =>
                                      this.handleModal(true, 'edit', id)
                                    }
                                    className="img-edit"
                                    src={IcoEdit}
                                    alt="Ícone de edição"
                                  />
                                  <img
                                    onClick={() => this.handleRemoveItem(id)}
                                    data-tip="Excluir"
                                    className="imagicon"
                                    src={RemoveIcon}
                                    alt="ícone apagar"
                                  />
                                </div>
                              </TableData>
                            </TableRow>
                          )
                        )}
                  </TableBody>
                </Table>
                {this.props.vouchers.pagination &&
                  this.props.vouchers.pagination.total_pages > 1 && (
                    <FooterPagination
                      activePage={this.state.activePage}
                      handlePageChange={(pageNumber) => {
                        this.props.getVouchers(courseDetails.id, pageNumber);
                        this.setState({
                          // init: (pageNumber - 1) * 10,
                          activePage: pageNumber
                        });
                      }}
                      totalItemsCount={
                        this.props.vouchers.pagination.total_count
                      }
                    />
                  )}
              </BodyTable>
            )}
          </div>
        </div>
        <ProgressToast progress={this.props.patchingQuizAnswers} />
        <Modal
          visible={this.state.showModal.show}
          width="736"
          height="450"
          effect="fadeInUp"
        >
          <form
            onSubmit={
              this.state.showModal.type === 'add'
                ? this.handleAddItem
                : this.handleEditItem
            }
          >
            <MuiThemeProvider>
              <div className="dialogyesno-body">
                <div className="label-dialog-tag">
                  {this.state.showModal.type === 'add'
                    ? 'Novo código'
                    : 'Editar código'}
                </div>
              </div>
              <div />
              <div className="row">
                <div className="offset-md-1 col-md-10">
                  <input type="hidden" name="id" value={courseDetails.id} />
                  <Field
                    name="name"
                    validate={[required]}
                    id="name"
                    label="Nome do código"
                    component={TextFieldDefault}
                    type="text"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="offset-md-1 col-md-10">
                  <Field
                    name="code"
                    validate={[required]}
                    id="code"
                    label="Código de validação"
                    component={TextFieldDefault}
                    type="text"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="offset-md-1 col-md-5">
                  <Field
                    name="expires_at"
                    validate={[required]}
                    id="expires_at"
                    label="Data de expiração (dd/mm/aaaa)"
                    component={TextFieldDefault}
                    type="text"
                    style={{ width: '100%' }}
                    onKeyUp={this.onDateKeyUp}
                    maxlength="10"
                  />
                </div>
                <div className="col-md-5">
                  <Field
                    name="quantity"
                    validate={[required]}
                    id="number"
                    label="Quantidade"
                    component={TextFieldDefault}
                    type="number"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <div style={{ marginTop: '20px' }} className="row">
                <div className="offset-md-1 col-md-5">
                  <ButtonDialogTag
                    onClick={() => this.handleModal(false, '')}
                    label="Cancelar"
                    type="button"
                  />
                </div>
                <div className="col-md-5 ">
                  <ButtonDialogTag type="submit" label="Salvar" />
                </div>
              </div>
            </MuiThemeProvider>
          </form>
        </Modal>

        {this.props.users_voucher_list_alert ? (
          <DialogVouchers
            visible={this.props.users_voucher_list_alert}
            users_voucher_list={this.props.users_voucher_list}
            total={this.state.quantity}
            closeDialog={this.props.closeDialog}
          />
        ) : null}

        <DialogReportEmail course_id={courseDetails.id} />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    courseDetails: state.course.course,
    usersCourse: state.course.usersCourse,
    usersNotification: state.course.usersNotification,
    usersCertificate: state.course.usersCertificate,
    showModal: state.show_modal_birthday,
    moduleActive: state.course.moduleActive,
    headerName: state.getHeaderCourseDetails,
    visible: state.dialog_notification_users,
    patchingQuizAnswers: state.course.patchingQuizAnswers,
    vouchers: state.course.vouchers,
    users_voucher_list: state.course.data,
    users_voucher_list_alert: state.course.visible,
    searchingCourseUsers: state.course.searching_course_users,
    report_success: state.dialog_report_email.report_success,
    loading: state.progress_indeterminate
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHeaderCourseDetails: (titleName) => {
      dispatch(CourseApi.getHeaderCourseDetails(titleName));
    },
    showDialogMsg: () =>
      dispatch({ type: 'OPEN_DIALOG_NOTIFICATION_USERS', alert: true }),
    closeDialog: () =>
      dispatch({ type: 'SHOW_USERS_LIST_VOUCHER', data: [], visible: false }),
    sendNotification: (users, title, description) => {
      dispatch(NotificationApi.sendNotification(users, title, description));
    },
    closeDialogNotification: () =>
      dispatch(NotificationApi.closeDialogNotification()),
    reloadedCourse: (id) => {
      dispatch(CourseApi.reloadedCourse(id));
    },
    editCourseV2: (id) => {
      dispatch(CourseApi.editCourseV2(id));
    },
    sendNotificationUsersV2: (idCourse, kind, title) => {
      dispatch(CourseApi.sendNotificationsUsersV2(idCourse, kind, title));
    },
    getUsersCourseTotalCount: (
      idCourse,
      kind,
      page,
      per_page,
      isItToNotify,
      message,
      isNewCourseMessage
    ) => {
      dispatch(
        CourseApi.getUsersCourseTotalCount(
          idCourse,
          kind,
          page,
          per_page,
          isItToNotify,
          message,
          isNewCourseMessage
        )
      );
    },
    getUsersCourse: (idCourse, kind, page, per_page, isItToNotify, message) => {
      dispatch(
        CourseApi.getUsersCourse(
          idCourse,
          kind,
          page,
          per_page,
          isItToNotify,
          message
        )
      );
    },
    initialSearchByUsers: () => {
      dispatch({ type: 'INITIAL_SEARCH_BY_USERS' });
    },
    getVouchers: (id, page) => {
      dispatch(CourseApi.getVouchers(id, page));
    },
    addVoucher: (values) => {
      dispatch(CourseApi.addVoucher(values));
    },
    editVoucher: (values, id) => {
      dispatch(CourseApi.editVoucher(values, id));
    },
    removeVoucher: (courseId, voucherId) => {
      dispatch(CourseApi.removeVoucher(courseId, voucherId));
    },
    viewUsersVoucher: (values, id) => {
      dispatch(CourseApi.viewUsersVoucher(values, id, 1));
    },
    openReportEmailDialog: (kind, startDate, endDate) => {
      dispatch({
        type: 'OPEN_REPORT_EMAIL_MODAL',
        payload: { kind, startDate, endDate }
      });
    },
    sendCourseProgressReport: (id, email) => {
      dispatch(CourseApi.sendCourseProgressReport(id, email));
    },
    getUserCertificate: (idCourse, search, per_page = 10, page = 1) => {
      dispatch(CourseApi.getUserCertificate(idCourse, search, per_page, page));
    },
    resetUsersCertificate: () => {
      dispatch({
        type: 'SET_USERS_CERTIFICATE',
        usersCertificate: {
          pagination: {
            current_page: 1,
            total_count: 0
          }
        }
      });
    },
    resendUserCertificate: (courseId, userId) => {
      dispatch(NotificationApi.resendCertificate(courseId, userId));
    },
    downloadUserCertificate: (courseId, userId, courseTitle) => {
      dispatch(
        CourseApi.downloadUserCertificate(courseId, userId, courseTitle)
      );
    },
    openErrorDialog: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message
        }
      })
  };
};

let InitializeFromStateForm = reduxForm({
  form: 'code'
})(ContainerModules);

InitializeFromStateForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
