import styled from 'styled-components';
import { style } from '../../../../style';

const fontFamily = { fontFamily: 'Rubik, sans-serif' };

export const TagsComponentHeader = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
`;

export const TagsComponentHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
`;

export const TagsComponentHeaderTitleValue = styled.h1`
  ${fontFamily};
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.isActiveTab ? style.inpuStyle.colorInput: '#808080'};
  border-bottom: ${(props) => props.isActiveTab ? `2px solid ${style.inpuStyle.colorInput}`: ''};

  &:hover{
    opacity: ${(props) => props.isActiveTab ? '': '0.3'};
    cursor: ${(props) => props.isActiveTab ? 'default': 'pointer'};
    border-bottom: ${(props) => props.isActiveTab ? '': '2px solid #403f4c'};
  }
`;

export const TagsComponentHeaderButtons = styled.div`
  display: flex;
  align-items: center;

  .btn-click:hover {
    font-size: inherit;
  }
`;

export const TagsContentBody = styled.div``;

export const TagsContentBodySection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &:not(:first-of-type) {
    margin-top: 48px;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  grid-area: search;
  position: relative;
  flex: 1;
  align-items: center;
  max-width: 400px;

  > svg {
    position: absolute;
  }

  .magnifier {
    right: 10px;
    bottom: 10px;
    opacity: 0.8;
  }
`;

export const InputSearch = styled.input`
  font-family: Rubik;
  height: 40px;
  width: 100%;

  /* border: 1px solid #E4E4E5; */
  border: 2px solid #e4e4e5;
  border-radius: 4px;
  padding: 10px 40px 10px 10px;
  font-size: 16px;

  color: #808080;

  &::placeholder {
    color: #808080;
  }

  &:focus {
    border: none;
    outline-style: solid;
    outline-color: ${style.inpuStyle.colorInput};
    outline-width: 1px;
  }

  &:hover {
    border: ${(props) => (props.isDisabled ? '1px solid #E4E4E5' : 'none')};
    outline-style: solid;
    outline-color: ${style.inpuStyle.colorInput};
    outline-width: ${(props) => (props.isDisabled ? '0px' : '1px')};
  }
`;
