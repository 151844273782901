export function tagsWithIds(state = { tags: []}, action) {

  if (action.type === 'GET_TAG_PARSED_WITH_IDS') {
    return {
      ...state,
      tags: action.continuation ? [...state.tags, ...action.tags_with_ids] : action.tags_with_ids
    }
  }

  return state;
}
