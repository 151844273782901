import React, { Component, Fragment } from 'react';

import { style } from '../style';

import iconSum from '../componentes/canvas/img/icon-sum.svg';
import iconSubtraction from '../componentes/canvas/img/icon-subtraction.svg';
import iconIntersection from '../componentes/canvas/img/icon-intersection.svg';

import './segmentationContentStyle.css';

export class dialogSegmentation extends Component {
  constructor() {
    super();
    this.state = { colorPrimaryOpacity: style.inpuStyle.colorInput };
  }

  componentDidMount() {
    let valueColor = this.hexToRGBA(style.inpuStyle.colorInput, 0.25);
    this.setState({ colorPrimaryOpacity: valueColor });
  }

  hexToRGBA = (hex, opacity) => {
    return (
      'rgba(' +
      (hex = hex.replace('#', ''))
        .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
        .map(function(l) {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(isFinite(opacity) ? opacity : 1)
        .join(',') +
      ')'
    );
  };

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-12 header-content-text-segmentation">
            Como funciona a segmentação?
          </div>
        </div>
        <div className="row content-segmentation-tag">
          <div className="col-sm-4">
            <div className="container-illustration-segmentation-tag">
              <div
                className="circle-segmentation"
                style={{ backgroundColor: this.state.colorPrimaryOpacity }}
              >
                Belo Horizonte
              </div>
              <div className="circle-segmentation">São Paulo</div>
            </div>
          </div>
          <div className="col-sm-8">
            <h4>Soma</h4>
            <p>
              Utilize quando quiser atingir mais de um grupo ao mesmo tempo.
            </p>
            <footer className="footer-segmentation-content">
              <div
                className="tag-segmentation-content-modal"
                style={{ backgroundColor: style.inpuStyle.colorInput }}
              >
                BELO HORIZONTE
              </div>
              <img src={iconSum} alt="Soma" />
              <div
                className="tag-segmentation-content-modal"
                style={{ backgroundColor: style.inpuStyle.colorInput }}
              >
                SÃO PAULO
              </div>
            </footer>
          </div>
        </div>
        <div className="row content-segmentation-tag">
          <div className="col-sm-4">
            <div className="container-illustration-segmentation-tag segmentation-tag-intercession">
              <div
                className="circle-segmentation"
                style={{ backgroundColor: this.state.colorPrimaryOpacity }}
              >
                Mulher
              </div>
              <div className="circle-segmentation">Bh</div>
            </div>
          </div>
          <div className="col-sm-8">
            <h4>Interseção</h4>
            <p>
              Utilize quando precisar atingir pessoas que estão entre dois
              grupos.
            </p>
            <footer className="footer-segmentation-content">
              <div
                className="tag-segmentation-content-modal"
                style={{ backgroundColor: style.inpuStyle.colorInput }}
              >
                MULHER
              </div>
              <img src={iconIntersection} alt="intercessão" />
              <div
                className="tag-segmentation-content-modal"
                style={{ backgroundColor: style.inpuStyle.colorInput }}
              >
                BELO HORIZONTE
              </div>
            </footer>
          </div>
        </div>
        <div className="row content-segmentation-tag last-footer-segmentation">
          <div className="col-sm-4">
            <div className="container-illustration-segmentation-tag segmentation-tag-subtraction">
              <div
                className="circle-segmentation"
                style={{ backgroundColor: this.state.colorPrimaryOpacity }}
              >
                Todos
                <div className="circle-segmentation">Rh</div>
              </div>
            </div>
          </div>
          <div className="col-sm-8">
            <h4>Subtração</h4>
            <p>
              Utilize quando precisar retirar algum grupo menor de um grupo
              maior.
            </p>
            <footer className="footer-segmentation-content">
              <div
                className="tag-segmentation-content-modal"
                style={{ backgroundColor: style.inpuStyle.colorInput }}
              >
                TODOS
              </div>
              <img src={iconSubtraction} alt="Subtração" />
              <div
                className="tag-segmentation-content-modal"
                style={{ backgroundColor: style.inpuStyle.colorInput }}
              >
                RH
              </div>
            </footer>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default dialogSegmentation;
