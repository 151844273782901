import styled, { css } from 'styled-components';
import { style as teamStyle } from '../../../../../style';

const giveMeRandom = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const particleAnimator = () => {
  let animation = '';

  for (let i = 0; i < 28; i++) {
    const size = giveMeRandom(0, 5) + 5;

    animation += `
      @keyframes particle-animation-${i} {
        100% {
          transform: translate3d(${giveMeRandom(0, 1110)}px,
          ${giveMeRandom(0, 296)}px, ${giveMeRandom(0, 100)}px);
        }
      }

      .particle:nth-child(${i}){
        animation: particle-animation-${i} 60s infinite;
        opacity: ${giveMeRandom(0, 100) / 100};
        height: ${size}px;
        width: ${size}px;
        animation-delay: -${i * 0.2}s;
        transform: translate3d(${giveMeRandom(0, 1110)}px,
        ${giveMeRandom(0, 296)}px, ${giveMeRandom(0, 100)}px);
        background: ${teamStyle.inpuStyle.colorInput};
      }
    `;
  }

  return css`
    ${animation}
  `;
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 296.5px;
  position: relative;

  .particle {
    position: absolute;
    border-radius: 50%;
  }

  ${particleAnimator()}
`;

export const NoContentMessage = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
