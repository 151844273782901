import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { reduxForm, formValueSelector } from 'redux-form';
import { required, maxLength190 } from '../validation/FormValidation';
import IcoExit from '../utils/img/exit-dialog.png';
import ButtonDefault, {
  TextFieldWithoutForm,
  ButtonDisable,
  CheckboxDefault
} from '../utils/FormsComponents';
import {
  TextArea,
  TextFieldForm,
  SelectForm
} from '../utils_form/FormComponents';
import UserApi from '../../Logicas/UserApi';
import TagApi from '../../Logicas/TagApi';
import NotificationApi from '../../Logicas/NotificationApi';
import Avatar from 'material-ui/Avatar';
import { BorderTop } from '../utils/dialog_styled';

require('./notificationdialog.css');

export class DialogCreateNotification extends Component {
  constructor() {
    super();
    this.state = { users_selected: [] };
    this.getByName = this.getByName.bind(this);
    this.sendValidation = this.sendValidation.bind(this);
  }
  getByName(value) {
    this.setState({ searchvalue: value.target.value });
    if (value.target.value.length > 3) {
      this.props.getUserName(value.target.value);
    } else if (value.target.value.length === 0) {
      this.props.getUserPage(1);
    }
  }
  selectUser(user_id, teste2, teste3) {
    if (this.state.users_selected.length === 0) {
      var user = [];
      user.push(user_id);
      this.setState({ users_selected: user });
    } else {
      var user = this.state.users_selected;
      var users_update = [];
      users_update = user.map(function(user) {
        if (user !== user_id) {
          return user_id;
        }
      });
      user = users_update;
      this.setState({ users_selected: user });
    }
  }
  sendValidation(values, action) {
    this.props.sendNotification(
      this.state.users_selected,
      values,
      this.props.tags_notification
    );
  }
  componentWillMount() {
    this.props.getTags();
  }
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <Modal
        classNames={{ width: '500px' }}
        showCloseIcon={false}
        open={this.props.visible}
        width="400px"
        height="90%"
        effect="fadeInUp"
      >
        <BorderTop></BorderTop>
        <div
          onClick={this.props.closeDialog}
          className="header-row-notification-header"
        >
          <img
            onClick={this.props.closeDialog}
            style={{
              width: '18px',
              height: '18px',
              marginRight: '20px',
              marginTop: '40px'
            }}
            src={IcoExit}
            alt="fechar"
          />
        </div>
        <form
          onSubmit={handleSubmit(this.sendValidation)}
          className="form-send-push"
        >
          <div className="column-notification-dialog">
            <label className="label-header-dialog">Criar notificaçao</label>
          </div>
          <div className="column-notification-text">
            <TextFieldForm
              name="notification_title"
              validations={[required, maxLength190]}
              floatingLabelText="Titulo"
            />
          </div>
          <div className="column-notification-text">
            <SelectForm
              multiple={true}
              elements_selected={this.props.tags_notification}
              elements={this.props.tags}
              name="notification_tags"
              validations={
                this.state.users_selected.length === 0 ? [required] : []
              }
              label="Tags*"
            />
          </div>
          <div className="row-actions-dialog-btn">
            <TextArea
              name="notification_description"
              validations={[required, maxLength190]}
              label="Mensagem da notificaçao"
            />
          </div>
          <div className="column-notification-text">
            <TextFieldWithoutForm
              disabled={
                this.props.tags_notification !== undefined
                  ? this.props.tags_notification.length > 0
                    ? true
                    : false
                  : null
              }
              value={this.state.searchvalue}
              onChange={this.getByName}
              floatingLabelText="Busque os usuários"
            />
          </div>
          <div className="users-row-select">
            {Array.isArray(this.props.list.users) ? (
              this.props.list.users.map(
                function(user) {
                  return (
                    <div className="row-user-select">
                      <div className="row-user-attributes">
                        <CheckboxDefault
                          checked={
                            this.state.users_selected.includes(user.id)
                              ? true
                              : false
                          }
                          onCheck={this.selectUser.bind(this, user.id)}
                        />
                        <Avatar size={40} src={user.image} />
                        <div className="name-user">{user.name}</div>
                      </div>
                    </div>
                  );
                }.bind(this)
              )
            ) : this.state.users_selected.length === 0 &&
              this.props.tags_notification === undefined ? (
              <div className="row-placeholder-dialog">
                <div className="label-header-dialog">
                  Sem tags ou usuários selecionados
                </div>
              </div>
            ) : (
              <div className="row-placeholder-dialog">
                <div className="label-header-dialog"> </div>
              </div>
            )}
          </div>
          <div className="row-btn-submit">
            {this.state.users_selected.length > 0 ||
            this.props.tags_notification !== undefined ? (
              <ButtonDefault label="Enviar notificaçao" />
            ) : (
              <ButtonDisable label="Enviar notificaçao" />
            )}
          </div>
        </form>
      </Modal>
    );
  }
}

let DialogCreateNotificationContainer = reduxForm({
  form: 'create_notification'
})(DialogCreateNotification);
const selector = formValueSelector('create_notification');
const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () => {
      dispatch({ type: 'CLOSE_DIALOG_PUSH' });
    },
    getUserPage: (id) => {
      dispatch(UserApi.getUserPage(id));
    },
    getUserName: (name) => {
      dispatch(UserApi.getUserName(name));
    },
    sendNotification: (users, notification, tags) => {
      dispatch(NotificationApi.sendNotification(users, notification, tags));
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    }
  };
};
DialogCreateNotificationContainer = connect(
  (state) => ({
    list: state.crudUser,
    tags: state.tagsparsed,
    tags_notification: selector(state, 'notification_tags'),
    list_marked: state.user.selectedUsers.users
  }),
  mapDispatchToProps
)(DialogCreateNotificationContainer);

export default DialogCreateNotificationContainer;
