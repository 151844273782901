import { style } from '../../style';
import { addImageProcess } from './addImageProcess';

const hasLargeImageLimit = ['youseed', 'sample', 'MRN', 'pelando'].includes(
  style.teamName.name
);

export function canAddFiles(filesToUpload) {
  let cont = 0;
  let conts = [];
  return new Promise(async (resolve, reject) => {
    filesToUpload.map(async (newFile, index) => {
      const i = await addImageProcess(URL.createObjectURL(newFile));
      if (i) cont += 1;
      if (
        (hasLargeImageLimit && (i.height > 4032 || i.width > 4032)) ||
        (!hasLargeImageLimit && (i.height > 2000 || i.width > 2000))
      ) {
        conts.push(index);
      }

      if (cont === filesToUpload.length) {
        if (conts.length === 0)
          resolve({ canAddAllFiles: true, indexesError: [] });
        else resolve({ canAddAllFiles: false, indexesError: conts });
      }
    });
  });
}
