import React, { Component } from 'react';
import { style } from '../../../style';

import IcoPontos from '../../utils/img/ic-pontos.svg';
require('./carddetailcourse.css');

class CardDetailsCourse extends Component {
  render() {
    const teamName = style.CurrencyName.name.split('/');

    return (
      this.props.course.tags_course && (
        <div>
          <div className="card-details-body">
            <div className="card-details-display">
              <div className="card-details-display-column">
                <label className="card-detail-label-title">
                  {this.props.course.title}
                </label>
                <label
                  style={{ marginTop: '-15px' }}
                  className="card-detail-label-tag"
                >
                  {this.props.course.description.length > 40
                    ? this.props.course.description.slice(0, 40) + '...'
                    : this.props.course.description}
                </label>
              </div>
              <label className="card-detail-label-tag">
                {this.props.course.tags_course[0]}
              </label>
              <label className="card-detail-label-score">
                <img className="img-score" src={IcoPontos} alt="pontuação"/>
                &nbsp;
                {this.props.course.score}&nbsp;
                {this.props.course.score > 1 ? teamName[1] : teamName[0]}
              </label>
              <img className="card-detail-img" src={this.props.course.image} alt="imagem do curso"/>
            </div>
          </div>
        </div>
      )
    );
  }
}
export default CardDetailsCourse;
