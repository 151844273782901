import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import errorHandler from '../componentes/utils/errorHandler';

export default class MenuApi {
  static getMenu() {
    return (dispatch) => {
      API.get('custom_menu_items', Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'GET_MENU_LIST',
              menuList: response.data.custom_menu_items
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static addItemMenu(values) {
    return (dispatch) => {
      const formData = new FormData(values);

      API.post('custom_menu_items', formData, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'ADD_MENU_LIST',
              menuList: response.data
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static editItemMenu(values, id) {
    return (dispatch) => {
      let formData = values;
      let configHeaders = Config.headersJson();

      if (values instanceof HTMLFormElement) {
        formData = new FormData(values);
        configHeaders = Config.headers();
      }

      API.patch('custom_menu_items/' + id, formData, configHeaders)
        .then(
          function(response) {
            dispatch({
              type: 'EDIT_MENU_LIST',
              menuList: response.data
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static deleteItemMenu(id) {
    return (dispatch) => {
      API.delete('custom_menu_items/' + id, Config.headers())
        .then(
          function() {
            dispatch({
              type: 'DELETE_MENU_LIST',
              id
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static sortMenu(values) {
    return (dispatch) => {
      API.post(
        'custom_menu_items/sort_menus',
        JSON.stringify(values),
        Config.headersJson()
      )
        .then(
          function(response) {
            // dispatch({
            //   type: 'GET_MENU_LIST'
            //   // id
            // });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }
}
