import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Login from './login';
class Home extends Component {
  render() {
    return (
      <div>
        <Login store={this.context.store} />
      </div>
    );
  }
}
Home.contextTypes = {
  store: PropTypes.object.isRequired
};
export default Home;
