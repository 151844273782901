import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

export class DashboardButton extends Component {
  render() {
    return (
      <S.ButtonBody
        bothDatesRequired={this.props.bothDatesRequired}
        disabled={this.props.disabled}
        isActive={this.props.isActive}
        onClick={this.props.onClick}
        withoutMargin={this.props.withoutMargin}
        style={this.props.style}
      >
        <S.ButtonLabel bothDatesRequired={this.props.bothDatesRequired}>
          {this.props.label}
        </S.ButtonLabel>
      </S.ButtonBody>
    );
  }
}

DashboardButton.propTypes = {
  bothDatesRequired: PropTypes.bool,
  isActive: PropTypes.bool
};

DashboardButton.defaultProps = {
  bothDatesRequired: true,
  isActive: false
};

export default DashboardButton;
