const defaultState = {
  loadingCourse: false,
  indexContent: 0,
  waitLoadingMovieImage: false
};

export function progress_course_loading(state = defaultState, action) {
  switch (action.type) {
    case 'SHOW_PROGRESS_COURSE_INDETERMINATE':
      return {
        ...state,
        loadingCourse: true,
        indexContent: action.indexContent
      };
    case 'HIDE_PROGRESS_COURSE_INDETERMINATE':
      return {
        ...state,
        loadingCourse: false,
        indexContent: action.indexContent
      };
    case 'WAIT_LOADING_MOVIE_IMAGE':
      return {
        ...state,
        waitLoadingMovieImage: action.waitLoadingMovieImage
      };
    default:
      return state;
  }
}
