export function dialoglink(state = false, action) {
  if (action.type === 'SHOW_DIALOG_LINK') {
    return action.visible;
  }

  if (action.type === 'CLOSE_DIALOG_LINK') {
    return action.visible;
  }
  return state;
}
