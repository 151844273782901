import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import { connect } from 'react-redux';
import SearchIcon from './SearchIcon';
import DialogApi from '../../../../Logicas/DialogApi';
import * as FormApiAsync from '../../../../Logicas/FormApiAsync';

import * as S from './UsersModal.style';

export class UsersModal extends Component {
  closeModal = () => {
    this.props.resetUsersModal();
    this.props.closeDialog();
  };

  render() {
    return (
      <Modal
        visible={this.props.alert.visible}
        width="600"
        height="400"
        effect="fadeInUp"
      >
        <S.ModalContainer>
          <S.QuestionContent>
            <span>Título:</span>
            <h2>{this.props.answerTitle}</h2>
          </S.QuestionContent>
          {this.props.content_survey !== undefined && (
            <>
              <S.AnswersContainer>
                <span>Opção escolhida:</span>
                <S.AnswerContent>
                  <span>{this.props.survey.index} - {this.props.survey.title}</span>
                  <div className="percentage">
                      {((this.props.survey
                          .users_who_voted
                          .length /
                          this.props.content.votes_amount) *
                          100).toFixed(1).replace(/[.,]0$/, "")}%
                  </div>
                </S.AnswerContent>
              </S.AnswersContainer>
            </>
          )}
          <S.InputWrapper>
            <S.Input
              onChange={(e) => this.props.searchUsersVoters(e.target.value)}
              placeholder="Busque pelos nomes de usuários"
              value={this.props.searchValue}
            />
            <S.Button>
              <SearchIcon />
            </S.Button>
          </S.InputWrapper>
          <S.UserList>
            {this.props.foundUsers.map((user) => (
              <S.UserName>{user.name}</S.UserName>
            ))}
          </S.UserList>
          <S.CloseButton onClick={() => this.closeModal()} />
        </S.ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.dialog,
    usersWhoVoted: state.getForm.modalContent.usersWhoVoted,
    answerTitle: state.getForm.modalContent.answerTitle,
    foundUsers: state.getForm.searchUsers.foundUsers,
    searchValue: state.getForm.searchUsers.searchValue,
    content: state.getForm.modalContent.content,
    content_survey: state.getForm.modalContent.content_survey,
    survey: state.getForm.modalContent.survey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    searchUsersVoters: (searchValue) => {
      dispatch(FormApiAsync.searchUsersVoters(searchValue));
    },
    resetUsersModal: () => {
      dispatch({ type: 'RESET_USERS_VOTERS' });
    }
  };
};

const UsersModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersModal);

export default UsersModalContainer;
