import React, { Component } from 'react';

import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import TagApi from '../../Logicas/TagApi';
import PostApi from '../../Logicas/PostApi';
import * as PostApiAsync from '../../Logicas/PostApiAsync';
import { BodyTable } from '../utils/BodyTable';
import DialogApi from '../../Logicas/DialogApi';
import DialogLikes from '../utils/dialogs/DialogLikes';
import ComponentHeader from '../utils/ComponenteHeader';
import DialogComments from '../utils/dialogs/DialogComments';
import { DialogYesNo, DialogAlert } from '../utils/DailogComponentes';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEdit
} from '../utils/Table';
import Loader from '../utils/AutoPagination/Loader';
import UserApi from '../../Logicas/UserApi';
import { FooterPagination } from '../utils/Pagination';
import MoodSurveysResults from './MoodSurveysResults';
import { style } from '../../style';

import { ReactComponent as IconEye } from './icons/eye.svg';
import ImageVeryHappy from './icons/veryHappy.png';
import ImageHappy from './icons/happy.png';
import ImageNormal from './icons/normal.png';
import ImageSad from './icons/sad.png';
import ImageAngry from './icons/angry.png';

import * as S from './MoodSurveys.styles';

class MoodSurveys extends Component {
  constructor() {
    super();
    this.state = {
      tabActive: 'search'
    };
  }

  componentWillMount() {
    !this.props.list.publications && this.props.getMoodSurveys();
  }

  render() {
    const { listRequestsActive } = this.props;

    if (this.props.list) {
      return (
        <div>
          <MuiThemeProvider>
            <ComponentHeader
              tooltip={
                <p>
                  Atualizar publicação
                  <br />
                </p>
              }
              actions={[
                {
                  name: 'Publicações',
                  action: () => this.props.getPostList('publication')
                },
                { name: 'Enquetes', action: this.props.getSurveyPublication },
                {
                  name: 'Formulários',
                  action: () => this.props.getPostList('form')
                },
                {
                  name: 'Sorteios',
                  action: () => this.props.getPostList('draw', 'raffle')
                },
                { name: 'Rascunhos', action: this.props.getDraftPublication }
              ]}
              name="Termômetro de humor"
              withMoodSurvey
              enablesubmit={listRequestsActive.length > 0}
            />
            <S.TabContainer>
              <S.Tab
                onClick={() => {
                  this.setState({ tabActive: 'search' });
                  !this.props.list.publications && this.props.getMoodSurveys();
                }}
                isActive={this.state.tabActive === 'search'}
              >
                Pesquisas
              </S.Tab>
              <S.Tab
                onClick={
                  this.props.paginationMoodSurveys &&
                  this.props.paginationMoodSurveys.total_count > 0
                    ? () => {
                        this.props.setAnswersStatus(true);
                        this.props.getMoodSurveys(1, 1, true);
                        this.setState({ tabActive: 'results' });
                      }
                    : () => {}
                }
                isActive={this.state.tabActive === 'results'}
                withoutAction={
                  this.props.paginationMoodSurveys &&
                  this.props.paginationMoodSurveys.total_count === 0
                }
              >
                Resultados
              </S.Tab>
            </S.TabContainer>
            {this.state.tabActive === 'search' && (
              <BodyTable>
                <Table>
                  <TableHead>
                    <TableHeadRow>Período:</TableHeadRow>
                    <TableHeadRow>Respostas:</TableHeadRow>
                    <TableHeadRow>Detalhes:</TableHeadRow>
                  </TableHead>
                  <TableBody>
                    {this.props.list.publications &&
                      this.props.list.publications.map(
                        function(post, i) {
                          return (
                            <S.TableRow
                              key={post.id}
                              onClick={() => {
                                this.props.setAnswersStatus(true);
                                this.props.getMoodSurveys(
                                  (i + 1) *
                                    this.props.paginationMoodSurveys
                                      .current_page,
                                  1,
                                  true
                                );
                                setTimeout(() => {
                                  this.setState({ tabActive: 'results' });
                                }, 500);
                              }}
                            >
                              <S.TableCol className="date">
                                <S.DateView>
                                  De
                                  <span>
                                    {' '}
                                    {moment
                                      .tz(post.start_date, 'America/Sao_Paulo')
                                      .format('DD/MM/YY')}{' '}
                                  </span>
                                  à
                                  <span>
                                    {' '}
                                    {moment
                                      .tz(post.end_date, 'America/Sao_Paulo')
                                      .format('DD/MM/YY')}
                                  </span>
                                </S.DateView>
                              </S.TableCol>
                              <S.TableCol>
                                <S.OptionSurveysContainer>
                                  <S.OptionSurvey>
                                    <img src={ImageAngry} />
                                    <span>
                                      {(post.statistics &&
                                        post.statistics.angry) ||
                                        0}
                                      %
                                    </span>
                                  </S.OptionSurvey>
                                  <S.OptionSurvey>
                                    <img src={ImageSad} />
                                    <span>
                                      {(post.statistics &&
                                        post.statistics.sad) ||
                                        0}
                                      %
                                    </span>
                                  </S.OptionSurvey>
                                  <S.OptionSurvey>
                                    <img src={ImageNormal} />
                                    <span>
                                      {(post.statistics &&
                                        post.statistics.normal) ||
                                        0}
                                      %
                                    </span>
                                  </S.OptionSurvey>
                                  <S.OptionSurvey>
                                    <img src={ImageHappy} />
                                    <span>
                                      {(post.statistics &&
                                        post.statistics.happy) ||
                                        0}
                                      %
                                    </span>
                                  </S.OptionSurvey>
                                  <S.OptionSurvey>
                                    <img src={ImageVeryHappy} />
                                    <span>
                                      {(post.statistics &&
                                        post.statistics.very_happy) ||
                                        0}
                                      %
                                    </span>
                                  </S.OptionSurvey>
                                  <S.OptionSurvey>
                                    <span className="option-survey">
                                      Prefiro não participar
                                    </span>
                                    <span>
                                      {(post.statistics &&
                                        post.statistics.not_participate) ||
                                        0}
                                      %
                                    </span>
                                  </S.OptionSurvey>
                                </S.OptionSurveysContainer>
                              </S.TableCol>
                              <S.TableCol>
                                <IconEye />
                              </S.TableCol>
                            </S.TableRow>
                          );
                        }.bind(this)
                      )}
                  </TableBody>
                </Table>

                {this.props.list &&
                  this.props.paginationMoodSurveys &&
                  this.props.paginationMoodSurveys.current_page && (
                    <footer className="footer-pagination">
                      <FooterPagination
                        activePage={
                          this.props.paginationMoodSurveys.current_page
                        }
                        totalItemsCount={
                          this.props.paginationMoodSurveys.total_count
                        }
                        handlePageChange={(pageNumber) =>
                          this.props.getMoodSurveys(pageNumber)
                        }
                      />
                    </footer>
                  )}
                <ReactTooltip className="tooltip" />
              </BodyTable>
            )}
            {this.state.tabActive === 'results' && <MoodSurveysResults />}
            <Loader loading={this.props.loading} />
          </MuiThemeProvider>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.post.list,
    paginationMoodSurveys: state.post.paginationMoodSurveys,
    searchActive: state.post.searchActive,
    page: state.page,
    alert: state.dialog,
    listTagName: state.tagsWithPagination.tags,
    tagsPagination: state.tagsWithPagination.pagination,
    pagination: state.pagination,
    edit_old_publication: state.edit_old_publication,
    comments_list: state.post_comments.data,
    comments_list_alert: state.post_comments.visible,
    likes_list: state.post_likes.data,
    likes_list_alert: state.post_likes.visible,
    views_list: state.post_views.data,
    views_list_alert: state.post_views.visible,
    userListName: state.user.userListName,
    loading: state.progress_indeterminate,
    listRequestsActive: state.post.listRequestsActive
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPostList: (kind, raffle = '') => {
      dispatch(PostApi.getPostListV2(kind, raffle));
    },
    getMorePublications: (page, kind, raffle) => {
      dispatch(PostApiAsync.getMorePublications(page, 'publication', ''));
    },
    getPage: (typeFilter, page, search, tag, per_page) => {
      dispatch(PostApi.getPostPage(typeFilter, page, search, tag, per_page));
    },
    deleteShowDialogPublication: (id, is_publication) => {
      dispatch(DialogApi.deleteAlert(id, is_publication));
    },
    editPost: (id, is_publication) => {
      dispatch(PostApi.editPost(id, is_publication));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    deletePost: (id, values) => {
      dispatch(PostApi.deletePost(id, values));
    },
    getDraftPublication: () => {
      dispatch(PostApi.getPostListDraft());
    },
    closeDialogAlert: () => {
      dispatch({ type: 'CLOSE_ALERT_NO_EDIT', alert: false });
    },
    getComments: (id) => {
      dispatch(PostApi.getComments(id, 1));
    },
    getLikes: (id) => {
      dispatch(PostApi.getLikes(id, 1));
    },
    getViews: (id) => {
      dispatch(PostApi.getViews(id));
    },
    getTags: (page, search) => {
      dispatch(TagApi.getTagsUserV2(page, search));
    },
    getUserListName: () => {
      dispatch(UserApi.getAuthors());
    },
    getSurveyPublication: () => {
      dispatch(PostApi.getSurveyList(1));
    },
    getTypeForm: (isForm) => {
      dispatch(PostApi.getTypeForm(isForm));
    },
    getMoodSurveys: (page = 1, per_page = 10, getAnswers = false) => {
      dispatch(PostApi.getMoodSurveys(page, per_page, getAnswers));
    },
    setNotificationShow: () => {
      dispatch({ type: 'ENABLE_NOTIFICATION_SHOW' });
    },
    setAnswersStatus: (getAnswers) => {
      dispatch({
        type: 'GET_ANSWERS_UPDATE',
        getAnswers
      });
    }
  };
};
const MoodSurveysContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoodSurveys);

export default MoodSurveysContainer;
