const defaultState = {
  course_list: [],
  course_list_pagination: {
    next_page: 1
  },
  course_pagination_is_loading: false,
  course_list_search_not_available: [],
  course_list_search_available: [],
  course_all_list_search: [],
  search_actual: '',
  course_search_active: false,
  searching_course_users: false,
  course: {},
  usersCourse: {},
  usersNotification: {},
  usersCertificate: {
    pagination: {
      current_page: 1,
      total_count: 0,
    },
  },
  moduleActive: 0,
  patchCourse: 0,
  patchingQuizAnswers: false,
  vouchers: [],
  visible: false,
  data: []
};

export function course(state = defaultState, action) {
  switch (action.type) {
    case 'SET_USERS_CERTIFICATE':
      return {
        ...state,
        usersCertificate: action.usersCertificate
      };
    case 'IS_LOADING_COURSE_PAGINATION':
      return {
        ...state,
        course_pagination_is_loading: true
      };
    case 'NOT_LOADING_COURSE_PAGINATION':
      return {
        ...state,
        course_pagination_is_loading: false
      };
    case 'GET_COURSE_LIST':
      return {
        ...state,
        course_list:
          action.course_list_pagination &&
          action.course_list_pagination.current_page > 1
            ? [...state.course_list, ...action.course_list]
            : action.course_list,
        course_list_pagination: action.course_list_pagination
      };
    case 'GET_COURSE_LIST_SEARCH':
      return {
        ...state,
        course_list_search_available: action.course_list_search_available,
        course_list_search_not_available:
          action.course_list_search_not_available,
        course_search_active: true,
        search_actual: action.search_actual
      };
    case 'CLEAR_COURSE_SEARCH':
      return {
        ...state,
        course_list_search_available: [],
        course_list_search_not_available: []
      };
    case 'SET_COURSE_SEARCH_ACTIVE':
      return {
        ...state,
        course_search_active: true
      };
    case 'SET_COURSE_SEARCH_NOT_ACTIVE':
      return {
        ...state,
        course_search_active: false
      };
    case 'GET_ALL_COURSES_LIST_SEARCH':
      return {
        ...state,
        course_all_list_search: action.course_all_list_search
      };
    case 'EDIT_COURSE':
      return {
        ...state,
        course: action.course
      };
    case 'EDIT_USERS_COURSE':
      return {
        ...state,
        usersCourse: action.usersCourse
      };
    case 'INITIAL_SEARCH_BY_USERS':
      return {
        ...state,
        searching_course_users: true
      };
    case 'SEARCH_BY_USERS_FINISHED':
      return {
        ...state,
        searching_course_users: false
      };
    case 'EDIT_USERS_NOTIFICATION':
      return {
        ...state,
        usersNotification: action.usersNotification
      };
    case 'SELECT_MODULE':
      return {
        ...state,
        moduleActive: action.moduleActive
      };
    case 'PATCH_COURSE':
      return {
        ...state,
        patchCourse: action.patchCourse
      };
    case 'PATCH_QUIZ_ANSWER_START': {
      return {
        ...state,
        patchingQuizAnswers: true
      };
    }
    case 'PATCH_QUIZ_ANSWER_FINISH': {
      return {
        ...state,
        patchingQuizAnswers: false
      };
    }
    case 'GET_VOUCHERS': {
      return {
        ...state,
        vouchers: action.vouchers
      };
    }
    case 'ADD_VOUCHER': {
      const addVoucher = [...state.vouchers.vouchers, action.voucher];
      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          vouchers: addVoucher
        }
      };
    }
    case 'EDIT_VOUCHER': {
      const findItem = state.vouchers.vouchers.map((item) =>
        action.voucher.id === item.id ? action.voucher : item
      );

      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          vouchers: findItem
        }
      };
    }
    case 'DELETE_VOUCHER': {
      const filterItem = state.vouchers.vouchers.filter(
        ({ id }) => action.voucherId !== id
      );

      return {
        ...state,
        vouchers: {
          ...state.vouchers,
          vouchers: filterItem
        }
      };
    }
    case 'SHOW_USERS_LIST_VOUCHER': {
      return {
        ...state,
        visible: action.visible,
        data: action.data.user_vouchers
      };
    }
    default:
      return state;
  }
}
