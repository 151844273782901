import styled from 'styled-components';

export const TagsList = styled.ul`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-inline-start: 0;
`;

export const TagItem = styled.li`
  margin: 4px;
  padding: 5px 12px;
  background-color: rgb(224, 224, 224);
  border-radius: 16px;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #333333;
`;
