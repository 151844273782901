import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import PubSub from 'pubsub-js';
import CourseApi from './CourseApi';
import * as CourseApiAsync from './CourseApiAsync';
import errorHandler from '../componentes/utils/errorHandler';
import { storeCopy } from '../index';

const isEmptyAnswer = (values) => {
  let quizAnswer = {
    isEmpty: false
  };
  if (values !== undefined) {
    const { answers } = values;
    if (answers !== undefined) {
      answers.forEach((value) => {
        if (value.answer_text === '') {
          quizAnswer.isEmpty = true;
        }
      });
    } else {
      values.forEach((value) => {
        if (value.answerstext === '') {
          quizAnswer.isEmpty = true;
        }
      });
    }
  }
  return quizAnswer;
};

export const PatchContentQuiz = (
  values,
  idcontent,
  idcourse,
  typeCourse,
  indexAnswers,
  fieldKind
) => async (dispatch) => {
  try {
    const {
      request: { pendingRequests }
    } = storeCopy.getState();

    if (fieldKind === 'textField') {
      dispatch({ type: 'PATCH_QUIZ_ANSWER_START' });
    }

    if (fieldKind === 'toggleField') {
      dispatch({
        type: 'SHOW_PROGRESS_COURSE_INDETERMINATE',
        indexContent: idcontent
      });
    }

    const quizAnswer = isEmptyAnswer(values);
    if (quizAnswer.isEmpty) {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Campo de resposta não pode ficar em branco.'
        }
      });
      return;
    }

    const formData = new FormData();
    formData.append('questiontext', values.question_text);
    formData.append('score', values.score);
    await API.patch('contents/' + idcontent, formData, Config.headers());

    const { answers } = values;
    const newAnswersObj = answers.map((answer) => ({
      id: answer.id,
      answerstext: answer.answer_text,
      iscorrect: answer.is_correct,
      content_id: answer.content_id,
      chosen: answer.chosen
    }));

    if (indexAnswers !== undefined) {
      const updatedAnswer = newAnswersObj[indexAnswers];
      await API.patch(
        `answers/${updatedAnswer.id}`,
        updatedAnswer,
        Config.headersJson()
      );
    }

    if (fieldKind === 'toggleField') {
      if (typeCourse === 'Longo') {
        await dispatch(CourseApiAsync.editCourseV2(idcourse));
      } else {
        await dispatch(CourseApi.editCourse(idcourse));
      }
      dispatch({
        type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
        indexContent: 0
      });
    }

    if (pendingRequests === 0) {
      dispatch({ type: 'PATCH_QUIZ_ANSWER_FINISH' });
    }

    PubSub.publish('clear_new_elements', true);
    setTimeout(() => {
      PubSub.publish('move_scroll', true);
    }, 1000);

    dispatch({ type: 'NO_SUBMITTING' });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
    dispatch(CourseApi.editCourseV2(idcourse));
    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
    dispatch({
      type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
      indexContent: 0
    });
    dispatch({ type: 'PATCH_QUIZ_ANSWER_FINISH' });
  }
};
