import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ReactTooltip from 'react-tooltip';
import ToolsButton from '../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import DashboardButton from '../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SearchComponent from '../academy/course/SearchComponent';

require('./componentheader.css');

class ComponentHeader extends Component {
  constructor() {
    super();
    this.state = {};
  }

  toggleSendPush = (checked) => {
    checked
      ? this.props.toggleSendPush({ type: 'SET_TRUE_SEND_PUSH' })
      : this.props.toggleSendPush({ type: 'SET_NULL_SEND_PUSH' });
  };

  componentDidMount() {
    this.props.toggleSendPush({ type: 'SET_NULL_SEND_PUSH' });
  }

  componentWillUnmount() {
    this.props.toggleSendPush({ type: 'SET_NULL_SEND_PUSH' });
    this.props.enableNotification && this.props.setEnableNotificationHidden();
  }

  render() {
    const styleComponentHeader = {
      minWidth: this.props.minWidth ? `${this.props.minWidth}px` : '0',
      marginTop: this.props.fieldDescription ? '20px' : '0',
      marginBottom: this.props.isNewCreatePost ? '20px' : '0'
    };

    const styleTitle = {
      padding: this.props.isNewCreatePost ? '0' : '20px',
      paddingTop: '20px',
      textAlign: 'center'
    };

    const styleMessage = {
      marginLeft: '5px'
    };

    const styleUl = {
      width: this.props.isNewCreatePost
        ? '100%'
        : this.props.withMoodSurvey
        ? '70%'
        : '',
      marginBottom: this.props.isNewCreatePost ? '0px' : '',
      paddingLeft: this.props.isNewCreatePost ? '0px' : ''
    };

    const styleWrapList = {
      width: this.props.isNewCreatePost ? '80%' : '100%'
    };

    return (
      <MuiThemeProvider>
        <div
          className={
            this.props.withoutBorderBottom
              ? 'container-fluid'
              : 'container-fluid header-second-border'
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: this.props.fieldDescription ? '' : '90px',
            // boxShadow: "0px 15px 10px -15px rgba(0, 0, 0, 0.3)",
            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottom: this.props.withoutBorderBottom
              ? 'none'
              : '1px solid rgba(0, 0, 0, 0.1)'
          }}
        >
          <div className="wrap-list" style={styleWrapList}>
            <ul className="ul-sub-nav" style={styleUl}>
              <li style={styleComponentHeader}>
                {this.props.fieldDescription && (
                  <span>{this.props.fieldDescription}:</span>
                )}
                <h1 className="title-header" style={styleTitle}>
                  {this.props.name}
                </h1>
              </li>
              {!!this.props.actions &&
                this.props.actions.map(
                  function(action, index) {
                    return (
                      <li key={index}>
                        <label
                          onClick={action.action}
                          className="menu-item-secondary"
                          style={action.style}
                        >
                          {action.name}
                        </label>
                      </li>
                    );
                  }.bind(this)
                )}
            </ul>
            {this.props.functionSearch !== undefined && (
              <SearchComponent
                functionSearch={this.props.functionSearch}
                setActiveSearch={this.props.setActiveSearch}
                setNotActiveSearch={this.props.setNotActiveSearch}
                course_search_active={this.props.course_search_active}
                clearSearch={this.props.clearSearch}
              />
            )}
            {/* <ul className="notification-container" style={this.props.functionSearch !== undefined ? {width:"50%"} : {}}>
              <li>
                {this.props.initialValues.enable_notification && (
                  <label className="checkbox-container" htmlFor="send_push">
                    Notificar alteração:
                    <input
                      type="checkbox"
                      name="send_push"
                      id="send_push"
                      onChange={({ currentTarget: { checked } }) => {
                        this.toggleSendPush(checked);
                      }}
                    />
                    <span className="checkmark" />
                  </label>
                )}
              </li>
            </ul> */}

            <ul
              className="ul-sub-nav-actions notification-container"
              style={
                this.props.isNewCreatePost
                  ? { justifyContent: 'right' }
                  : { justifyContent: 'right', paddingRight: '40px' }
              }
            >
              <li>
                {this.props.initialValues.enable_notification && (
                  <label className="checkbox-container" htmlFor="send_push">
                    Notificar alteração:
                    <input
                      type="checkbox"
                      name="send_push"
                      id="send_push"
                      onChange={({ currentTarget: { checked } }) => {
                        this.toggleSendPush(checked);
                      }}
                    />
                    <span className="checkmark" />
                  </label>
                )}
              </li>
              <li>{this.props.fieldarea}</li>
              {!!this.props.actionSecondarys &&
                this.props.actionSecondarys.map(
                  function(action, index) {
                    return (
                      <li key={index}>
                        <DashboardButton
                          disabled={this.props.enablesubmit}
                          bothDatesRequired={!this.props.enablesubmit}
                          label={action.name}
                          onClick={action.action}
                          style={{ width: '100px' }}
                        />
                      </li>
                    );
                  }.bind(this)
                )}
              {this.props.namesecondaction && (
                <li style={{ marginLeft: '0px' }}>
                  <DashboardButton
                    onClick={this.props.secondOnClick}
                    label={this.props.namesecondaction}
                    disabled={this.props.enablesecondsubmit}
                    isDisabled={this.props.enablesecondsubmit}
                    style={
                      this.props.namesecondaction.length <= 10
                        ? { width: '100px' }
                        : {}
                    }
                  />
                </li>
              )}
              {this.props.nameaction && (
                <li style={{ marginLeft: '0px' }}>
                  <ToolsButton
                    onClick={this.props.onClick}
                    label={this.props.nameaction}
                    disabled={this.props.enablesubmit}
                    isDisabled={this.props.enablesubmit}
                    style={
                      this.props.nameaction.length <= 10
                        ? { width: '100px' }
                        : {}
                    }
                  />
                </li>
              )}
            </ul>
          </div>
          {this.props.children}
        </div>
      </MuiThemeProvider>
    );
  }
}

export class ComponentHeaderClear extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        <nav
          style={{
            height: '80px'
          }}
          class="navbar   nav-header-componente navbar-toggleable-md navbar-light bg-faded"
        >
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ">
              <li>
                <a class=" menu-item" href="#"></a>
              </li>
              <li>
                <a class=" menu-item" href="#"></a>
              </li>
              <div data-tip data-for="descriptionheader">
                <a
                  style={{
                    marginLeft: '50px'
                  }}
                  class="title-header"
                >
                  {this.props.name}
                </a>
              </div>
              <ReactTooltip
                id="descriptionheader"
                aria-haspopup="true"
                role="example"
              >
                {this.props.tooltip}
              </ReactTooltip>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'componentHeader',
  enableReinitialize: true
})(ComponentHeader);

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSendPush: (type) => {
      dispatch(type);
    },
    setEnableNotificationHidden: () => {
      dispatch({ type: 'ENABLE_NOTIFICATION_HIDDEN' });
    }
  };
};

const selector = formValueSelector('componentHeader');

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.editpostform,
    send_push: selector(state, 'send_push')
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
