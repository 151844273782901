import React, { Component } from 'react';
import IcoPontos from '../../utils/img/ic-pontos.svg';
import RemoveItemIco from '../../utils/img/ic-excluir.svg';
import { AlternativeToggleCourse } from '../../utils/FormsComponents';
import { MuiThemeProvider } from 'material-ui/styles';

require('./card.css');

class CardCouse extends Component {
  render() {
    return (
      <MuiThemeProvider>
        <div
          className={
            this.props.avaiable ? 'corse-card-body' : 'card-body-disable'
          }
        >
          <div className="toogle-row">
            <div className="toogle-course">
              <AlternativeToggleCourse
                onToggle={this.props.changeAvaiableCourse.bind(
                  this,
                  this.props.index,
                  this.props.tags,
                  this.props.avaiable
                )}
                defaultToggled={this.props.avaiable}
              />
            </div>
          </div>
          <div className="delete-row">
            <div className="delete-course">
              <img
                onClick={this.props.deleteCourse.bind(
                  this,
                  this.props.index,
                  this.props.avaiable
                )}
                style={{ marginLeft: '15px' }}
                src={RemoveItemIco}
                alt="remover"
              />
            </div>
          </div>
          <div
            className="row-course-info"
            onClick={this.props.editCourse.bind(
              this,
              this.props.index,
              this.props.typeCourse
            )}
          >
            <img
              className="image-course"
              onMouseOver={this.setimage}
              onMouseLeave={this.removeimage}
              src={this.props.courseimage}
              alt="editar curso"
            />
            <span className="card-division-course" />
            <label className="title-item">
              {this.props.coursename.length > 44
                ? this.props.coursename.slice(0, 44) + '...'
                : this.props.coursename}
            </label>
          </div>
          <div
            className="row-course-attribute"
            onClick={this.props.editCourse.bind(
              this,
              this.props.index,
              this.props.typeCourse
            )}
          >
            <label className="duration-item">
              {this.props.description.length > 40
                ? this.props.description.slice(0, 40) + '...'
                : this.props.description}
            </label>
          </div>
          <div
            className="row-course-attribute"
            onClick={this.props.editCourse.bind(
              this,
              this.props.index,
              this.props.typeCourse
            )}
          >
            <label className="duration-item">
              <img className="img-score" src={IcoPontos} alt="pontuação" />
              <labe className="score-item-course">
                {this.props.coursescore.toLocaleString("pt-BR")}
              </labe>
              <labe className="score-item-name">{this.props.teamName}</labe>
            </label>
            <label className="duration-item">{this.props.courseduration}</label>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}
export default CardCouse;
