import styled from 'styled-components';
import { style as profile } from '../../../style';

export const dialog = styled.dialog`
  background-color: ${profile.inpuStyle.colorInput};
`;

export const BodyCardGallery = styled.div`
  width: calc(33% - 40px);
  height: 250px;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    transform: scale(1.15, 1.15);
  }

  &:not(:nth-child(3n + 1)) {
    margin-left: 40px;
  }

  &:nth-child(n + 4) {
    margin-top: 40px;
  }
`;

export const FooterCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
`;

export const ImageCard = styled.img`
  width: 100%;
  height: 183.38px;
  border-radius: 10px 10px 0px 0px;
  object-fit: contain;

  &:hover {
    cursor: pointer;
  }
`;

export const LabelCard = styled.label`
  font-family: Rubik;
  font-size: 12.1px;
  font-weight: 500;
  text-align: left;
  color: #acacac;
`;

export const EditIcon = styled.img`
  margin-right: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const DeleteIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
