import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Modal from 'react-awesome-modal';
import { Button } from '../../../user/components/UsersHeader/UsersHeader.styles';
import { TextFieldForm } from '../../../utils_form/FormComponents';
import { required, email } from '../../../validation/FormValidation';
import IcoExit from '../../../utils/img/exit-dialog.png';
import HistoricalApi from '../../../../Logicas/HistoricalApi';
import StoreApi from '../../../../Logicas/StoreApi';

import * as S from './DialogReportEmail.styles';

export class DialogHistoricalEmail extends Component {
  constructor() {
    super();
    this.sendReport = this.sendReport.bind(this);
  }

  sendReport(values) {
    const { userEmail } = values;
    const { kind, startDate, endDate, sendHistoricalReport } = this.props;

    sendHistoricalReport(kind, userEmail, startDate, endDate);
  }

  render() {
    const {
      showEmailDialog,
      closeEmailDialog,
      handleSubmit,
      requesting_historical,
      historical_success,
      success_message
    } = this.props;

    return (
      <Modal
        effect="fadeInUp"
        width="500px"
        height="310px"
        visible={showEmailDialog}
      >
        <S.CloseButton src={IcoExit} onClick={() => closeEmailDialog()} />
        <S.ModalContentBody>
          {!historical_success ? (
            <>
              <S.ModalTitle>
                Informe um e-mail para envio do relatório
              </S.ModalTitle>
              <S.ModalForm>
                <TextFieldForm
                  name="userEmail"
                  floatingLabelText="E-mail"
                  validations={[required, email]}
                />
              </S.ModalForm>
              <S.ModalText>
                <S.WarningText>Atenção:&nbsp;</S.WarningText>O relatório será
                processado e enviado para o<br />
                e-mail inserido. Antes de enviar, por favor, certifique-se
                <br />
                de que o endereço de e-mail informado está correto.
              </S.ModalText>
              {!requesting_historical ? (
                <Button
                  onClick={handleSubmit(this.sendReport)}
                  disabled={!this.props.valid}
                >
                  Enviar
                </Button>
              ) : (
                <Button>Solicitando...</Button>
              )}
            </>
          ) : (
            <>
              <S.ModalTitle>Relatório solicitado com sucesso!</S.ModalTitle>
              <S.ModalTextSuccess>{success_message}</S.ModalTextSuccess>
              <Button onClick={closeEmailDialog}>OK</Button>
            </>
          )}
        </S.ModalContentBody>
      </Modal>
    );
  }
}

DialogHistoricalEmail = reduxForm({
  form: 'dialogHistoricalEmailForm',
  enableReinitialize: true
})(DialogHistoricalEmail);

const mapStateToProps = (state) => ({
  showEmailDialog: state.dialog_historical_email.visible,
  kind: state.dialog_historical_email.kind,
  startDate: state.dialog_historical_email.startDate,
  endDate: state.dialog_historical_email.endDate,
  requesting_historical: state.dialog_historical_email.requesting_historical,
  historical_success: state.dialog_historical_email.historical_success,
  success_message: state.dialog_historical_email.success_message
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeEmailDialog: () =>
      dispatch({
        type: 'CLOSE_HISTORICAL_EMAIL_MODAL'
      }),
    sendPublicationsReport: (kind, email, startDate, endDate) => {
      dispatch(
        HistoricalApi.sendPublicationsReport(kind, email, startDate, endDate)
      );
    },
    sendHistoricalReport: (kind, email, startDate, endDate) => {
      dispatch(
        HistoricalApi.sendHistoricalReport(kind, email, startDate, endDate)
      );
    },
    sendSolicitationsReport: (kind, email, startDate, endDate) => {
      dispatch(
        StoreApi.sendSolicitationsReport(kind, email, startDate, endDate)
      );
    }
  };
};

const DialogHistoricalEmailForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogHistoricalEmail);

export default DialogHistoricalEmailForm;
