import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import IcoExit from '../../utils/img/exit-dialog.png';
import { MuiThemeProvider } from 'material-ui/styles';
import { reduxForm, formValueSelector } from 'redux-form';
import {
  AutoCompleteForm,
  SelectFormId,
  ToggleForm
} from '../../utils_form/FormComponents';
import ButtoDeFault from '../../utils/FormsComponents';
import { required } from '../../validation/FormValidation';
import TagApi from '../../../Logicas/TagApi';
import SectorApi from '../../../Logicas/SectorApi';
import ReportApi from '../../../Logicas/ReportApi';
import { style } from '../../../style';
import { BorderTop } from '../dialog_styled';

require('./dialogcomments.css');

class DialogReport extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillMount() {
    this.props.getTags();
    this.props.getSectorUser();
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <Modal
        open={this.props.visible}
        onClose={this.closeDialog}
        closeIconSvgPath={IcoExit}
        showCloseIcon={false}
        center={true}
        classNames={{
          width: '500px',
          heigth: '500px',
          'border-top': `8px solid ${style.inpuStyle.colorInput}`
        }}
      >
        <MuiThemeProvider>
          <BorderTop onClick={this.props.closeDialog}>
            <img
              alt="ícone sair"
              onClick={this.props.closeDialog}
              style={{
                width: '18px',
                height: '18px',
                marginRight: '20px',
                marginTop: '20px'
              }}
              src={IcoExit}
            />
          </BorderTop>
          <div className="header-row-report">
            <div className="label-comments">Rélatorio dos usuários</div>
            <div className="label-desc-report">
              Gere um relatório como xls segmentado do engajamento dos usuários
              na plataforma.
            </div>
          </div>
          <form
            className="form-report"
            onSubmit={handleSubmit(this.props.generateReport)}
          >
            <AutoCompleteForm
              name="tags_dialog_report"
              isLoading={this.props.isLoadingTags}
              validations={[required]}
              isMulti={false}
              label="Tag*"
              getTagAutoComplete={this.props.getTagAutoComplete}
              getAuto={this.props.getTagAutoComplete}
              tags={this.props.tags}
            />
            <SelectFormId
              name="sector"
              id="user_image"
              validations={
                this.props.report_all_users === undefined ? [required] : []
              }
              elements={this.props.sectorReducer}
              label="Setor"
            />
            <div className="display-flex-toogle">
              <label>Todos os usuários ?</label>
              <ToggleForm name="report_all_users" />
            </div>

            <div style={{ marginTop: '40px' }} />
            <ButtoDeFault label="Gerar" />
          </form>
        </MuiThemeProvider>
      </Modal>
    );
  }
}
let DialogReportContainer = reduxForm({ form: 'generatereport' })(DialogReport);
const mapStateToProps = (state) => {
  return {
    visible: state.dialog_report.visible,
    sectorReducer: state.sectorReducer.sectorList,
    tags: state.tagsparsed,
    tags_selected: selector(state, 'tags_dialog_report'),
    report_all_users: selector(state, 'report_all_users'),
    isLoadingTags: state.isLoadingtags,
  };
};
const selector = formValueSelector('generatereport');
const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () => {
      dispatch({ type: 'CLOSE_DIALOG_REPORT', data: { visible: false } });
    },
    getSectorUser: () => {
      dispatch(SectorApi.getSectorUser());
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser(true));
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    generateReport: (values) => dispatch(ReportApi.generateReport(values))
  };
};
DialogReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogReportContainer);

export default DialogReportContainer;
