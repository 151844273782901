import styled from 'styled-components';

export const Mansory = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  width: 100%;
  margin: 32px 0;
`;

export const MansoryItem = styled.div`
  height: 250px;
  min-width: 150px;
  margin: 0 0 8px 8px;
  border-radius: 5px;
  flex: auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;

  &::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    cursor: pointer;
  }

  &:nth-child(4n + 1) {
    width: 250px;
  }

  &:nth-child(4n + 2) {
    width: 325px;
  }

  &:nth-child(4n + 3) {
    width: 180px;
  }

  &:nth-child(4n + 4) {
    width: 380px;
  }
`;

export const MansoryImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  filter: brightness(50%);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    filter: brightness(100%);
  }
`;

export const MansoryVideo = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  filter: brightness(50%);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  .video-react.video-react-fluid {
    border-radius: 5px;
    padding-top: 0 !important;
    height: 100%;

    .video-react-video {
      border-radius: 5px;
      object-fit: cover;
    }
  }

  &:hover {
    filter: brightness(100%);
  }
`;

export const DeleteIconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  padding: 8px 10px;
  border-radius: 5px 0 0 0;
  background-color: #ffffff;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  ${MansoryItem}:hover {
    opacity: 1;
  }
`;

export const DeleteIcon = styled.img``;
