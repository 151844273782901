export function statusimport(state = [], action) {
  if (action.type === 'ERROR_IMPORT') {
    return { errors: action.errors, kind: 'error' };
  }
  if (action.type === 'SUCCESS_IMPORT') {
    return { sucess: action.success, kind: 'sucess' };
  }

  if (action.type === 'CLEAR') {
    return [];
  }

  return state;
}
