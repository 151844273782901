import React, { Component } from 'react';
import styled from 'styled-components';
import { style } from '../../../../../style';
import { TextFieldTitle } from '../../../../utils/FormsComponents';
import { TextAreaForm } from '../../../../utils_form/FormComponents';
import IconDelete from '../../../../utils/img/ic-delete.png';
import { debounce } from 'lodash';

require('../createForm.css');

const Action = styled.p`
  font-size: 14px;
  text-transform: none !important;
  margin: 0 6px 6px;
  font-family: 'Rubik';
  color: #808080;
  border-bottom: 2px solid transparent;
  transition: all .5s ease-out;

  &:hover{
    border-color: ${style.inpuStyle.colorInput};
    color: ${style.inpuStyle.colorInput};
  }
`

export class QuizModule extends Component {
  constructor() {
    super();
    this.state = {
      title: '',
      idContent: ''
    };
    this.editTitle = this.editTitle.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.zeroTitle = this.zeroTitle.bind(this);
  }

  componentDidMount() {
    this.setState({ title: this.props.element.text });
  }
  contaQuestions = debounce((idContent, value, idPatch, typeQuestion) => {
    this.props.saveContentEditQuestions(
      idContent,
      value,
      idPatch,
      typeQuestion
    );
  }, 1000);

  editTitle(title, value) {
    this.setState({ title: value });

    const idPatch = this.props.element.id;
    const typeQuestion = title.props.element.kind;
    const idContent = this.props.editPostFormId;

    this.contaQuestions(idContent, value, idPatch, typeQuestion);
  }


  editQuestion(question, idContent, value, idQuestion) {
    const type = question.props.element.kind;

    this.props.contaOptions(value, idContent, idQuestion, type);
  }

  zeroTitle(target) {
    if (target.nodeName === 'IMG') {
      this.setState({ title: "" })
      this.editTitle(this, "");
    }
  };


  render() {
    return (
      <div className="form-edit-canvas-body">
        <div className="tooltip-remove-text-form" style={{ flexDirection: 'column' }}>
          <div className="col-md-12">
            <div className="cancel" style={{ width: '100%' }}>
              <img
                style={{ marginLeft: '99%', zIndex: '3' }}
                src={IconDelete}
                alt=""
                onClick={(e) => this.zeroTitle(e.target)}
              />
            </div>
            <TextFieldTitle
              name={`quizTitle_${this.props.element.id}`}
              rows={1}
              onChange={(e) => this.editTitle(this, e.target.value)}
              id={`quizTitle_${this.props.element.id}`}
              label="Pergunta"
              type="text"
              value={this.state.title}
              style={{ borderBottom: 'none', width: '113%' }}
            />
          </div>
          <p className="view-characters" style={{ 'zIndex': 2, marginLeft: '15px', marginRight: 'auto', marginBottom: '1rem' }}>
            {this.state.title &&
              (this.state.title.length <= 255 ?
                `${this.state.title.length}/255 caracteres`
                : (
                  <span className='text-red'>
                    Quantidade de caracteres acima do permitido.
                  </span>
                )
              )
            }
          </p>
        </div>
        <div>
          {this.props.element.content_survey &&
            this.props.element.content_survey.map((element, id) => (
              <div className="list-questions" style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div className='col-md-11' style={{ minWidth: '330px' }}>
                    <div className="cancel" style={{ width: '100%' }}>
                      <img
                        style={{ marginLeft: '85%', marginTop: '15px', zIndex: '3' }}
                        src={IconDelete}
                        alt=""
                        onClick={() => this.editQuestion(
                          this,
                          this.props.element.id,
                          "..",
                          element.id
                        )}
                      />
                    </div>
                    <TextAreaForm
                      name={`quizAnswer_${element.id}`}
                      rowsMax={10}
                      multiLine={false}
                      onChange={(e) =>
                        this.editQuestion(
                          this,
                          this.props.element.id,
                          e.target.value,
                          element.id
                        )
                      }
                      id={`quizAnswer_${element.id}`}
                      floatingLabelText={`Resposta ${id + 1}`}
                      type="text"
                      value={element.title}
                      style={{ width: '92%' }}
                    />
                  </div>
                  <div
                    className="btn-body"
                    onClick={() =>
                      this.props.removeQuestion(
                        this.props.element.id,
                        element.id,
                        'quiz',
                        this.props.element.content_survey.length
                      )
                    }
                    style={{
                      color: this.props.style.inpuStyle.colorInput,
                      marginTop: 'auto'
                    }}
                  >
                    <Action>
                      Remover
                    </Action>
                  </div>
                </div>
                <p className="view-characters" style={{ 'zIndex': 2, marginRight: 'auto', marginBottom: '1rem', marginLeft: '15px' }}>
                  {element.title &&
                    (element.title.length <= 255 ?
                      `${255 - element.title.length} caracteres restantes.`
                      : (
                        <span className='text-red'>
                          Quantidade de caracteres acima do permitido.
                        </span>
                      ))
                  }
                </p>
              </div>
            ))}
          <div className="form-btn-body">
            <div
              className="btn-body"
              onClick={() =>
                this.setState(
                  {
                    idContent: this.props.element.id
                  },
                  () =>
                    this.props.saveOptionQuestion(
                      this.props.editPostFormId,
                      this.props.element.id
                    )
                )
              }
              style={{
                color: this.props.style.inpuStyle.colorInput,
                marginTop: '2rem'
              }}
            >
              <Action>
                Adicionar Opção
              </Action>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuizModule;
