const defaultState = {
  isDrawType: false,
  registeredDraw: false,
  drawDetails: {},
  viewDrawDetails: {},
  isDrawModalOpen: false,
  drawRules: [],
  showDrawResults: false
};

const drawReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'GET_TYPE_DRAW':
      return {
        ...state,
        isDrawType: action.isDrawType
      };
    case 'REGISTERED_DRAW':
      return {
        ...state,
        registeredDraw: action.registeredDraw
      };
    case 'DRAW_EDIT':
      return {
        ...state,
        drawDetails: action.drawDetails
      };
    case 'GET_DETAILS_DRAW':
      return {
        ...state,
        viewDrawDetails: action.viewDrawDetails
      };
    case 'DRAW_MODAL_OPEN':
      return {
        ...state,
        isDrawModalOpen: true
      };
    case 'DRAW_MODAL_CLOSE':
      return {
        ...state,
        isDrawModalOpen: false
      };
    case 'DRAW_RESULTS_SHOW':
      return {
        ...state,
        showDrawResults: true
      };
    case 'DRAW_RESULTS_HIDE':
      return {
        ...state,
        showDrawResults: false
      };
  }
  return state;
};

export default drawReducer;
