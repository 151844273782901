import styled from 'styled-components';

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const LazyLoad = styled.div`
  transform: ${(props) => (props.visible ? 'scale(1)' : 'scale(1.15)')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  box-shadow: ${(props) =>
    props.visible
      ? '0 0 0 rgba(0, 0, 0, 0.1)'
      : '0 5px 15px rgba(0, 0, 0, 0.3)'};
  ${defaultTransition};

  &:nth-child(6n + 2) {
    transition-delay: 150ms;
  }

  &:nth-child(6n + 3) {
    transition-delay: 300ms;
  }

  &:nth-child(6n + 4) {
    transition-delay: 450ms;
  }

  &:nth-child(6n + 5) {
    transition-delay: 500ms;
  }

  &:not(:nth-child(5n + 1)) {
    margin-left: 32px;
  }
`;
