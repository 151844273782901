import React, { Component } from 'react';
import { DropImageVIew } from './FormsComponents';
import BodyApi from '../../Logicas/BodyApi';
import { MuiThemeProvider } from 'material-ui/styles';

export class BodyContent extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onDrop(e) {
    BodyApi.setImage(e);
  }

  render() {
    const bodyclass = {
      borderRadius: '10px',
      padding: '50px',
      backgroundColor: '#ffffff',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
      marginTop: '10px',
      marginBottom: '10px',
      height: '700px'
    };

    const bodyclasscontent = {
      padding: '50px',
      backgroundColor: '#ffffff',
      marginTop: '10px',
      marginBottom: '10px',
      height: '700px'
    };

    if (!this.props.drag_area) {
      return (
        <MuiThemeProvider>
          <div>
            <div style={bodyclasscontent}>{this.props.children}</div>
          </div>
        </MuiThemeProvider>
      );
    } else {
      return (
        <div className="container">
          <div style={bodyclass}>
            <DropImageVIew onDrop={this.onDrop} />
          </div>
        </div>
      );
    }
  }
}
