const initialState = {
  sectionList: [],
  sectionEdit: {},
  toolEdit: {},
  sectionNameList: []
};

export const sectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SECTION_LIST':
      return {
        ...state,
        sectionList: action.sectionList
      };
    case 'GET_SECTION_NAMES':
      return {
        ...state,
        sectionNameList: action.sectionNameList
      };
    case 'GET_SECTION_EDIT':
      const {
        sectionEdit: { tags_names }
      } = action;

      const tagsValues = tags_names.map((tag) => ({
        value: tag,
        label: `#${tag}`
      }));

      action.sectionEdit.tags_names = tagsValues;

      return {
        ...state,
        sectionEdit: action.sectionEdit
      };
    case 'EDIT_SECTION_LINK':
      return {
        ...state,
        toolEdit: action.toolEdit
      };
    case 'EDIT_SECTION_RESET':
      return {
        ...state,
        sectionEdit: {}
      };
    default:
      return state;
  }
};
