import API, { API_V2 } from '../componentes/restservice/api.instance';
import APIDelete, { APIDeleteV2 } from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import CourseApi from '../Logicas/CourseApi';
import { browserHistory } from 'react-router';
import errorHandler from '../componentes/utils/errorHandler';
import { style } from '../style';

export default class DialogApi {
  static deleteModule(idelemente, idcourse, typeCourse) {
    return (dispatch) => {
      let API_CLIENT = API_V2;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDeleteV2;
      }

      API_CLIENT.delete('module_courses/' + idelemente, Config.headers())
        .then(function (response) {
          dispatch({
            type: 'SELECT_MODULE',
            moduleActive: 0,
          });
          if (typeCourse === 'long') {
            dispatch(CourseApi.editCourseV2(idcourse));
          } else {
            dispatch(CourseApi.editCourse(idcourse));
          }

          dispatch({
            type: 'CLOSE_ALERT',
            alert: {
              visible: false,
            },
          });
        })
        .catch(function (err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err),
            },
          });
        });
    };
  }

  static createModule(values, idcourse) {
    return (dispatch) => {
      var module_ = this.buildFormData(values, idcourse);
      API.post('module_courses/', module_, Config.headers())
        .then(function (response) {
          localStorage.setItem('IdCourseEdit', response.data.course_id);
          dispatch(CourseApi.editCourseV2(response.data.course_id));
          browserHistory.push('/course/modules/create');
          dispatch({ type: 'NO_SUBMITTING' });
        })
        .catch(function (err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err),
            },
          });
        });
    };
  }

  static pathModule(values, idmodule, idcourse) {
    return (dispatch) => {
      var module_ = this.buildFormDataPatch(values);
      API.patch('module_courses/' + idmodule, module_, Config.headers())
        .then(function (response) {
          dispatch({ type: 'EDIT_MODULE', module: response.data });
          if (idcourse) {
            dispatch(CourseApi.editCourseV2(idcourse));
          }
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'ENABLE_SUBMITION' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err),
            },
          });
          dispatch(CourseApi.editCourseV2(idcourse));
        });
      dispatch({ type: 'PATCH_COURSE', patchCourse: 0 });
    };
  }

  static getModuleEdit(idelemente, redirect) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('module_courses/' + idelemente, Config.headers())
        .then(function (response) {
          dispatch({ type: 'NO_SUBMITTING' });
          if (redirect === undefined) {
            dispatch({ type: 'EDIT_MODULE', module: response.data });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({ type: 'NO_SUBMITTING_CONTENT' });
          } else {
            dispatch({
              type: 'EDIT_MODULE',
              module: response.data,
              edit: true,
            });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }
        })
        .catch(function (err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err),
            },
          });
        });
    };
  }

  static createSubModule(values, idcourse, idModule) {
    return (dispatch) => {
      var module_ = this.buildFormData(values, idcourse, idModule);
      API.post('module_courses/', module_, Config.headers())
        .then(() => {
          dispatch(CourseApi.editCourseV2(idcourse));
          dispatch({ type: 'NO_SUBMITTING' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err),
            },
          });
        });
    };
  }

  static buildFormData(values, idcourse, idModule) {
    var formData = new FormData();
    formData.append('name', values.module_title);
    formData.append('score', values.module_score);
    formData.append('description', values.module_description);
    formData.append('course_id', idcourse);
    if (idModule) {
      formData.append('module_course_id', idModule);
    }
    return formData;
  }

  static buildFormDataPatch(values) {
    var formData = new FormData();
    formData.append('name', values.name);
    formData.append('score', values.score);
    formData.append('description', values.description);
    return formData;
  }
}
