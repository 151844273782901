import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import errorHandler from '../componentes/utils/errorHandler';

export default class TermApi {
  static saveTerm(values) {
    return async (dispatch) => {
      try {
        const formData = new FormData();

        if (values.img_term !== undefined) {
          formData.append('file', values.img_term[0]);
        } else {
          formData.append('link', values.link);
        }

        await API.post('terms/', formData, Config.headers());
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({
          type: 'CLOSE_DIALOG_TERM',
          alert: { visible: false }
        });
      }
    };
  }
}
