import React, { Component } from 'react';
import { DialogProgressIndeterminated } from '../../../../utils/DailogComponentes';
import Dropzone from 'react-dropzone';
import { Player } from 'video-react';

import CardContentVideo from '../../../../utils/img/card-content-video.svg';
import IcoRemove from '../../../../utils/img/ic-excluir.svg';
import CoverIcon from '../../../../utils/img/img-cover.svg';

import '../CourseLongStyles.css';

export class VideoModule extends Component {
  componentDidMount() {
    this.setState({ loading: this.props.loading.loadingCourse });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loading: nextProps.loading.loadingCourse });
  }

  render() {
    return (
      <div className="container-content-submodule">
        <div className="loading-content">
          {this.props.loading.loadingCourse &&
            this.props.loading.indexContent === this.props.content.id && (
              <DialogProgressIndeterminated />
            )}
        </div>
        <div className="header-content-submodule">
          <div className="header-title-img">
            <img src={CardContentVideo} alt="Componente de Video" />
            <div className="header-content-description">
              <p>CARD VIDEO</p>
              <p>{this.props.content.question_text}</p>
            </div>
          </div>
          <div className="removed-submodule">
            <img
              src={IcoRemove}
              alt="Remover Conteudo"
              onClick={() =>
                this.props.loading.waitLoadingMovieImage
                  ? this.props.callbackImageMovie()
                  : this.props.removedSubModuleAndContent(
                      this.props.content.id,
                      'content',
                      this.props.index,
                      this.props.indexContent,
                      "v2"
                    )
              }
            />
          </div>
        </div>
        <div className="video-cover-container">
          <Dropzone
            style={{
              border: 'none',
              width: '300px'
            }}
            onDrop={this.props.patchMovieContent.bind(
              this,
              this.props.subModule.id,
              this.props.content.id
            )}
          >
            {this.props.content.video ? (
              <Player src={this.props.content.video.url} />
            ) : (
              <div className="image-cover-default">
                <img alt="Capa do conteudo escolhido" src={CoverIcon} />
                <p>Adicionar video</p>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}

export default VideoModule;
