import { style } from '../../style';
var host = style.url.urlClient;
let hostClient = style.url.urlPerfilClient;
let hostClientYouseed = style.url.urlClientYouseed;
var version = 'v1';
var config = {
  url: host,
  imagesUrl: host,
  apiversion: version,
  apiurl: host + 'api/' + version,
  apiurlClient: hostClient + 'api/v2',
  apiurlClientYouseed: hostClientYouseed + 'api/v1',
  apiurlClientYouseedV2: hostClientYouseed + 'api/v2',
  apiUrlApiGatewayUploadVideo: 'https://aheypgto3c.execute-api.us-east-1.amazonaws.com/default',
};


export default config;
