import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Modal from 'react-awesome-modal';
import { MuiThemeProvider } from 'material-ui/styles';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import PostApi from '../../../../../../Logicas/PostApi';
import { style as teamStyles } from '../../../../../../style';
import { required, minValue1 } from '../../../../../validation/FormValidation';
import {
  DatePickerForm,
  TextFieldForm,
  SelectForm
} from '../../../../../utils_form/FormComponents';
import ToolsButton from '../../../../../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import DashboardButton from '../../../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import HourSelect from '../../../../../utils/dialogs/Components/HourSelect/HourSelect';
import * as S from './DrawModal.styles';
import * as Style from '../../../../../utils/dialogs/Components/ScheduleModal/ScheduleModal.styles';

require('../../../css/sweepstakes.css');

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: teamStyles.inpuStyle.colorInput,
    headerColor: teamStyles.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: teamStyles.inpuStyle.colorInput }
});

export class DrawModal extends Component {
  constructor() {
    super();
    this.formRef = React.createRef();
    this.state = {
      selectedTime: { selectedHour: '00', selectedMinutes: '00' },
    }
    this.handleSelectTimeExpiration = this.handleSelectTimeExpiration.bind(this);
    this.saveDrawSend = this.saveDrawSend.bind(this);
  }

  saveDrawSend = (values) => {
    values.selectedTime = this.state.selectedTime;
    const {
      postId,
      initialValues: { canvasId, raffleId },
      saveDraw
    } = this.props;
    saveDraw(postId, values, canvasId, raffleId);
  };

  handleSelectTimeExpiration = (selectedTimeExpiration) => {
    this.setState({selectedTime: selectedTimeExpiration});
  };

  render() {
    const { handleSubmit, isDrawModalOpen, initialValues } = this.props;
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div
          className="react-awesome-modal"
          style={{
            visibility: isDrawModalOpen ? 'visible' : 'hidden'
          }}
        >
          <Modal
            visible={isDrawModalOpen}
            width="600"
            height="400"
            effect="fadeInUp"
          >
            <S.FormContainer>
              <S.FormTitle>Regras do sorteio</S.FormTitle>
              <S.DrawForm
                onSubmit={handleSubmit(this.saveDrawSend)}
                ref={this.formRef}
              >
                <S.Line>
                  <S.InputContainer>
                    <TextFieldForm
                      style={{ width: '90%'}}
                      textareaStyle={{width:'160px'}}
                      name="qty_people"
                      disabled={false}
                      validations={[required, minValue1]}
                      floatingLabelText=" Quantidade de sorteados*"
                      type="number"
                    />
                  </S.InputContainer>
                  <S.InputContainer>
                    <SelectForm
                      name="rulesParsed"
                      multiple={false}
                      validations={[required]}
                      elements={['Curtir', 'Comentar', 'Curtir e Comentar']}
                      label="Regras do sorteio*"
                    />
                  </S.InputContainer>
                </S.Line>
                <S.Line>
                  <S.InputContainer>
                    <DatePickerForm
                      validations={[required]}
                      name="date_draw"
                      label="Data da realização do sorteio*"
                      isDrawModal="240px"
                    />
                  </S.InputContainer>
                  <Style.ScheduleHourContainer
                    style={{
                      marginLeft:"-60px"
                    }}
                  >
                    <HourSelect
                      label="Horário do Sorteio"
                      handleSelectTime={this.handleSelectTimeExpiration}
                      allowSelection={true}
                      marginRight={false}
                      date={initialValues.draw_date_kind === 'date_time' && initialValues.date_draw}
                    />
                  </Style.ScheduleHourContainer>
                </S.Line>
              </S.DrawForm>
              <div className="button-header-sweepstakes-draw-modal">
                <DashboardButton
                  label="Voltar"
                  onClick={ () =>
                    this.props.closeDrawModal()
                  }
                />
                <ToolsButton
                  label="Salvar"
                  onClick={() =>
                    this.formRef.current.dispatchEvent(new Event('submit'))
                  }
                  style={{':hover': {
                    'font-size': '16px'
                  }}}
                />
              </div>
            </S.FormContainer>
          </Modal>
        </div>
      </MuiThemeProvider>
    );
  }
}

const form = 'DrawModalForm';

let DrawModalForm = reduxForm({
  form,
  enableReinitialize: true
})(DrawModal);

const mapDispatchToProps = (dispatch) => ({
  saveDraw: (idPublish, values, canvasId, raffleId) => {
    dispatch(PostApi.saveDraw(idPublish, values, canvasId, raffleId));
  },
  closeDrawModal: () => {
    dispatch({ type: 'DRAW_MODAL_CLOSE' });
  }
});

DrawModalForm = connect(
  (state) => ({
    initialValues: state.draw.drawDetails,
    isDrawModalOpen: state.draw.isDrawModalOpen,
    drawRules: state.draw.drawRules,
    postId: state.editpostform.id
  }),
  mapDispatchToProps
)(DrawModalForm);

export default DrawModalForm;
