const initialState = {
  visible: false,
  apiMessage: '',
  isLoading: false
};

export function dialog_pontuation_users(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_DIALOG_PONTUATION_USERS':
      return {
        ...state,
        visible: true
      };
    case 'CLOSE_DIALOG_PONTUATION_USERS':
      return {
        ...state,
        visible: false,
        apiMessage: ''
      };
    case 'PONTUATION_USERS_SUCCESS':
      return {
        ...state,
        apiMessage: action.apiMessage
      };
    case 'PONTUATION_USERS_IS_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'PONTUATION_USERS_NOT_IS_LOADING':
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
