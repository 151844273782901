import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import UserApi from '../../Logicas/UserApi';
import PostApi from '../../Logicas/PostApi';
import * as FormApiAsync from '../../Logicas/FormApiAsync';
import { ProgressBar } from 'react-bootstrap';

import ComponentHeader from '../utils/ComponenteHeader';
import PreviewForm from '../canvas/components/previews/PreviewForm';
import AnswersTab from './AnswersTab/AnswersTab';

import shape from '../utils/img/Shape.svg';
import textCurto from '../utils/img/textCurto.svg';
import IcoPontos from '../utils/img/ic-pontos.svg';
import radiobutton from '../utils/img/radiobutton.svg';
import check_box from '../utils/img/outline-check_box-24px.png';
import ImgPlaceHolder from '../utils/img/place_holder_user.png';
import { ReactComponent as IconCheck} from '../utils/img/check-circle.svg';
import { ReactComponent as IconEye} from '../utils/img/eye-results.svg';
import eye from '../dashbord/dashboard_components/PublicationDash/img/ic-visualiza-oes@3x.png';

import { style } from '../../style';

require('./viewForm.css');

class ViewForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.getUserTagsAssociation(this.props.valuesForm.tags);
  }

  render() {
    if (this.props.valuesForm.teamName === undefined) {
      browserHistory.push('/post/form');
      return null;
    }

    const teamNames = this.props.valuesForm.teamName.split('/');
    let formInteraction =
      this.props.valuesForm.interaction.length / this.props.userTagAssociation;
    let preview = this.props.visualization / this.props.userTagAssociation;
    const nowDate = moment(new Date());
    return (
      <div>
        <ComponentHeader
          tooltip={
            <p>
              Atualizar publicação
              <br />
            </p>
          }
          actions={[]}
          name="Formulário"
          onClick={() => browserHistory.push('/post/form')}
          nameaction="Voltar"
          withoutBorderBottom={true}
        />
        <div className="select-form-details">
          <div className="">
            <ul className="body-details-first" style={{marginTop:"-30px", paddingBottom: "20px"}}>
              <li>
                <div
                  className={
                    this.props.nameFormDetails === 'Visão geral'
                      ? 'menu-item-details active'
                      : 'menu-item-details'
                  }
                  onClick={() => this.props.getFormDetails('Visão geral')}
                  style={{borderColor: style.inpuStyle.colorInput}}
                >
                  Visão geral
                </div>
              </li>
              {/* <li>
                <div
                  className={
                    this.props.nameFormDetails === 'Resultados'
                      ? 'menu-item-details active'
                      : 'menu-item-details'
                  }
                  onClick={() => this.props.getFormDetails('Resultados')}
                  style={{borderColor: style.inpuStyle.colorInput}}
                >
                  Resultados
                </div>
              </li>
              <li>
                <div
                  className={
                    this.props.nameFormDetails === 'Respostas'
                      ? 'menu-item-details active'
                      : 'menu-item-details'
                  }
                  onClick={() => this.props.getFormDetails('Respostas')}
                  style={{borderColor: style.inpuStyle.colorInput}}
                >
                  Respostas
                </div>
              </li> */}
              <li>
                <div
                  className={
                    this.props.nameFormDetails === 'Resultados'
                      ? 'menu-item-details active'
                      : 'menu-item-details'
                  }
                  onClick={() => this.props.getFormDetails('Resultados')}
                  style={{borderColor: style.inpuStyle.colorInput}}
                >
                  Resultados
                </div>
              </li>
            </ul>
            <div className='results-form' style={
              this.props.nameFormDetails === "Resultados" ?
                {paddingBottom: "25px"} : {}
            }>
              {
                this.props.nameFormDetails === 'Resultados' && (
                  <>
                    <>
                      <div className='result-form'>
                        <IconCheck style={{marginRight:"5px"}}/>
                        <span className='low-opacity'>Respostas:</span> 
                        <span style={{marginLeft:"5px", fontSize:"17px"}}>{this.props.valuesForm.form_answers_count}</span>
                      </div>
                      <div className='result-form'>
                        <IconEye style={{marginRight:"5px"}}/>
                        <span className='low-opacity'>Visualizações:</span> 
                        <span style={{marginLeft:"5px", fontSize:"17px"}}>{this.props.valuesForm.user_visualizations_count}</span>
                      </div>
                    </>
                    <button
                      className="download-report-btn"
                      onClick={() =>
                        this.props.downloadSpreadsheet(
                          this.props.valuesForm.id,
                          this.props.valuesForm.title
                        )
                      }
                      style={{ backgroundColor: style.inpuStyle.colorInput, marginLeft:"auto" }}
                    >
                      <div className="download-report-btn-txt">
                        Baixar respostas
                      </div>
                     </button>
                  </>

                  
                )
              }
                
            </div>
            <div className="container-body">
              {this.props.nameFormDetails === 'Visão geral' ? (
                <div className="body-view-world">
                  <div className="body-details-world">
                    <div className="details-wolrd">
                      <p className="title-world status">Status</p>
                      <div className="first-detais">
                        {/* {moment
                          .tz(this.props.valuesForm.end_date, 'Europe/Berlin')
                          .format('DD/MM/YYYY') >
                        moment.tz(nowDate, 'Europe/Berlin').format('DD/MM/YYYY')
                          ? 'Ativo'
                          : 'Desativado'}{' '}
                        -{' '} */}
                        <div className="deadline-responses">
                          <span>Prazo para respostas:</span>
                            {this.props.valuesForm.end_date !== null && 
                              this.props.valuesForm.end_date !== undefined
                                ? moment
                                    .tz(this.props.valuesForm.end_date, 'Europe/Berlin')
                                    .format('DD/MM/YYYY')
                                : 'Sem data final'}
                        </div>

                        <div className="deadline-gamification">
                          <span>Prazo para gamificação:</span>
                          {this.props.valuesForm.not_score_after !== null && 
                            this.props.valuesForm.not_score_after !== undefined
                              ? moment
                                  .tz(this.props.valuesForm.not_score_after, 'Europe/Berlin')
                                  .format('DD/MM/YYYY')
                              : 'Sem data final'}
                        </div>
                        
                      </div>
                    </div>
                    <div className="details-wolrd">
                      <p className="title-world engagement">Engajamento</p>
                      <div className="first-detais">
                        <React.Fragment>
                          <td className="form-progress visualization" style={{maxWidth:"50%"}}>
                            <img
                              data-tip="visualizações"
                              className="imagicon-eye-form-result"
                              src={eye}
                              alt="ícone visualizações"
                            />
                            <ProgressBar
                              now={
                                (this.props.valuesForm
                                  .user_visualizations_count /
                                  this.props.userTagAssociation) *
                                100
                              }
                            />
                            <p className="progress-description">
                              {this.props.valuesForm.user_visualizations_count}{' '}
                              / {this.props.userTagAssociation}
                            </p>
                            {this.props.valuesForm.user_visualizations &&
                              this.props.valuesForm.user_visualizations
                                .slice(0, 3)
                                .map(function(user) {
                                  return (
                                    <div className="user-img">
                                      <img
                                        className="img-engagement"
                                        src={
                                          user.image_url
                                            ? user.image_url
                                            : ImgPlaceHolder
                                        }
                                        alt="perfil-user"
                                      />
                                    </div>
                                  );
                                })}
                            <div className="user-img">
                              <p className="progress-description">...</p>
                            </div>
                          </td>
                          <td className="form-progress interaction" style={{maxWidth:"50%"}}>
                            <img
                              data-tip="interação"
                              className="imagicon-eye-form-result"
                              src={shape}
                              alt="ícone interações"
                            />
                            <ProgressBar now={formInteraction * 100} />
                            <p className="progress-description">
                              {this.props.valuesForm.interaction.length} /{' '}
                              {this.props.userTagAssociation}
                            </p>
                            {this.props.valuesForm.users_likes &&
                              this.props.valuesForm.users_likes.map(function(
                                user,
                                index
                              ) {
                                if (index < 3) {
                                  return (
                                    <div className="user-img">
                                      <img
                                        className="img-engagement"
                                        src={
                                          user.image.url
                                            ? user.image.url
                                            : ImgPlaceHolder
                                        }
                                        alt="perfil-user"
                                      />
                                    </div>
                                  );
                                } else if (index === 3) {
                                  return (
                                    <div className="user-img">
                                      <p className="progress-description">
                                        ...
                                      </p>
                                    </div>
                                  );
                                }
                              })}
                          </td>
                        </React.Fragment>
                      </div>
                    </div>
                    <div className="details-wolrd">
                      <p className="title-world punctuation">Pontuação</p>
                      <div className="first-detais">
                        <div>
                          <img className="img-score" src={IcoPontos} alt="ícone pontuação"/>
                          <labe className="score-item-course">
                            {this.props.valuesForm.score}
                          </labe>
                          <labe className="score-item-name">
                            {this.props.valuesForm.score > 1
                              ? teamNames[1]
                              : teamNames[0]}
                          </labe>
                        </div>
                      </div>
                    </div>
                    <div className="details-wolrd">
                      <p className="title-world segmentation">Segmentação</p>
                      <div className="first-detais">
                        <div className="segmentation-container">
                          {this.props.valuesForm.tags &&
                            this.props.valuesForm.tags.map(
                              function(tags, i) {
                                return (
                                  <div className="segmentation-container">
                                    <div className="tag-segmentation">
                                      <p className="tag-text-segmentation">
                                        {tags.name}
                                      </p>
                                    </div>
                                    {i + 1 <
                                      this.props.valuesForm.tags.length && (
                                      <div className="tag-segmentation-sum">
                                        <p className="tag-text-segmentation">
                                          +
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                );
                              }.bind(this)
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="body-preview-form">
                    <PreviewForm
                      props={this.props}
                      title={this.props.valuesForm.title}
                      contents={this.props.valuesForm.contents}
                    />
                  </div>
                </div>
              ) /* : this.props.nameFormDetails === 'Resultados' ? (
                <div className="body-result">
                  <div className="values-details">
                    <div className="card-details">
                      <p className="title-world results">Respostas</p>
                      <p className="title-world results-number">
                        {this.props.valuesForm.form_answers_count}
                      </p>
                    </div>
                    <div className="card-details">
                      <p className="title-world results">Visualizações</p>
                      <p className="title-world results-number">
                        {this.props.valuesForm.user_visualizations_count}
                      </p>
                    </div>
                    <div>
                      <button
                        className="download-report-btn"
                        onClick={() =>
                          this.props.downloadSpreadsheet(
                            this.props.valuesForm.id,
                            this.props.valuesForm.title
                          )
                        }
                        style={{ backgroundColor: style.inpuStyle.colorInput }}
                      >
                        <div className="download-report-btn-txt">
                          Baixar respostas
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="body-questions">
                    {this.props.valuesForm.contents &&
                      this.props.valuesForm.contents.map(function(content) {
                        return (
                          <>
                            {content.kind === 'survey' && (
                              <div className="container-question survey">
                                <div className="header-container-question">
                                  <img
                                    className="img-question-title"
                                    src={radiobutton}
                                    alt="radio-button"
                                  />
                                  <p className="title-question">
                                    {content.text}
                                  </p>
                                </div>
                                <div className="body-container-question">
                                  {content.votes_amount < 1 ? (
                                    <p className="text-details-question">
                                      Não foi obtida nenhuma resposta.
                                    </p>
                                  ) : (
                                    <div className="segmentation-container-survey">
                                      <div className="body-survey">
                                        <div className="body-graphic-survey">
                                          {content.content_survey.map(function(
                                            survey,
                                            index
                                          ) {
                                            return (
                                              <div className="graphic-survey">
                                                <div className="percentage-body-survey">
                                                  <div
                                                    className="quiz-percentage"
                                                    style={{
                                                      borderBottom: `1px solid ${style.inpuStyle.colorInput}`,
                                                      background: `${style.inpuStyle.colorInput}`,
                                                      height: `${(survey
                                                        .users_who_voted
                                                        .length /
                                                        content.votes_amount) *
                                                        100}%`,
                                                      width: '100%'
                                                    }}
                                                  />
                                                </div>
                                                <div className="title-graphic-survey">
                                                  <p className="text-details-question">
                                                    <strong>{index + 1}</strong>
                                                  </p>
                                                  <p>
                                                    {parseFloat(
                                                      (survey.users_who_voted
                                                        .length /
                                                        content.votes_amount) *
                                                        100
                                                    ).toFixed(0)}
                                                    %
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div className="body-list-subtitle-options">
                                          {content.content_survey.map(function(
                                            survey,
                                            index
                                          ) {
                                            return (
                                              <div className="list-subtitle-options">
                                                <p className="text-details-question">
                                                  <strong>{index + 1}</strong> -{' '}
                                                  {survey.title}
                                                </p>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {this.props.valuesForm.contents &&
                      this.props.valuesForm.contents.map(function(content) {
                        return (
                          <>
                            {content.kind === 'open_question' && (
                              <div className="container-question survey">
                                <div className="header-container-question">
                                  <img
                                    className="img-question-title"
                                    src={textCurto}
                                    alt="radio-button"
                                  />
                                  <p className="title-question">
                                    {content.content_open_question.question}
                                  </p>
                                </div>
                                <div className="body-container-question">
                                  <div className="body-quiz">
                                    <ul className="question-body">
                                      {content.content_open_question
                                        .open_responses.length > 0 ? (
                                        content.content_open_question.open_responses.map(
                                          function(responses) {
                                            return (
                                              <li className="responses-question">
                                                {responses.response}
                                              </li>
                                            );
                                          }
                                        )
                                      ) : (
                                        <p className="text-details-question">
                                          Não foi obtida nenhuma resposta.
                                        </p>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    {this.props.valuesForm.contents &&
                      this.props.valuesForm.contents.map(function(content) {
                        return (
                          <>
                            {content.kind === 'quiz' && (
                              <div className="container-question survey">
                                <div className="header-container-question">
                                  <img
                                    className="img-question-title"
                                    src={check_box}
                                    alt="radio-button"
                                  />
                                  <p className="title-question">
                                    {content.text}
                                  </p>
                                </div>
                                <div className="body-container-question">
                                  <div className="body-quiz">
                                    {content.votes_amount < 1 ? (
                                      <p className="text-details-question">
                                        Não foi obtida nenhuma resposta.
                                      </p>
                                    ) : (
                                      content.content_survey.map(function(
                                        quiz
                                      ) {
                                        return (
                                          <div className="graphic-quiz">
                                            <div className="title-graphic-quiz">
                                              <p className="text-details-question">
                                                {quiz.title}
                                              </p>
                                            </div>
                                            <div className="percentage-body">
                                              <div
                                                className="quiz-percentage"
                                                style={{
                                                  background: `${style.inpuStyle.colorInput}`,
                                                  width: `${(quiz
                                                    .users_who_voted.length /
                                                    content.votes_amount) *
                                                    100}%`,
                                                  height: '40px'
                                                }}
                                              />
                                            </div>
                                            <p className="text-details-question">
                                              {parseFloat(
                                                (quiz.users_who_voted.length /
                                                  content.votes_amount) *
                                                  100
                                              ).toFixed(0)}
                                              %
                                            </p>
                                          </div>
                                        );
                                      })
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
              ) */ /* : this.props.nameFormDetails === 'Respostas' ? (
                <AnswersTab />
              )  */: this.props.nameFormDetails === 'Resultados' ? (
                <div className="body-result">
                  <AnswersTab/>
                </div>
              ) : (
                <div className="body-individual-responses"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    valuesForm: state.getForm.formDetails,
    nameFormDetails: state.getHeaderFormDetails,
    userTagAssociation: state.user.userTagAssociation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserTagsAssociation: (tags) =>
      dispatch(UserApi.getUserTagsAssociation(tags)),

    getFormDetails: (titleName) => {
      dispatch(PostApi.getHeaderFormDetails(titleName));
    },
    downloadSpreadsheet: (formId, formTitle) => {
      dispatch(FormApiAsync.downloadSpreadsheet(formId, formTitle));
    }
  };
};

const ViewFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewForm);

export default ViewFormContainer;
