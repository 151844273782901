import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { browserHistory } from 'react-router';
import ButtonDefault from '../../../utils/FormsComponents';
import {
  LongTextFieldTitle,
  SelectForm
} from '../../../utils_form/FormComponents';
import ValidFile from '../../../utils/fileTypeFilter';
import DropZoneFile from '../../ToolsList/Components/DropZoneTools/DropZoneFile';
import IcoDocDrop from '../../../utils/img/ic-doc_newdoc.png';
import findIcon from './findIcon';

import * as SectionApi from '../../../../Logicas/SectionApi';
import * as S from './FileEdit.style';
import { addImageProcess } from '../../../utils/addImageProcess';

export class FileEdit extends Component {
  constructor() {
    super();
    this.state = {
      sectionIds: [],
      selectedSectorId: '',
      file: [0],
      previewIcon: IcoDocDrop
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { initialValues } = this.props;

    if (!Object.keys(this.props.initialValues).length) {
      browserHistory.push('/tools/list');
      return null;
    }

    this.setState({
      selectedSectorId: initialValues.external_link_section_id,
      previousSectorId: initialValues.external_link_section_id,
      file: [
        {
          preview: initialValues.file,
          name: initialValues.file.split('/').pop()
        }
      ],
      previewIcon: findIcon(initialValues.file)
    });
  }

  setSectorId = (fieldValue) => {
    const {
      sectionList,
      initialValues: { external_link_section_id: originalSectionId }
    } = this.props;

    delete fieldValue.preventDefault;
    const sectionName = Object.values(fieldValue).join('');

    const { id: selectedSectorId } = sectionList.find(
      (section) => sectionName === section.name
    );

    this.setState({
      previousSectorId: originalSectionId,
      selectedSectorId
    });
  };

  async updateFile(file) {
    if (!ValidFile(file[0].type)) {
      this.props.showErroAlert(
        'Arquivo não suportado para cadastro nesta seção.'
      );
      return;
    }
    const fileIsImage = file[0].type.includes("image");
    const fileIsGif = file[0].type.includes("gif");

    let hasError = false;
    if(fileIsImage){
      const i = await addImageProcess(URL.createObjectURL(file[0])); 
      if(i.height > 2000 || i.width > 2000) {
        hasError = true;
        this.props.dispatchImageError();
      }
    }
    
    if(fileIsGif && (file[0].size / (1024*1024).toFixed(2)) > 20){
      hasError = true;
      this.props.dispatchGifError();
    }
    
    if(!hasError) {
      const extension = findIcon(file);
      this.setState({ file, previewIcon: extension });
    }
  }

  patchFile = (formValues) => {
    const { file, selectedSectorId, previousSectorId } = this.state;
    const { patchFile } = this.props;

    formValues.file = file;
    formValues.sectorId = selectedSectorId;

    patchFile(formValues, previousSectorId);
  };

  render() {
    const { handleSubmit, sectionNameList } = this.props;

    return (
      <>
        <MuiThemeProvider>
          <S.ToolsComponentHeader>
            <S.ToolsComponentHeaderTitle>
              <S.ToolsComponentHeaderTitleValue>
                Editar arquivo
              </S.ToolsComponentHeaderTitleValue>
            </S.ToolsComponentHeaderTitle>
            <S.ToolsComponentHeaderButtons>
              <ButtonDefault
                label="Salvar"
                onClick={() =>
                  this.formRef.current.dispatchEvent(new Event('submit'))
                }
              />
            </S.ToolsComponentHeaderButtons>
          </S.ToolsComponentHeader>
          <S.FormContainer>
            <form onSubmit={handleSubmit(this.patchFile)} ref={this.formRef}>
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <LongTextFieldTitle
                          fullWidth={true}
                          name="name"
                          id="name"
                          label="Nome do arquivo*"
                          floatingLabelText="Digite ou cole o nome do recurso*"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <SelectForm
                          name="sector"
                          multiple={false}
                          label="Selecione um setor"
                          elements={sectionNameList}
                          onChange={(e) => this.setSectorId(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <S.DropZone className="col md-4">
                    <S.DropZoneActions>
                      <S.DropZoneField>
                        <DropZoneFile
                          onDrop={(file) => this.updateFile(file)}
                          file={this.state.file}
                          previewIcon={this.state.previewIcon}
                          name="file"
                        />
                      </S.DropZoneField>
                      <S.DropZoneText>{this.state.file[0].name}</S.DropZoneText>
                      <S.DropZoneText>
                        Clique ou arraste arquivos para aqui
                      </S.DropZoneText>
                    </S.DropZoneActions>
                  </S.DropZone>
                </div>
              </div>
            </form>
          </S.FormContainer>
        </MuiThemeProvider>
      </>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'createfile'
})(FileEdit);

const mapDispatchToProps = (dispatch) => {
  return {
    getToolsList: async () => {
      await dispatch(SectionApi.getSectionList());
    },
    patchFile: async (values, previousSectorId) => {
      await dispatch(SectionApi.patchTool(values, previousSectorId));
    },
    showErroAlert: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: message }
      }),
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
    dispatchGifError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.",
        }
      });
    },
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.section.toolEdit,
    sectionList: state.section.sectionList,
    sectionNameList: state.section.sectionNameList,
    alert: state.dialog
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
