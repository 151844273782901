export function edit_old_publication(state = false, action) {
  if (action.type === 'CLOSE_ALERT_NO_EDIT') {
    return action.alert;
  }
  if (action.type === 'OPEN_ALERT_NO_EDIT') {
    return action.alert;
  }

  return state;
}
