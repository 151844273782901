import React, { Component } from 'react';
import check_box from '../../../../utils/img/outline-check_box-24px.png';
import radiobutton from '../../../../utils/img/radiobutton.svg';
import textCurto from '../../../../utils/img/textCurto.svg';

require('../../css/newcanvasform.css');

export class FormComponentsButtons extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-8">
          <div className="body-components-end-date">
            <p className="form-title-question components-question-title">
              Adicionar componentes de resposta:
            </p>
            <div className="selected-component-form">
              <div
                className="body-select-form"
                onClick={() => this.props.InsertContentQuestion('survey')}
              >
                <div className="row">
                  <div data-tip="Inserir seleção única" className="col-md-12">
                    <img className="image-title-component" src={radiobutton} alt="ícone botão radial"/>
                  </div>
                </div>
              </div>
              <div
                className="body-select-form"
                onClick={() => this.props.InsertContentQuestion('quiz')}
              >
                <div className="row">
                  <div
                    data-tip="Inserir seleção multipla"
                    className="col-md-12"
                  >
                    <img
                      style={{ marginLeft: '5px' }}
                      className="image-title-component"
                      src={check_box}
                      alt="ícone caixa de seleção"
                    />
                  </div>
                </div>
              </div>
              <div
                className="body-select-form"
                onClick={() =>
                  this.props.InsertContentQuestion('open_question')
                }
              >
                <div className="row">
                  <div data-tip="inserir resposta curta" className="col-md-12">
                    <img
                      style={{ marginLeft: '5px' }}
                      className="image-title-component"
                      src={textCurto}
                      alt="ícone texto curto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormComponentsButtons;
