import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { generatePublishReport } from '../utils/historyReportGenerator';
import { style } from '../../style';

import HistoricalApi from '../../Logicas/HistoricalApi';

import { BodyTable } from '../utils/BodyTable';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableData,
  TableHeadRow
} from '../utils/Table';
import { FooterPagination } from '../utils/Pagination';

import IcoPontos from '../utils/img/ic-pontos.svg';
import Not_img from '../utils/img/ico-external.png';

class PublishDetails extends Component {
  render() {
    const teamName = style.CurrencyName.name.split('/');
    const { publishDetails, pageActive } = this.props;
    return (
      <div>
        <div className="container">
          <div className="header-child-title">
            <div className="title-historical">
              <p>Histórico de {teamName[1]}</p>
            </div>
          </div>
          <div className="header-section-list">
            <div className="header-title-user-history">
              <img
                id="img-history-publish"
                src={
                  publishDetails.publishImage
                    ? publishDetails.publishImage
                    : Not_img
                }
                alt="Perfil usuario"
              />
              <p className="title-name-user-history">
                {publishDetails.publishTitle}
              </p>
            </div>
            <div className="header-full-score">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() =>
                  this.props.getPublishDetailsHistory(
                    publishDetails.publishId,
                    publishDetails.publishTitle,
                    publishDetails.publishImage
                  )
                }
              >
                <path
                  d="M19 8L15 12H18C18 15.31 15.31 18 12 18C10.99 18 10.03 17.75 9.2 17.3L7.74 18.76C8.97 19.54 10.43 20 12 20C16.42 20 20 16.42 20 12H23L19 8ZM6 12C6 8.69 8.69 6 12 6C13.01 6 13.97 6.25 14.8 6.7L16.26 5.24C15.03 4.46 13.57 4 12 4C7.58 4 4 7.58 4 12H1L5 16L9 12H6Z"
                  fill={style.inpuStyle.colorInput}
                />
              </svg>
              <p>{teamName[1]} Totais</p>
              <p>
                {publishDetails.score}{' '}
                {publishDetails.score > 0 ? teamName[1] : teamName[0]}
              </p>
              <img src={IcoPontos} alt="moeda de pontos" />
              <button
                className="download-report-btn"
                onClick={() => {
                  generatePublishReport(publishDetails);
                }}
              >
                <div className="download-report-btn-txt">Baixar histórico</div>
              </button>
            </div>
          </div>
        </div>
        <BodyTable>
          <Table>
            <TableHead>
              <TableHeadRow>Nome</TableHeadRow>
              <TableHeadRow>Motivo da pontuação</TableHeadRow>
              <TableHeadRow>{teamName[1]} premiadas</TableHeadRow>
              <TableHeadRow>Data da pontuação</TableHeadRow>
            </TableHead>

            <TableBody>
              {publishDetails.interactions &&
                publishDetails.interactions.map((detailsPublish, index) => (
                  <TableRow className="content-list-history" key={index}>
                    <TableData>
                      <div className="content-user-details-history">
                        <img
                          id="img-publish-details-history"
                          src={
                            detailsPublish.image.url
                              ? detailsPublish.image.url
                              : Not_img
                          }
                          alt="Foto do usuario"
                        />
                        {detailsPublish.name && detailsPublish.name.length > 30
                          ? `${detailsPublish.name.slice(0, 30)}...`
                          : detailsPublish.name}
                      </div>
                    </TableData>
                    <TableData>
                      <div>
                        {detailsPublish.kind === 'visualization' ? (
                          <div className="content-publish-point-reason">
                            <svg
                              width="22"
                              height="16"
                              viewBox="0 0 22 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                                fill="#FFC61B"
                              />
                            </svg>
                            <p>Visualização</p>
                          </div>
                        ) : detailsPublish.kind === 'comment' ? (
                          <div className="content-publish-point-reason">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.4 5H17.6C18.37 5 19 5.63 19 6.4V19L16.2 16.2H6.4C5.63 16.2 5 15.57 5 14.8V6.4C5 5.63 5.63 5 6.4 5Z"
                                fill={style.inpuStyle.colorInput}
                              />
                            </svg>
                            <p>Comentário</p>
                          </div>
                        ) : (
                          detailsPublish.kind === 'like' && (
                            <div className="content-publish-point-reason">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.1687 6.67365C15.3874 5.44964 13.1877 6.02084 12.0002 7.42166C10.8126 6.02084 8.61299 5.44284 6.83167 6.67365C5.88704 7.32646 5.29326 8.42807 5.25278 9.59088C5.15832 12.2293 7.47942 14.3441 11.0218 17.5877L11.0893 17.6489C11.6021 18.1181 12.3915 18.1181 12.9043 17.6421L12.9786 17.5741C16.5209 14.3373 18.8353 12.2225 18.7476 9.58408C18.7071 8.42807 18.1133 7.32646 17.1687 6.67365Z"
                                  fill={style.inpuStyle.colorInput}
                                />
                              </svg>
                              <p>Curtida</p>
                            </div>
                          )
                        )}
                      </div>
                    </TableData>
                    <TableData>
                      <div className="content-user-details-history text-color-title-publish">
                        {detailsPublish.score}
                      </div>
                    </TableData>
                    <TableData>
                      <div>
                        {detailsPublish.created_at
                          ? `
                      ${moment
                        .tz(detailsPublish.created_at, 'America/Sao_Paulo')
                        .format('DD/MM/YYYY')}
                        às
                        ${moment
                          .tz(detailsPublish.created_at, 'America/Sao_Paulo')
                          .format('LT')}
                          `
                          : 'Data inexistente.'}
                      </div>
                    </TableData>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {publishDetails.size &&
            publishDetails.size[1] > 0 &&
            publishDetails.interactions && (
              <FooterPagination
                activePage={pageActive}
                totalItemsCount={publishDetails.size[0]}
                handlePageChange={this.props.getPublishDetailsHistory.bind(
                  this,
                  publishDetails.publishId,
                  publishDetails.publishTitle,
                  publishDetails.publishImage
                )}
              />
            )}
        </BodyTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    publishDetails: state.historical_points.publishDetails,
    pageActive: state.historical_points.pageActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPublishDetailsHistory: (id, publishTitle, publishImage, page) => {
      dispatch(
        HistoricalApi.getPublishDetailsHistory(
          id,
          publishTitle,
          publishImage,
          page
        )
      );
    }
  };
};

const PublishDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishDetails);

export default PublishDetailsContainer;
