import React from 'react';

import styled from 'styled-components';
import { style } from '../../style';

import { ReactComponent as IconOpenQuestion } from './icons/open-question.svg';
import { ReactComponent as IconRadioButton } from './icons/radio-button.svg';
import { ReactComponent as IconCheckbox } from './icons/checkbox.svg';

const ContainerAddFormContent = styled.div`
    width: 100%;
    height: 150px;
    margin-top: 30px;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-size: 14px;
    color: #808080;

    >span{
        font-family: 'Rubik', sans-serif;
    }
`;

const ContainerOptions = styled.div`
    width: 100%;
    height: 80px;

    display:flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
`;

export const Option = styled.div`
    width: 140px;
    height: 60px;
    gap: 12px;

    background-color: #F4F6FB;

    border-radius: 8px;
    padding: 8px 16px 8px 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    >span{
        font-size: 16px;
        line-height: 1.2;
    }

    &:hover{
        cursor: pointer;
        background-color:${style.inpuStyle.colorInput};
        color:white;
        svg {
            >circle, path {
              stroke: white;
            }
            #second-circle-radio-button{
                fill:white;
            }
          }
    }
`

export function AddFormContent(props) {
    return (
        <ContainerAddFormContent>
            <span>Escolha componentes para as respostas:</span>
            <ContainerOptions>
                <Option onClick={() => props.InsertContentQuestion('survey')}>
                    <IconRadioButton/>
                    <span>Seleção Única</span>
                </Option>

                <Option onClick={() => props.InsertContentQuestion('quiz')}>
                    <IconCheckbox/>
                    <span>Seleção Múltipla</span>
                </Option>

                <Option onClick={() => props.InsertContentQuestion('open_question')}>
                    <IconOpenQuestion/>
                    <span>Resposta Aberta</span>
                </Option>
            </ContainerOptions>
        </ContainerAddFormContent>
    )
}