import styled from 'styled-components';
import { style } from '../../../../style';

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const QuizAnswerContainer = styled.div`
    width: 70%;
    min-height: 300px;
    margin-bottom: 20px;
    margin-top: 30px;

    border-radius: 5px;
    background: #F4F6FB;
    font-family: Rubik;
    font-size: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TypeContainer = styled.div`
    height: 20px;
    width: 90%;
    margin-top: 30px;
    // background: green;

    display: flex;
    align-items: center;

    font-size: 14px;
    opacity: 0.75;
`

export const QuestionContainer = styled.div`
    min-height: 60px;
    width: 90%;
    margin-top: 30px;
    // background: green;

    display: flex;
    align-items: center;
    svg{
        &:hover{
            circle{
                fill: ${style.inpuStyle.colorInput};
            }
        }

        circle{
            fill:${(props) => (props.iconSortActive ? style.inpuStyle.colorInput : '')};
        }
    }
`

export const SortModal = styled.div`
    position: absolute;
    left: ${(props) => (props.left ? props.left : '1040')}px;
    margin-top: 180px;
`
export const SortModalContainer = styled.div`
    position: relative;
    width: 145px;
    height: 210px;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background: #FFFFFF;

    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-evenly;

    font-family: Rubik;
    font-size: 16px;
    opacity: 0.7;

    h4{
        height: 46px;
        width: 90%;

        padding-top: 15px;
    }

    span{
        height: 52px;
        width: 100%;
        padding-left:10px;

        display:flex;
        align-items: center;

        &:hover{
            background: #E4E4E5;
        }
        cursor:pointer;
    }

    .first{
        border-top: 1px solid #E4E4E5;
    }
`

export const QuestionContent = styled.div`
    height: 100%;
    width: 93%;
    // background: blue;

    >span{
        font-size: 14px;
        opacity: 0.7;
    }
`

export const AnswersContainer = styled.div`
    // min-height: 100px;
    width: 90%;
    margin-top: 30px;
    // background: blue;

    >span{
        font-size: 14px;
        opacity: 0.7;
    }

`

export const AnswersContent = styled.div`
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`

export const AnswerContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
`

export const AnswerContent = styled.div`
    width: 100%;
    min-height: 20px;
    padding: 5px;
    border-radius: 5px;
    background: #E6EAF3;

    display: flex;
    align-items: center;
    justify-content: space-between;

    >span{
        width: 90%;
        height: 100%;
        // background: green;

        display: flex;
        align-items: center;
    }

    .percentage{
        width: 6%;
        height: 100%;
        // background: blue;
        color: ${style.inpuStyle.colorInput};

        display: flex;
        align-items: center;
    }
`

export const AnswerBottom = styled.div`
    width: 12%;
    padding-left: 10px;
    margin-left: auto;
    height: 40px;
    background: none;
`

export const ButtonShowModal = styled.button.attrs({ type: 'button' })`
  justify-content: center;
  position: relative;
  padding: 0 4px;
  margin: 8px 0;
  border: none;
  font-family: Rubik, sans-serif;
  font-size: 12px;
  color: ${(props) => (props.disabled ? '#808080' : style.inpuStyle.colorInput)};
  appearance: none !important;
  background-color: transparent;
  align-self: flex-end;
  ${defaultTransition};

  &::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: ${(props) => (props.disabled ? 'none' : style.inpuStyle.colorInput)};
    transition: width 0.33s ease 0s, left 0.33s ease 0s;
    width: 0;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
`;



