import styled from 'styled-components';
import { style as profile, style } from '../../style';

export const dialog = styled.dialog`
  background-color: ${profile.inpuStyle.colorInput};
`;
export const LabelLink = styled.li`
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  cursor: pointer;
  border-bottom: ${({ selected }) =>
    selected
      ? `2.1px solid ${style.inpuStyle.colorInput} !important;`
      : '2.1px solid transparent !important;'};
  list-style-type: none;
  &:hover {
    cursor: pointer;
    border-bottom: 2.1px ${style.inpuStyle.colorInput} solid !important;
  }
  & + & {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
`;

export const MenuItemStiled = styled.li`
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 15px;
  margin-right: 0;
  color: #403f4c;
  &:hover {
    border-bottom: ${profile.inpuStyle.colorInput} !important;
    border-bottom: 2.1px ${profile.inpuStyle.colorInput} solid !important;
    cursor: pointer;
  }
`;
