const formatsFile = [
  '.txt',
  '.odt',
  'application/vnd.oasis.opendocument.text',
  'text/plain',
  '.rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.docx',
  '.doc',
  'application/msword',
  'text/html',
  'application/rtf',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
  'text/csv',
  'application/vnd.ms-excel.sheet.macroenabled.12', 
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  '.xls',
  '.xml',
  '.ppt',
  '.pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.presentation',
  'image/png',
  'image/jpeg',
  'image/gif',
  '.form',
  '.prezi'
];

export const ValidFile = (value) => {
  if (formatsFile.includes(value)) {
    return true;
  } else {
    return false;
  }
};

export default ValidFile;
