import React, { useState } from 'react';
import { connect } from 'react-redux';
import DialogApi from '../../../../Logicas/DialogApi';
import { ReactComponent as IconQuiz} from '../icons/multipla-escolha.svg';
import { ReactComponent as IconSort} from '../icons/sort.svg';

import * as S from './QuizAnswers.style';

function QuizAnswers(props) {

    const [sortModalIsActive, setSortModalIsActive] = useState(false);
    let survey_index = 0;
    const [actualContentSurvey, setActualContentSurvey] = useState(
        props.content.content_survey.map(survey => {
            survey_index += 1;
            return {
                ...survey,
                index: survey_index
            }
        })
    );

    const content = props.content;

    const showUsersModal = (answerTitle, usersWhoVoted, content_survey, survey, content) => {
        props.setUsersWhoVoted(answerTitle, usersWhoVoted, content_survey, survey, content);
        props.openDialog();
    };
    
    const sortContent = () => {
        setSortModalIsActive(!sortModalIsActive);
    }

    function sortContentBy(type = "Original"){
        if(type === "Original") {
            setActualContentSurvey(
                actualContentSurvey.sort(
                    function (a, b) {
                        if (a.index > b.index) {
                            return 1;
                        }
                        if (a.index < b.index) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    }
                )
            )
        }
        else if(type === "Decrescente") {
            setActualContentSurvey(
                actualContentSurvey.sort(
                    function (a, b) {
                        if (a.users_who_voted.length < b.users_who_voted.length) {
                            return 1;
                        }
                        if (a.users_who_voted.length > b.users_who_voted.length) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    }
                )
            )
        }
        else if(type === "Crescente"){
            setActualContentSurvey(
                actualContentSurvey.sort(
                    function (a, b) {
                        if (a.users_who_voted.length > b.users_who_voted.length) {
                            return 1;
                        }
                        if (a.users_who_voted.length < b.users_who_voted.length) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    }
                )
            )
        }
    }

    document.body.addEventListener('click', (event) => {
        const modal = document.getElementById(`SortModal-${content.id}`);
        if(modal !== null && !modal.contains(event.target)){
            setSortModalIsActive(false);
        }
    });

    return(
        <S.QuizAnswerContainer>
            <S.TypeContainer>
                <IconQuiz/>
                <span style={{marginLeft:"5px"}}>Múltipla escolha</span>
            </S.TypeContainer>

            <S.QuestionContainer iconSortActive={sortModalIsActive}>
                <S.QuestionContent>
                    <span>Título:</span>
                    <h2>{content.text}</h2>
                </S.QuestionContent>
                <IconSort
                    style={{
                        cursor: "pointer"
                    }}
                    onClick={sortContent}
                    id={`IconSort-${content.id}`}
                />
                {
                    sortModalIsActive && (
                        <S.SortModal 
                            id={`SortModal-${content.id}`}
                            left={ (document.getElementById(`IconSort-${content.id}`).getBoundingClientRect().left) + 50}
                        >
                            <S.SortModalContainer>
                                <h4>Ordem:</h4>
                                <span className='first' onClick={()=>{
                                    setSortModalIsActive(false);
                                    sortContentBy("Crescente")
                                }}>Crescente</span>
                                <span onClick={()=>{
                                    setSortModalIsActive(false);
                                    sortContentBy("Decrescente")
                                }}>Decrescente</span>
                                <span onClick={()=>{
                                    setSortModalIsActive(false);
                                    sortContentBy("Original")
                                }}>Original</span>
                            </S.SortModalContainer>
                        </S.SortModal>
                    )
                }
            </S.QuestionContainer>

            <S.AnswersContainer>
                <span>Respostas escolhidas:</span>

                {actualContentSurvey.map(survey => (
                    <S.AnswersContent>
                        <S.AnswerContainer>
                            <S.AnswerContent>
                                <span>{survey.index} - {survey.title}</span>
                                <div className="percentage">
                                    {((survey
                                        .users_who_voted
                                        .length /
                                        content.votes_amount) *
                                        100).toFixed(1).replace(/[.,]0$/, "")}%
                                </div>
                            </S.AnswerContent>
                            <S.AnswerBottom>
                                <S.ButtonShowModal
                                    onClick={() =>
                                        showUsersModal(
                                            content.text,
                                            survey.users_who_voted,
                                            content.content_survey, 
                                            survey, 
                                            content
                                        )
                                    }
                                    disabled={
                                        (survey
                                        .users_who_voted
                                        .length /
                                        content.votes_amount) *
                                        100 === 0
                                    }
                                >
                                    Ver usuários
                                </S.ButtonShowModal>
                            </S.AnswerBottom>
                        </S.AnswerContainer>
                    </S.AnswersContent>
                ))}

            </S.AnswersContainer>
        </S.QuizAnswerContainer>
    )
}

const mapStateToProps = (state) => {
    return {
      valuesForm: state.getForm.formDetails,
      usersWhoVoted: state.getForm.modalContent.usersWhoVoted,
      answerTitle: state.getForm.modalContent.answerTitle
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      openDialog: () => {
        dispatch(DialogApi.openDialog());
      },
      setUsersWhoVoted: (answerTitle, usersWhoVoted, content_survey, survey, content) =>
        dispatch({
          type: 'GET_USERS_VOTERS',
          modalContent: { answerTitle, usersWhoVoted, content_survey, survey, content}
        })
    };
  };
  
  const QuizAnswersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuizAnswers);
  
  export default QuizAnswersContainer;