import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { CheckboxDefaultList } from '../utils/FormsComponents';
import { BodyTable } from '../utils/BodyTable';
import { Table, TableHead, TableBody, TableHeadRow } from '../utils/Table';
import { FooterPagination } from '../utils/Pagination';
import DashboardButton from '../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import { PeriodManagement } from './components/PeriodManagement';
import PostApi from '../../Logicas/PostApi';

import { ReactComponent as IconCalendar } from './icons/calendar.svg';
import { ReactComponent as IconChecked } from './icons/checked.svg';
import { ReactComponent as IconCheckResponse } from './icons/check-response.svg';
import { ReactComponent as IconEyeLight } from './icons/eye-light.svg';
import { ReactComponent as IconAnonymous } from './icons/anonymous.svg';
import { ReactComponent as IconSearch } from './icons/search.svg';

import ImageVeryHappy from './icons/veryHappy.png';
import ImageHappy from './icons/happy.png';
import ImageNormal from './icons/normal.png';
import ImageSad from './icons/sad.png';
import ImageAngry from './icons/angry.png';

import * as S from './MoodSurveysResults.styles';

export const MoodSurveysResults = (props) => {
  const [displayAnonymous, setDisplayAnonymous] = useState(false);
  const [activeFilter, setActiveFilter] = useState('period');
  const [activeResponse, setActiveResponse] = useState('');
  const [searchUser, setSearchUser] = useState('');

  useEffect(() => {
    //First request on the page
    if (!props.editMoodSurvey.id && !props.getAnswers) {
      if (activeResponse !== '') {
        props.getMoodSurvey(1, false);
        props.getAnswersMoodSurvey(
          props.editMoodSurvey.id,
          activeResponse,
          searchUser
        );
      } else {
        props.getMoodSurvey(1, true);
      }
    }

    //Pass page
    else if (activeResponse !== '') {
      props.getAnswersMoodSurvey(
        props.editMoodSurvey.id,
        activeResponse,
        searchUser
      );
    }
  }, [activeResponse, props.editMoodSurvey.id]);

  return (
    <S.ResultsPage>
      <S.ViewContainer>
        <h3>Visualização:</h3>
        <S.ViewContent>
          <S.ViewOption
            activeFilter={activeFilter === 'period'}
            onClick={() => {
              setActiveFilter('period');
              setActiveResponse('');
              props.getAnswersMoodSurvey(
                props.editMoodSurvey.id,
                '',
                searchUser
              );
            }}
          >
            <IconCalendar />
            <span>Período</span>
          </S.ViewOption>
          <S.ViewOption
            activeFilter={activeFilter === 'response'}
            onClick={() => {
              setActiveFilter('response');
              setActiveResponse('angry');
            }}
          >
            <IconChecked />
            <span>Resposta</span>
          </S.ViewOption>
          <S.ViewOption
            onClick={() => setDisplayAnonymous(!displayAnonymous)}
            activeFilter={displayAnonymous}
          >
            <span className="withBorderLeft" />
            <CheckboxDefaultList
              checked={displayAnonymous}
              style={{
                marginLeft: '-10px',
                marginRight: '-15px',
                width: '1rem',
                height: '1rem'
              }}
            />
            <span>
              Exibir Anônimos (
              {(props.editMoodSurvey.statistics &&
                props.editMoodSurvey.statistics.anonymous &&
                props.editMoodSurvey.statistics.anonymous.total_anonymous) ||
                0}
              )
            </span>
          </S.ViewOption>

          {/* <DashboardButton
                        label="Baixar relatório"
                        // onClick={}
                        style={{
                            marginLeft: 'auto'
                        }}
                    /> */}
        </S.ViewContent>
      </S.ViewContainer>

      <S.PeriodAndOptionsContainer>
        <S.PeriodContainer>
          <h3>Período:</h3>
          <PeriodManagement
            startDate={props.editMoodSurvey.start_date}
            endDate={props.editMoodSurvey.end_date}
            hasPreviousPage={
              props.paginationEditMoodSurvey &&
              props.paginationEditMoodSurvey.previous_page
            }
            hasNextPage={
              props.paginationEditMoodSurvey &&
              props.paginationEditMoodSurvey.next_page
            }
            handleNextPage={
              props.paginationEditMoodSurvey &&
              props.paginationEditMoodSurvey.next_page
                ? () =>
                    props.getMoodSurvey(
                      props.paginationEditMoodSurvey.next_page,
                      !activeResponse,
                      searchUser
                    )
                : () => {}
            }
            handlePreviousPage={
              props.paginationEditMoodSurvey &&
              props.paginationEditMoodSurvey.previous_page
                ? () =>
                    props.getMoodSurvey(
                      props.paginationEditMoodSurvey.previous_page,
                      !activeResponse,
                      searchUser
                    )
                : () => {}
            }
          />
          <S.InfoMoodSurveyContainer>
            <S.InfoContent>
              <IconCheckResponse />
              Respostas:{' '}
              <span>
                {(props.editMoodSurvey.statistics &&
                  props.editMoodSurvey.statistics.answers_total) ||
                  0}
              </span>
            </S.InfoContent>
            <S.InfoContent>
              <IconEyeLight />
              Visualizações:{' '}
              <span>
                {(props.editMoodSurvey.statistics &&
                  props.editMoodSurvey.statistics.visualizations_total) ||
                  0}
              </span>
            </S.InfoContent>
          </S.InfoMoodSurveyContainer>
        </S.PeriodContainer>

        <S.OptionsContainer>
          <h3 style={{ paddingLeft: '1.25rem' }}>Opções:</h3>
          <S.OptionSurveysContainer>
            <S.OptionSurvey
              className={
                activeFilter === 'response' ? 'visualization-by-response' : ''
              }
              isActiveByFilter={activeResponse === 'angry'}
              onClick={() => setActiveResponse('angry')}
            >
              <img src={ImageAngry} />
              <span>
                {(props.editMoodSurvey.statistics &&
                  props.editMoodSurvey.statistics.angry) ||
                  0}
                %
              </span>
            </S.OptionSurvey>
            <S.OptionSurvey
              className={
                activeFilter === 'response' ? 'visualization-by-response' : ''
              }
              isActiveByFilter={activeResponse === 'sad'}
              onClick={() => setActiveResponse('sad')}
            >
              <img src={ImageSad} />
              <span>
                {(props.editMoodSurvey.statistics &&
                  props.editMoodSurvey.statistics.sad) ||
                  0}
                %
              </span>
            </S.OptionSurvey>
            <S.OptionSurvey
              className={
                activeFilter === 'response' ? 'visualization-by-response' : ''
              }
              isActiveByFilter={activeResponse === 'normal'}
              onClick={() => setActiveResponse('normal')}
            >
              <img src={ImageNormal} />
              <span>
                {(props.editMoodSurvey.statistics &&
                  props.editMoodSurvey.statistics.normal) ||
                  0}
                %
              </span>
            </S.OptionSurvey>
            <S.OptionSurvey
              className={
                activeFilter === 'response' ? 'visualization-by-response' : ''
              }
              isActiveByFilter={activeResponse === 'happy'}
              onClick={() => setActiveResponse('happy')}
            >
              <img src={ImageHappy} />
              <span>
                {(props.editMoodSurvey.statistics &&
                  props.editMoodSurvey.statistics.happy) ||
                  0}
                %
              </span>
            </S.OptionSurvey>
            <S.OptionSurvey
              className={
                activeFilter === 'response' ? 'visualization-by-response' : ''
              }
              isActiveByFilter={activeResponse === 'very_happy'}
              onClick={() => setActiveResponse('very_happy')}
            >
              <img src={ImageVeryHappy} />
              <span>
                {(props.editMoodSurvey.statistics &&
                  props.editMoodSurvey.statistics.very_happy) ||
                  0}
                %
              </span>
            </S.OptionSurvey>
            <S.OptionSurvey
              className={
                activeFilter === 'response' ? 'visualization-by-response' : ''
              }
              isActiveByFilter={activeResponse === 'not_participate'}
              onClick={() => setActiveResponse('not_participate')}
            >
              <span className="option-survey">Prefiro não participar</span>
              <span>
                {(props.editMoodSurvey.statistics &&
                  props.editMoodSurvey.statistics.not_participate) ||
                  0}
                %
              </span>
            </S.OptionSurvey>
          </S.OptionSurveysContainer>
        </S.OptionsContainer>
      </S.PeriodAndOptionsContainer>

      <S.AnonymousContainer
        style={displayAnonymous ? { maxHeight: '200px' } : { maxHeight: '0' }}
      >
        <S.ImageAndDescriptionAnonymous>
          <IconAnonymous />
          <span>
            Anônimos (
            {(props.editMoodSurvey.statistics &&
              props.editMoodSurvey.statistics.anonymous &&
              props.editMoodSurvey.statistics.anonymous.total_anonymous) ||
              0}
            )
          </span>
        </S.ImageAndDescriptionAnonymous>

        <S.OptionSurveysContainer style={{ marginRight: '1.7rem' }}>
          <S.OptionSurvey>
            <img src={ImageAngry} />
            <span>
              {(props.editMoodSurvey.statistics &&
                props.editMoodSurvey.statistics.anonymous &&
                props.editMoodSurvey.statistics.anonymous.angry) ||
                0}
              %
            </span>
          </S.OptionSurvey>
          <S.OptionSurvey>
            <img src={ImageSad} />
            <span>
              {(props.editMoodSurvey.statistics &&
                props.editMoodSurvey.statistics.anonymous &&
                props.editMoodSurvey.statistics.anonymous.sad) ||
                0}
              %
            </span>
          </S.OptionSurvey>
          <S.OptionSurvey>
            <img src={ImageNormal} />
            <span>
              {(props.editMoodSurvey.statistics &&
                props.editMoodSurvey.statistics.anonymous &&
                props.editMoodSurvey.statistics.anonymous.normal) ||
                0}
              %
            </span>
          </S.OptionSurvey>
          <S.OptionSurvey>
            <img src={ImageHappy} />
            <span>
              {(props.editMoodSurvey.statistics &&
                props.editMoodSurvey.statistics.anonymous &&
                props.editMoodSurvey.statistics.anonymous.happy) ||
                0}
              %
            </span>
          </S.OptionSurvey>
          <S.OptionSurvey>
            <img src={ImageVeryHappy} />
            <span>
              {(props.editMoodSurvey.statistics &&
                props.editMoodSurvey.statistics.anonymous &&
                props.editMoodSurvey.statistics.anonymous.very_happy) ||
                0}
              %
            </span>
          </S.OptionSurvey>
          <S.OptionSurvey>
            <span className="option-survey">Prefiro não participar</span>
            <span>
              {(props.editMoodSurvey.statistics &&
                props.editMoodSurvey.statistics.anonymous &&
                props.editMoodSurvey.statistics.anonymous.not_participate) ||
                0}
              %
            </span>
          </S.OptionSurvey>
        </S.OptionSurveysContainer>
      </S.AnonymousContainer>

      <S.SearchContainer displayAnonymous={displayAnonymous}>
        <S.SearchComponent>
          <S.InputSearch
            value={searchUser}
            onChange={(e) => {
              setSearchUser(e.target.value);
              props.getAnswersMoodSurvey(
                props.editMoodSurvey.id,
                activeResponse,
                e.target.value
              );
            }}
            placeholder="Buscar por nome"
          />
          <IconSearch className="magnifier" />
        </S.SearchComponent>
      </S.SearchContainer>

      <S.TableContainer>
        <BodyTable>
          <Table>
            <TableHead>
              <TableHeadRow style={{ width: '54%' }}>Usuários:</TableHeadRow>
              <TableHeadRow>Respostas:</TableHeadRow>
            </TableHead>
            <TableBody>
              {props.answersMoodSurvey.mood_survey_answers &&
                props.answersMoodSurvey.mood_survey_answers.map((answer) => {
                  return (
                    <S.TableRow key={answer.id} onClick={() => {}}>
                      <S.TableCol style={{ width: '54%' }}>
                        <S.UserImageAndName>
                          <img
                            style={{
                              background: `url(${(answer.user.image &&
                                answer.user.image.url) ||
                                ''}) center / cover`
                            }}
                          />
                          <span>{answer.user.name}</span>
                        </S.UserImageAndName>
                      </S.TableCol>
                      <S.TableCol withoutPadding>
                        <S.OptionSurveysContainer withoutSpan>
                          <S.OptionSurvey
                            isInactive={answer.answer_kind !== 'angry'}
                          >
                            <img src={ImageAngry} />
                          </S.OptionSurvey>
                          <S.OptionSurvey
                            isInactive={answer.answer_kind !== 'sad'}
                          >
                            <img src={ImageSad} />
                          </S.OptionSurvey>
                          <S.OptionSurvey
                            isInactive={answer.answer_kind !== 'normal'}
                          >
                            <img src={ImageNormal} />
                          </S.OptionSurvey>
                          <S.OptionSurvey
                            isInactive={answer.answer_kind !== 'happy'}
                          >
                            <img src={ImageHappy} />
                          </S.OptionSurvey>
                          <S.OptionSurvey
                            isInactive={answer.answer_kind !== 'very_happy'}
                          >
                            <img src={ImageVeryHappy} />
                          </S.OptionSurvey>
                          <S.OptionSurvey
                            isInactive={
                              answer.answer_kind !== 'not_participate'
                            }
                          >
                            <span className="option-survey">
                              Prefiro não participar
                            </span>
                          </S.OptionSurvey>
                        </S.OptionSurveysContainer>
                      </S.TableCol>
                    </S.TableRow>
                  );
                })}
            </TableBody>
          </Table>

          {props.answersMoodSurvey.mood_survey_answers &&
            props.answersMoodSurvey.mood_survey_answers.length > 0 &&
            props.answersMoodSurvey.pagination &&
            props.answersMoodSurvey.pagination.current_page > 0 && (
              <footer className="footer-pagination">
                <FooterPagination
                  activePage={props.answersMoodSurvey.pagination.current_page}
                  totalItemsCount={
                    props.answersMoodSurvey.pagination.total_count
                  }
                  handlePageChange={(pageNumber) =>
                    props.getAnswersMoodSurvey(
                      props.editMoodSurvey.id,
                      activeResponse,
                      searchUser,
                      pageNumber
                    )
                  }
                  itemsPerPage={25}
                />
              </footer>
            )}
          <ReactTooltip className="tooltip" />
        </BodyTable>
      </S.TableContainer>
    </S.ResultsPage>
  );
};

const mapStateToProps = (state) => {
  return {
    editMoodSurvey: state.post.editMoodSurvey,
    paginationEditMoodSurvey: state.post.paginationEditMoodSurvey,
    answersMoodSurvey: state.post.answersMoodSurvey,
    getAnswers: state.post.getAnswers
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMoodSurvey: (page = 1, getAnswers = true, search) => {
      dispatch(PostApi.getMoodSurveys(page, 1, getAnswers, search));
    },
    getAnswersMoodSurvey: (id, activeResponse, search, page = null) => {
      dispatch(PostApi.getAnswersMoodSurvey(id, activeResponse, search, page));
    }
  };
};
const MoodSurveysResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MoodSurveysResults);

export default MoodSurveysResultsContainer;
