import React from 'react';
import { LinkIconStyle } from './LinkIcon.styles';
import { style as teamStyle } from '../../../../../../style';

const stopColor = `${teamStyle.inpuStyle.colorInput}`;

const LinkIcon = () => (
  <LinkIconStyle viewBox="0 0 32 32" >
    <g id="link 1" clipPath="url(#clip0)">
      <g id="Group">
        <g id="Group_2">
          <g id="Group_3">
            <path
              id="Vector"
              d="M13.2669 24.3902L9.49619 28.1609C7.93293 29.7242 5.40135 29.7242 3.83941 28.1612C2.27715 26.599 2.27715 24.0671 3.83909 22.5052L11.3818 14.9624C12.9438 13.4004 15.4756 13.4004 17.0375 14.9624C17.5582 15.4831 18.4025 15.4831 18.9232 14.9624C19.4439 14.4418 19.4439 13.5975 18.9232 13.0768C16.3198 10.4734 12.0996 10.4734 9.49619 13.0768L1.95352 20.6195C-0.649871 23.2229 -0.649871 27.4431 1.95352 30.0465C4.5566 32.6512 8.77712 32.6512 11.3819 30.0465L15.1526 26.2758C15.6733 25.7551 15.6733 24.9108 15.1526 24.3901C14.6319 23.8694 13.7876 23.8695 13.2669 24.3902Z"
              fill="url(#paint0_linear)"
            />
            <path
              id="Vector_2"
              d="M30.0481 1.95254C27.4447 -0.650848 23.2232 -0.650848 20.6198 1.95254L16.0957 6.47657C15.575 6.99726 15.575 7.84152 16.0957 8.36221C16.6164 8.8829 17.4607 8.8829 17.9814 8.36221L22.5054 3.83818C24.0673 2.27617 26.6005 2.27617 28.1625 3.83818C29.7244 5.40013 29.7244 7.93195 28.1625 9.4939L19.8664 17.79C18.3044 19.352 15.7727 19.352 14.2107 17.79C13.69 17.2693 12.8458 17.2693 12.3251 17.79C11.8044 18.3107 11.8044 19.155 12.3251 19.6756C14.9285 22.279 19.1487 22.279 21.7521 19.6756L30.0481 11.3796C32.6515 8.77621 32.6515 4.55593 30.0481 1.95254Z"
              fill="url(#paint1_linear)"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="19.3137"
        y1="11.1243"
        x2="-3.60145"
        y2="16.4241"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={stopColor} />
        <stop offset="1" stopColor={stopColor} />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="32.0007"
        y1="-1.28914e-06"
        x2="8.19855"
        y2="5.52075"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={stopColor} />
        <stop offset="1" stopColor={stopColor} />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </LinkIconStyle>
);

export default LinkIcon;
