import React, { Component } from 'react';

require('./headerinteraction.css');

class HeaderInteraction extends Component {
  render() {
    if (this.props.interaction) {
      return (
        <div>
          <div className="header-interaction-row-display">
            <div className="element-header-interaction">
              <label className="main-label-interaction">
                {this.props.interaction.interactions_amount &&
                  this.props.interaction.interactions_amount.toLocaleString(
                    'pt-BR'
                  )}
              </label>
              <label className="secondary-label-interaction">
                ENGAJAMENTO TOTAL
              </label>
            </div>
            <div className="border-right-element"></div>
            <div className="element-header-interaction">
              <label className="main-label-interaction">
                {this.props.views.visualizations_amount &&
                  this.props.views.visualizations_amount.toLocaleString(
                    'pt-BR'
                  )}
              </label>
              <label className="secondary-label-interaction">
                VISUALIZAÇÕES
              </label>
            </div>
            <div className="border-right-element"></div>
            <div className="element-header-interaction">
              <label className="main-label-interaction">
                {this.props.comments.comments_amount &&
                  this.props.comments.comments_amount.toLocaleString('pt-BR')}
              </label>
              <label className="secondary-label-interaction">COMENTÁRIOS</label>
            </div>
            <div className="border-right-element"></div>
            <div className="element-header-interaction">
              <label className="main-label-interaction">
                {this.props.likes.likes_amount &&
                  this.props.likes.likes_amount.toLocaleString('pt-BR')}
              </label>
              <label className="secondary-label-interaction">CURTIDAS</label>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default HeaderInteraction;
