export function tagsWithPagination(
    state = { tags: [], pagination: {}, isLoading: false, search: "" }, 
    action
  ) {
    if (action.type === 'GET_TAG_PAGINATION') {
      return {
        tags: action.pagination.current_page > 1 ? [...state.tags, ...action.tags] : action.tags,
        pagination: action.pagination,
        isLoading: false,
        search: action.search
      }
    }

    if (action.type === 'GET_TAG_PAGINATION_LOADING') {
      return {
        ...state,
        isLoading: true
      }
    }
  
    return state;
  }
  