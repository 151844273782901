import React, { Component } from 'react';
import * as S from './HourSelect.styles';

export class HourSelect extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      selectedHour: '00',
      selectedMinutes: '00'
    };
  }

  componentDidMount() {
    if (this.props.date) {
      const { date } = this.props;
      const newSelectedHour = String(date.getHours()).padStart(2, '0');
      const newSelectedMinutes = String(date.getMinutes()).padStart(2, '0');
      this.setState({
        selectedHour: newSelectedHour,
        selectedMinutes: newSelectedMinutes
      });
      const { handleSelectTime } = this.props;
      handleSelectTime({
        selectedHour: newSelectedHour,
        selectedMinutes: newSelectedMinutes
      });
    }
  }

  updateSelectedTime = () => {
    const { handleSelectTime } = this.props;
    const { selectedHour, selectedMinutes } = this.state;

    handleSelectTime({ selectedHour, selectedMinutes });
  };

  render() {
    const { isActive, selectedHour, selectedMinutes } = this.state;
    const { titleLeft, allowSelection, marginRight, label, isOptional } = this.props;
    return (
      <S.HourSelectContainer marginRight={marginRight}>
        <S.HourSelectTitle titleLeft={titleLeft} marginRight={marginRight}>
          {label}
          {isOptional? '': '*'}
        </S.HourSelectTitle>
        <S.HourSelectLabel>
          <S.HourSelectValues
            className={allowSelection ? '' : 'disabledHour'}
            onClick={
              allowSelection
                ? () => this.setState({ isActive: true })
                : () => {}
            }
          >
            {selectedHour}
          </S.HourSelectValues>
          :
          <S.HourSelectValues
            className={allowSelection ? '' : 'disabledHour'}
            onClick={
              allowSelection
                ? () => this.setState({ isActive: true })
                : () => {}
            }
          >
            {selectedMinutes}
          </S.HourSelectValues>
        </S.HourSelectLabel>
        <S.HourSelectArea isActive={isActive}>
          <S.HourSelectList isActive={isActive}>
            {isActive &&
              hoursList.map((hour, index) => (
                <S.HourSelectItem
                  key={index}
                  onClick={() =>
                    this.setState({ selectedHour: hour }, () => {
                      this.updateSelectedTime();
                    })
                  }
                  currentlySelected={hour === selectedHour}
                >
                  {hour}
                </S.HourSelectItem>
              ))}
          </S.HourSelectList>
          <S.HourSelectList isActive={isActive}>
            {isActive &&
              minutesList.map((minute, index) => (
                <S.HourSelectItem
                  key={index}
                  onClick={() =>
                    this.setState({ selectedMinutes: minute }, () => {
                      this.updateSelectedTime();
                    })
                  }
                  currentlySelected={minute === selectedMinutes}
                >
                  {minute}
                </S.HourSelectItem>
              ))}
          </S.HourSelectList>
        </S.HourSelectArea>
        <S.HourSelectOverlay
          isActive={isActive}
          onClick={() => this.setState({ isActive: false })}
        />
      </S.HourSelectContainer>
    );
  }
}

const hoursList = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];

const minutesList = ['00', '15', '30', '45'];

export default HourSelect;
