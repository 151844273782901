import React, { Component } from 'react';
import LinearProgress from 'material-ui/LinearProgress/LinearProgress';
import { style } from '../../../../style';

require('./userdevice.css');

class UserDevice extends Component {
  iOsPercent =
    (this.props.users_devices.ios /
      (this.props.users_devices.android + this.props.users_devices.ios)) *
    100;

  androidPercent = 100 - this.iOsPercent;

  render() {
    return (
      <div className="device-card-body">
        <div>
          <label className="main-label-speaker">Plataformas utilizadas</label>
        </div>
        <div className="row-devices-porc">
          <label className="label-devices">
            iOS {parseFloat(this.iOsPercent).toFixed(2)}%
          </label>
          <label className="label-devices">
            Android {parseFloat(this.androidPercent).toFixed(2)}%
          </label>
        </div>
        <div className="row-device-progress">
          <LinearProgress
            color={style.inpuStyle.colorInput}
            mode="determineted"
            value={this.iOsPercent}
          />
        </div>
      </div>
    );
  }
}
export default UserDevice;
