import React, { Component } from 'react';
import {
  TextFieldForm,
  LongTextFieldTitle
} from '../utils_form/FormComponents';
import { required, validurl } from '../validation/FormValidation';
import { Field, reduxForm } from 'redux-form';
import ComponentHeader from '../utils/ComponenteHeader';
import { Body } from '../utils/BodyComponente';
import TagApi from '../../Logicas/TagApi';
import { connect } from 'react-redux';
import IcoDocDrop from '../utils/img/ic-doc_newdoc.png';
import { formValueSelector } from 'redux-form';
import RepositorieApi from '../../Logicas/RepositoryApi';
import { DropZoneFileForm } from '../utils_form/FormComponents';
import DialogProgressCircular from '../utils/DialogProgressCircular';

class EditFile extends Component {
  constructor() {
    super();
    this.state = { drag_area: false, imageico: IcoDocDrop };
    this.saveFile = this.saveFile.bind(this);
  }

  saveFile(values) {
    this.props.saveFile(
      values,
      this.props.id_folder.folder_id,
      this.props.initialValues.id
    );
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.saveFile)}>
        <ComponentHeader
          disabled={submitting}
          tooltip={
            <p>
              Atualizar publicação
              <br />
            </p>
          }
          actions={[]}
          name="Arquivos"
          nameaction="Salvar"
          onClick={handleSubmit(this.saveFile)}
        />
        <Body drag_area={this.state.drag_area}>
          <div className="row">
            <div className="col-md-8">
              <LongTextFieldTitle
                name="name_file"
                validations={[required]}
                id="name"
                label="Nome do arquivo*"
                type="text"
              />
            </div>
            <Field
              onDrop={this.onDrop}
              showAlert={this.props.showErroAlert}
              url={this.props.file !== undefined ? this.props.file.url : ''}
              name="file"
              edit={true}
              component={(e)=> DropZoneFileForm({...e, dispatchImageError: this.props.dispatchImageError, dispatchGifError: this.props.dispatchGifError})}
            />
          </div>
          <div style={{ marginBottom: '150px' }} className="row">
            <div className="col-md-6">
              <TextFieldForm
                fullWidth={true}
                name="url_file"
                rows={1}
                validations={
                  this.props.file === undefined ? [required, validurl] : []
                }
                id="name"
                floatingLabelText="Digite a URL ou envie um arquivo ao lado*"
                type="text"
              />
            </div>
          </div>
          <DialogProgressCircular />
        </Body>
      </form>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'editFile'
})(EditFile);

const mapDispatchToProps = (dispatch) => {
  return {
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    saveFile: (values, idfolder, idfile) => {
      dispatch(RepositorieApi.patchFile(values, idfolder, idfile));
    },
    showErroAlert: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: message }
      }),
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
    dispatchGifError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.",
        }
      });
    },
  };
};
const selector = formValueSelector('editFile');
InitializeFromStateForm = connect(
  (state) => ({
    tags: state.tagsparsed,
    file: selector(state, 'file'),
    initialValues: state.repositories.file,
    id_folder: state.repositories
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
