import React from 'react';
import moment from 'moment';

import { ReactComponent as IconArrow } from '../icons/arrow.svg';

import * as S from './PeriodManagement.styles';

export const PeriodManagement = (props) => {
  return (
    <S.PeriodManagementContainer>
      <S.ButtonChangePage isActive={props.hasPreviousPage} onClick={props.hasPreviousPage && props.handlePreviousPage}>
        <IconArrow />
      </S.ButtonChangePage>
      <S.DateView>
        De
        <span>
          {' '}
          {moment
            .tz(props.startDate, 'America/Sao_Paulo')
            .format('DD/MM/YY')}{' '}
        </span>
        à
        <span>
          {' '}
          {moment.tz(props.endDate, 'America/Sao_Paulo').format('DD/MM/YY')}
        </span>
      </S.DateView>
      <S.ButtonChangePage className='next-page' isActive={props.hasNextPage} onClick={props.hasNextPage && props.handleNextPage}>
        <IconArrow />
      </S.ButtonChangePage>
    </S.PeriodManagementContainer>
  );
};
