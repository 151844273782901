import React, { Component } from 'react';
import { Player } from 'video-react';
import ToolTipVideo from '../../toolstips/TooltipVideo';
import ToolTipLinkVideoEmbeded from '../../toolstips/TooltipLinkVideoEmbeded';
import ReactPlayer from 'react-player';

require('../../css/newcanvasform.css');

export class VideoModule extends Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
  }

  render() {
    return (
      <div
        onMouseLeave={() => this.setState({ show: false })}
        style={{ marginLeft: '2px', marginTop: '10px', maxWidth: '90%' }}
        className="row"
      >
        <div
          onMouseOver={() => this.setState({ show: true })}
          data-tip
          data-for={'video_' + this.props.element.id}
          className="col-md-8"
        >
          {this.props.embededVideo ? (
            <ReactPlayer
              controls
              style={{ marginLeft: '2px', marginTop: '10px' }}
              width={'100%'}
              url={this.props.element.link}
            />
          ) : (
            <Player
              style={{
                width: '100%',
                position: 'relative',
                height: 'auto'
              }}
              src={this.props.element.canvas_content_videos[0].video.url}
            />
          )}
          {this.props.embededVideo ? (
            <ToolTipLinkVideoEmbeded
              showbtn={this.state.show}
              id_canvas={this.props.id_canvas}
              id={'video_' + this.props.element.id}
              elements={this.props.elements}
              setComponentsToState={this.props.setComponentsToState}
              embededVideo={this.props.embededVideo}
            />
          ) : (
            <ToolTipVideo
              showbtn={this.state.show}
              id_canvas={this.props.id_canvas}
              id={'video_' + this.props.element.id}
              elements={this.props.elements}
              setComponentsToState={this.props.setComponentsToState}
            />
          )}
        </div>
      </div>
    );
  }
}

export default VideoModule;
