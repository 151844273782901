import React, { Component } from 'react';
import ToolTipImage from '../../toolstips/TooltipImage';

require('../../css/newcanvasform.css');

export class ImageModule extends Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
  }

  render() {
    return (
      <div
        onMouseLeave={() => this.setState({ show: false })}
        style={{ marginLeft:'2px', marginTop: '10px', maxWidth:'90%' }}
        className="row"
      >
        <div className="col-md-8 img-element">
          <img
            onMouseOver={() => this.setState({ show: true })}
            data-tip
            data-for={'image_' + this.props.element.id}
            style={{
              position: 'relative',
              width: '100%',
              height: 'auto',
              'object-fit': 'cover'
            }}
            src={this.props.element.canvas_content_images[0].image.url}
            alt="conteúdo de imagem"
          ></img>
          <ToolTipImage
            showbtn={this.state.show}
            id_canvas={this.props.id_canvas}
            id={'image_' + this.props.element.id}
            link={this.props.element.link}
            elements={this.props.elements}
            setComponentsToState={this.props.setComponentsToState}
          />
        </div>
      </div>
    );
  }
}

export default ImageModule;
