export function dialogeditmidia(
  state = { visible: false, message: '' },
  action
) {
  if (action.type === 'CLOSE_ALERT_MIDIA') {
    return action.alert;
  }
  if (action.type === 'OPEN_DIALOG_YES_NO_MIDIA') {
    return action.alert;
  }
  if (action.type === 'OPEN_DIALOG_MIDIA') {
    return action.alert;
  }
  if (action.type === 'SHOW_DIALOG_FILE_MIDIA') {
    return action.alert;
  }
  if (action.type === 'CLOSE_DIALOG_FILE_MIDIA') {
    return action.alert;
  }

  return state;
}
