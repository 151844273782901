import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import { Avatar } from 'material-ui';

import DashboardButton from '../../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import Loader from '../../../../utils/AutoPagination/Loader';
import ToolsButton from '../../../../Tools/ToolsList/Components/ToolsButton/ToolsButton';

import { BodyTable } from '../../../../utils/BodyTable';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeadRow,
  TableRow
} from '../../../../utils/Table';
import { FooterPagination } from '../../../../utils/Pagination';

import { ReactComponent as IconRelatorio } from './icons/relatorio.svg';
import { ReactComponent as IconMessage } from './icons/msg.svg';
import { ReactComponent as IconCertificado } from './icons/certificado.svg';
import { ReactComponent as IconDownload } from './icons/download.svg';
import { ReactComponent as IconResend } from './icons/resend.svg';
import { ReactComponent as IconSearch } from '../../../../academy/course/img/iconSearchSmall.svg';
import ImgPlaceHolder from '../../../../utils/img/place_holder_user.png';

import * as S from './ActionsModule.styles';

export const ActionsModule = (props) => {
  const [optionActive, setOptionActive] = useState();
  const [email, setEmail] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [optionRadio, setOptionRadio] = useState('not_initiated');
  const [message, setMessage] = useState('');

  function isEmail(value) {
    if (
      (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) ||
      !value
    ) {
      return false;
    }
    return true;
  }

  const handleOptionRadio = (e) => {
    setOptionRadio(e.target.value);
  };

  function useDebounce(fn, delay) {
    const timeoutRef = useRef(null);

    function debouncedFn(...args) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        fn(...args);
      }, delay);
    }

    return debouncedFn;
  }

  function handleSearchUser(newValue, page) {
    setSearchUser(newValue);
    props.searchUserCertificate(newValue, page);
  }

  const handleSearchUserDebounced = useDebounce(handleSearchUser, 1000);

  useEffect(() => {
    props.resetUsersCertificate();
  }, []);

  return (
    <S.ActionsModuleContainer>
      <S.ActionsModuleContent>
        <S.ChooseOptionContainer>
          <h2>Escolha uma opção:</h2>
          <S.OptionContainer>
            <S.Option
              isActive={optionActive === 'relatorio'}
              onClick={() => {
                setOptionActive('relatorio');
                setEmail('');
                setMessage('');
              }}
            >
              <div className="ImageContainer">
                <IconRelatorio />
              </div>
              <span>Relatório</span>
            </S.Option>

            <S.Option
              isActive={optionActive === 'mensagem'}
              onClick={() => {
                setOptionActive('mensagem');
                setEmail('');
                setMessage('');
              }}
            >
              <div className="ImageContainer">
                <IconMessage />
              </div>
              <span>Mensagem</span>
            </S.Option>

            <S.Option
              isActive={optionActive === 'certificado'}
              onClick={() => {
                setOptionActive('certificado');
                setEmail('');
                setMessage('');
              }}
            >
              <div className="ImageContainer">
                <IconCertificado />
              </div>
              <span>Certificado</span>
            </S.Option>
          </S.OptionContainer>
        </S.ChooseOptionContainer>

        {optionActive === 'relatorio' && (
          <S.RelatorioContainer>
            <h2>Receber relatório:</h2>

            <S.Input
              type="email"
              placeholder="Digite um e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              withError={!isEmail(email) && email.length > 0}
            />
            {!isEmail(email) && email.length > 0 && (
              <span className="span-error">E-mail inválido</span>
            )}

            <span>
              O relatório será processado e enviado para o e-mail inserido.
              Antes de enviar, por favor, certifique-se de que o endereço de
              e-mail informado está correto.
            </span>

            <DashboardButton
              disabled={!isEmail(email)}
              label={'Confirmar'}
              onClick={() => props.sendCourseReport(email)}
            />
          </S.RelatorioContainer>
        )}

        {optionActive === 'mensagem' && (
          <S.MessageContainer>
            <h2>Comunicar aos usuários que:</h2>

            <div
              className="form-modality form-modality-menu"
              onChange={handleOptionRadio}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Field
                  name="kind"
                  component="input"
                  type="radio"
                  value="initiated"
                  id="initiated"
                  checked={optionRadio === 'initiated'}
                />
                <label for="initiated" className="form-label">
                  Iniciaram
                </label>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Field
                  name="kind"
                  component="input"
                  type="radio"
                  value="not_initiated"
                  id="not_initiated"
                  checked={optionRadio === 'not_initiated'}
                />
                <label for="not_initiated" className="form-label">
                  Não iniciaram
                </label>
              </div>
            </div>

            <S.InputContainer>
              <div className="first-line">
                <span>Digite uma mensagem:</span>
                <span>{message.length}/280</span>
              </div>

              <S.Textarea
                maxLength={280}
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </S.InputContainer>

            <DashboardButton
              disabled={!message}
              label={'Enviar'}
              onClick={
                !!message
                  ? () => props.sendNotificationReport(optionRadio, message)
                  : () => {}
              }
            />
          </S.MessageContainer>
        )}

        {optionActive === 'certificado' && (
          <S.CertificadoContainer>
            <h3>Buscar usuário:</h3>

            <S.SearchUserContainer>
              <S.ContainerSearch>
                <S.Input
                  placeholder="Digite um nome"
                  onChange={(e) => handleSearchUserDebounced(e.target.value, 1)}
                  style={{
                    padding: '0.8rem 4rem 0.8rem 0.8rem',
                    height: '4rem'
                  }}
                  // withError={!isEmail(email) && email.length > 0}
                />
                <IconSearch className="magnifier" />
              </S.ContainerSearch>

              <ToolsButton
                onClick={() =>
                  handleSearchUser(
                    searchUser,
                    props.usersCertificate.pagination.current_page || 1
                  )
                }
                label="Pesquisar"
                disabled={searchUser.length < 1}
                isDisabled={searchUser.length < 1}
                style={{
                  width: 'auto',
                  padding: '0 2rem'
                }}
              />
            </S.SearchUserContainer>

            <S.TableContainer>
              <BodyTable>
                <Table>
                  <TableHead>
                    <TableHeadRow>Usuário</TableHeadRow>
                    <TableHeadRow>Login</TableHeadRow>
                    <TableHeadRow>Conclusão</TableHeadRow>
                    <TableHeadRow>Certificado</TableHeadRow>
                  </TableHead>
                  <TableBody>
                    {props.usersCertificate.users &&
                      props.usersCertificate.users.map((userInfo) => {
                        return (
                          <TableRow index={userInfo.certificate.id}>
                            <TableData>
                              <S.AvatarContainer>
                                <Avatar
                                  style={{
                                    background: `url(${
                                      userInfo.user.image &&
                                      userInfo.user.image.url !== null
                                        ? userInfo.user.image.url
                                        : ImgPlaceHolder
                                    }
                                      ) center / cover`
                                  }}
                                />
                                {userInfo.user.name}
                              </S.AvatarContainer>
                            </TableData>
                            <TableData>{userInfo.user.email}</TableData>
                            <TableData>
                              {moment
                                .tz(
                                  userInfo.conclusion_date,
                                  'America/Sao_Paulo'
                                )
                                .format('DD/MM/YYYY')}
                            </TableData>
                            <TableData>
                              <S.ActionsCertificateContainer>
                                <div
                                  title="Baixar"
                                  onClick={() =>
                                    props.downloadUserCertificate(
                                      userInfo.user.id
                                    )
                                  }
                                >
                                  <IconDownload />
                                </div>
                                <div
                                  title="Reenviar"
                                  onClick={() =>
                                    props.resendUserCertificate(
                                      userInfo.user.id
                                    )
                                  }
                                >
                                  <IconResend />
                                </div>
                              </S.ActionsCertificateContainer>
                            </TableData>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>

                {props.usersCertificate.users &&
                props.usersCertificate.users.length === 0 ? (
                  <S.TableWarning>Nenhum resultado encontrado</S.TableWarning>
                ) : !props.usersCertificate.users ? (
                  <S.TableWarning>
                    Realize uma pesquisa para encontrar os usuários que possuem
                    um certificado
                  </S.TableWarning>
                ) : (
                  <></>
                )}
                {props.usersCertificate.pagination &&
                  props.usersCertificate.pagination.current_page > 0 && (
                    <footer className="footer-pagination">
                      <FooterPagination
                        activePage={
                          props.usersCertificate.pagination.current_page || 1
                        }
                        totalItemsCount={
                          props.usersCertificate.pagination.total_count || 0
                        }
                        handlePageChange={(pageNumber) =>
                          handleSearchUser(searchUser, pageNumber)
                        }
                      />
                    </footer>
                  )}
                <ReactTooltip className="tooltip" />
              </BodyTable>
            </S.TableContainer>
          </S.CertificadoContainer>
        )}
      </S.ActionsModuleContent>

      <Loader loading={props.loading} />
    </S.ActionsModuleContainer>
  );
};
