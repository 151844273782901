import styled from 'styled-components';
import { style } from '../../../../style';

export const OpenAnswerContainer = styled.div`
    width: 70%;
    min-height: 300px;
    margin-bottom: 20px;
    margin-top: 30px;

    border-radius: 5px;
    background: #F4F6FB;
    font-family: Rubik;
    font-size: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TypeContainer = styled.div`
    height: 20px;
    width: 90%;
    margin-top: 30px;
    // background: green;

    display: flex;
    align-items: center;

    font-size: 14px;
    opacity: 0.75;
`

export const QuestionContainer = styled.div`
    min-height: 60px;
    width: 90%;
    margin-top: 30px;
    // background: green;

    display: flex;
    align-items: center;
`

export const QuestionContent = styled.div`
    height: 100%;
    width: 100%;
    // background: blue;

    >span{
        font-size: 14px;
        opacity: 0.7;
    }
`

export const AnswersContainer = styled.div`
    // min-height: 100px;
    width: 90%;
    margin-top: 30px;
    // background: blue;

    >span{
        font-size: 14px;
        opacity: 0.7;
    }

`

export const AnswersContent = styled.div`
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`

export const AnswerContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
`

export const AnswerContent = styled.div`
    width: 100%;
    min-height: 20px;
    padding: 5px;
    border-radius: 5px;
    background: #E6EAF3;

    display: flex;
    align-items: center;

    >span{
        width: 100%;
        height: 100%;
        // background: green;

        display: flex;
        align-items: center;
    }
`

export const AnswerBottom = styled.div`
    padding-left: 5px;
    height: 40px;
    background: none;
    font-size: 14px;
    opacity: 0.7;

    display: flex;
    align-items: center;
`



