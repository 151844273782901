import React, { Component } from 'react';

import * as S from './LazyLoad.style';

let observer;

export class LazyLoad extends Component {
  ref = React.createRef();

  registerObserver = () => {
    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio > 0) {
          this.props.onVisible();
          observer.unobserve(this.ref.current);
        }
      },
      {
        root: null,
        rootMargin: '64px',
        threshold: 1.0
      }
    );
  };

  componentDidMount() {
    this.registerObserver();

    if (this.ref.current) {
      observer.observe(this.ref.current);
    }
  }

  componentWillUnmount() {
    observer.disconnect();
  }

  render() {
    return (
      <S.LazyLoad visible={this.props.visible} ref={this.ref}>
        {this.props.children}
      </S.LazyLoad>
    );
  }
}

export default LazyLoad;
