import React, { Component } from 'react';
import ComponentHeader from '../../utils/ComponenteHeader';
import HeaderModule from './HeaderModule';
import Pulse from 'react-reveal/Pulse';
import { connect } from 'react-redux';
import CardContent from '../cards/CardContent';
import CreateNewContent from '../contents/NewContent';
import ModuleApi from '../../../Logicas/ModuleApi';
import EditContent from '../contents/EditContent';
import { DialogYesNo } from '../../utils/DailogComponentes';
import DialogApi from '../../../Logicas/DialogApi';
import ContentApi from '../../../Logicas/ContentApi';
import PubSub from 'pubsub-js';

class EditModuleCards extends Component {
  constructor() {
    super();
    this.state = {};
    this.showNewContent = this.showNewContent.bind(this);
    this.showCard = this.showCard.bind(this);
  }

  componentDidMount() {
    PubSub.subscribe(
      'remove-new-content',
      function(render) {
        this.setState({ newContent: null });
      }.bind(this)
    );
  }
  showNewContent() {
    this.setState({
      newContent: (
        <Pulse>
          <CardContent indextext="Novo conteúdo" kind="new">
            <CreateNewContent idmodule={this.props.module_data.id} />
          </CardContent>
        </Pulse>
      )
    });
    setTimeout(
      function() {
        setTimeout(
          function() {
            this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
          }.bind(this),
          300
        );
      }.bind(this),

      300
    );
  }

  showCard() {
    this.setState({ show: true });
  }

  render() {
    if (this.props.module_data.content !== undefined) {
      return (
        <div>
          <ComponentHeader
            actions={[
              {
                name: 'Editar módulo',
                action: this.props.editModuleForm.bind(
                  this,
                  this.props.module_data.id
                )
              }
            ]}
            name="Cards"
            nameaction="Adicionar conteúdo"
            onClick={this.showNewContent}
          />
          <div className="container">
            <HeaderModule
              modulename={this.props.module_data.name}
              score={this.props.module_data.score}
              description={this.props.module_data.description}
            />

            {this.props.module_data.content.map(
              function(content, id) {
                var kind = '';
                switch (content.kindcontent) {
                  case 'text':
                    kind = 'Texto';
                    break;
                  case 'image':
                    kind = 'Imagem';
                    break;
                  case 'video':
                    kind = 'Video';
                    break;
                  default:
                    kind = 'Card';
                    break;
                }
                return (
                  <Pulse>
                    <CardContent
                      Delete={this.props.deleteShowDialogUser.bind(
                        this,
                        content.id
                      )}
                      store={this.props.store}
                      content={content}
                      anco={id}
                      indextext={id + 1}
                      contentname={content.title}
                      kind={kind}
                    >
                      <EditContent content={content} colapse={'#anco' + id} />
                    </CardContent>
                  </Pulse>
                );
              }.bind(this)
            )}
            {this.state.newContent}
          </div>
          <DialogYesNo
            yesclick={this.props.deleteContent.bind(
              this,
              this.props.alert.id_elemente,
              this.props.module_data.id
            )}
            noclick={this.props.closeDialog}
            onClick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message="Tem certeza que deseja remover este conteúdo ?"
          />
          <div ref={(ref) => (this.body = ref)} />
        </div>
      );
    } else {
      return <div>adsdasd</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    module_data: state.module,
    alert: state.dialog,
    pagination: state.pagination
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editModuleForm: (id) => {
      dispatch(ModuleApi.getModuleEdit(id, 'redirect'));
    },
    deleteShowDialogUser: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    deleteContent: (values, idmodule) => {
      dispatch(ContentApi.deleteContent(values, idmodule));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    }
  };
};
const EditModuleCardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditModuleCards);

export default EditModuleCardsContainer;
