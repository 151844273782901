import styled from 'styled-components';
import { style as teamAttributes } from '../../../style';

export const BodyDialogGallery = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-evenly;
  padding: 20px;
  height: 200px;
  opacity: 0.6;
`;

export const BodyDialogImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
`;

export const BodyDialogLabel = styled.span`
  font-family: Rubik;
  font-size: 16px;
  text-align: center;
  color: #403f4c;
  cursor: pointer;
  border-bottom: 2.1px transparent solid;
  &:hover {
    cursor: pointer;
    border-bottom: 2.1px ${teamAttributes.inpuStyle.colorInput} solid;
  }
`;

export const LabelLoad = styled.span`
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: 500;
  text-align: left;
  color: #acacac;
  position: absolute;
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 260px;
  padding: 16px 0;
  overflow-y: scroll;
`;

export const PreviewItem = styled.div`
  width: calc(25% - 16px);
  margin: 16px 8px;
  display: flex;
  flex-direction: column;
`;

export const PreviewImage = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const RemovePreview = styled.span`
  font-family: Rubik;
  cursor: pointer;
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 16px;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: -28px;
  top: -28px;
  width: 24px;
  height: 24px;
  opacity: 0.6;
  background-color: #ffffff;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 11px;
    top: 2px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
