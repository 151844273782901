import React, { Component } from 'react';

require('./placeholder.css');

class PlaceHolder extends Component {
  render() {
    return (
      <div className="place-holder-body">
        <label className="label-place-holder">
          Sem informações disponíveis
        </label>
      </div>
    );
  }
}
export default PlaceHolder;
