import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SectorButton from '../SectorButton/SectorButton';
import SectorSearch from '../SectorSearch/SectorSearch';
import SectorApi from '../../../../Logicas/SectorApi';
import { TextFieldWithoutForm } from '../../../utils/FormsComponents';


import * as S from './SectorHeader.style';

export class SectorHeader extends Component {
  constructor() {
    super();
    this.state = {
      searchvalue: ''
    };
    this.getBySearch = this.getBySearch.bind(this);
    this.getBySearchDebounced = this.getBySearchDebounced.bind(this);
  }

  getBySearch({ target: { value } }) {
   this.setState({ searchvalue: value }, () => {
      this.getBySearchDebounced(
        value
      )
    });
  }

  getBySearchDebounced = debounce((text) => {
    this.props.getSectorList(1, text)
  }, 1500);

  render() {
    return (
      <MuiThemeProvider>
        <S.SectorComponentHeader>
          <S.SectorComponentHeaderTitle>
            <S.SectorComponentHeaderTitleValue>
              Setor
            </S.SectorComponentHeaderTitleValue>
          </S.SectorComponentHeaderTitle>
          <S.SectorComponentHeaderButtons>
            {/* <SectorSearch
              setPatchSector={this.props.setPatchSector}
              editSector={this.props.editSector}
              deleteSector={this.props.deleteSector}
            /> */}
            <TextFieldWithoutForm
              value={this.state.searchvalue}
              onChange={this.getBySearch}
              floatingLabelText="Busque pelo setor"
              style={{width: '200px'}}
            />
            <SectorButton
              label="Novo setor"
              onClick={this.props.setPatchSector}
            />
          </S.SectorComponentHeaderButtons>
        </S.SectorComponentHeader>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSectorList: (page, search) => {
      dispatch(SectorApi.getSectorUserV2(page, search));
    },
  };
};
const SectorHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectorHeader);

export default SectorHeaderContainer;