import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import moment from 'moment-timezone';
import errorHandler from '../componentes/utils/errorHandler';
import teamSettings from '../componentes/utils/TeamsSettings/generalSettings';

export const getTabContent = (dashboardTab) => async (dispatch) => {
  const { hasCourses } = teamSettings();

  switch (dashboardTab) {
    case 'PUBLICAÇÕES':
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data: publications } = await API.get(
          `dashboard/publications`,
          Config.headers()
        );

        const more_popular_graphic = buildGraphic(
          publications.interactions_graphic
        );
        publications.more_popular_graphic = more_popular_graphic;

        dispatch({
          type: 'GET_DASHBOARD_PUBLICATIONS',
          publications
        });
        dispatch({
          type: 'CHANGE_DASHBOARD_TAB',
          dashboardTab
        });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
      break;
    case 'USUÁRIOS':
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data: users } = await API.get(
          `dashboard/users`,
          Config.headers()
        );

        dispatch({
          type: 'GET_DASHBOARD_USERS',
          users
        });
        dispatch({
          type: 'CHANGE_DASHBOARD_TAB',
          dashboardTab
        });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
      break;
    case 'LOJA':
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data: store } = await API.get(
          `dashboard/products`,
          Config.headers()
        );

        dispatch({
          type: 'GET_DASHBOARD_STORE',
          store
        });
        dispatch({
          type: 'CHANGE_DASHBOARD_TAB',
          dashboardTab
        });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
      break;
    case `${hasCourses.value.toUpperCase()}`:
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        const { data: courses } = await API.get(
          `dashboard/courses`,
          Config.headers()
        );

        dispatch({
          type: 'GET_DASHBOARD_COURSES',
          courses
        });
        dispatch({
          type: 'CHANGE_DASHBOARD_TAB',
          dashboardTab
        });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
      break;
  }
};

export const getPeriodInteractions = (values) => async (dispatch) => {
  try {
    const { first_date_start, first_date_end } = values;

    dispatch({ type: 'LOADING_DASHBOARD_START' });
    dispatch({
      type: 'UPDATE_DATES',
      first_date_start,
      first_date_end
    });

    const firstDateStart = first_date_start.toLocaleDateString();
    const firstDateEnd = first_date_end.toLocaleDateString();

    const { data: publications } = await API.get(
      `dashboard/publications?first_date_start=${firstDateStart}&first_date_end=${firstDateEnd}&without_cache=true?`,
      Config.headers()
    );

    const more_popular_graphic = buildGraphic(
      publications.interactions_graphic
    );
    publications.more_popular_graphic = more_popular_graphic;

    dispatch({
      type: 'GET_DASHBOARD_PUBLICATIONS',
      publications
    });
    dispatch({ type: 'LOADING_DASHBOARD_END' });
    dispatch({ type: 'PERIODS_COMPARISON_FALSE' });
  } catch (error) {
    dispatch({ type: 'LOADING_DASHBOARD_END' });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

export const comparePeriodsInteractions = (values) => async (dispatch) => {
  try {
    dispatch({ type: 'LOADING_DASHBOARD_START' });

    const {
      first_date_start,
      first_date_end,
      second_date_start,
      second_date_end
    } = values;

    dispatch({
      type: 'UPDATE_DATES',
      first_date_start,
      first_date_end,
      second_date_start,
      second_date_end
    });

    const firstDateStart = first_date_start.toLocaleDateString();
    const firstDateEnd = first_date_end.toLocaleDateString();
    const secondDateStart = second_date_start.toLocaleDateString();
    const secondDateEnd = second_date_end.toLocaleDateString();

    const { data: publications } = await API.get(
      `dashboard/publications?first_date_start=${firstDateStart}&first_date_end=${firstDateEnd}&second_date_start=${secondDateStart}&second_date_end=${secondDateEnd}&without_cache=true?`,
      Config.headers()
    );

    const more_popular_graphic = buildGraphic(
      publications.interactions_graphic
    );

    publications.more_popular_graphic = more_popular_graphic;

    dispatch({
      type: 'GET_DASHBOARD_PUBLICATIONS',
      publications
    });
    dispatch({ type: 'LOADING_DASHBOARD_END' });
    dispatch({ type: 'PERIODS_COMPARISON_TRUE' });
  } catch (error) {
    dispatch({ type: 'LOADING_DASHBOARD_END' });
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};

const buildGraphic = (publications) => {
  const graphicdata = publications.map((publication) => {
    const date = moment.utc(publication.day).valueOf();

    return {
      date,
      Interações: publication.interactions_amount
    };
  });

  return graphicdata;
};

export const getLoggedUserData = () => async (dispatch) => {
  try {
    const {
      data: {
        image: { url: userImage },
        name: userName,
        id: userId,
        sector: { name: userSector },
        email: userEmail,
        score: userScore
      }
    } = await API.get(
      `users/${localStorage.getItem('Userid')}`,
      Config.headers()
    );

    const payload = {
      userImage,
      userName,
      userId,
      userSector,
      userEmail,
      userScore
    };

    dispatch({
      type: 'SET_LOGGED_USER_DATA',
      payload
    });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
  }
};
