import React, { Component } from 'react';
import { DialogProgressIndeterminated } from '../../../../utils/DailogComponentes';
import Dropzone from 'react-dropzone';

import CardContentImage from '../../../../utils/img/card-content-image.svg';
import IcoRemove from '../../../../utils/img/ic-excluir.svg';
import CoverIcon from '../../../../utils/img/img-cover.svg';

import '../CourseLongStyles.css';

export class ImageModule extends Component {
  render() {
    return (
      <div className="container-content-submodule">
        <div className="loading-content">
          {this.props.loading.loadingCourse &&
            this.props.loading.indexContent === this.props.content.id && (
              <DialogProgressIndeterminated />
            )}
        </div>
        <div className="header-content-submodule">
          <div className="header-title-img">
            <img src={CardContentImage} alt="Componente de Texto" />
            <div className="header-content-description">
              <p>CARD IMAGEM</p>
            </div>
          </div>
          <div className="removed-submodule">
            <img
              src={IcoRemove}
              alt="Remover Conteudo"
              onClick={() =>
                this.props.loading.waitLoadingMovieImage
                  ? this.props.callbackImageMovie()
                  : this.props.removedSubModuleAndContent(
                      this.props.content.id,
                      'content',
                      this.props.index,
                      this.props.indexContent
                    )
              }
            />
          </div>
        </div>
        <div className="image-cover-container">
          <Dropzone
            style={{ border: 'none' }}
            onDrop={this.props.patchImageContent.bind(
              this,
              this.props.subModule.id,
              this.props.content.id
            )}
          >
            {this.props.content.image ? (
              <div className="image-cover">
                <img
                  alt="Capa curso padrão"
                  src={this.props.content.image.url}
                />
              </div>
            ) : (
              <div className="image-cover-default">
                <img alt="Capa curso escolhida" src={CoverIcon} />
                <p>Adicionar imagem</p>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}

export default ImageModule;
