import React, { Component } from 'react';
import { connect } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { browserHistory } from 'react-router';
import * as S from './ToolsList.styles';
import GridCard from './Components/GridCard/GridCard';
import ToolsButton from './Components/ToolsButton/ToolsButton';
import NoContent from './Components/NoContent/NoContent';
import LazyLoad from './Components/LazyLoad/LazyLoad';
import SectionCreate from '../Section/SectionCreate/SectionCreate';
import SectionEdit from '../Section/SectionEdit/SectionEdit';
import * as SectionApi from '../../../Logicas/SectionApi';
import DialogApi from '../../../Logicas/DialogApi';
import TagApi from '../../../Logicas/TagApi';

import teamSettings from '../../utils/TeamsSettings/generalSettings';
import { style } from '../../../style';

const { hasTools } = teamSettings();

export class ToolsList extends Component {
  constructor() {
    super();
    this.state = {
      externalLinkSections: [],
      showModal: {},
      cardVisibility: {}
    };
  }

  async componentDidMount() {
    await this.props.getToolsList();
    await this.props.getTags();
    this.populateModalsState();
  }

  createNewLink = () => {
    browserHistory.push('/tools/link/create');
  };

  createNewFile = () => {
    browserHistory.push('/tools/file/create');
  };

  populateModalsState = () => {
    for (let i = 0; i < this.props.sectionList.length; i++) {
      this.state.showModal[i] = false;
    }
  };

  showModalHandler = (k) => {
    let modalToUpdate = {};
    modalToUpdate[k] = true;
    this.setState({
      showModal: Object.assign({}, this.state.showModal, modalToUpdate)
    });
  };

  hideModalHandler = (k) => {
    let modalToUpdate = {};
    modalToUpdate[k] = false;
    this.setState({
      showModal: Object.assign({}, this.state.showModal, modalToUpdate)
    });
    this.props.resetEditForm();
  };

  showCard = (k) => {
    this.setState((prevState) => ({
      cardVisibility: {
        ...prevState.cardVisibility,
        [k]: true
      }
    }));
  };

  render() {
    return (
      <>
        <MuiThemeProvider>
          <S.ToolsComponentHeader>
            <S.ToolsComponentHeaderTitle>
              <S.ToolsComponentHeaderTitleValue>
                {hasTools.value}
              </S.ToolsComponentHeaderTitleValue>
            </S.ToolsComponentHeaderTitle>
            <S.ToolsComponentHeaderButtons>
              <ToolsButton label="Novo Link" onClick={this.createNewLink} />
              <ToolsButton label="Novo Arquivo" onClick={this.createNewFile} />
              <ToolsButton label="Nova Seção" onClick={this.props.openDialog} />
            </S.ToolsComponentHeaderButtons>
          </S.ToolsComponentHeader>
        </MuiThemeProvider>
        <div className="">
          {this.props.sectionList &&
            this.props.sectionList.map((section) => (
              <div key={section.id}>
                <SectionCreate
                  name={section.name}
                  sectionId={section.id}
                  showModal={this.state.showModal}
                  showModalHandler={this.showModalHandler}
                  hideModalHandler={this.hideModalHandler}
                />
                <S.ToolsContentBody className="container">
                  <S.ToolsContentBodySection>
                    {section.external_links.length > 0 ? (
                      section.external_links.map((link) => (
                        <GridCard data={link} visible={true} />
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </S.ToolsContentBodySection>
                </S.ToolsContentBody>
              </div>
            ))}
        </div>
        <SectionEdit />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sectionList: state.section.sectionList,
    alert: state.dialog
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getToolsList: async () => {
      await dispatch(SectionApi.getSectionList());
    },
    openDialog: () => {
      dispatch(DialogApi.openDialog());
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    resetEditForm: () => dispatch({ type: 'EDIT_SECTION_RESET' })
  };
};
const ToolsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolsList);

export default ToolsListContainer;
