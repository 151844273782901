import React, { Component } from 'react';
import 'video-react/dist/video-react.css';
import ImgDrop from '../utils/img/img-drag-drop-preview2.png';
import IconImg from '../utils/img/ic-pontos.svg';
import IcoFisic from '../utils/img/ic-prod-fisico.png';
import IcoVirtual from '../utils/img/ic-prod-virtual.png';

require('./previewsproduct.css');

class ProductPreview extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="preview-product" data-tip data-for="title">
        <div className="row">
          <div
            data-tip
            data-for="title"
            className="col-md-12 preview-product-img"
          >
            {this.props.image ? (
              <img
                data-tip
                data-for="title"
                className="img-default-product-drop"
                src={this.props.image}
                alt="imagem do produto"
              />
            ) : (
              <img
                data-tip
                data-for="title"
                className="img-default-product"
                src={ImgDrop}
                alt="ícone imagem"
              />
            )}
          </div>
        </div>
        <div className="preview-product-row">
          <div className="product-row">
            <div className="preview-product-division"></div>
          </div>
          <div className="product-row">
            <div className="preview-product-title ">
              <div className="col-md-12">{this.props.title}</div>
            </div>
          </div>
          <div className="product-row">
            <div className="preview-product-text ">
              <div className="col-md-12">
                <textarea
                  className="text-area-preview-product"
                  value={this.props.text}
                >
                  {' '}
                </textarea>
              </div>
            </div>
          </div>
          <div className="product-row">
            <div className="row-score-text">
              <img src={IconImg} alt="ícone imagem"/>{' '}
              <p className="text-score">{this.props.score && Number(this.props.score).toLocaleString("pt-BR")}</p>
            </div>
          </div>
          <div className="product-row">
            <div style={{ marginLeft: '20px' }} className="text-product">
              {this.props.kind === 'Produto Físico' ? (
                <>
                  <img className="img-product" src={IcoFisic} alt="ícone produto físico"/>
                  <span className="type-description">Produto físico</span>
                </>
              ) : (
                <>
                  <img className="img-product" src={IcoVirtual} alt="ícone produto virtual"/>
                  <span className="type-description">Produto virtual</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProductPreview;
