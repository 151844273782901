const defaultState = {
  surveyList: {
    pagination: {},
    survey_content_list: []
  },
  filter_survey: {},
  filter_details: {}
};

export function survery(state = defaultState, action) {
  switch (action.type) {
    case 'GET_SURVERY_LIST':
      return {
        ...state,
        surveyList: action.payload
      };
    case 'GET_MORE_SURVEYS':
      return {
        ...state,
        surveyList: action.payload
      };
    case 'GET_FILTER_SURVERY':
      return {
        ...state,
        filter_survey: action.filter_survey
      };
    case 'GET_FILTER_SURVERY_DETAILS':
      return {
        ...state,
        filter_details: action.filter_details
      };
    default:
      return state;
  }
}
