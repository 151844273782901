import React, { Component } from 'react';
import TextIcon from '../../img/ic-text.png';
import ImageIcon from '../../img/ic-imagem.png';
import VideoIcon from '../../img/ic-video.png';
import CarouselIcon from '../../img/ic-carousel.png';
import LinkIcon from '../../img/ic-link.png';
import ModalCanvasMidia from '../dialog/DialogMidia';
import Pulse from 'react-reveal/Pulse';

require('./SelectComponentinitial.css');

export default class SelectComponentInitial extends Component {
  render() {
    return (
      <div>
        <ModalCanvasMidia
          InsertComponentLink={this.props.InsertComponentLink}
          closeDialogUploadFile={this.props.closeDialogUploadFile}
          InsertComponentCarousel={this.props.InsertComponentCarousel}
          InsertComponentVideo={this.props.InsertComponentVideo}
          InsertComponentImage={this.props.InsertComponentImage}
          closeDialogUploadFile={this.props.closeDialogUploadFile}
          alert={this.props.alert}
        />
        <Pulse>
          <div style={{ marginTop: '5%', marginBottom: '5%' }} className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="label-add-file">
                Agora adicione um primeiro componente
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-1"></div>
            <div
              onClick={this.props.ShowComponentText.bind(this, true)}
              className="col-md-2 select-component-body-initial"
            >
              <img className="image-title-component-initial" src={TextIcon} alt="ícone de texto"/>
              <div className="label-component">Texto</div>
            </div>
            <div
              onClick={this.props.showDialogUploadFile.bind(this, 'image')}
              className="col-md-2 select-component-body-initial"
            >
              <img className="image-title-component-initial" src={ImageIcon} alt="ícone de imagem"/>
              <div className="label-component">Imagem</div>
            </div>
            <div
              onClick={this.props.showDialogUploadFile.bind(this, 'video')}
              className="col-md-2 select-component-body-initial"
            >
              <img className="image-title-component-initial" src={VideoIcon} alt="ícone de vídeo"/>
              <div className="label-component">Video</div>
            </div>
            <div
              onClick={this.props.showDialogUploadFile.bind(this, 'carousel')}
              className="col-md-2 select-component-body-initial"
            >
              <img
                className="image-title-component-initial"
                src={CarouselIcon}
                alt="ícone de carrossel"
              />
              <div className="label-component">Carousel</div>
            </div>
            <div
              onClick={this.props.showDialogUploadFile.bind(this, 'link')}
              className="col-md-2 select-component-body-initial"
            >
              <img className="image-title-component-initial" src={LinkIcon} alt="ícone de link"/>
              <div className="label-component">Link</div>
            </div>
          </div>
        </Pulse>
      </div>
    );
  }
}
