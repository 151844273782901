import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from 'material-ui/styles';
import { TextAreaForm, TextForm } from '../../utils_form/FormComponents';
import ContentApi from '../../../Logicas/ContentApi';
import DialogApi from '../../../Logicas/DialogApi';
import IconRemove from '../../utils/img/ic-excluir.svg';
import ReactLoading from 'react-loading';
import PubSub from 'pubsub-js';
require('./contentbody.css');

class CreateContentVideo extends Component {
  constructor() {
    super();
    this.state = { edit: false, render: true };
    this.resetEdit = this.resetEdit.bind(this);
    this.setVideo = this.setVideo.bind(this);
    this.resetEdit = this.resetEdit.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
    this.saveText = this.saveText.bind(this);
  }

  componentDidMount() {
    this.setState({ title_text: this.props.text_name });
    this.setState({ text: this.props.text });
  }

  resetEdit() {
    if (this.props.id_edit === undefined) {
      PubSub.publish('clear_new_elements', true);
    } else {
      this.setState({ title_text: this.props.text_name });
      this.setState({ text: this.props.text });
      this.setState({ edit: false });
    }
  }

  saveText() {
    if (this.state.text !== undefined && this.state.title_text !== undefined) {
      this.setState({ edit: false });
      if (this.props.id_edit !== undefined) {
        this.props.patchContent(
          { title: this.state.title_text, textcontent: this.state.text },
          this.props.module_id,
          this.props.id_edit,
          this.props.course_id
        );
      } else {
        this.props.saveContent(
          { title: this.state.title_text, textcontent: this.state.text },
          this.props.module_id,
          this.props.course_id
        );
      }
      this.props.showLoad(this.props.id_edit);
    } else {
      this.props.showErroValidation('Campos obrigatórios não informados');
    }
  }

  deleteCard() {
    this.props.deleteContent(this.props.id_edit, this.props.course_id);
  }

  setVideo(file) {
    this.setState({ video: file });
  }

  render() {
    if (this.state.render) {
      return (
        <MuiThemeProvider>
          <form className="content-body-display">
            {this.props.load_content_requisition.visible === false ||
            this.props.load_content_requisition.id !== this.props.id_edit ? (
              <React.Fragment>
                <div className="actions-card-course">
                  {this.state.edit === true ||
                  this.props.id_edit === undefined ? (
                    <React.Fragment>
                      <label onClick={this.saveText} className="label-actions">
                        Salvar
                      </label>
                      <label onClick={this.resetEdit} className="label-actions">
                        Cancelar
                      </label>
                    </React.Fragment>
                  ) : (
                    <label
                      onClick={() => this.setState({ edit: true })}
                      className="label-actions"
                    >
                      Editar
                    </label>
                  )}
                  {this.state.edit === true ? (
                    <img
                      onClick={this.deleteCard}
                      className="img-card-course"
                      src={IconRemove}
                      alt="Ícone de remoção de card"
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <TextForm
                  onChange={(event) =>
                    event.target.value.length > 50
                      ? this.setState({
                          errorTextname: 'Limite de caracteres atingido'
                        })
                      : this.setState({
                          title_text: event.target.value,
                          errorTextname: ''
                        })
                  }
                  value={this.state.title_text}
                  errorText={this.state.errorTextname}
                  hintText="Texto"
                />
                <TextAreaForm
                  onChange={(event) =>
                    event.target.value.length > 300
                      ? this.setState({
                          texterro: 'Limite de caracteres atingido'
                        })
                      : this.setState({ text: event.target.value })
                  }
                  value={this.state.text}
                  errorText={this.state.texterro}
                  rowsMax={10}
                  hintText="Texto"
                />
              </React.Fragment>
            ) : (
              <div className="display-load">
                <div className="display-load">
                  <label className="save-label">Salvando</label>
                  <ReactLoading
                    type="balls"
                    color="#c3c3c3"
                    height={'10%'}
                    width={'10%'}
                  />
                </div>
              </div>
            )}
          </form>
        </MuiThemeProvider>
      );
    } else {
      return <div />;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveContent: (values, idmodule, idcourse) => {
      dispatch(ContentApi.saveContentText(values, idmodule, idcourse));
    },
    patchContent: (values, idmodule, idcontent, idcourse) => {
      dispatch(
        ContentApi.patchContentText(values, idmodule, idcontent, idcourse)
      );
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    },
    deleteShowDialogUser: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    deleteContent: (values, idmodule) => {
      dispatch(ContentApi.deleteContent(values, idmodule));
    },
    showLoad: (id) => {
      dispatch({ type: 'SUBMITTING_CONTENT', id: id });
    },
    showErroValidation: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: message
        }
      })
  };
};

const mapStateToProps = (state) => {
  return {
    load_content_requisition: state.load_content_requisition
  };
};

const ContentVideoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContentVideo);

export default ContentVideoContainer;
