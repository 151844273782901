import React, { Component } from 'react';
import { Body } from '../utils/BodyComponente';
import { connect } from 'react-redux';
import ComponentHeader from '../utils/ComponenteHeader';
import {
  required,
  maxLengthDescription,
  minValue0,
  minProductsQty1,
  parseValue,
  maxLength255
} from '../validation/FormValidation';
import { reduxForm, formValueSelector } from 'redux-form';
import TagApi from '../../Logicas/TagApi';
import {
  SelectForm,
  LongTextFieldTitle,
  TextArea,
  TextFieldForm,
  SelectFormIdStore,
  AutoCompleteForm,
  DropZoneImagePreview
} from '../utils_form/FormComponents';
import ProductApi from '../../Logicas/ProductApi';
import ProductPreview from '../previews/ProductPreview';
import DialogProgressCircular from '../utils/DialogProgressCircular';
import { addImageProcess } from '../utils/addImageProcess';

class EditProduct extends Component {
  constructor() {
    super();
    this.state = {
      tags: [],
      image: ''
    };
    this.saveProduct = this.saveProduct.bind(this);
    this.updateImage = this.updateImage.bind(this);
  }

  componentDidMount() {
    this.props.getTags();

    const setImagePreview = setInterval(() => {
      if (
        this.props === undefined ||
        this.props.initialValues === undefined ||
        this.props.initialValues.image_product === undefined
      ) {
        return;
      }
      clearInterval(setImagePreview);

      this.setState({ image: this.props.initialValues.image_product });
    });
  }

  updatePublication(values) {
    this.props.editpost(values);
  }

  isImg(file){
    return file.type === "image/jpeg" || file.type === "image/png" ||
          file.type === ".jpg" || file.type === ".png" || file.type === ".jpeg" || file.type === "image/gif";
  }

  isGif(file){
    return file.type === "image/gif";
  }

  async updateImage(file) {
    const { preview } = file[0];

    if(!this.isImg(file[0]) && !this.isGif(file[0])) return;

    let hasError = false;

    if(this.isImg(file[0])){
      const i = await addImageProcess(URL.createObjectURL(file[0]));
      if(i.height > 2000 || i.width > 2000) {
        hasError = true;
        this.props.dispatchImageError();
      }
    }

    if(this.isGif(file[0]) && (file[0].size / (1024*1024).toFixed(2)) > 20){
      hasError = true;
      this.props.dispatchGifError();
    }

    if(!hasError)
      this.setState({
        image: preview,
        file
      });
  }

  saveProduct(values) {
    const {stores} = this.props;
    if (values.image_product !== undefined || this.state.image !== undefined) {
      if (this.state.file !== undefined) {
        values.image_product = this.state.file;
      }
      this.props.saveProduct({...values, store_product: stores && stores[0] ? stores[0].id : 1});
    } else {
      this.props.showError();
    }
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    let score = this.props.score_product;
    if (typeof this.props.score_product === 'string') {
      score = this.props.score_product.replace(/[^0-9]/g, '');
    }
    return (
      <div>
        <form onSubmit={handleSubmit(this.saveProduct)}>
          <ComponentHeader
            disabled={submitting}
            actions={[]}
            name="Produtos"
            nameaction="Salvar"
            onClick={handleSubmit(this.saveProduct)}
          />
          <Body>
            <div className="row">
              <div className="col-md-8">
                <LongTextFieldTitle
                  name="name_product"
                  validations={[required]}
                  id="name"
                  label="Nome do produto*"
                  type="text"
                />
              </div>
              <div className="col-md-4">
                <DropZoneImagePreview onDrop={(file) => this.updateImage(file)}>
                  <div className="col-md-4">
                    {
                      <ProductPreview
                        image={this.state.image}
                        kind={this.props.kind_product}
                        score={score}
                        text={this.props.description_product}
                        title={this.props.name_product}
                      />
                    }
                  </div>
                </DropZoneImagePreview>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <TextArea
                  name="description_product"
                  validations={[required, maxLengthDescription]}
                  id="name"
                  label="Descrição*"
                  type="text"
                />
                <p className="view-characters">
                  {this.props.description_product &&
                    this.props.description_product.length <= 1024 &&
                    `${1024 -
                      this.props.description_product
                        .length} caracteres restantes.`}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <AutoCompleteForm
                  name="tags_product"
                  isLoading={this.props.isLoadingTags}
                  validations={[required]}
                  label="Tags"
                  getTagAutoComplete={()=>{}}
                  infiniteScroll={{
                    searchByRequest: true,
                    loadMore: (page, search) => this.props.getTags(page, search)
                  }}
                  tags={this.props.listTagName}
                />
              </div>
              <div className="col-md-4">
                <TextFieldForm
                  name="score_product"
                  validations={[required, minValue0]}
                  id="name"
                  floatingLabelText="Valor*"
                  type="text"
                  isFormattedNumber
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <TextFieldForm
                  name="amount_product"
                  validations={[required, minProductsQty1]}
                  id="name"
                  floatingLabelText="Quantidade disponível*"
                  type="number"
                />
              </div>
              {/* <div className="col-md-4">
                <SelectFormIdStore
                  name="store_product"
                  elements={this.props.stores}
                  validations={[required]}
                  id="name"
                  label="Loja*"
                  type="text"
                />
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-4">
                <SelectForm
                  name="kind_product"
                  elements={['Produto Físico', 'Produto Virtual']}
                  validations={[required]}
                  id="name"
                  label="Tipo*"
                  type="text"
                />
              </div>
              <div className="col-md-4">
                <div>
                  <TextFieldForm
                    name="purchase_limit"
                    id="name"
                    floatingLabelText="Limite de resgate"
                    type="number"
                    textareaStyle={{ backgroundColor: 'transparent' }}
                  />
                </div>
                <small
                  style={{
                    fontSize: '12px',
                    color: 'rgb(59, 59, 59)',
                    opacity: '0.66'
                  }}
                >
                  Digite 0 (zero) para deixar sem limite
                </small>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div>
                  <TextArea
                    name="default_message_to_send"
                    id="default_message_to_send"
                    label="Mensagem de suporte*"
                    type="text"
                    validations={[required, maxLength255]}
                    textareaStyle={{ backgroundColor: 'transparent' }}
                  />
                  <p className="view-characters">
                    {this.props.default_message_to_send &&
                      this.props.default_message_to_send.length <= 255 &&
                      `${255 -
                        this.props.default_message_to_send
                          .length} caracteres restantes.`}
                  </p>
                </div>
                <small
                  style={{
                    fontSize: '12px',
                    color: 'rgb(59, 59, 59)',
                    opacity: '0.66'
                  }}
                >
                  Informação ou contato para dúvidas sobre o envio
                </small>
              </div>
            </div>
            <DialogProgressCircular />
          </Body>
        </form>
      </div>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'editproduct',
  enableReinitialize: true
})(EditProduct);

const mapDispatchToProps = (dispatch) => {
  return {
    saveProduct: (values) => {
      dispatch(ProductApi.patchProduct(values));
    },
    getTags: async (page, search) => {
      const res = await dispatch(TagApi.getTagsUserV2(page, search));
      return res;
    },
    showError: () =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: 'É obrigatório adicionar uma imagem' }
      }),
    dispatchImageError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não foi possível carregar a imagem",
          submessage:"O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.",
        }
      });
    },
    dispatchGifError:()=>{
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message:"Não é possível enviar o gif, recomendamos o upload de arquivos de gif com no máximo 20mb.",
        }
      });
    },
  };
};

const selector = formValueSelector('editproduct');

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.storeList.editProduct,
    stores: state.market,
    name_product: selector(state, 'name_product'),
    description_product: selector(state, 'description_product'),
    tags_product: selector(state, 'tags_product'),
    score_product: selector(state, 'score_product'),
    amount_product: selector(state, 'amount_product'),
    store_product: selector(state, 'store_product'),
    kind_product: selector(state, 'kind_product'),
    image_product: selector(state, 'image_product'),
    purchase_limit: selector(state, 'purchase_limit'),
    default_message_to_send: selector(state, 'default_message_to_send'),
    listTagName: state.tagsWithPagination.tags,
    isLoadingTags: state.tagsWithPagination.isLoading,
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
