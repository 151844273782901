import React, { Component } from 'react';
import SelectComponent from '../select components/SelectComponent';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { MuiThemeProvider } from 'material-ui/styles'
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { required, minValue0, dateValue } from '../../../validation/FormValidation';
import PostApi from '../../../../Logicas/PostApi';
import TagApi from '../../../../Logicas/TagApi';
import ModalCanvasMidia from '../dialog/DialogMidia';
import {
  TextFieldForm,
  AutoCompleteForm,
  ToggleForm,
  DatePickerForm
} from '../../../utils_form/FormComponents';
import { ComponentSegmentationTags } from '../../../utils_form/ComponentSegmentationTags';
import SpecialPostModal from './Components/SpecialPostModal/SpecialPostModal';
import { style as teamStyle } from '../../../../style';
import DialogMinimumCommentsForm from '../../../publication/dialog/DialogMinimumComments/DialogMinimumComments';

import IconCancel from '../../../utils/img/ic-cancel.png';
import styled from 'styled-components';
import { style } from '../../../../style';

require('./headercanvas.css');

const EditMinComments = styled.div`
  color:#8A8A8A;
  font-weight: 400;
  height: 30px;
  cursor: pointer;
  &:hover {
    border-bottom: 2.1px ${style.inpuStyle.colorInput} solid !important; 
  }
  margin-bottom: 5px; 
`
const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

class HeaderCanvasEdit extends Component {
  constructor() {
    super();
    this.state = {
      showComponentes: false,
      tags: [],
      expressionTags: [],
      not_score_after: undefined
    };
    this.setScore = this.setScore.bind(this);
    this.setTag = this.setTag.bind(this);
    this.setLimitDateGamification = this.setLimitDateGamification.bind(this);
  }

  setTag(event, index, values) {
    this.props.setTag(this.props.editpost.id, { tags: index });
  }

  setLimitDateGamification(){
    this.props.setLimitDateGamification(this.props.editpost.id, this.props.not_score_after);
  }
  setScore(value) {
    if (value.target.value >= 0) {
      this.setState({ score: value.target.value });
      this.props.setPublication('score', value);
      this.props.setTag(this.props.editpost.id, { score: value.target.value });
    }
  }

  updateTags = (tags) => {
    const { setTag, editpost } = this.props;
    setTag(editpost.id, { tags });
  };

  setTagsExpression = (values) => {
    this.setState({ expressionTags: values });
    this.props.setTagExpressions(this.props.editpost.id, values);
  };

  setSpecialPost = () => {
    const specialPostStatus = !this.props.special_post;

    this.props.setSpecialPost(this.props.editpost.id, specialPostStatus);

    if (!this.props.special_post) {
      this.props.openSpecialPostDialog(this.props.editpost.id);
    }
  };

  setFixedPost = () => {
    const fixed_post = !this.props.fixed_post;

    this.props.setFixedPost(this.props.editpost.id, fixed_post);
  };

  setLikesEnabled = () => {
    const likesEnabled = !this.props.likes_enabled;

    this.props.setLikesEnabled(this.props.editpost.id, likesEnabled);
  };

  setCommentsEnabled = () => {
    const commentsEnabled = !this.props.comments_enabled;

    this.props.setCommentsEnabled(this.props.editpost.id, commentsEnabled);
  };

  setMinCommentsEnabled = () => {
    const minCommentsEnabled = !this.props.min_comments_enabled;

    this.props.setMinCommentsEnabled(this.props.editpost.id, minCommentsEnabled);
    if(!minCommentsEnabled) this.props.setMinComments(this.props.editpost.id,1);
  }

  zeroScore = target => {
    if(target.nodeName === 'IMG'){
      this.props.dispatch(change('createalogo', 'score', '0'));
      this.setScore({target:{value:0}});
    }
  }

  zeroDate = target => {
    if(target.nodeName === 'IMG'){
      this.setState({not_score_after:null});
      this.props.dispatch(change('createalogo', 'not_score_after', null));
      this.props.setLimitDateGamification(this.props.editpost.id, null);
    }
  }

  componentDidMount(){
    if(this.props.not_score_after > this.state.not_score_after || this.props.not_score_after < this.state.not_score_after ||
      (typeof this.props.not_score_after === 'object' &&  this.state.not_score_after === undefined)){
      this.setState({not_score_after:this.props.not_score_after});
    }
  }

  componentDidUpdate(){
    if(this.props.not_score_after > this.state.not_score_after || this.props.not_score_after < this.state.not_score_after ||
      (typeof this.props.not_score_after === 'object' &&  this.state.not_score_after === undefined)){
      this.setState({not_score_after:this.props.not_score_after});
      this.setLimitDateGamification();

    }
  }

  render() {
    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let roleAdmin = roles.filter(function(role) {
      return role.name === 'admin';
    });
    let roleManager = roles.filter(function(role) {
      return role.name === 'manager';
    });

    
    return (
      <div
        style={{ marginTop: '10px', marginBottom: '10px' }}
        className="row header-row"
      >
        <MuiThemeProvider muiTheme={muiTheme}>
          <div className="col-md-4">
            {this.props.tagsExpression ? (
              <div className="header-segmentation-tag-edit">
                <h2>Segmentação:</h2>
              </div>
            ) : (
              <div className="header-segmentation-tag-field">
                <AutoCompleteForm
                  name="tags"
                  isLoading={this.props.isLoadingTags}
                  validations={[required]}
                  label="Segmentação*"
                  getTagAutoComplete={this.props.getTagAutoComplete}
                  getAuto={this.props.getTagAutoComplete}
                  tags={this.props.tags}
                  onChange={(tags) => this.updateTags(Object.values(tags))}
                />
              </div>
            )}
          </div>
          <div className="col-md-2">
            {(roleAdmin[0] || roleManager[0]) && (
              <>
                <div className="cancel-edit" >
                  {
                    this.props.score && this.props.score !== '0' ? (
                      <img src={IconCancel} alt="" onClick={(e) => this.zeroScore(e.target)}/>
                    ) : <></>
                  }
                </div>
                <TextFieldForm
                  onChange={this.setScore}
                  name="score"
                  rows={1}
                  validate={[required, minValue0]}
                  floatingLabelText="Pontuação"
                  id="name"
                  label="Pontuação*"
                  type="number"
                  style={{ width: '85%'}}
                  textareaStyle={{width:'120px'}}
                />
                <div>
                  <span className="note-schedule">
                    Premiação ao interagir
                  </span>
                </div>

                <div className="cancel-edit cancel-date" >
                  {
                    this.props.not_score_after !== null && this.props.not_score_after !== undefined && (
                      <img src={IconCancel} alt="" onClick={(e) => this.zeroDate(e.target)}/>
                    )
                  }
                </div>
                <label style={{borderBottom:'1px solid #3B3B3B4D', width:'130px'}}>
                  <DatePickerForm
                    name="not_score_after"
                    label="Prazo para gamificação"
                    value={this.props.not_score_after}
                    isHeaderCanvasEdit={true}
                    style={{ width: '100%'}}
                  />
                </label>
                <div style={{marginBottom:'20px'}}>
                  <span className="note-schedule">
                    Validade da interação
                  </span>
                </div>
              </>
            )}
          </div>
          <div
            className="col-md-2"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {(roleAdmin[0] || roleManager[0]) && (
              <div className="special-post-container">
                <div className="special-post__field-container">
                  <ToggleForm
                    name="special_post"
                    label="Post especial"
                    toggled={this.props.special_post}
                    onChange={() => this.setSpecialPost()}
                  />
                  {this.props.special_post &&
                    this.props.interaction_message !== undefined && (
                      <small
                        className="special-post__label"
                        onClick={() =>
                          this.props.openSpecialPostDialog(this.props.editpost.id)
                        }
                        style={{
                          borderColor: teamStyle.inpuStyle.colorInput
                        }}
                      >
                        Editar mensagem
                      </small>
                    )}
                </div>
                <div className="special-post__field-container">
                  <ToggleForm
                    name="fixed_post"
                    label="Post fixado"
                    toggled={this.props.fixed}
                    onChange={() => this.setFixedPost()}
                  />
                </div>
                {!this.props.isDrawType && (
                  <div className="special-post__field-container">
                    <ToggleForm
                      name="likes_enabled"
                      label="Likes ?"
                      toggled={this.props.likes_enabled}
                      onChange={() => this.setLikesEnabled()}
                    />
                    {teamStyle.teamName.name !== 'grupopardini' && (
                      <>
                      <ToggleForm
                        name="comments_enabled"
                        label="Comentários ?"
                        toggled={this.props.comments_enabled}
                        onChange={() => this.setCommentsEnabled()}
                      />
                      <ToggleForm
                        name="min_comments_enabled"
                        label={this.props.min_comments_enabled ? `Mínimo de caracteres ? (${this.props.min_size_comments})` : 'Mínimo de caracteres ?'}
                        toggled={this.props.min_comments_enabled}
                        onChange={() => this.setMinCommentsEnabled()}
                      />
                      {this.props.min_comments_enabled && <EditMinComments onClick={this.props.openDialogMinimumComments}>editar</EditMinComments>}

                      {this.props.showDialogMinimumComments && <DialogMinimumCommentsForm postId={this.props.editpost.id}/>}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          {this.state.showComponentes === true ? (
            <SelectComponent
              fixedMenu={this.props.fixedMenu}
              InsertComponentLink={this.props.InsertComponentLink}
              InsertComponentCarousel={this.props.InsertComponentCarousel}
              InsertComponentVideo={this.props.InsertComponentVideo}
              InsertComponentText={this.props.InsertComponentText}
              InsertComponentImage={this.props.InsertComponentImage}
              alert={this.props.alert}
              showDialogUploadFile={this.props.showDialogUploadFile}
              closeDialogUploadFile={this.props.closeDialogUploadFile}
              onMouseLeave={() => {
                this.setState({ showComponentes: false });
              }}
            />
          ) : (
            <div
              onMouseOver={() => {
                this.setState({ showComponentes: true });
              }}
              className={
                this.props.fixedMenu === true
                  ? 'col-md-3 btn-add-component-fixed'
                  : 'col-md-4 btn-add-component'
              }
            >
              <div className="btn-add-component-label">Adicionar componente</div>
            </div>
          )}

          {this.props.tagsExpression && (
            <div className="container-edit-tags-segmentation">
              <ComponentSegmentationTags
                onChange={this.setTagsExpression}
                tags={this.props.tags}
                tagsExpression={this.props.tagsExpression}
                showHeader={false}
              />
            </div>
          )}

        </MuiThemeProvider>

        <ModalCanvasMidia
          InsertComponentLink={this.props.InsertComponentLink}
          closeDialogUploadFile={this.props.closeDialogUploadFile}
          InsertComponentCarousel={this.props.InsertComponentCarousel}
          InsertComponentVideo={this.props.InsertComponentVideo}
          InsertComponentImage={this.props.InsertComponentImage}
          alert={this.props.alert}
        />
        <SpecialPostModal />   
      </div>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'createalogo',
  enableReinitialize: true
})(HeaderCanvasEdit);

const mapDispatchToProps = (dispatch) => {
  return {
    setPublication: (kind, value) =>
      dispatch({ type: 'formPost', kind: kind, value: value }),
    setTag: (id, values) => dispatch(PostApi.canvasUpdate(id, values)),
    setTagExpressions: (id, tagsExpressions) =>
      dispatch(PostApi.canvasUpdateInTagExpressions(id, tagsExpressions)),
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    setSpecialPost: (postId, value) =>
      dispatch(PostApi.setSpecialPost(postId, value)),
    setFixedPost: (postId, value) =>
      dispatch(PostApi.setFixedPost(postId, value)),
    setLikesEnabled: (postId, value) =>
      dispatch(PostApi.setLikesEnabled(postId, value)),
    setCommentsEnabled: (postId, value) =>
      dispatch(PostApi.setCommentsEnabled(postId, value)),
    setLimitDateGamification: (postId, value) =>
      dispatch(PostApi.setLimitDateGamification(postId, value)),
    setMinCommentsEnabled:()=>
      dispatch({
        type: 'SET_MIN_COMMENTS_ENABLED'
      }),    
    setMinComments: (postId, value) =>
      dispatch(PostApi.setMinComments(postId, value)),
    openDialogMinimumComments: () =>
      dispatch({
        type: 'OPEN_MINIMUM_COMMENTS_MODAL'
      }),
    openSpecialPostDialog: (postId) => {
      dispatch({ type: 'OPEN_SPECIAL_POST_DIALOG', postId });
    }
  };
};

const selector = formValueSelector('createalogo');

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.editpostform,
    tags: state.tagsparsed,
    editpost: state.editpostform,
    tags_select: selector(state, 'tags'),
    special_post: selector(state, 'special_post'),
    fixed_post: selector(state, 'fixed_post'),
    likes_enabled: selector(state, 'likes_enabled'),
    comments_enabled: selector(state, 'comments_enabled'),
    min_size_comments: selector(state, 'min_size_comments'),
    min_comments_enabled: selector(state, 'min_comments_enabled'),
    showDialogMinimumComments: selector(state, 'modal_min_comments_is_visible'),
    interaction_message: selector(state, 'interaction_message'),
    send_push: selector(state, 'send_push'),
    not_score_after: selector(state, 'not_score_after'),
    score: selector(state,'score'),
    isLoadingTags: state.isLoadingtags,
    specialPostDialogOpen: state.specialPostDialog.open
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
