import { browserHistory } from 'react-router';

const errorHandler = (error) => {
  if (error.response && error.response.data) {
    const {
      response: { status, data }
    } = error;

    if (status === 401) {
      localStorage.clear();
      browserHistory.push('/');
    } else if (status === 500) {
      return 'Comportamento inesperado do servidor. Tente mais tarde.';
    }

    const errorType = {
      error: () => data.error,
      message: () => data.message,
      errors: () => data.errors[0].message || data.errors[0],
      base: () => data.base[0],
      tags: () => data.tags[0]
    };

    const dataProperties = Object.getOwnPropertyNames(data);
    const errorKeys = Object.keys(errorType);
    const target = String(
      errorKeys.filter((property) => dataProperties.includes(property))
    );

    return errorType[target]();
  } else if(error.message === 'Network Error') {
    return 'O servidor não está respondendo.';
  } else {
    return error.message;
  }
};

export default errorHandler;
