import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';
import Modal from 'react-awesome-modal';
import PostApi from '../../../../../../Logicas/PostApi';
import { TextFieldForm } from '../../../../../utils_form/FormComponents';
import { style as teamAttributes } from '../../../../../../style';

import * as S from './SpecialPostModal.styles';

const {
  CurrencyName: { name: moneyName }
} = teamAttributes;

export class SpecialPostModal extends Component {
  cancelSpecialPostMessage = () => {
    const {
      initialValues: { interaction_message },
      resetMessage,
      closeEpecialPostDialog
    } = this.props;

    resetMessage(interaction_message);

    closeEpecialPostDialog();
  };

  setSpecialPostMessage = ({ interaction_message }) => {
    const specialPostStatus = this.props.special_post;
    this.props.setSpecialPost(
      this.props.editPostId,
      specialPostStatus,
      interaction_message
    );
    this.props.closeEpecialPostDialog();
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Modal width="50%" height="45%" visible={this.props.dialogOpen}>
        <S.SpecialPostContainer>
          <S.SpecialPostTextContainer>
            <S.SpecialPostTitle>
              Defina uma mensagem para exibir no post especial
            </S.SpecialPostTitle>
            <S.SpecialPostDescription>
              Deixando o campo abaixo em branco, será exibida a mensagem padrão{' '}
              <i>"Você interagiu e ganhou X {`${moneyName.split('/')[1]}`}!"</i>
            </S.SpecialPostDescription>
            <TextFieldForm
              name="interaction_message"
              style={{ width: '100%' }}
            />
          </S.SpecialPostTextContainer>
          <S.SpecialPostButtonGroup>
            <S.SpecialPostButtonBody
              onClick={() => this.cancelSpecialPostMessage()}
            >
              <S.SpecialPostButtonLabel>Cancelar</S.SpecialPostButtonLabel>
            </S.SpecialPostButtonBody>
            <S.SpecialPostButtonBody
              onClick={handleSubmit((values) =>
                this.setSpecialPostMessage({ ...values })
              )}
            >
              <S.SpecialPostButtonLabel>Salvar</S.SpecialPostButtonLabel>
            </S.SpecialPostButtonBody>
          </S.SpecialPostButtonGroup>
        </S.SpecialPostContainer>
      </Modal>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'specialPostMessage',
  enableReinitialize: true
})(SpecialPostModal);

const mapDispatchToProps = (dispatch) => {
  return {
    setSpecialPost: (postId, value, interaction_message) =>
      dispatch(PostApi.setSpecialPost(postId, value, interaction_message)),
    closeEpecialPostDialog: () => {
      dispatch({ type: 'CLOSE_SPECIAL_POST_DIALOG' });
    },
    resetMessage: (value) => {
      dispatch(change('specialPostMessage', 'interaction_message', value));
    }
  };
};

const selector = formValueSelector('specialPostMessage');
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.editpostform,
    editPostId: state.editpostform.id,
    special_post: state.editpostform.special_post,
    interaction_message: selector(state, 'interaction_message'),
    dialogOpen: state.specialPostDialog.open
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
