import React, { useEffect, useRef, useState } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

import styled from 'styled-components';

import PostContents from './PostContents';

import { style } from '../../style';
import {
  required,
  maxLength190,
  minValue0,
  dateValue
} from '../validation/FormValidation';
import { TextFieldTitle } from '../utils/FormsComponents';
import {
  AutoCompleteForm,
  DatePickerForm,
  NewDropZoneCover,
  TextFieldForm,
  ToggleForm
} from '../utils_form/FormComponents';
import IconDelete from '../utils/img/ic-delete.png';
import { ReactComponent as IconSpecialPost } from './icons/special_post.svg';
import { ReactComponent as IconFixedPost } from './icons/fixed_post.svg';
import { ReactComponent as IconLikes } from './icons/likes.svg';
import { ReactComponent as IconComments } from './icons/comments.svg';
import { ReactComponent as IconMinComments } from './icons/min_comments.svg';

import PreviewCreateCanvas from '../canvas/components/previews/CreatePreview';
import ProgressToast from '../_shared/ProgressToast/ProgressToast';
import DialogProgressCircular from '../utils/DialogProgressCircular';
import EmojiPicker from '../utils/EmojiPicker/EmojiPicker';

import TagApi from '../../Logicas/TagApi';
import PostApi from '../../Logicas/PostApi';
import ComponentHeader from '../utils/ComponenteHeader';

import DashboardButton from '../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import ToolsButton from '../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import ScheduleModal from '../utils/dialogs/Components/ScheduleModal/ScheduleModal';
import SpecialPostModal from '../canvas/components/header/Components/SpecialPostModal/SpecialPostModal';
import DialogMinimumCommentsForm from '../publication/dialog/DialogMinimumComments/DialogMinimumComments';

import './createOrEdit.css';

const ContainerIconToggle = styled.div`
  svg {
    path {
      fill: ${({ iconActive }) => iconActive && style.inpuStyle.colorInput};
    }
  }
`;

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

export function EditPost(props) {
  const [selectionStart, setSelectionStart] = useState(
    props.editpostform.title ? props.editpostform.title.length : 0
  );
  const [notScoreAfter, setNotScoreAfter] = useState(undefined);
  const [hasTags, setHasTags] = useState(true);

  useEffect(() => {
    !hasTags &&
      props.tags_selected &&
      setHasTags(props.tags_selected.length > 0);
  }, [props.tags_selected]);

  function preserveCursor() {
    setSelectionStart(document.querySelector('textarea#title').selectionStart);
  }

  function handleOnBlur() {
    preserveCursor();
  }

  function setCursor() {
    document
      .querySelector('textarea#name')
      .setSelectionRange(selectionStart, selectionStart);
  }
  const zeroTitle = (target) => {
    if (target.nodeName === 'IMG') {
      props.dispatch(change('createoreditpost', 'title', ''));
    }
  };

  const updateTags = (tags) => {
    const { setTag, editpostform, tags_selected } = props;
    if (!tags[0].value && tags_selected && tags_selected.length === 0) return;
    setTag(editpostform.id, { tags });
  };

  let roles = JSON.parse(localStorage.getItem('UserRoles'));
  let roleAdmin = roles.filter(function(role) {
    return role.name === 'admin';
  });
  let roleManager = roles.filter(function(role) {
    return role.name === 'manager';
  });
  const tagspeaker = JSON.parse(localStorage.getItem('tagspeaker'));
  const tags = tagspeaker.map((tag) => tag.name);

  const setScore = (valueParam) => {
    let value;
    if (typeof valueParam === 'object') {
      const array = Object.values(valueParam);
      array.pop();
      value = array.join('');
    } else {
      value = valueParam;
    }

    if (Number(value.replaceAll('.', '')) > 0) {
      props.setPublication('score', value);
      props.setTag(props.editpostform.id, {
        score: Number(value.replaceAll('.', ''))
      });
    }
  };

  const zeroScore = (target) => {
    if (target.nodeName === 'IMG') {
      props.dispatch(change('createoreditpost', 'score', '0'));
      setScore(0);
    }
  };

  const setLimitDateGamification = () => {
    props.setLimitDateGamification(
      props.editpostform.id,
      props.not_score_after
    );
  };

  const zeroDate = (target) => {
    if (target.nodeName === 'IMG') {
      setNotScoreAfter(null);
      props.dispatch(change('createoreditpost', 'not_score_after', null));
      props.setLimitDateGamification(props.editpostform.id, null);
    }
  };

  const verifyDates = () => {
    if (
      props.not_score_after > notScoreAfter ||
      props.not_score_after < notScoreAfter ||
      (typeof props.not_score_after === 'object' && notScoreAfter === undefined)
    ) {
      setNotScoreAfter(props.not_score_after);
      setLimitDateGamification();
    }
  };

  const setSpecialPost = () => {
    const specialPostStatus = !props.special_post;

    props.setSpecialPost(props.editpostform.id, specialPostStatus);

    if (!props.special_post) {
      props.openSpecialPostDialog(props.editpostform.id);
    }
  };

  const setFixedPost = () => {
    const fixed_post = !props.fixed_post;

    props.setFixedPost(props.editpostform.id, fixed_post);
  };

  const setLikesEnabled = () => {
    const likesEnabled = !props.likes_enabled;

    props.setLikesEnabled(props.editpostform.id, likesEnabled);
  };

  const setCommentsEnabled = () => {
    const commentsEnabled = !props.comments_enabled;

    props.setCommentsEnabled(props.editpostform.id, commentsEnabled);
  };

  const setMinCommentsEnabled = () => {
    const minCommentsEnabled = !props.min_comments_enabled;

    props.setMinCommentsEnabled(props.editpostform.id, minCommentsEnabled);
    if (!minCommentsEnabled) props.setMinComments(props.editpostform.id, 1);
  };

  function setTitleRequest(newTitle) {
    props.editName(props.editpostform.id, {
      title: newTitle
    });
  }

  const setTitleRequestDebounced = useDebounce(setTitleRequest, 1000);

  const searchTagsDebounced = useDebounce(props.getTags, 1000);

  function useDebounce(fn, delay) {
    const timeoutRef = useRef(null);

    function debouncedFn(...args) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        fn(...args);
      }, delay);
    }

    return debouncedFn;
  }

  const isForm =
    /post\/form\/edit/.test(window.location.href) ||
    /post\/form\/create/.test(window.location.href);
  const isRaffle = props.isDrawType;

  function publishPost(values) {
    props.tags_selected && props.tags_selected.length > 0
      ? props.canvasPublish(props.editpostform.id, props.send_push)
      : setHasTags(false);
  }

  if (props.editpostform.elements !== undefined) {
    return (
      <div>
        {verifyDates()}
        <MuiThemeProvider muiTheme={muiTheme}>
          <ComponentHeader
            enableNotification
            enablesubmit={
              props.title === '' ||
              (props.title !== undefined && props.title.length > 190) ||
              props.progressIndeterminate
            }
            description={props.load_status.message}
            onClick={publishPost}
            tooltip={
              <p>
                Atualizar publicação
                <br />
              </p>
            }
            actionSecondarys={
              props.editpostform.enable_notification
                ? []
                : [
                    {
                      name: 'Agendar',
                      action: props.showScheduleModalForTrue
                    }
                  ]
            }
            nameaction={
              props.editpostform.enable_notification ? 'Atualizar' : 'Publicar'
            }
            name={
              isRaffle
                ? props.editpostform.enable_notification
                  ? 'Editar Sorteio'
                  : 'Novo Sorteio'
                : isForm
                ? props.editpostform.enable_notification
                  ? 'Editar Formulário'
                  : 'Novo Formulário'
                : props.editpostform.enable_notification
                ? 'Editar Publicação'
                : 'Nova Publicação'
            }
            isNewCreatePost={true}
          />

          <form
            className="container"
            style={{
              boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.1)',
              display: 'flex',
              paddingBottom: '10px',
              paddingRight: '0px',
              maxWidth: '100%',
              width: '85%'
            }}
          >
            <div
              style={{
                width: '70%',
                minHeight: '67vh',
                maxHeight: '67vh',
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <div
                className="form-createoredit"
                style={{ width: '100%', paddingTop: '30px' }}
              >
                <div className="row" style={{ width: '78%' }}>
                  <div className="col-md-12">
                    {
                      <div className="cancel" style={{ width: '100%' }}>
                        <img
                          style={{ marginLeft: '90%', zIndex: '3' }}
                          src={IconDelete}
                          alt=""
                          onClick={(e) => zeroTitle(e.target)}
                        />
                      </div>
                    }

                    <Field
                      name="title"
                      rows={1}
                      validate={[required, maxLength190]}
                      id="title"
                      label="Título da publicação"
                      hintText="Editar titulo"
                      component={TextFieldTitle}
                      type="text"
                      onBlur={handleOnBlur}
                      onChange={(e) => setTitleRequestDebounced(e.target.value)}
                    />
                    <p className="view-characters-description">
                      {props.title && props.title.length > 0 ? (
                        props.title.length <= 190 ? (
                          `${190 - props.title.length} caracteres restantes.`
                        ) : (
                          <span className="text-red">
                            Quantidade de caracteres acima do permitido.
                          </span>
                        )
                      ) : (
                        <span className="text-red">
                          O título não pode ser vazio.
                        </span>
                      )}
                    </p>
                  </div>
                  {
                    <div
                      className="col-md-1"
                      style={{
                        position: 'relative',
                        marginLeft: '85%',
                        marginTop: '-105px'
                      }}
                    >
                      <div
                        className="emoji-modal__container"
                        style={{ position: 'relative' }}
                      >
                        <EmojiPicker
                          blockEmojiPicker={false}
                          element="textarea#title"
                          propToChange="title"
                          parentProps={props}
                          cursorPosition={selectionStart}
                        />
                      </div>
                    </div>
                  }
                </div>
                {
                  <>
                    <div
                      className="row"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '92%'
                      }}
                    >
                      <div
                        className={'col-md-12'}
                        style={{ width: '100%', paddingLeft: '73px' }}
                      >
                        <AutoCompleteForm
                          isNewEditPost={true}
                          isEmpty={!hasTags}
                          label="Tags *"
                          tags={
                            roleAdmin[0] || roleManager[0]
                              ? props.listTagName
                              : tags
                          }
                          name="tags"
                          validations={[required]}
                          isLoading={props.isLoadingTags}
                          getTagAutoComplete={() => {}}
                          onChange={(tags) => {
                            updateTags(Object.values(tags));
                            props.updatePostRequisition(props.editpostform.id);
                          }}
                          infiniteScroll={
                            roleAdmin[0] || roleManager[0]
                              ? {
                                  searchByRequest: true,
                                  loadMore: (page, search) =>
                                    props.getTags(page, search)
                                }
                              : {
                                  searchByRequest: false,
                                  loadMore: () => {}
                                }
                          }
                        />

                        {!hasTags && (
                          <span
                            style={{
                              color: 'red',
                              fontSize: '12px',
                              position: 'relative',
                              bottom: '15px',
                              right: '7px'
                            }}
                          >
                            Campo obrigatório
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row" style={{ width: '78%' }}>
                      <div className="col-md-6">
                        {(roleAdmin[0] || roleManager[0]) && (
                          <>
                            <div
                              className="cancel-edit"
                              style={{ width: '100%' }}
                            >
                              {props.score && props.score !== '0' ? (
                                <img
                                  style={{ marginLeft: '68%', zIndex: '3' }}
                                  src={IconDelete}
                                  alt=""
                                  onClick={(e) => zeroScore(e.target)}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            <TextFieldForm
                              onChange={setScore}
                              name="score"
                              rows={1}
                              validate={[required, minValue0]}
                              floatingLabelText="Pontuação"
                              id="name"
                              label="Pontuação"
                              type="text"
                              style={{ width: '85%' }}
                              textareaStyle={{ width: '180px' }}
                              isFormattedNumber
                            />
                            <div>
                              <span className="note-schedule">
                                Pontos distribuídos a cada interação
                              </span>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="col-md-6">
                        <div
                          className="cancel-edit cancel-date"
                          style={{ width: '100%' }}
                        >
                          {props.not_score_after !== null &&
                            props.not_score_after !== undefined && (
                              <img
                                style={{
                                  bottom: '10px',
                                  marginLeft: '82%',
                                  zIndex: '3'
                                }}
                                src={IconDelete}
                                alt=""
                                onClick={(e) => zeroDate(e.target)}
                              />
                            )}
                        </div>
                        <label
                          style={{
                            borderBottom: '1px solid #dee1e8',
                            width: '100%',
                            marginTop: '-10px'
                          }}
                        >
                          <DatePickerForm
                            name="not_score_after"
                            label="Prazo para pontuação"
                            value={props.not_score_after}
                            isHeaderCanvasEdit={true}
                            style={{ width: '100%' }}
                          />
                        </label>
                        <div style={{ marginBottom: '20px' }}>
                          <span className="note-schedule">
                            Data limite para o usuário pontuar
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        width: '78%',
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <div
                        className="col-md-2"
                        style={{
                          display: 'flex'
                        }}
                      >
                        {(roleAdmin[0] || roleManager[0]) && (
                          <div className="special-post-container">
                            <div className="special-post__field-container">
                              <ContainerIconToggle
                                iconActive={props.special_post}
                              >
                                <IconSpecialPost />
                                <ToggleForm
                                  name="special_post"
                                  label="Post especial"
                                  toggled={props.special_post}
                                  onChange={() => setSpecialPost()}
                                />
                              </ContainerIconToggle>
                              {props.special_post &&
                                props.interaction_message !== undefined && (
                                  <small
                                    className="special-post__label"
                                    onClick={() =>
                                      props.openSpecialPostDialog(
                                        props.editpostform.id
                                      )
                                    }
                                    style={{
                                      borderColor: style.inpuStyle.colorInput,
                                      color: '#8A8A8A',
                                      opacity: '1',
                                      'margin-left': '-15px',
                                      'margin-top': '5px'
                                    }}
                                  >
                                    Editar
                                  </small>
                                )}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-md-2">
                        <div className="special-post__field-container">
                          <ContainerIconToggle iconActive={props.fixed_post}>
                            <IconFixedPost />
                            <ToggleForm
                              name="fixed_post"
                              label="Post fixo"
                              toggled={props.fixed}
                              onChange={() => setFixedPost()}
                            />
                          </ContainerIconToggle>
                        </div>
                      </div>
                      {!isRaffle && (
                        <>
                          <div className="col-md-2">
                            <div className="special-post__field-container">
                              <ContainerIconToggle
                                iconActive={props.likes_enabled}
                              >
                                <IconLikes />
                                <ToggleForm
                                  name="likes_enabled"
                                  label="Curtidas"
                                  toggled={props.likes_enabled}
                                  onChange={() => setLikesEnabled()}
                                />
                              </ContainerIconToggle>
                            </div>
                          </div>
                          {style.teamName.name !== 'grupopardini' && (
                            <>
                              <div className="col-md-3">
                                <div className="special-post__field-container">
                                  <ContainerIconToggle
                                    iconActive={props.comments_enabled}
                                  >
                                    <IconComments />
                                    <ToggleForm
                                      name="comments_enabled"
                                      label="Comentários"
                                      toggled={props.comments_enabled}
                                      onChange={() => setCommentsEnabled()}
                                    />
                                  </ContainerIconToggle>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="special-post__field-container">
                                  <ContainerIconToggle
                                    iconActive={props.min_comments_enabled}
                                  >
                                    <IconMinComments />
                                    <ToggleForm
                                      name="min_comments_enabled"
                                      label={
                                        props.min_comments_enabled
                                          ? `Caracteres mínimos (${props.min_size_comments})`
                                          : 'Caracteres mínimos'
                                      }
                                      toggled={props.min_comments_enabled}
                                      onChange={() => setMinCommentsEnabled()}
                                    />
                                    {props.min_comments_enabled && (
                                      <small
                                        className="special-post__label"
                                        onClick={() =>
                                          props.openDialogMinimumComments()
                                        }
                                        style={{
                                          borderColor:
                                            style.inpuStyle.colorInput,
                                          color: '#8A8A8A',
                                          opacity: '1'
                                        }}
                                      >
                                        Editar
                                      </small>
                                    )}
                                  </ContainerIconToggle>
                                </div>
                              </div>

                              {props.showDialogMinimumComments && (
                                <DialogMinimumCommentsForm
                                  postId={props.editpostform.id}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                }
              </div>

              <div
                className="row"
                style={{
                  marginTop: '40px',
                  width: '75%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <PostContents store={props.store} />
              </div>
            </div>

            <PreviewCreateCanvas
              tags={props.tags_selected}
              date={
                (props.editpostform && props.editpostform.published_at) || null
              }
              end_date={
                (props.editpostform && props.editpostform.end_date) || null
              }
              elements={
                (props.editpostform && props.editpostform.elements) || []
              }
              user={(props.editpostform && props.editpostform.user) || ''}
              title={props.title || ''}
              hasLikes={props.likes_enabled}
              hasComments={props.comments_enabled}
              isFixedPost={props.fixed_post}
            />
          </form>

          {props.editpostform.showScheduleModal && <ScheduleModal />}
          <SpecialPostModal />
          <DialogProgressCircular />
          <ProgressToast
            statusRequest={props.statusRequest}
            progress={props.patchingPost}
          />
        </MuiThemeProvider>
      </div>
    );
  } else {
    browserHistory.push('/post/publication');
    return null;
  }
}
export const EditPostMemoized = React.memo(EditPost);
let InitializeFromStateForm = reduxForm({
  form: 'createoreditpost',
  enableReinitialize: true
})(EditPostMemoized);

const mapDispatchToProps = (dispatch) => {
  return {
    setPublication: (kind, value) =>
      dispatch({ type: 'formPostEdit', kind: kind, value: value }),
    getTags: async (page, search) => {
      const res = await dispatch(TagApi.getTagsUserV2(page, search));
      return res;
    },
    setTag: (id, values) => dispatch(PostApi.canvasUpdate(id, values)),
    updateStatusRequest: (message) => {
      dispatch({
        type: 'UPDATE_STATUS_REQUEST',
        message: message
      });
    },
    showScheduleModalForTrue: () => {
      dispatch({ type: 'SCHEDULE_MODAL_SHOW' });
    },
    canvasPublish: (id, send_push) => {
      dispatch(PostApi.canvasPublish(id, send_push));
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    showError: () =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: 'É obrigatório adicionar uma imagem' }
      }),
    editName: (id, value) => {
      dispatch(PostApi.canvasUpdate(id, value));
    },
    saveOptionQuestion: async (idPublish, idContent) => {
      await dispatch(PostApi.saveOptionQuestion(idPublish, idContent));
    },
    sortContent: (id_post, contents_position) => {
      dispatch(PostApi.sortContent(id_post, contents_position));
    },
    setLimitDateGamification: (postId, value) =>
      dispatch(PostApi.setLimitDateGamification(postId, value)),
    setSpecialPost: (postId, value) =>
      dispatch(PostApi.setSpecialPost(postId, value)),
    setFixedPost: (postId, value) =>
      dispatch(PostApi.setFixedPost(postId, value)),
    setLikesEnabled: (postId, value) =>
      dispatch(PostApi.setLikesEnabled(postId, value)),
    setCommentsEnabled: (postId, value) =>
      dispatch(PostApi.setCommentsEnabled(postId, value)),
    setMinComments: (postId, value) =>
      dispatch(PostApi.setMinComments(postId, value)),
    setMinCommentsEnabled: () =>
      dispatch({
        type: 'SET_MIN_COMMENTS_ENABLED'
      }),
    openDialogMinimumComments: () => {
      dispatch({
        type: 'OPEN_MINIMUM_COMMENTS_MODAL'
      });
    },
    openSpecialPostDialog: (postId) => {
      dispatch({ type: 'OPEN_SPECIAL_POST_DIALOG', postId });
    },
    updatePostRequisition: (postId) => {
      dispatch(PostApi.editPost(postId));
    }
  };
};
const selector = formValueSelector('createoreditpost');
InitializeFromStateForm = connect(
  (state) => ({
    // cover_form: selector(state, 'cover_form'),
    title: selector(state, 'title'),
    end_date: selector(state, 'end_date'),
    score: selector(state, 'score'),
    not_score_after: selector(state, 'not_score_after'),
    special_post: selector(state, 'special_post'),
    fixed_post: selector(state, 'fixed_post'),
    likes_enabled: selector(state, 'likes_enabled'),
    comments_enabled: selector(state, 'comments_enabled'),
    min_size_comments: selector(state, 'min_size_comments'),
    min_comments_enabled: selector(state, 'min_comments_enabled'),
    showDialogMinimumComments: state.editpostform.modal_min_comments_is_visible,
    interaction_message: selector(state, 'interaction_message'),
    statusRequest: state.editpostform.statusRequest,
    tags: state.tagsparsed,
    tags_selected: selector(state, 'tags'),
    listTagName: state.tagsWithPagination.tags,
    paginationTags: state.tagsWithPagination.pagination,
    searchTags: state.tagsWithPagination.search,
    isLoadingTags: state.tagsWithPagination.isLoading,
    creatpost: state.createpost,
    initialValues: state.editpostform,
    editpostform: state.editpostform,
    isDrawType: state.draw.isDrawType,
    send_push: state.editpostform.send_push,
    patchingPost: state.editpostform.patchingPost,
    progressIndeterminate: state.progress_indeterminate,
    load_status: state.loadstatus
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
