import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import * as CourseApiAsync from '../Logicas/CourseApiAsync';
import errorHandler from '../componentes/utils/errorHandler';

export const pathModule = (values, idmodule, idcourse) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

    const moduleData = buildFormDataPatch(values);

    await API.patch('module_courses/' + idmodule, moduleData, Config.headers());

    await dispatch(CourseApiAsync.editCourseV2(idcourse));

    dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
  } catch (error) {
    dispatch({
      type: 'OPEN_ERROR',
      alert: {
        visible: true,
        message: errorHandler(error)
      }
    });
    dispatch(CourseApiAsync.editCourseV2(idcourse));
  }

  dispatch({ type: 'PATCH_COURSE', patchCourse: 0 });
};

const buildFormDataPatch = (values) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('score', values.score);
  formData.append('description', values.description);
  return formData;
};
