import React, { Component } from 'react';
import IcoDuration from '../utils/img/ic-dura-o.svg';
import 'video-react/dist/video-react.css';
import IcoMult from '../utils/img/flaticon1500052516-svg.png';
import Icotext from '../utils/img/flaticon1500052772-svg.png';

require('./previewmodule.css');

class PreviewModule extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="preview-module" data-tip data-for="title">
        <div className="row">
          <div
            data-tip
            data-for="title"
            className="col-md-12 preview-product-img"
          >
            <img
              data-tip
              data-for="title"
              className="preview-module-img"
              src={this.props.img}
              alt="imagem de prévia"
            />
          </div>
        </div>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div className="preview-product-division"></div>
            </div>
          </div>
          <div className="row">
            <div className="preview-module-title">
              <div className="col-md-12">{this.props.title}</div>
            </div>
          </div>
          <div className="row">
            <div className="preview-product-text ">
              <div className="col-md-12">
                <textarea
                  className="text-area-preview-product"
                  value={this.props.text}
                >
                  {' '}
                </textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 preview-module-duration-course">
              <img
                style={{ marginLeft: '2px', marginTop: '7px' }}
                src={IcoDuration}
                alt="ícone duração"
              />{' '}
              {this.props.duration}
              <img style={{ marginLeft: '2px' }} src={IcoMult} alt="ícone multimídia"/>
              <img style={{ marginLeft: '2px' }} src={Icotext} alt="ícone texto"/>
            </div>
            <div className="col-md-2 ">
              <div className="tag-text-course"> {this.props.tag}</div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 ">
              <div className="preview-module-title-module">
                {this.props.module_title}
              </div>
            </div>
          </div>
          <div style={{ marginTop: '-20px' }} className="row">
            <div className="col-md-12 ">
              <div className="preview-module-descriptio-module">
                {this.props.module_description}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="divisoria"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewModule;
