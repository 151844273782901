import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change, formValueSelector } from 'redux-form';
import Modal from 'react-awesome-modal';
import { Button } from '../../../user/components/UsersHeader/UsersHeader.styles';
import { TextFieldForm } from '../../../utils_form/FormComponents';
import { required, email } from '../../../validation/FormValidation';
import IcoExit from '../../../utils/img/exit-dialog.png';
import HistoricalApi from '../../../../Logicas/HistoricalApi';
import StoreApi from '../../../../Logicas/StoreApi';
import CourseApi from '../../../../Logicas/CourseApi';

import { DatePickerForm } from '../../../utils_form/FormComponents';

import IconCancel from '../../../utils/img/ic-cancel.png';

import * as S from './DialogReportEmail.styles';
import { style } from '../../../../style';
import { getMuiTheme, MuiThemeProvider } from 'material-ui/styles';

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

export class DialogReportEmail extends Component {
  constructor() {
    super();
    this.state = {
      minDate: new Date('2018-01-01T00:00:00'),
      startDateUsageReport: null,
      endDateUsageReport: null
    };
    this.sendReport = this.sendReport.bind(this);
  }

  sendReport(values) {
    const {
      kind,
      startDate,
      endDate,
      sendPublicationsReport,
      sendSolicitationsReport,
      sendCourseProgressReport,
      sendUsersReport,
      sendAccessReport,
      sendUsageReport,
      course_id
    } = this.props;
    const { userEmail } = values;
    const { startDateUsageReport, endDateUsageReport } = this.state;

    const publicationKinds = ['publication', 'form', 'draft'];
    const storeKinds = ['despatched', 'not_despatched'];
    const courseKinds = ['courseHistory'];
    const userKinds = ['user'];
    const accessKinds = ['accessReport'];
    const usageKinds = ['usageReport'];

    if (publicationKinds.includes(kind)) {
      sendPublicationsReport(kind, userEmail, startDate, endDate);
    } else if (storeKinds.includes(kind)) {
      sendSolicitationsReport(kind, userEmail, startDate, endDate);
    } else if (courseKinds.includes(kind)) {
      sendCourseProgressReport(course_id, userEmail);
    } else if (userKinds.includes(kind)) {
      sendUsersReport(kind, userEmail, startDate, endDate);
    } else if (accessKinds.includes(kind)) {
      sendAccessReport(kind, userEmail);
    } else if (usageKinds.includes(kind)) {
      sendUsageReport(
        kind,
        userEmail,
        startDateUsageReport,
        endDateUsageReport
      );
    }
  }

  zeroDate = (target) => {
    if (target.nodeName === 'IMG') {
      this.setState({ startDateUsageReport: null });
      this.props.dispatch(
        change('dialogReportEmailForm', 'startDateUsageReport', null)
      );
    }
  };

  zeroDateEnd = (target) => {
    if (target.nodeName === 'IMG') {
      this.setState({ endDateUsageReport: null });
      this.props.dispatch(
        change('dialogReportEmailForm', 'endDateUsageReport', null)
      );
    }
  };

  openStartDateCalendar() {
    var element = document.querySelector('#startDateUsageReport');
    if (element !== null) element.click();
  }
  openEndDateCalendar() {
    var element = document.querySelector('#endDateUsageReport');
    if (element !== null) element.click();
  }

  render() {
    const {
      showEmailDialog,
      closeEmailDialog,
      handleSubmit,
      requesting_report,
      report_success,
      success_message,
      kind
    } = this.props;
    const { minDate, startDateUsageReport, endDateUsageReport } = this.state;
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div
          className="react-awesome-modal"
          style={{
            visibility: showEmailDialog ? 'visible' : 'hidden'
          }}
        >
          <Modal
            effect="fadeInUp"
            width="500px"
            height="410px"
            visible={showEmailDialog}
          >
            <S.CloseButton src={IcoExit} onClick={() => closeEmailDialog()} />
            <S.ModalContentBody>
              {!report_success ? (
                <>
                  <S.ModalTitle>
                    {kind === 'usageReport'
                      ? 'Informe as datas do período e um e-mail para envio do relatório.'
                      : 'Informe um e-mail para envio do relatório'}
                  </S.ModalTitle>
                  <S.ModalForm>
                    {kind === 'usageReport' && (
                      <S.LineDates>
                        <S.DatePickerFormDiv>
                          <div
                            className="cancel-edit cancel-date"
                            style={{ width: '50%' }}
                          >
                            {startDateUsageReport !== null &&
                              startDateUsageReport !== undefined && (
                                <img
                                  src={IconCancel}
                                  alt=""
                                  onClick={(e) => this.zeroDate(e.target)}
                                  style={{
                                    bottom: '3.5rem',
                                    marginLeft: '45%',
                                    zIndex: '3'
                                  }}
                                />
                              )}
                          </div>
                          <DatePickerForm
                            id="startDateUsageReport"
                            name="startDateUsageReport"
                            label="Data inicial"
                            minDate={minDate}
                            maxDate={endDateUsageReport}
                            selected={startDateUsageReport}
                            onChange={(preventDefault, date) =>
                              this.setState({ startDateUsageReport: date })
                            }
                            validations={[required]}
                          />
                        </S.DatePickerFormDiv>
                        <S.DatePickerFormDiv>
                          <div
                            className="cancel-edit cancel-date"
                            style={{ width: '50%' }}
                          >
                            {endDateUsageReport !== null &&
                              startDateUsageReport !== undefined && (
                                <img
                                  src={IconCancel}
                                  alt=""
                                  onClick={(e) => this.zeroDateEnd(e.target)}
                                  style={{
                                    bottom: '3.5rem',
                                    marginLeft: '45%',
                                    zIndex: '3'
                                  }}
                                />
                              )}
                          </div>
                          <DatePickerForm
                            id="endDateUsageReport"
                            name="endDateUsageReport"
                            label="Data final"
                            minDate={startDateUsageReport || minDate}
                            maxDate={new Date()}
                            selected={endDateUsageReport}
                            onChange={(preventDefault, date) =>
                              this.setState({ endDateUsageReport: date })
                            }
                            validations={[required]}
                          />
                        </S.DatePickerFormDiv>
                      </S.LineDates>
                    )}

                    <TextFieldForm
                      name="userEmail"
                      floatingLabelText="E-mail"
                      validations={[required, email]}
                    />
                  </S.ModalForm>
                  <S.ModalText>
                    <S.WarningText>Atenção:&nbsp;</S.WarningText>O relatório
                    será processado e enviado para o<br />
                    e-mail inserido. Antes de enviar, por favor, certifique-se
                    <br />
                    de que o endereço de e-mail informado está correto.
                  </S.ModalText>
                  {!requesting_report ? (
                    <Button
                      onClick={handleSubmit(this.sendReport)}
                      disabled={!this.props.valid}
                    >
                      Enviar
                    </Button>
                  ) : (
                    <Button>Solicitando...</Button>
                  )}
                </>
              ) : (
                <>
                  <S.ModalTitle>Relatório solicitado com sucesso!</S.ModalTitle>
                  <S.ModalTextSuccess>{success_message}</S.ModalTextSuccess>
                  <Button onClick={closeEmailDialog}>OK</Button>
                </>
              )}
            </S.ModalContentBody>
          </Modal>
        </div>
      </MuiThemeProvider>
    );
  }
}

DialogReportEmail = reduxForm({
  form: 'dialogReportEmailForm',
  enableReinitialize: true
})(DialogReportEmail);

const selector = formValueSelector('dialogReportEmailForm');

const mapStateToProps = (state) => ({
  showEmailDialog: state.dialog_report_email.visible,
  kind: state.dialog_report_email.kind,
  startDate: state.dialog_report_email.startDate,
  endDate: state.dialog_report_email.endDate,
  requesting_report: state.dialog_report_email.requesting_report,
  report_success: state.dialog_report_email.report_success,
  success_message: state.dialog_report_email.success_message,
  startDateUsageReport: selector(state, 'startDateUsageReport'),
  endDateUsageReport: selector(state, 'endDateUsageReport')
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeEmailDialog: () =>
      dispatch({
        type: 'CLOSE_REPORT_EMAIL_MODAL'
      }),
    sendPublicationsReport: (kind, email, startDate, endDate) => {
      dispatch(
        HistoricalApi.sendPublicationsReport(kind, email, startDate, endDate)
      );
    },
    sendSolicitationsReport: (kind, email, startDate, endDate) => {
      dispatch(
        StoreApi.sendSolicitationsReport(kind, email, startDate, endDate)
      );
    },
    sendCourseProgressReport: (id, email) => {
      dispatch(CourseApi.sendCourseProgressReport(id, email));
    },
    sendUsersReport: (kind, email, startDate, endDate) => {
      dispatch(HistoricalApi.sendUsersReport(kind, email, startDate, endDate));
    },
    sendAccessReport: (kind, email) => {
      dispatch(HistoricalApi.sendAccessReport(kind, email));
    },
    sendUsageReport: (kind, email, startDate, endDate) => {
      dispatch(HistoricalApi.sendUsageReport(kind, email, startDate, endDate));
    }
  };
};

const DialogReportEmailForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogReportEmail);

export default DialogReportEmailForm;
