const INITIAL_STATE = {
  openDialog: false,
  userName:"",
  userAddress:"",
  userPhoneNumber:""
}

export function dialog_user_address(state = INITIAL_STATE, action) {
    if (action.type === 'OPEN_DIALOG_USER_ADDRESS') {
      return {
        ...state,
        openDialog:true
      }
    }
  
    if (action.type === 'CLOSE_DIALOG_USER_ADDRESS') {
      return {
        ...state,
        openDialog:false
      }
    }

    if (action.type === 'UPDATE_DIALOG_USER_AND_ADDRESS') {
      return {
        ...state,
        userName:action.payload.userName,
        userAddress:action.payload.userAddress,
        userPhoneNumber:action.payload.userPhoneNumber
      }
    }
  
    return state;
}