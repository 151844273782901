import React, { Component } from 'react';
import { ShortAnswerField } from '../../../../utils/FormsComponents';
import IconDelete from '../../../../utils/img/ic-delete.png';
import { debounce } from 'lodash';

require('../createForm.css');

export class OpenQuestionModule extends Component {
  constructor() {
    super();
    this.state = {
      title: '',
      idContent: ''
    };
    this.editTitle = this.editTitle.bind(this);
  }

  componentDidMount() {
    this.setState({ title: this.props.element.text });
  }

  contaQuestions = debounce((idContent, value, idPatch, typeQuestion) => {
    this.props.saveContentEditQuestions(
      idContent,
      value,
      idPatch,
      typeQuestion
    );
  }, 1000);

  editTitle(title, value) {
    this.setState({ title: value });

    const idPatch = this.props.element.id;
    const typeQuestion = title.props.element.kind;
    const idContent = this.props.editPostFormId;

    this.contaQuestions(idContent, value, idPatch, typeQuestion);
  }


  render() {
    return (
      <div className="tooltip-remove-text-form" style={{ minWidth: '100%' }}>
        <div className="body-question short" style={{ flexDirection: 'column', minWidth: '520px' }}>
          <div className="col-lg-12">
            <div className="cancel" style={{ width: '100%' }}>
              <img
                style={{ marginLeft: '90%', zIndex: '3' }}
                src={IconDelete}
                alt=""
                onClick={() =>
                  this.props.removeQuestion(
                    this.props.editPostFormId,
                    this.props.element.id,
                    'open_question'
                  )
                }
              />
            </div>
            <ShortAnswerField
              name={`questionTitle_${this.props.element.id}`}
              rows={1}
              onChange={(e) => this.editTitle(this, e.target.value)}
              id={`questionTitle_${this.props.element.id}`}
              label="Pergunta"
              type="text"
              value={this.state.title}
              hasDeleteIcon={true}
            />
            {/* <div
              className="btn-body"
              onClick={() =>
                this.props.removeQuestion(
                  this.props.editPostFormId,
                  this.props.element.id,
                  'open_question'
                )
              }
              style={{
                color: this.props.style.inpuStyle.colorInput
              }}
            >
              <p className="btn-form-questions">REMOVER</p>
            </div> */}
          </div>
          <p className="view-characters" style={{ 'zIndex': 2, marginLeft: '15px', marginRight: 'auto', marginBottom: '1rem' }}>
            {this.state.title &&
              (this.state.title.length <= 255 ?
                `${this.state.title.length}/255 caracteres`
                : (
                  <span className='text-red'>
                    Quantidade de caracteres acima do permitido.
                  </span>
                )
              )
            }
          </p>
        </div>
      </div>
    );
  }
}

export default OpenQuestionModule;
