import React, { Component } from 'react';
import { getMuiTheme, MuiThemeProvider } from 'material-ui/styles/';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { style } from '../../../style';
import DashboardButton from '../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import { DatePickerForm } from '../../utils_form/FormComponents';
import DialogReportEmail from '../../publication/dialog/DialogReportEmail/DialogReportEmail';

import * as S from './DownloadPostData.styles';

const DatePickerFormDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    border-bottom: 2.1px ${style.inpuStyle.colorInput} solid ;
  }
`

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  timePicker: {
    pickerHeaderColor: style.inpuStyle.colorInput,
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput,
    pickerSelectColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

class DownloadPostData extends Component {
  constructor() {
    super();
    this.state = {
      minDate: new Date('2018-01-01T00:00:00'),
      startDate: null,
      endDate: null
    };
  }
  openStartDateCalendar(){
    var element = document.querySelector('#start_date');
    if(element !== null)
      element.click();
  }
  openEndDateCalendar(){
    var element = document.querySelector('#end_date');
    if(element !== null)
      element.click();
  }
  render() {
    const { openReportEmailDialog, isLoading, kind, containerStyle } = this.props;
    const { minDate, startDate, endDate } = this.state;
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <S.Container className="container" style={containerStyle}>
          <DatePickerFormDiv>
            <DatePickerForm
              id="start_date"
              name="start_date"
              label="Data inicial"
              minDate={minDate}
              maxDate={endDate}
              selected={startDate}
              onChange={(preventDefault, date) =>
                this.setState({ startDate: date })
              }
            />
          </DatePickerFormDiv>
          <DatePickerFormDiv>
            <DatePickerForm
              id="end_date"
              name="end_date"
              label="Data Final"
              selected={endDate}
              minDate={startDate}
              onChange={(preventDefault, date) =>
                this.setState({ endDate: date })
              }
            />
          </DatePickerFormDiv>
          <DashboardButton
            isActive={isLoading}
            label={
              isLoading && startDate !== null && endDate !== null
                ? 'Obtendo...'
                : 'Obter histórico'
            }
            //bothDatesRequired={startDate !== null && endDate !== null}
            onClick={
              startDate === null ? this.openStartDateCalendar
                : endDate === null ? this.openEndDateCalendar 
                : () => openReportEmailDialog(kind, startDate, endDate)
            }
          />
        </S.Container>

        <DialogReportEmail />
      </MuiThemeProvider>
    );
  }
}

DownloadPostData.defaultProps = {
  kind: 'publication'
};

let DownloadPostDataContainer = reduxForm({
  form: 'DownloadPostData'
})(DownloadPostData);

const mapStateToProps = (state) => {
  return {
    isLoading: state.historical_points.loadingSpreadsheet
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openReportEmailDialog: (kind, startDate, endDate) => {
      dispatch({
        type: 'OPEN_REPORT_EMAIL_MODAL',
        payload: { kind, startDate, endDate }
      });
    }
  };
};

DownloadPostDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadPostDataContainer);

export default DownloadPostDataContainer;
