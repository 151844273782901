export function load_progress_dialog(state = {}, action) {
  if (action.type === 'START_LOAD') {
    return {
      visible: true,
      percent: action.percent
    };
  }
  if (action.type === 'SET_PROGRESS') {
    return {
      visible: true,
      percent: action.percent
    };
  }
  if (action.type === 'END_LOAD') {
    return {
      visible: false,
      percent: action.percent
    };
  }
  return state;
}
