import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import CardCouse from './CardCourse';
import Slide from 'react-reveal/Slide';

require('./courselistcard.css');

export default class CourseListCards extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const teamNames = this.props.cards[0].teamName.split('/');

    return (
      <React.Fragment>
        <div 
          className="row-card-corses-list" 
          id="scrollableDiv"
          style={{
            overflowX: "hidden",
            overflowY: "auto",
            maxHeight: "69vh",
            boxShadow: "0px 15px 10px -15px rgba(0, 0, 0, 0.3)"
          }}
        >
          <InfiniteScroll
            pageStart={1}
            initialLoad={true}
            loadMore={this.props.loadMore}
            hasMore={this.props.hasMore}
            useWindow={false}
            threshold={50}
            loader={<></>}
            scrollableTarget="scrollableDiv"
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            {Array.isArray(this.props.cards) ? (
              this.props.cards.map(
                function(course, index) {
                  return (
                    <CardCouse
                      index={course.id}
                      typeCourse={course.kind}
                      indexmodule={course.title + index}
                      deleteCourse={this.props.deleteCourse}
                      modules={course.module_courses}
                      courseduration={course.duration}
                      courseimage={course.image.url}
                      description={course.description}
                      coursename={course.title}
                      coursescore={course.score}
                      teamName={course.score > 1 ? teamNames[1] : teamNames[0]}
                      tags={course.tags}
                      avaiable={course.available}
                      changeAvaiableCourse={this.props.changeAvaiableCourse}
                      editCourse={this.props.editCourse}
                    />
                  );
                }.bind(this)
              )
            ) : (
              <React.Fragment />
            )}
          </InfiniteScroll>
        </div>
      </React.Fragment>
    );
  }
}
