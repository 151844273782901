import styled from "styled-components";

import { style } from '../../../style';

export const PeriodManagementContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`

export const ButtonChangePage = styled.button`
    width: 24px;
    height: 24px;
    border-radius: 24px;

    background: #F4F6FB;
    border: none;

    display: flex;
    align-items: center;

    cursor: ${(props) => props.isActive ? 'pointer' : 'default !important'};

    svg{
        margin: 0 auto;
        path{
            stroke: ${(props) => props.isActive ? style.inpuStyle.colorInput : ''}
        }
    }

    &.next-page{
        svg{
            transform: rotate(180deg);
            margin: 0 auto;
        }
    }

    
`

export const DateView = styled.div`
    max-width: 24rem;
    padding: 0;
    margin: 1rem 0 1rem 0rem;
    font-size: 14px;

    span {
        font-size: 20px;
        color: #4B4E5C;
    }
`