import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
  TextFieldDefault,
  ButtonDialogTag,
  ShortAnswerField
} from '../utils/FormsComponents';
import Modal from 'react-awesome-modal';
import CircularProgressbar from 'react-circular-progressbar';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required } from '../validation/FormValidation';
import DialogApi from '../../Logicas/DialogApi';
import TagApi from '../../Logicas/TagApi';
import LinearProgress from 'material-ui/LinearProgress';
import DashboardButton from '../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import { style } from '../../style';
import { ButtonConfirm, BodyDialog } from './dialog_styled';
import IcoAddress from '../utils/img/ic-address.svg';
import IcoUser from '../utils/img/ic-user.svg';
import IcoCopy from '../utils/img/ic-copy.svg';
import IcoPhone from '../utils/img/ic-phone.svg';

require('./dialog.css');

export class DialogYesNo extends Component {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        width="500"
        height="250"
        effect="fadeInUp"
      >
        <BodyDialog>
          <div className="label-dialog">{this.props.message}</div>
          <div>
            <button onClick={this.props.noclick} className="btn-cancel">
              Não
            </button>
            <ButtonConfirm onClick={this.props.yesclick}>Sim</ButtonConfirm>
          </div>
        </BodyDialog>
      </Modal>
    );
  }
}

export class DialogProgress extends Component {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }

  render() {
    return (
      <div>
        {this.props.percentage > 0 ? (
          <LinearProgress
            color={style.inpuStyle.colorInput}
            mode="determinate"
            value={this.props.percentage}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }
}

export class DialogProgressIndeterminated extends Component {
  constructor() {
    super();
    this.state = {
      errorText: ''
    };
  }

  render() {
    return (
      <div>
        <LinearProgress
          style={{ backgroundColor: '#ffffff', height: '1px' }}
          color={style.inpuStyle.colorInput}
          mode="indeterminate"
        />
      </div>
    );
  }
}

export class DialogAlert extends Component {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }

  render() {
    let stringComplete = '';
    this.props.fileList &&
      this.props.fileList.map((file) => {
        stringComplete += file.filename + '\n';
      });
    return (
      <Modal visible={this.props.visible} width="500" height="300">
        <BodyDialog>
          <div className="label-dialog">{this.props.message}</div>
          {this.props.submessage && (
            <span className="submessage-dialog">{this.props.submessage}</span>
          )}
          {this.props.message === 'O servidor não está respondendo.' && (
            <span className="submessage-dialog">
              Por favor, tente recarregar a página.
            </span>
          )}
          {this.props.fileList &&
            this.props.fileList.slice(0, 3).map((file) => (
              <>
                <span className="submessage-dialog">{file.filename}</span>
              </>
            ))}
          {this.props.fileList && this.props.fileList.length > 3 ? (
            <span className="submessage-dialog" title={stringComplete}>
              ...
            </span>
          ) : (
            <></>
          )}
          {this.props.fileList && (
            <span style={{ marginTop: '10px' }} className="submessage-dialog">
              Acesse "?" para saber quais os formatos permitidos.
            </span>
          )}
          {this.props.message === 'O servidor não está respondendo.' ? (
            <ButtonConfirm onClick={() => window.location.reload()}>Recarregar</ButtonConfirm>
          ) : (
            <ButtonConfirm
              withoutMarginTop={
                this.props.submessage || this.props.fileList ? true : false
              }
              onClick={this.props.closeDialog}
            >
              Ok
            </ButtonConfirm>
          )}
        </BodyDialog>
      </Modal>
    );
  }
}

class DialogTagForm extends Component {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }

  saveTag(values) {
    const { reset } = this.props;
    this.props.saveTag(values);
    reset();

    this.props.closeDialog();
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <Modal
        visible={this.props.visible}
        width="343"
        height="400"
        effect="fadeInUp"
      >
        <form onSubmit={handleSubmit(this.saveTag.bind(this))}>
          <MuiThemeProvider>
            <div className="dialogyesno-body">
              <div className="label-dialog-tag">Nova Tag</div>
            </div>
            <div />
            <div className="row">
              <div className="offset-md-1 col-md-2">
                <Field
                  name="name_tag"
                  validate={[required]}
                  id="name"
                  label="Nome da Tag"
                  component={TextFieldDefault}
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              <div className="offset-md-1 col-md-2">
                <Field
                  name="description_tag"
                  validate={[required]}
                  id="name"
                  label="Descrição"
                  component={TextFieldDefault}
                  type="text"
                />
              </div>
            </div>

            <div className="text-descri-tag">
              <p>
                Atribua usuários e filtros na tela de <br />
                <span className="edit-tag">Edição da tag</span>
              </p>
            </div>

            <div style={{ marginTop: '30px' }} className="row">
              <div className="offset-md-1 col-md-5">
                <DashboardButton
                  label="Cancelar"
                  onClick={this.props.closeDialog}
                  style={{
                    margin: 0,
                    width: '120px',
                    height: '35px'
                  }}
                />
              </div>
              <div className="col-md-5 ">
                <ButtonDialogTag type="submit" label="Salvar" />
              </div>
            </div>
          </MuiThemeProvider>
        </form>
      </Modal>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'createtag'
})(DialogTagForm);

const mapDispatchToProps2 = (dispatch) => {
  return {
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    saveTag: (values) => {
      dispatch(TagApi.createTag(values));
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({
    alert: state.dialog
  }),
  mapDispatchToProps2
)(InitializeFromStateForm);

export default InitializeFromStateForm;

export class DialogProgressLoad extends Component {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        width="300"
        height="220"
        effect="fadeInUp"
      >
        <CircularProgressbar
          strokeWidth={4}
          className="CircularProgressbar"
          percentage={this.props.percentage}
        />
      </Modal>
    );
  }
}

export class DialogProgressLoadIndetermineted extends Component {
  constructor() {
    super();

    this.state = {
      errorText: ''
    };
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        width="300"
        height="220"
        effect="fadeInUp"
      >
        <CircularProgress
          className="CircularProgressbardetermineted"
          thickness={15}
          size={200}
        />
      </Modal>
    );
  }
}

export class DialogUserAddress extends Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        width="488"
        height="300"
        effect="fadeInUp"
      >
        <BodyDialog className="body-dialog">
          <div className="label-dialog">Endereço de entrega</div>
          <div className="main-dialog">
            <div className="user-name">
              <img src={IcoUser} alt="user" />
              <span className="span-user-name">{this.props.user_name}</span>
            </div>
            <div className="user-address">
              <img src={IcoAddress} alt="address" />
              <span className="span-user-address">
                {this.props.user_address}
              </span>
            </div>
            <div className="user-phoneNumber">
              <img src={IcoPhone} alt="phone number" />
              <span className="span-user-phoneNumber">
                {this.props.user_phoneNumber}
              </span>
            </div>
          </div>
          <div className="buttons-dialog">
            <ButtonConfirm
              className="button-copy-address"
              onClick={() => {
                navigator.clipboard.writeText(this.props.user_address);
                this.props.yesclick();
              }}
            >
              <img src={IcoCopy} alt="copy" />
              <span>Copiar endereço</span>
            </ButtonConfirm>
            <button onClick={this.props.noclick} className="btn-cancel">
              voltar
            </button>
          </div>
        </BodyDialog>
      </Modal>
    );
  }
}

export class DialogEditRecurrency extends Component {
  constructor() {
    super();

    this.state = {
      only_this_event: true
    };

    this.handleOnlyThisOrThisAndOthers = this.handleOnlyThisOrThisAndOthers.bind(this);
  }

  handleOnlyThisOrThisAndOthers = (e) => {
    this.setState({only_this_event: e.target.value === 'false' ? false : true});
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        width="350"
        height="300"
        effect="fadeInUp"
      >
        <BodyDialog className="body-dialog">
          <div 
            className="label-dialog" 
            style={{display: "flex", alignItems: "center", width: "70%", fontSize:"26px"}}
          >
            Editar Recorrência
          </div>
          <div>
            <div
              style={{fontSize:"15px", fontFamily:"Rubik"}}
              onChange={this.handleOnlyThisOrThisAndOthers}
            >
              <div>
                <Field
                  name="only_this_event"
                  component="input"
                  type="radio"
                  value={true}
                  id="only_this"
                  checked={this.state.only_this_event}
                />
                <label for="only_this" className="form-label">
                  Este evento apenas
                </label>
              </div>
              <div>
                <Field
                  name="only_this_event"
                  component="input"
                  type="radio"
                  value={false}
                  id="this_and_others"
                  checked={!this.state.only_this_event}
                />
                <label
                  for="this_and_others"
                  className="form-label"
                >
                  Este e os próximos eventos
                </label>
              </div>
            </div>
          </div>
          <div className="buttons-dialog">
            <button 
              onClick={this.props.noclick} 
              className="btn-cancel" 
              style={{borderColor: style.inpuStyle.colorInput, color: style.inpuStyle.colorInput, opacity: 1}}
            >
              Voltar
            </button>
            <ButtonConfirm
              style={{marginTop: 0}}
              onClick={() => this.props.yesclick({...this.props.options, only_this_event: this.state.only_this_event})}
            >
              Confirmar
            </ButtonConfirm>
          </div>
        </BodyDialog>
      </Modal>
    );
  }
}


