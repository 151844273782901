import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import IcoExit from '../../utils/img/exit-dialog.png';
import { MuiThemeProvider } from 'material-ui/styles';
import { Avatar } from 'material-ui';
import { TextFieldWithoutForm } from '../../utils/FormsComponents';
import PostApi from '../../../Logicas/PostApi';
import { HeaderDialog } from './style_dialogs';
import PubSub from 'pubsub-js';

require('./dialoglikes.css');

class DialogLikes extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      request: true,
      showLabel: true,
      id_comment: 0
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.getByName = this.getByName.bind(this);
  }

  componentWillMount() {
    window.onscroll = () => this.handleScroll();
    PubSub.subscribe(
      'get_next_page_comments',
      function(render) {
        this.setState({ request: true });
      }.bind(this)
    );
  }

  handleScroll() {
    if (document.getElementById('#body-scroll') !== null) {
      let scrollposition = document.getElementById('#body-scroll').scrollTop;
      var max = document.getElementById('#body-scroll').scrollHeight;
      if (
        scrollposition > max / 3 &&
        this.props.like_list.length % 10 === 0 &&
        this.state.request
      ) {
        this.setState({ page: (this.state.page += 1), request: false }, () => {
          this.props.getLikes(this.props.id_post, this.state.page);
        });

        document
          .getElementById('#body-scroll')
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }

  getByName(value) {
    if (value.target.value.length >= 3) {
      this.setState({ page: 1 });
      this.props.getLikesByNameUser(this.props.id_post, value.target.value);
    }
    if (value.target.value.length === 0) {
      this.setState({ page: 1 });
      this.props.getLikes(this.props.id_post, 1, true);
    }
    this.setState({ searchvalue: value.target.value });
  }

  render() {
    let visible = this.props.visible;
    return (
      <Modal visible={visible} width="35%" height="70%" effect="fadeInUp">
        <MuiThemeProvider>
          <HeaderDialog />
          <div className="header-row-likes">
            <div className="label-likes">
              {this.props.like_list.length === 1
                ? this.props.like_list.length + ' Curtida'
                : this.props.like_list.length + ' Curtidas'}{' '}
            </div>
          </div>
          <div onClick={this.props.closeDialog} className="header-row-exit">
            <img
              style={{
                width: '18px',
                height: '18px',
                marginRight: '20px',
                marginTop: '-15%'
              }}
              src={IcoExit}
              alt="fechar"
            />
          </div>
          <div
            id="#body-scroll"
            onScroll={this.handleScroll}
            className="body-likes"
          >
            <div className="field-search">
              <TextFieldWithoutForm
                value={this.state.searchvalue}
                onChange={this.getByName}
                floatingLabelText="Busque por usuário"
              />
            </div>
            {Array.isArray(this.props.like_list)
              ? this.props.like_list.map(
                  function(user) {
                    return (
                      <div className="col-likes">
                        <div className="row-likes">
                          <Avatar src={user.image.url} />
                          <div className="col-row-likes">
                            <div className="user-name-likes">{user.name}</div>
                          </div>
                        </div>
                      </div>
                    );
                  }.bind(this)
                )
              : null}
          </div>
        </MuiThemeProvider>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () =>
      dispatch({ type: 'GET_LIKES', data: [], visible: false }),
    getLikes: (id, page, reset) => {
      dispatch(PostApi.getLikes(id, page, reset));
    },
    getLikesByNameUser: (id, name) => {
      dispatch(PostApi.getLikesByNameUser(id, name));
    }
  };
};

let DialogLikesContainer = connect(
  (state) => ({
    like_list: state.post_likes.data,
    id_post: state.post_likes.id_post
  }),
  mapDispatchToProps
)(DialogLikes);

export default DialogLikesContainer;
