const initialState = {
  visible: false,
  apiMessage: '',
  isLoading: false
};

export function dialog_spreadsheet_tag_assignment(
  state = initialState,
  action
) {
  switch (action.type) {
    case 'OPEN_DIALOG_SPREADSHEET_TAG_ASSIGNMENT_USERS':
      return {
        ...state,
        visible: true
      };
    case 'CLOSE_DIALOG_SPREADSHEET_TAG_ASSIGNMENT_USERS':
      return {
        ...state,
        visible: false,
        apiMessage: ''
      };
    case 'TAG_ASSIGNMENT_USERS_SUCCESS':
      return {
        ...state,
        apiMessage: action.apiMessage
      };
    case 'TAG_ASSIGNMENT_USERS_IS_LOADING':
      return {
        ...state,
        isLoading: true
      };
    case 'TAG_ASSIGNMENT_USERS_NOT_IS_LOADING':
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
