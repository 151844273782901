import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectorApi from '../../Logicas/SectorApi';
import DialogApi from '../../Logicas/DialogApi';
import { MuiThemeProvider } from 'material-ui/styles';
import { FooterPagination } from '../utils/Pagination';

import SaveSector from './DialogSaveSector';
import { BodyTable } from '../utils/BodyTable';
import DeleteSector from './DialogDeleteSector';
import ComponentHeader from '../utils/ComponenteHeader';
import {
  Table,
  TableRow,
  TableData,
  TableBody,
  TableHead,
  TableHeadRow
} from '../utils/Table';

import SectorHeader from './Components/SectorHeader/SectorHeader';

import IcoEdit from '../utils/img/ic-editar.svg';
import RemoveIcon from '../utils/img/ic-excluir.svg';
import '../tag/tag.css';

class SectorList extends Component {
  constructor() {
    super();
    this.state = {
      init: 0,
      idSector: 0,
      activePage: 1,
      patchSector: false,
      associated_users: 0
    };
  }

  componentWillMount() {
    this.props.getSectorList(1);
  }

  setPatchSector = () => {
    this.setState({ patchSector: false });
    this.props.openDialog();
  };

  editSector = (sectorId) => {
    this.props.openDialog();
    this.setState({
      patchSector: true,
      idSector: sectorId
    });
  };

  deleteSector = (sectorId, users) => {
    this.props.openDialogDelete();
    this.setState({
      idSector: sectorId,
      associated_users: users
    });
  };

  render() {
    const { sectorsV2, sectorsPagination } = this.props;
    return (
      <MuiThemeProvider>
        <SectorHeader
          setPatchSector={this.setPatchSector}
          editSector={this.editSector}
          deleteSector={this.deleteSector}
        />
        <BodyTable>
          <Table>
            <TableHead>
              <TableHeadRow>
                <label>Id</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Nome exibido</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Usuários Associados</label>
              </TableHeadRow>
              <TableHeadRow>
                <label>Ação</label>
              </TableHeadRow>
            </TableHead>

            <TableBody>
              {Array.isArray(sectorsV2) &&
                sectorsV2
                  .map((sector, index) => {
                    return (
                      <TableRow value={index}>
                        <TableData>
                          <div>{sector.id_sector}</div>
                        </TableData>
                        <TableData>
                          <div>{sector.name_sector}</div>
                        </TableData>
                        <TableData>
                          <div>{sector.users_size}</div>
                        </TableData>
                        <TableData>
                          <div className="img-removed-edit">
                            <img
                              onClick={() => this.editSector(sector.id_sector)}
                              className="img-edit"
                              src={IcoEdit}
                              alt="editar"
                            />
                            <img
                              onClick={() =>
                                this.deleteSector(
                                  sector.id_sector,
                                  sector.associated_users
                                )
                              }
                              className="img-edit"
                              src={RemoveIcon}
                              alt="apagar"
                            />
                          </div>
                        </TableData>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <FooterPagination
            activePage={sectorsPagination.current_page}
            totalItemsCount={sectorsPagination.total_count}
            handlePageChange={
              (pageNumber) => this.props.getSectorList(pageNumber, this.props.search)
            }
          />
        </BodyTable>
        <SaveSector
          patchSector={this.state.patchSector}
          sectorId={this.state.idSector}
        />
        <DeleteSector
          sectorId={this.state.idSector}
          associated_users={this.state.associated_users}
        />
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.sectorReducer.sectorList,
    sectorsV2: state.sectorReducer.sectorListV2,
    sectorsPagination: state.sectorReducer.paginationV2,
    search: state.sectorReducer.search
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSectorList: (page, search) => {
      dispatch(SectorApi.getSectorUserV2(page, search));
    },
    openDialog: () => {
      dispatch({ type: 'SHOW_MODAL_INSERT_TAG' });
    },
    openDialogDelete: () => {
      dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
    }
  };
};

const SectorListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SectorList);

export default SectorListContainer;
