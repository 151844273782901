import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import errorHandler from '../componentes/utils/errorHandler';
export default class ImportApi {
  static sendFile(file) {
    return (dispatch) => {
      if (dispatch(this.validFile(file))) {
        dispatch({ type: 'SUBMITTING' });

        var formData = new FormData();
        formData.append('file', file[0]);

        API.post('imports/', formData, Config.headers())
          .then(
            function(response) {
              dispatch({ type: 'SUCCESS_IMPORT', success: response.data });
            }.bind(this)
          )
          .catch(
            function(error) {
              dispatch({ type: 'NO_SUBMITTING' });

              if (error.response.status === 422) {
                dispatch({
                  type: 'ERROR_IMPORT',
                  errors: error.response.data.errors
                });
              } else {
                dispatch({
                  type: 'ERROR_IMPORT',
                  errors: [
                    'Verifique se a planilha enviada possui extensão .xlsx.',
                    'Verifique se a planilha enviada segue a estrutura do modelo.',
                    'Caso a planilha esteja correta, tente novamente mais tarde.'
                  ]
                });
              }
            }.bind(this)
          );
      } else {
        dispatch({
          type: 'ERROR_IMPORT',
          errors: ['Formato de arquivo enviado inválido.']
        });
      }
    };
  }

  static validFile(file) {
    return (dispatch) => {
      switch (file[0].type) {
        case 'application/vnd.ms-excel':
          return true;

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return true;

        case 'application/vnd.oasis.opendocument.spreadsheet':
          return true;

        case '.xls':
          return true;

        case '.xml':
          return true;

        default:
          return false;
      }
    };
  }

  static downloadSpreadsheetModel = () => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data } = await API.get('imports/template', {
          responseType: 'blob',
          headers: Config.headers().headers
        });

        const src = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement('a');
        link.href = src;
        link.setAttribute('download', 'template.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  };
}
