import React, { Component } from 'react';
import { DialogImport } from './componentes/DialogImport/DialogImport';
import { connect } from 'react-redux';
import DialogValidation from './componentes/DialogValidation/DialogValidation';
import ImportApi from '../../Logicas/ImportApi';
import DialogErrors from './componentes/DialogErrors/DialogErrors';
import DialogSucess from './componentes/DialogSucess/DialogSucess';
class ImportUser extends Component {
  componentWillUnmount() {
    this.props.clear();
  }
  render() {
    return (
      <div>
        {this.props.dropfile.length === 0 ? (
          <DialogImport
            file={this.props.dropfile}
            onDropFile={this.props.onDropFile}
            downloadSpreadsheetModel={this.props.downloadSpreadsheetModel}
          />
        ) : null}
        {this.props.submit_form === false ? (
          <DialogValidation visible={true} />
        ) : null}

        {this.props.statusimport.kind === 'error' ? (
          <DialogErrors
            errors={this.props.statusimport.errors}
            visible={true}
            clear={this.props.clear}
          />
        ) : null}

        {this.props.statusimport.kind === 'sucess' ? (
          <DialogSucess
            sucessinfo={this.props.statusimport.sucess}
            visible={true}
          />
        ) : null}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onDropFile: (file) => dispatch(ImportApi.sendFile(file)),
    clear: () => {
      dispatch({ type: 'CLEAR' });
    },
    downloadSpreadsheetModel: () =>
      dispatch(ImportApi.downloadSpreadsheetModel())
  };
};
const mapStateToProps = (state) => {
  return {
    dropfile: state.dropfile,
    submit_form: state.submitting,
    statusimport: state.statusimport
  };
};
const ImportarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportUser);
export default ImportarContainer;
