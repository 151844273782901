import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TagsButton from '../TagsButton/TagsButton';
import TagsSearch from '../TagsSearch/TagsSearch';
import { TextFieldWithoutForm } from '../../../utils/FormsComponents';
import DialogApi from '../../../../Logicas/DialogApi';
import TagApi from '../../../../Logicas/TagApi';

import { ReactComponent as IconSearch } from '../../../academy/course/img/iconSearchSmall.svg';

import * as S from './TagsHeader.style';

export class TagsHeader extends Component {
  constructor() {
    super();
    this.state = {
      searchvalue: '',
      activeTab: 'available'
    };
    this.getBySearch = this.getBySearch.bind(this);
    this.getBySearchDebounced = this.getBySearchDebounced.bind(this);
  }

  getBySearch({ target: { value } }) {
    this.setState({ searchvalue: value }, () => {
      this.getBySearchDebounced(value);
    });
  }

  getBySearchDebounced = debounce((text) => {
    this.props.getTags(1, text, this.props.available);
  }, 1500);

  componentDidUpdate() {
    if (this.props.available !== this.state.activeTab) {
      this.setState({
        activeTab: this.props.available
      });
    }
  }

  render() {
    return (
      <MuiThemeProvider>
        <S.TagsComponentHeader>
          <S.TagsComponentHeaderTitle>
            <S.TagsComponentHeaderTitleValue
              isActiveTab={this.state.activeTab === 'available'}
              onClick={
                this.state.activeTab !== 'available'
                  ? () => {
                      this.setState({ activeTab: 'available' });
                      this.props.getTags(1, this.props.search, 'available');
                    }
                  : () => {}
              }
            >
              Ativas
            </S.TagsComponentHeaderTitleValue>
            <S.TagsComponentHeaderTitleValue
              isActiveTab={this.state.activeTab === 'unavailable'}
              onClick={
                this.state.activeTab !== 'unavailable'
                  ? () => {
                      this.setState({ activeTab: 'unavailable' });
                      this.props.getTags(1, this.props.search, 'unavailable');
                    }
                  : () => {}
              }
            >
              Inativas
            </S.TagsComponentHeaderTitleValue>
            <S.TagsComponentHeaderTitleValue
              isActiveTab={this.state.activeTab === ''}
              onClick={
                this.state.activeTab !== ''
                  ? () => {
                      this.setState({ activeTab: '' });
                      this.props.getTags(1, this.props.search, '');
                    }
                  : () => {}
              }
            >
              Todas
            </S.TagsComponentHeaderTitleValue>
          </S.TagsComponentHeaderTitle>
          <S.TagsComponentHeaderButtons>
            {/* <TagsSearch tagsList={this.props.list} /> */}
            <S.ContainerSearch>
              <S.InputSearch
                value={this.state.searchvalue}
                onChange={this.getBySearch}
                placeholder={
                  this.state.activeTab === 'Ativas'
                    ? 'Busque nas tags ativas'
                    : this.state.activeTab === 'Inativas'
                    ? 'Busque nas tags inativas'
                    : 'Busque em todas as tags'
                }
              />
              <IconSearch className="magnifier" />
            </S.ContainerSearch>
            <TagsButton label="Nova Tag" onClick={this.props.openDialog} />
          </S.TagsComponentHeaderButtons>
        </S.TagsComponentHeader>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.tag.search,
    available: state.tag.available,
    list: state.tagReducer,
    alert: state.dialog,
    erroalert: state.erroalert
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openDialog: () => {
      dispatch(DialogApi.openDialog());
    },
    getTags: (page, search, available) => {
      dispatch(TagApi.getTagsV2(page, search, available));
    }
  };
};
const TagsHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsHeader);

export default TagsHeaderContainer;
