import API, { API_V2 } from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import { browserHistory } from 'react-router';
import PubSub from 'pubsub-js';
import ModuleApi from './ModuleApi';
import Axios from 'axios';
import { style } from '../style';
import { parseTagsObject, parseObjectTags } from './ParseTags';
import errorHandler from '../componentes/utils/errorHandler';
import NotificationApi from './NotificationApi';

export default class CourseApi {
  static getCourses(page, avaiable) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      Axios.get(
        `${style.url.urlClient}api/v2/courses?page=${page}&origin=admin&available=${avaiable}`,
        Config.headers()
      )
        .then((response) => {
          Axios.get(
            `${style.url.urlClient}api/v2/courses?page=${page}&per_page=${response.data.pagination.total_count}&origin=admin&available=${avaiable}`,
            Config.headers()
          )
            .then((response) => {
              response = this.parseCourseList(
                response.data,
                style.CurrencyName.name
              );
              if (response.course.length > 0) {
                dispatch({ type: 'NO_SUBMITTING' });
                PubSub.publish('re-render-course-list', true);
              } else {
                PubSub.publish('re-render-course-list', false);
              }
              dispatch({
                type: 'GET_COURSE_LIST',
                course_list: response,
                page: page
              });
              dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            })
            .catch((err) => {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getCoursesV2(page, avaiable) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      dispatch({ type: 'IS_LOADING_COURSE_PAGINATION' });
      Axios.get(
        `${style.url.urlClient}api/v2/courses?page=${page}&origin=admin&available=${avaiable}`,
        Config.headers()
      )
        .then((response) => {
          let course_list = this.parseCourseList(
            response.data,
            style.CurrencyName.name,
            'v2'
          );
          if (course_list.length > 0) {
            dispatch({ type: 'NO_SUBMITTING' });
            PubSub.publish('re-render-course-list', true);
          } else {
            PubSub.publish('re-render-course-list', false);
          }
          dispatch({
            type: 'GET_COURSE_LIST',
            course_list,
            course_list_pagination: response.data.pagination
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({ type: 'NOT_LOADING_COURSE_PAGINATION' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getCoursesBySearch(page, search) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      Axios.get(
        `${style.url.urlClient}api/v2/courses?page=${page}&per_page=5&origin=admin&search=${search}&available=1`,
        Config.headers()
      )
        .then((responseAvailable) => {
          Axios.get(
            `${style.url.urlClient}api/v2/courses?page=${page}&per_page=5&origin=admin&search=${search}&available=0`,
            Config.headers()
          )
            .then((responseNotAvailable) => {
              responseNotAvailable = this.parseCourseList(
                responseNotAvailable.data,
                style.CurrencyName.name
              );
              responseAvailable = this.parseCourseList(
                responseAvailable.data,
                style.CurrencyName.name
              );

              dispatch({
                type: 'GET_COURSE_LIST_SEARCH',
                course_list_search_available: responseAvailable,
                course_list_search_not_available: responseNotAvailable,
                search_actual: search,
                page: page
              });

              dispatch({ type: 'NO_SUBMITTING' });

              dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            })
            .catch((err) => {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getAllCoursesBySearch(page, search) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      Axios.get(
        `${style.url.urlClient}api/v2/courses?page=${page}&origin=admin&search=${search}`,
        Config.headers()
      )
        .then((response) => {
          Axios.get(
            `${style.url.urlClient}api/v2/courses?page=${page}&per_page=${response.data.pagination.total_count}&origin=admin&search=${search}`,
            Config.headers()
          )
            .then((response_final) => {
              response_final = this.parseCourseList(
                response_final.data,
                style.CurrencyName.name
              );

              dispatch({
                type: 'GET_ALL_COURSES_LIST_SEARCH',
                course_all_list_search: response_final,
                page: page
              });

              dispatch({ type: 'SET_COURSE_SEARCH_NOT_ACTIVE' });

              dispatch({ type: 'NO_SUBMITTING' });

              dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });

              browserHistory.push('/course/results');
            })
            .catch((err) => {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static editCourse(idelemente, module) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(`coursers/${idelemente}?origin=web`, Config.headers())
        .then(
          function(response) {
            let course = this.parseCourse(response.data);
            dispatch({
              type: 'EDIT_COURSE',
              course: course
            });
            if (response.data.kind === 'long') {
              dispatch(this.editCourseV2(response.data.id));
              browserHistory.push('/course/modules/create');
            } else if (module !== true) {
              browserHistory.push('/course/modules');
            }

            dispatch({ type: 'NO_SUBMITTING' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static editCourseV2(idcourse, redirect) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      Axios.get(
        `${style.url.urlClient}api/v2/courses/${idcourse}?origin=admin`,
        Config.headers()
      )
        .then((response) => {
          let course = this.parseCourseV2(response.data);
          dispatch({
            type: 'EDIT_COURSE',
            course: course
          });
          dispatch({ type: 'NO_SUBMITTING' });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'HIDE_PROGRESS_COURSE_INDETERMINATE',
            indexContent: 0
          });
          if (redirect) {
            browserHistory.push('/course/modules/create');
          }
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUsersCourseTotalCount(
    idCourse,
    kind,
    page = 1,
    per_page = 10,
    isItToNotify = false,
    message,
    isNewCourseMessage = false
  ) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      Axios.get(
        `${style.url.urlClient}api/v1/coursers/${idCourse}/users_course?kind=${kind}&page=${page}&per_page=${per_page}`,
        Config.headers()
      )
        .then((response) => {
          dispatch(
            this.getUsersCourse(
              idCourse,
              kind,
              (page = 1),
              response.data.pagination.total_count,
              isItToNotify,
              message,
              isNewCourseMessage
            )
          );
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static sendNotificationsUsersV2(idCourse, kind, title) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      var formData = new FormData();
      formData.append('kind', kind);
      formData.append('title', title);
      API_V2.post(
        `courses/${idCourse}/notification_users`,
        formData,
        Config.headers()
      )
        .then((response) => {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: 'Mensagem enviada!'
            }
          });
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUserCertificate(idCourse, search, page = 1, per_page = 10) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get(
        `courses/${idCourse}/find_certificate?page=${page}&per_page=${per_page}&user_name=${search}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'SET_USERS_CERTIFICATE',
            usersCertificate: response.data
          });

          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getUsersCourse(
    idCourse,
    kind,
    page = 1,
    per_page = 10,
    isItToNotify = false,
    message,
    isNewCourseMessage
  ) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      Axios.get(
        `${style.url.urlClient}api/v1/coursers/${idCourse}/users_course?kind=${kind}&page=${page}&per_page=${per_page}`,
        //order_for=name&order=asc
        Config.headers()
      )
        .then((response) => {
          if (isItToNotify) {
            dispatch({
              type: 'EDIT_USERS_NOTIFICATION',
              usersNotification: response.data
            });
            dispatch(
              NotificationApi.sendNotification(
                response.data.users,
                message,
                '',
                isNewCourseMessage
              )
            );
          } else {
            dispatch({
              type: 'EDIT_USERS_COURSE',
              usersCourse: response.data
            });
          }
          if (!isNewCourseMessage) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }
          dispatch({ type: 'SEARCH_BY_USERS_FINISHED' });
        })
        .catch((err) => {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static deleteCourse(idelemente, avaiable) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(`coursers/${idelemente}?origin=web`, Config.headers())
        .then(
          function(response) {
            dispatch(this.getCoursesV2(1, avaiable ? 1 : 0));
            dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({ type: 'CLOSE_ALERT', alert: { visible: false } });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static editCourseForm(idelemente) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get('coursers/' + idelemente + '?origin=web', Config.headers())
        .then(
          function(response) {
            var course = this.parseCourseEdit(response.data);
            dispatch({
              type: 'EDIT_COURSE',
              course: course
            });

            browserHistory.push('/course/edit');
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static patchCourse(values, typeCourse) {
    return (dispatch) => {
      var course = this.buildFormData(values);
      API.patch(
        'coursers/' + values.id + '?origin=web',
        course,
        Config.headers()
      )
        .then(
          function(response) {
            if (typeCourse === 'long') {
              dispatch(this.editCourseV2(values.id));
              dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
            } else {
              dispatch(this.editCourse(values.id));
              browserHistory.push('/course/modules');
            }
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static patchCourseExpressionTags(values, tagsExpressions) {
    return (dispatch) => {
      const tagsObjectParse = parseTagsObject(tagsExpressions);
      const objectSaveCanvas = {
        kind: 'long',
        title: values.title,
        score: values.score,
        image: values.image[0],
        expression: tagsObjectParse,
        description: values.description,
        minimum_percentage_for_certificate: values.score_for_certificate || 0
      };
      const objectToJson = JSON.stringify(objectSaveCanvas);
      API.patch(`coursers/${values.id}?origin=web`, objectToJson, {
        headers: Object.assign(Config.headers().headers, {
          'content-type': 'application/json'
        })
      })
        .then((response) => {
          dispatch(this.editCourseV2(values.id));
          dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
          dispatch({ type: 'NO_SUBMITTING' });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  // TODO: remove this duplicate after API migration
  static createCourse(values, expressionsTags) {
    return (dispatch) => {
      const tagsObjectParse = parseTagsObject(expressionsTags);
      const objectSaveCanvas = {
        kind: 'long',
        title: values.title,
        score: values.score,
        image: values.image[0],
        expression: tagsObjectParse,
        description: values.description,
        minimum_percentage_for_certificate: values.score_for_certificate || 0
      };
      const objectToJson = JSON.stringify(objectSaveCanvas);
      API.post('coursers/', objectToJson, {
        headers: Object.assign(Config.headers().headers, {
          'content-type': 'application/json'
        })
      })
        .then(function(response) {
          dispatch(
            ModuleApi.createModule(
              {
                module_title: 'Módulo 1',
                module_score: 0,
                module_description: 'Descrição módulo 1',
                idcourse: response.data.id
              },
              response.data.id
            )
          );
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static createCourseV2(values, expressionsTags) {
    return (dispatch) => {
      let course = this.buildFormData(values);
      // TODO: Resolve this function after expressionsTags implementation on API
      // const tagsObjectParse = parseTagsObject(expressionsTags);
      // const objectSaveCanvas = {
      //   kind: 'long',
      //   title: values.title,
      //   score: values.score,
      //   image: values.image[0],
      //   expression: tagsObjectParse,
      //   description: values.description,
      //   minimum_percentage_for_certificate: values.score_for_certificate,
      // };
      // const objectToJson =  JSON.stringify(objectSaveCanvas);
      API.post('coursers/', course, Config.headers())
        .then(function(response) {
          if (response.data.minimum_percentage_for_certificate > 0) {
            dispatch(
              ModuleApi.createModule(
                {
                  module_title: 'Módulo 1',
                  module_score: 0,
                  module_description: 'Descrição Modulo 1',
                  idcourse: response.data.id
                },
                response.data.id
              )
            );
          } else {
            localStorage.setItem('IdCourseEdit', response.data.id);
            dispatch(CourseApi.editCourseV2(response.data.id));
            browserHistory.push('/course/modules/create');
            dispatch({ type: 'NO_SUBMITTING' });
          }
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static changeAvaiableCourse(id, tags, available, method) {
    if (available) {
      available = false;
    } else {
      available = true;
    }
    var formData = new FormData();
    formData.append('available', available);
    tags.map(function(tag) {
      return formData.append('tags[]', tag.name);
    });

    return (dispatch) => {
      API_V2.patch(
        'courses/' + id + '?origin=admin',
        formData,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_COURSE_LIST',
              course_list: {},
              page: 1
            });
            dispatch(this.getCoursesV2(1, available ? 1 : 0));
            dispatch({ type: 'NO_SUBMITTING' });
          }.bind(this)
        )
        .catch((err) => {
          dispatch({
            type: 'GET_COURSE_LIST',
            course_list: {},
            page: 1
          });
          dispatch(this.getCoursesV2(1, available ? 1 : 0));
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static downloadUserCertificate = (courseId, userID, courseTitle) => {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      API.get(`coursers/${courseId}/certificate.pdf?user_id=${userID}`, {
        responseType: 'blob',
        headers: Config.headers().headers
      }).then((res) => {
        const src = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = src;
        link.setAttribute(
          'download',
          `certificado_${courseTitle.replace(' ', '_')}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      });
    };
  };

  static sendCourseProgressReport = (courseId, email) => {
    return (dispatch) => {
      dispatch({ type: 'REPORT_REQUEST_START' });

      API.get(
        `historical/course_users_history.xlsx?course_id=${courseId}&email=${email}`,
        Config.headers()
      )
        .then((response) => {
          dispatch({
            type: 'REPORT_REQUEST_SUCCESS',
            payload: { message: response.data.mesage }
          });
        })
        .catch((err) => {
          dispatch({ type: 'CLOSE_REPORT_EMAIL_MODAL' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  };

  static parseCourse(values) {
    var course = {};
    course.title = values.title;
    course.score = values.score;
    course.description = values.description;
    course.level = values.level;
    course.duration = values.duration;
    course.tags_course = [];
    course.module_courses = [];
    course.id = values.id;
    values.tags.map(function(tag, id) {
      return course.tags_course.push(tag.name);
    });
    values.module_courses.map(function(module_) {
      return course.module_courses.push(module_);
    });
    course.image = values.image.url;
    course.users_finished =
      values.users_progress_data &&
      values.users_progress_data.users_who_finished;
    course.users_not_finish = [];
    course.users_not_started = [];
    values.users_progress_data &&
      values.users_progress_data.users_who_did_not_finish.map(function(
        not_finish
      ) {
        if (not_finish.finished_modules.length > 0) {
          course.users_not_finish.push(not_finish);
        } else {
          course.users_not_started.push(not_finish);
        }
      });
    return course;
  }

  static parseCourseV2(values) {
    let course = {};
    course.title = values.title;
    course.score = values.score;
    course.description = values.description;
    course.score_for_certificate =
      values.minimum_percentage_for_certificate || 0;
    course.has_certificate = values.has_certificate;
    course.modality = values.modality;
    course.module_courses = [];
    course.id = values.id;
    course.tags_course = [];
    values.tags.map(function(tag) {
      return course.tags_course.push({
        value: tag.name,
        label: '#' + tag.name
      });
    });
    values.module_courses.map(function(module_) {
      return course.module_courses.push(module_);
    });
    course.image = values.image.url;
    course.can_edit_configs = values.can_edit_configs;
    // course.users_finished =
    //   values.users_progress_data &&
    //   values.users_progress_data.users_who_finished;
    // course.users_not_finish = [];
    // course.users_not_started = [];
    // values.users_progress_data.users_who_did_not_finish.map(function(
    //   not_finish
    // ) {
    //   if (not_finish.finished_modules.length > 0) {
    //     course.users_not_finish.push(not_finish);
    //   } else {
    //     course.users_not_started.push(not_finish);
    //   }
    // });
    return course;
  }

  static parseCourseEdit(values) {
    var course = {};
    course.title = values.title;
    course.score = values.score;
    course.description = values.description;
    course.level = values.level;
    course.duration = values.duration;
    course.tags_course_form = [];
    course.module_courses = [];
    course.id = values.id;
    values.tags.map(function(tag, id) {
      return course.tags_course_form.push({ value: tag.name, label: tag.name });
    });
    values.module_courses.map(function(module_) {
      return course.module_courses.push(module_);
    });
    course.image = values.image.url;
    return course;
  }

  static buildFormData(values) {
    var formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    if (values.image[0].preview) {
      formData.append('image', values.image[0]);
    }
    formData.append(
      'score',
      Number(values.score.toString().replaceAll('.', '')) || 0
    );
    formData.append(
      'minimum_percentage_for_certificate',
      values.score_for_certificate || 0
    );

    if (
      values.modality === 'presential' ||
      (values.quiz === 'Sim' &&
        values.gamification === 'Não' &&
        !values.has_certificate) ||
      values.quiz === 'Não' ||
      Number(values.score_for_certificate) === 0
    )
      formData.append('score_with', 'conclusion');
    else formData.append('score_with', 'percentage_of_hits');

    formData.append('kind', 'long');
    values.tags_course.forEach(function(tag) {
      formData.append('tags[]', tag.value);
    });

    formData.append('has_certificate', values.has_certificate);

    if (values.modality === 'presential')
      formData.append('modality', 'presential');
    else formData.append('modality', 'online');

    if (values.quiz === 'Sim') formData.append('quiz', true);
    else formData.append('quiz', false);

    return formData;
  }

  static parseCourseList(courses, teamName, version = 'v1') {
    let courseArray = { course: [] };
    let courseArrayAux = courses.courses;
    if (courseArrayAux.length > 0) {
      let courseAux = [];
      courseArrayAux.map(function(element, index) {
        let course_format = {};
        course_format.id = element.id;
        course_format.title = element.title;
        course_format.description = element.description;
        course_format.image = element.image;
        course_format.kind = element.kind;
        course_format.score = element.score;
        course_format.teamName = teamName;
        course_format.duration = element.duration;
        course_format.level = element.level;
        course_format.module_courses = element.module_courses;
        course_format.tags = element.tags;
        course_format.available = element.available;
        if (element.expression) {
          course_format.tagsExpression = parseObjectTags(element.expression);
        }
        if (element.available) {
          courseArray.course.push(course_format);
        } else {
          courseAux.push(course_format);
        }
      });
      let courseConcat = courseArray.course.concat(courseAux);
      courseArray.size = courses.size;
      courseArray.course = courseConcat;
    }
    return version === 'v2' ? courseArray.course : courseArray;
  }

  static getHeaderCourseDetails(value) {
    return (dispatch) => {
      dispatch({
        type: 'GET_HEADER_COURSE_DETAILS',
        headerName: value
      });
    };
  }

  static getVouchers(courseId, page) {
    return (dispatch) => {
      API.get(
        `coursers/${courseId}/vouchers?page=${page}&per_page=10`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_VOUCHERS',
              vouchers: response.data
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static addVoucher(values) {
    return (dispatch) => {
      const formData = new FormData(values);

      API.post(
        `coursers/${formData.get('id')}/vouchers`,
        JSON.stringify(Object.fromEntries(formData)),
        Config.headersJson()
      )
        .then(
          function(response) {
            dispatch({
              type: 'ADD_VOUCHER',
              voucher: response.data
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static editVoucher(values, id) {
    return (dispatch) => {
      const formData = new FormData(values);

      API.patch(
        `coursers/${formData.get('id')}/vouchers/${id}`,
        JSON.stringify(Object.fromEntries(formData)),
        Config.headersJson()
      )
        .then(
          function(response) {
            dispatch({
              type: 'EDIT_VOUCHER',
              voucher: response.data
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static removeVoucher(courseId, voucherId) {
    return (dispatch) => {
      API.delete(`coursers/${courseId}/vouchers/${voucherId}`, Config.headers())
        .then(
          function() {
            dispatch({
              type: 'DELETE_VOUCHER',
              voucherId
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static viewUsersVoucher(voucherId, id, page) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(
        'coursers/' +
          id +
          '/vouchers/' +
          voucherId +
          '/user_vouchers?per_page=999999',
        Config.headers()
      ).then(function(response) {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        // if (response.data.length > 0) {
        PubSub.publish('get_next_page_comments', response.data.length === 10);
        dispatch({
          type: 'SHOW_USERS_LIST_VOUCHER',
          visible: true,
          data: response.data
        });
        // }
      });
    };
  }
}
