import React, { Component } from 'react';
import { style as teamAttributes } from '../../style';
import CardProduct from './CardProduct';
import ComponentHeader from '../utils/ComponenteHeader';
import { browserHistory } from 'react-router';
import { DialogYesNo } from '../utils/DailogComponentes';
import PlaceHolderProduct from '../utils/img/place_holder_product.png';
import Slide from 'react-reveal/Slide';
import { connect } from 'react-redux';
import StoreApi from '../../Logicas/StoreApi';
import DialogApi from '../../Logicas/DialogApi';
import { MuiThemeProvider } from 'material-ui/styles';
import { CircularProgressIndeterminate } from '../utils_form/FormComponents';
import PubSub from 'pubsub-js';
import { FooterPagination } from '../utils/Pagination';
require('./storelist.css');

const {
  CurrencyName: { name: moneyName }
} = teamAttributes;
const currentCurrency = moneyName.split('/');

class ProductList extends Component {
  constructor() {
    super();
    this.state = { page: 1, getNew: true };
  }

  componentWillMount() {
    this.props.getProducts(this.state.page);
    PubSub.subscribe(
      're-render-product-list',
      function(render) {
        this.setState({ getNew: true });
      }.bind(this)
    );
  }

  render() {
    return (
      <div>
        <ComponentHeader
          actions={[
            { name: 'Todos' },
            {
              name: 'Solicitações',
              action: () => browserHistory.push('/product/history')
            }
          ]}
          name="Loja "
          nameaction="Adicionar Produto +"
          onClick={function() {
            browserHistory.push('/store/create');
          }}
        />

        {this.props.loadingProducts && !this.props.list.length ? (
          <div className="loader-container">
            <MuiThemeProvider>
              <CircularProgressIndeterminate />
              <h2 className="loader-container__heading">Carregando...</h2>
            </MuiThemeProvider>
          </div>
        ) : (
          !this.props.loadingProducts &&
          this.props.list.length === 0 && (
            <div className="place-holder">
              <img
                className="img-place-holder"
                src={PlaceHolderProduct}
                alt="ícone produto"
              />
              {this.props.requestError ? (
                <>
                  <p className="request-error-message">
                    Não conseguimos buscar os produtos na loja.
                  </p>
                  <p className="request-error-message">
                    O servidor se comportou de forma inesperada.
                  </p>
                </>
              ) : (
                <>
                  <p className="request-error-message">
                    Nenhum produto encontrado na loja.
                  </p>
                  <p className="request-error-message">
                    Que tal cadastrar algum?
                  </p>
                </>
              )}
            </div>
          )
        )}

        {this.props.list !== undefined && this.props.list.length > 0 && (
          <>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <div className="row-card-corses-list">
                {Array.isArray(this.props.list)
                  ? this.props.list.map(
                      function(product, index) {
                        if (product.description.length > 20) {
                          product.description =
                            product.description.slice(0, 60) + '...';
                        }
                        return (
                          <Slide right>
                            <CardProduct
                              key={product.id}
                              productimage={product.image.url}
                              producttitle={product.name}
                              productdescription={product.description}
                              productscore={product.valuescore}
                              productTeamName={
                                product.valuescore > 1
                                  ? currentCurrency[1]
                                  : currentCurrency[0]
                              }
                              productid={product.id}
                              productamount={product.amount}
                              onClickEdit={this.props.editProduct.bind(
                                this,
                                product.id
                              )}
                              onClickDelete={this.props.deleteShowDialogProduct.bind(
                                this,
                                product.id
                              )}
                            />
                          </Slide>
                        );
                      }.bind(this)
                    )
                  : undefined}
              </div>
            </div>
            <DialogYesNo
              yesclick={this.props.deleteProduct.bind(
                this,
                this.props.alert.id_elemente
              )}
              noclick={this.props.closeDialog}
              onClick={this.props.closeDialog}
              visible={this.props.alert.visible}
              message="Tem certeza que deseja remover este produto ?"
            />

            {this.props.list[0].size[0] > 0 && (
              <footer className="footer-pagination">
                <FooterPagination
                  activePage={this.props.page}
                  totalItemsCount={this.props.list[0].size[0]}
                  handlePageChange={this.props.getProducts.bind(this)}
                />
              </footer>
            )}
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    list: state.storeList.productList,
    page: state.storeList.page,
    alert: state.dialog,
    pagination: state.pagination,
    requestError: state.requestError.get,
    loadingProducts: state.loadingProducts.loading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteShowDialogProduct: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    deleteProduct: (values, id) => {
      dispatch(StoreApi.deleteProduct(values));
    },
    getProducts: (page) => {
      dispatch(StoreApi.getProducts(page));
    },
    editProduct: (id, values) => {
      dispatch(StoreApi.editProduct(id));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    }
  };
};
const CourseListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);

export default CourseListContainer;
