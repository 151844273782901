const initialState = {
  sectorList: [],
  sectorNamesList: [],
  loadingSectors: false,
  sectorListV2: [],
  paginationV2: [],
  search:""
};

export function sectorReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_SECTOR_V2':
      return { ...state, sectorListV2: action.sectorListV2, paginationV2: action.paginationV2, search: action.search };
    case 'GETSECTOR':
      return { ...state, sectorList: action.sectors };
    case 'SECTOR_NAMES_LIST_GET':
      return { ...state, sectorNamesList: action.sectorNamesList };
    case 'LOADING_SECTORS_START':
      return {
        ...state,
        loadingTags: true
      };
    case 'LOADING_SECTORS_FINISH':
      return {
        ...state,
        loadingTags: false
      };
    default:
      return state;
  }
}
