import styled from 'styled-components';

export const CheckIcon = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink'
})`
  position: absolute;
  left: calc(32px - 7.5px);
  width: 15px;
  height: 15px;
  margin-right: 8px;
  fill: #ffffff;
`;
