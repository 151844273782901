import React, { Component } from 'react';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import IcoLike from './img/ic-curtidas@3x.png';
import IcoView from './img/ic-visualiza-oes@3x.png';
import IcoComments from './img/ic-coment-rios@3x.png';
import moment from 'moment';
import PlaceHolder from '../PlaceHolder/PlaceHolder';

require('./publicationlist.css');

class PublicationList extends Component {
  render() {
    return (
      <div className="publication-list-dash">
        <div>
          <div className="header-publication-list">
            <div className="header-publication-list-description">
              <label className="main-label-header-publication-list">
                Publicações em destaque
              </label>
              <label className="secondary-label-header-publication-list">
                Publicações com mais interações no período
              </label>
            </div>
          </div>
          {this.props.publication_list &&
            (this.props.publication_list.length === 0 ? (
              <PlaceHolder />
            ) : (
              <Table height="360px">
                <TableBody displayRowCheckbox={false}>
                  {Array.isArray(this.props.publication_list) &&
                    this.props.publication_list.map(function(publication) {
                      let hour = moment(publication.created_at).fromNow();
                      return (
                        <TableRow displayBorder={false}>
                          <TableRowColumn>
                            <label className="label-title-list">
                              {publication.title}
                            </label>
                          </TableRowColumn>
                          <TableRowColumn style={{width: "140px", paddingLeft: "25px", paddingRight:"0"}}>
                            <label className="label-title-secondary" >
                              {publication.user}
                            </label>
                          </TableRowColumn>
                          <TableRowColumn style={{width: "80px", padding: "0"}}>
                            <label className="label-title-secondary">
                              {hour}
                            </label>
                          </TableRowColumn>
                          <TableRowColumn style={{padding: "0"}}>
                            <div className="post-atributes-list" style={{gap: "1rem"}}>
                              <div className="item-list-post">
                                <img
                                  className="ico-publication-list"
                                  src={IcoLike}
                                  alt="ícone curtidas"
                                />{' '}
                                <label className="label-title-secondary">
                                  {publication.likes.toLocaleString("pt-BR")}
                                </label>
                              </div>
                              <div className="item-list-post">
                                <img
                                  className="ico-publication-list"
                                  src={IcoView}
                                  alt="ícone visualizações"
                                />{' '}
                                <label className="label-title-secondary">
                                  {publication.views.toLocaleString("pt-BR")}
                                </label>
                              </div>
                              <div className="item-list-post">
                                <img
                                  className="ico-publication-list"
                                  src={IcoComments}
                                  alt="ícone comentários"
                                />{' '}
                                <label className="label-title-secondary">
                                  {publication.comments.toLocaleString("pt-BR")}
                                </label>
                              </div>
                            </div>
                          </TableRowColumn>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            ))}
        </div>
      </div>
    );
  }
}
export default PublicationList;
