import React, { Component } from 'react';
import { debounce } from 'lodash';

import { getMuiTheme, MuiThemeProvider } from 'material-ui/styles/';
import { style } from '../../../style';
import {
  CheckboxDefaultList,
  ButtonConfirm,
  TextFieldWithoutForm
} from '../../utils/FormsComponents';
import {
  CircularProgressIndeterminate,
  DatePickerForm
} from '../../utils_form/FormComponents';
import { Field, reduxForm } from 'redux-form';
import CardProductHistory from './CardProductHistory';
import { BodyTable } from '../../utils/BodyTable';
import { connect } from 'react-redux';
import styled from 'styled-components';
import IcoPlaceHolder from '../../utils/img/place_holder_product.png';
import StoreApi from '../../../Logicas/StoreApi';
import DialogApi from '../../../Logicas/DialogApi';
import { FooterPagination } from '../../utils/Pagination';
import TabLabel from './Components/TabLabel/TabLabel';
import DialogDispatchProduct from './DialogHistory';
import DialogReportEmail from '../../publication/dialog/DialogReportEmail/DialogReportEmail';
import DashboardButton from '../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';

import { ReactComponent as IconSearch } from '../../academy/course/img/iconSearchSmall.svg';

require('./historycss.css');

const DatePickerFormDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    border-bottom: 2.1px ${style.inpuStyle.colorInput} solid;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  grid-area: search;
  position: relative;
  flex: 1;
  align-items: center;
  max-width: 370px;

  > svg {
    position: absolute;
  }

  .magnifier {
    right: 10px;
    bottom: 10px;
    opacity: 0.8;
  }
`;

export const InputSearch = styled.input`
  font-family: Rubik;
  height: 40px;
  width: 100%;

  /* border: 1px solid #E4E4E5; */
  border: 2px solid #e4e4e5;
  border-radius: 4px;
  padding: 10px 40px 10px 10px;
  font-size: 16px;

  color: #808080;

  &::placeholder {
    color: #808080;
  }

  &:focus {
    border: none;
    outline-style: solid;
    outline-color: ${style.inpuStyle.colorInput};
    outline-width: 1px;
  }

  &:hover {
    border: ${(props) => (props.isDisabled ? '1px solid #E4E4E5' : 'none')};
    outline-style: solid;
    outline-color: ${style.inpuStyle.colorInput};
    outline-width: ${(props) => (props.isDisabled ? '0px' : '1px')};
  }
`;

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  timePicker: {
    pickerHeaderColor: style.inpuStyle.colorInput,
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput,
    pickerSelectColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

function transformAddressToString(address) {
  if (address === null) {
    return '';
  }
  return `${address.street}, ${address.number} - ${address.complement} - ${address.neighborhood} - ${address.city} / ${address.state}, ${address.zip_code}`;
}

function getPhoneFromContacts(contacts) {
  if (contacts && contacts.length === 0) {
    return '';
  }
  let numberPhone = '';
  contacts &&
    contacts.forEach((contact) => {
      if (contact.kind === 'phone') numberPhone = contact.contact;
    });

  return numberPhone;
}

class ProductHistory extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: 1,
      getDispatchedProducts: false,
      kind: 'not_despatched',
      startDate: null,
      endDate: null,
      minDate: new Date('2018-01-01T00:00:00'),
      search: '',
      selectedsCarts: [],
      allCartsAreSelecteds: false,
      kindSearch: 'product'
    };

    this.getDispatchedProductListDebounced = this.getDispatchedProductListDebounced.bind(
      this
    );
    this.getNotDispatchedProductListDebounced = this.getNotDispatchedProductListDebounced.bind(
      this
    );
  }

  handleSelectedsCarts(newIdCart, cartsQuantity) {
    let newSelectedsCarts = this.state.selectedsCarts.includes(newIdCart)
      ? this.state.selectedsCarts.filter((ids) => ids !== newIdCart)
      : [...this.state.selectedsCarts, newIdCart];
    this.setState({
      selectedsCarts: newSelectedsCarts,
      allCartsAreSelecteds:
        newSelectedsCarts.length === cartsQuantity ? true : false
    });
  }

  setSelectedsCarts(list) {
    if (this.state.allCartsAreSelecteds) {
      this.setState({
        allCartsAreSelecteds: false,
        selectedsCarts: []
      });
    } else {
      this.setState({
        allCartsAreSelecteds: true,
        selectedsCarts: list
      });
    }
  }

  getAllCartsIds(products) {
    let allCartsIds = products.map((product) => product.cart_id);
    return allCartsIds;
  }

  setAllProductsToDispatch(products, filtersInformation) {
    let allProductsToDispatch = products.map(
      (product) =>
        this.state.selectedsCarts.includes(product.cart_id) && {
          cart_id: product.cart_id,
          product_id: product.product.id,
          user_id: product.user.id
        }
    );

    allProductsToDispatch = allProductsToDispatch.filter(
      (product) => !!product && product
    );
    this.props.dispatchProducts(allProductsToDispatch, filtersInformation);
    this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
  }

  componentDidMount() {
    const { list, getInitialProductList } = this.props;

    if (!list.notDispatchedProducts.length && !list.dispatchedProducts.length) {
      getInitialProductList();
    }
  }

  searchProduct = (search, kindSearchParams = '') => {
    const {
      getDispatchedProducts,
      startDate,
      endDate,
      kindSearch
    } = this.state;

    this.setState({
      search
    });

    if (getDispatchedProducts) {
      this.getDispatchedProductListDebounced(
        1,
        search,
        kindSearchParams ? kindSearchParams : kindSearch,
        startDate,
        endDate
      );
      this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
    } else {
      this.getNotDispatchedProductListDebounced(
        1,
        search,
        kindSearchParams ? kindSearchParams : kindSearch,
        startDate,
        endDate
      );
      this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
    }
  };

  getProductsPageList = (nextPage) => {
    const {
      getDispatchedProducts,
      startDate,
      endDate,
      search,
      kindSearch
    } = this.state;
    const {
      getDispatchedProductList,
      getNotDispatchedProductList
    } = this.props;

    if (getDispatchedProducts) {
      getDispatchedProductList(
        nextPage,
        search,
        kindSearch,
        startDate,
        endDate
      );
      this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
    } else {
      getNotDispatchedProductList(
        nextPage,
        search,
        kindSearch,
        startDate,
        endDate
      );
      this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
    }
  };

  changePeriodStart = (value, date) => {
    const { getDispatchedProducts, endDate, search, kindSearch } = this.state;
    const {
      getDispatchedProductList,
      getNotDispatchedProductList
    } = this.props;

    this.setState({
      startDate: date
    });

    if (endDate) {
      if (getDispatchedProducts) {
        getDispatchedProductList(1, search, kindSearch, date, endDate);
        this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
      } else {
        getNotDispatchedProductList(1, search, kindSearch, date, endDate);
        this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
      }
    }
  };

  changePeriodEnd = (value, date) => {
    const { getDispatchedProducts, startDate, search, kindSearch } = this.state;
    const {
      getDispatchedProductList,
      getNotDispatchedProductList
    } = this.props;

    this.setState({
      endDate: date
    });

    if (startDate) {
      if (getDispatchedProducts) {
        getDispatchedProductList(1, search, kindSearch, startDate, date);
        this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
      } else {
        getNotDispatchedProductList(1, search, kindSearch, startDate, date);
        this.setState({ selectedsCarts: [], allCartsAreSelecteds: false });
      }
    }
  };

  openStartDateCalendar() {
    var element = document.querySelector('#start_date');
    if (element !== null) element.click();
  }
  openEndDateCalendar() {
    var element = document.querySelector('#end_date');
    if (element !== null) element.click();
  }

  getDispatchedProductListDebounced = debounce(
    (page, search, kindSearch, startDate, endDate) => {
      this.props.getDispatchedProductList(
        page,
        search,
        kindSearch,
        startDate,
        endDate
      );
    },
    1500
  );

  getNotDispatchedProductListDebounced = debounce(
    (page, search, kindSearch, startDate, endDate) => {
      this.props.getNotDispatchedProductList(
        page,
        search,
        kindSearch,
        startDate,
        endDate
      );
    },
    1500
  );

  handleProductOrUserSearch = (e) => {
    this.setState({ kindSearch: e.target.value });

    if (this.state.search !== '') {
      this.searchProduct(this.state.search, e.target.value);
    }
  };

  render() {
    const { activeTab, kind, startDate, endDate, kindSearch } = this.state;
    const {
      list,
      loading,
      loadingProducts,
      getDispatchedProductList,
      getNotDispatchedProductList,
      openReportEmailDialog
    } = this.props;

    return (
      <>
        <MuiThemeProvider muiTheme={muiTheme}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '2rem 10rem 0 13rem'
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '2rem'
              }}
            >
              <div>
                <span
                  style={{
                    fontFamily: 'Rubik',
                    fontWeight: '500',
                    fontSize: '2rem',
                    color: '#808080'
                  }}
                >
                  Solicitações
                </span>
              </div>
              <div>
                <TabLabel
                  active={activeTab === 1}
                  label="Em aprovação"
                  onClick={() => (
                    this.setState({
                      activeTab: 1,
                      getDispatchedProducts: false,
                      kind: 'not_despatched'
                    }),
                    getNotDispatchedProductList(
                      1,
                      this.state.search,
                      this.state.kindSearch,
                      startDate && endDate && startDate,
                      endDate
                    )
                  )}
                />
              </div>
              <div>
                <TabLabel
                  active={activeTab === 2}
                  label="Aprovadas"
                  onClick={() => (
                    this.setState({
                      activeTab: 2,
                      getDispatchedProducts: true,
                      kind: 'despatched'
                    }),
                    getDispatchedProductList(
                      1,
                      this.state.search,
                      this.state.kindSearch,
                      startDate && endDate && startDate,
                      endDate
                    )
                  )}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <div
                  style={{
                    padding: '3rem 0 1rem',
                    fontFamily: 'Rubik',
                    display: 'flex',
                    gap: '9rem'
                  }}
                >
                  <span
                    style={{
                      fontWeight: '500',
                      color: '#808080',
                      fontSize: '1.6rem'
                    }}
                  >
                    Pesquisar por
                  </span>
                  <div
                    onChange={this.handleProductOrUserSearch}
                    style={{
                      gap: '1rem',
                      display: 'flex',
                      color: '#808080'
                    }}
                  >
                    <div>
                      <Field
                        name="kind"
                        component="input"
                        type="radio"
                        value="product"
                        id="product"
                        checked={kindSearch === 'product'}
                      />
                      <label
                        for="product"
                        className="form-label"
                        style={{ fontSize: '1.6rem' }}
                      >
                        Produto
                      </label>
                    </div>
                    <div>
                      <Field
                        name="kind"
                        component="input"
                        type="radio"
                        value="user"
                        id="user"
                        checked={kindSearch === 'user'}
                      />
                      <label
                        for="user"
                        className="form-label"
                        style={{ fontSize: '1.6rem' }}
                      >
                        Usuário
                      </label>
                    </div>
                  </div>
                </div>

                <div>
                  <ContainerSearch>
                    <InputSearch
                      onChange={(values) =>
                        this.searchProduct(values.target.value)
                      }
                      placeholder={`Digite o nome do ${
                        this.state.kindSearch === 'product'
                          ? 'produto'
                          : 'usuário'
                      }`}
                    />
                    <IconSearch className="magnifier" />
                  </ContainerSearch>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '3.5rem 0 0 0'
                }}
              >
                <div
                  style={{
                    position: 'absolute'
                  }}
                >
                  <span
                    style={{
                      fontWeight: '500',
                      color: '#808080',
                      fontSize: '1.6rem'
                    }}
                  >
                    Histórico de pedidos
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '1.5rem',
                    marginTop: '1rem'
                  }}
                >
                  <div>
                    <DatePickerFormDiv>
                      <DatePickerForm
                        id="start_date"
                        name="start_date"
                        label="Início do período"
                        minDate={this.state.minDate}
                        maxDate={this.state.endDate}
                        selected={this.state.startDate}
                        onChange={this.changePeriodStart}
                      />
                    </DatePickerFormDiv>
                  </div>
                  <div>
                    <DatePickerFormDiv>
                      <DatePickerForm
                        id="end_date"
                        name="end_date"
                        label="Final do período"
                        selected={this.state.endDate}
                        minDate={this.state.startDate}
                        onChange={this.changePeriodEnd}
                      />
                    </DatePickerFormDiv>
                  </div>
                  <div>
                    <DashboardButton
                      label="Solicitar"
                      style={{
                        width: '100%',
                        padding: '1rem 2rem'
                      }}
                      // bothDatesRequired={startDate !== null && endDate !== null}
                      onClick={
                        startDate === null
                          ? this.openStartDateCalendar
                          : endDate === null
                          ? this.openEndDateCalendar
                          : () =>
                              openReportEmailDialog(kind, startDate, endDate)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loadingProducts && list.notDispatchedProducts.products.length === 0 && (
            <div className="loader-container">
              <MuiThemeProvider>
                <CircularProgressIndeterminate />
                <h2 className="loader-container__heading">Carregando...</h2>
              </MuiThemeProvider>
            </div>
          )}

          {
            <div
              style={{
                'border-top': 'solid 1px #cccccc',
                'border-bottom': 'solid 1px #cccccc'
              }}
            >
              <div
                className="body-card"
                style={{
                  width: '90%',
                  height: '75px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  border: 'none'
                }}
              >
                {activeTab === 1 && (
                  <>
                    <div className="card-product-column">
                      <CheckboxDefaultList
                        checked={this.state.allCartsAreSelecteds}
                        onCheck={() =>
                          this.setSelectedsCarts(
                            this.getAllCartsIds(
                              list.notDispatchedProducts.products
                            )
                          )
                        }
                      />
                    </div>
                    <div
                      className="card-product-column"
                      style={{ fontSize: '18px', fontWeight: '600' }}
                    >
                      {this.state.selectedsCarts.length}/
                      {activeTab === 1
                        ? list.notDispatchedProducts.products.length
                        : list.dispatchedProducts.products.length}
                      <br />
                      Selecionados
                    </div>
                  </>
                )}
                {activeTab === 2 && (
                  <div
                    className="card-product-column"
                    style={{ fontSize: '18px', fontWeight: '600' }}
                  ></div>
                )}
                <div
                  className="card-product-column"
                  style={{
                    minWidth: '180px',
                    fontSize: '18px',
                    fontWeight: '600'
                  }}
                >
                  Produto
                </div>
                <div
                  className="card-product-column"
                  style={{ fontSize: '18px', fontWeight: '600' }}
                >
                  Quantidade disponível
                </div>
                <div
                  className="card-product-column"
                  style={{ fontSize: '18px', fontWeight: '600' }}
                >
                  Solicitante
                </div>
                {(style.teamName.name === 'Pintou Parceria Suvinil' ||
                  style.teamName.name === 'sample') && (
                  <div
                    className="card-product-column"
                    style={{ fontSize: '18px', fontWeight: '600' }}
                  >
                    Endereço de entrega
                  </div>
                )}
                <div
                  className="card-product-column"
                  style={{ fontSize: '18px', fontWeight: '600' }}
                >
                  Data da solicitação
                </div>
                {activeTab === 1 && (
                  <>
                    <div
                      className="card-product-column"
                      style={{ fontSize: '18px', fontWeight: '600' }}
                    >
                      Excluir
                    </div>
                    <div
                      className="card-product-column"
                      style={{ fontSize: '18px' }}
                    >
                      {
                        <ButtonConfirm
                          onClick={() =>
                            this.setAllProductsToDispatch(
                              list.notDispatchedProducts.products,
                              {
                                page: 1,
                                search: this.state.search,
                                startDate: startDate,
                                endDate: endDate
                              }
                            )
                          }
                          label="Confirmar"
                          height="none"
                          padding="1rem"
                          disabled={this.state.selectedsCarts.length <= 1}
                        />
                      }
                    </div>
                  </>
                )}
              </div>
            </div>
          }

          <BodyTable isDispatchProducts={true}>
            <section>
              {activeTab === 1 && (
                <div
                  className="tab__waiting-confirmation"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  {list.notDispatchedProducts.products.length > 0 ? (
                    list.notDispatchedProducts.products.map(
                      function(product) {
                        const hasProductInfo = product.product !== null;
                        const hasUserInfo = product.user !== null;
                        return (
                          <CardProductHistory
                            key={product.cart_id}
                            hasCheckbox={true}
                            checked={this.state.selectedsCarts.includes(
                              product.cart_id
                            )}
                            onCheck={() =>
                              this.handleSelectedsCarts(
                                product.cart_id,
                                list.notDispatchedProducts.products.length
                              )
                            }
                            send={true}
                            closeDialog={this.props.closeDialog}
                            dialogOpen={this.props.dialogOpen}
                            alert={this.props.alert}
                            id_product={hasProductInfo && product.product.id}
                            user_id={hasUserInfo && product.user.id}
                            cart_id={product.cart_id}
                            user_name={hasUserInfo && product.user.name}
                            user_address={transformAddressToString(
                              product.user_address
                            )}
                            user_phoneNumber={getPhoneFromContacts(
                              product.user_contact
                            )}
                            amount={hasProductInfo && product.product.amount}
                            score={hasProductInfo && product.product.valuescore}
                            name_product={
                              hasProductInfo && product.product.name
                            }
                            img={hasProductInfo && product.product.image.url}
                            requested_date={product.created_at}
                          />
                        );
                      }.bind(this)
                    )
                  ) : (
                    <div className="row">
                      {!loading &&
                        list.notDispatchedProducts.products.length === 0 && (
                          <div className="col-md-4 offset-md-4">
                            <img
                              className="img-place-holder"
                              src={IcoPlaceHolder}
                              alt="ícone carrinho"
                            />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}

              {activeTab === 2 && (
                <div
                  className="tab__solicitation-approved"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  {list.dispatchedProducts.products.length > 0 ? (
                    list.dispatchedProducts.products.map(function(product) {
                      const hasProductInfo = product.product !== null;
                      const hasUserInfo = product.user !== null;
                      return (
                        <CardProductHistory
                          key={product.id_cart}
                          cart_id={product.id_cart}
                          user_name={hasUserInfo && product.user.name}
                          user_address={transformAddressToString(
                            product.user_address
                          )}
                          user_phoneNumber={getPhoneFromContacts(
                            product.user_contact
                          )}
                          amount={hasProductInfo && product.product.amount}
                          score={hasProductInfo && product.product.valuescore}
                          name_product={hasProductInfo && product.product.name}
                          img={hasProductInfo && product.product.image.url}
                          requested_date={product.created_at}
                        />
                      );
                    })
                  ) : (
                    <div className="row">
                      {!loading &&
                        list.dispatchedProducts.products.length === 0 && (
                          <div className="col-md-4 offset-md-4">
                            <img
                              className="img-place-holder"
                              src={IcoPlaceHolder}
                              alt="ícone carrinho"
                            />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}
            </section>

            {activeTab === 1 && (
              <>
                {list.notDispatchedProducts.pagination.total_pages > 0 && (
                  <FooterPagination
                    activePage={
                      list.notDispatchedProducts.pagination.current_page
                    }
                    totalItemsCount={
                      list.notDispatchedProducts.pagination.total_count
                    }
                    handlePageChange={this.getProductsPageList}
                  />
                )}
              </>
            )}

            {activeTab === 2 && (
              <>
                {list.dispatchedProducts.pagination.total_pages > 0 && (
                  <FooterPagination
                    activePage={list.dispatchedProducts.pagination.current_page}
                    totalItemsCount={
                      list.dispatchedProducts.pagination.total_count
                    }
                    handlePageChange={this.getProductsPageList}
                  />
                )}
              </>
            )}
          </BodyTable>

          <DialogDispatchProduct
            filtersInformation={{
              page: 1,
              search: this.state.search,
              startDate: startDate,
              endDate: endDate
            }}
          />

          <MuiThemeProvider>
            <DialogReportEmail />
          </MuiThemeProvider>
        </MuiThemeProvider>
      </>
    );
  }
}

ProductHistory = reduxForm({ form: 'productHistoryForm' })(ProductHistory);

const mapStateToProps = (state) => {
  return {
    list: state.storeList.historyList,
    alert: state.dialog,
    pagination: state.pagination,
    loading: state.progress_indeterminate,
    loadingProducts: state.loadingProducts.loading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dialogOpen: (user_id, cart_id, id_product) => {
      dispatch(
        DialogApi.deleteAlertProductHistory(user_id, id_product, cart_id)
      );
    },
    getDispatchedProductList: (
      page,
      search,
      kindSearch,
      startDate,
      endDate
    ) => {
      dispatch(
        StoreApi.getDispatchedProductList(
          page,
          search,
          kindSearch,
          startDate,
          endDate
        )
      );
    },
    getNotDispatchedProductList: (
      page,
      search,
      kindSearch,
      start_date,
      end_date
    ) => {
      dispatch(
        StoreApi.getNotDispatchedProductList(
          page,
          search,
          kindSearch,
          start_date,
          end_date
        )
      );
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    },
    getByNameProduct: (name) => {
      dispatch(StoreApi.getProductsHistoryPage(name));
    },
    getProducthistory: (page) => {
      dispatch(StoreApi.getProductsHistory(page));
    },
    getInitialProductList: () => {
      dispatch(StoreApi.getInitialProductList());
    },
    openReportEmailDialog: (kind, startDate, endDate) => {
      dispatch({
        type: 'OPEN_REPORT_EMAIL_MODAL',
        payload: { kind, startDate, endDate }
      });
    },
    dispatchProducts: (product_purchase, filtersInformation) => {
      dispatch(StoreApi.dispatchProducts(product_purchase, filtersInformation));
    }
  };
};
const ProducHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductHistory);

export default ProducHistoryContainer;
