import React, { Component } from 'react';

require('./headerinteraction.css');

class HeaderProduct extends Component {
  render() {
    return (
      <div>
        <div className="header-interaction-row-display">
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.product_length.toLocaleString("pt-BR")}
            </label>
            <label className="secondary-label-interaction">PRODUTOS</label>
          </div>
          <div className="border-right-element"></div>
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.products_rescued.length.toLocaleString("pt-BR")}
            </label>
            <label className="secondary-label-interaction">SOLICITAÇÕES</label>
          </div>
          <div className="border-right-element"></div>
          <div className="element-header-interaction">
            <label className="main-label-interaction">
              {this.props.products_without_stock.length.toLocaleString("pt-BR")}
            </label>
            <label className="secondary-label-interaction">SEM ESTOQUE</label>
          </div>
        </div>
      </div>
    );
  }
}
export default HeaderProduct;
