import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import ImageIcon from '../../img/ic-imagem_3x.png';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Dropzone from 'react-dropzone';
import VideoIcon from '../../img/ic-video.png';
import CarouselIcon from '../../img/ic-carousel.png';
import { TextFieldDefault, LongTextLink } from '../../../utils/FormsComponents';
import { required, validurl } from '../../../validation/FormValidation';
import IcoLinkConfirm from '../../img/ic-add-link.png';
import IcoExit from '../../../utils/img/exit-dialog.png';
import DashboardButton from '../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import ToolsButton from '../../../Tools/ToolsList/Components/ToolsButton/ToolsButton';
import { ReactComponent as IconUpload } from '../../../CreateOrEditPost/icons/upload.svg';
import { ReactComponent as IconVideoUploaded } from '../../../CreateOrEditPost/icons/videoUpload.svg';
require('./dialogcanvas.css');
const formats = [
  '.webm',
  '.mkv',
  '.flv',
  '.vob',
  '.ogv',
  '.ogg',
  '.drc',
  '.gif',
  '.gifv',
  '.mng',
  '.avi',
  '.mov',
  '.qt',
  '.wmv',
  '.yuv',
  '.rm',
  '.rmvb',
  '.asf',
  '.amv',
  '.mp4',
  '.m4p',
  '.m4v',
  '.mpg',
  '.mp2',
  '.mpeg',
  '.mpe',
  '.mpg',
  '.mpeg',
  '.m2v',
  '.m4v',
  '.svi',
  '.3gp',
  '.3g2',
  '.mxf',
  '.roq',
  '.nsv',
  '.flv',
  '.f4v',
  '.f4p',
  '.mov',
  '.MOV',
  '.f4a',
  '.f4b',
  'video/mp4',
  'video/quicktime'
];

class ModalCanvasMidia extends Component {
  constructor() {
    super();
    this.state = {
      videoKind: 'link',
      inputValue: '',
      videoEmbededUrl: '',
      videoFile: [],
      errorMessage: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveLink = this.saveLink.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onUploadVideo = this.onUploadVideo.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  closeDialog() {}

  onOptionChange = (e) => {
    this.setState({ videoKind: e.target.value });
  };

  onClear = () => {
    this.setState({ videoFile: [] });
    this.setState({ inputValue: '' });
    this.props.closeDialogUploadFile();
  };

  onDrop = (acceptedFiles) => {
    this.setState({
      videoFile: acceptedFiles.filter((file) => file.type === 'video/mp4')
    });

    if (this.state.videoFile === 0) {
      this.setState({
        errorMessage: 'Apenas arquivos de vídeo são permitidos.'
      });
    } else {
      this.setState({ errorMessage: '' });
      this.setState({ inputValue: '' });
    }
  };

  onUploadVideo = () => {
    if (this.state.videoFile[0] && this.state.videoFile.length > 0) {
      this.handleChange(this.state.videoFile);
    }
    if (this.state.inputValue !== '') {
      this.saveContentEmbededVideoLink(this.state.inputValue);
    }
  };

  handleChange(e) {
    if (e[0] !== undefined && e !== null) {
      try {
        if (e.length === 1) {
          if (formats.includes(e[0].type)) {
            this.props.InsertComponentVideo(e);
            this.props.closeDialogUploadFile();
            this.props.setPublication('video', e);
          } else {
            this.props.InsertComponentImage(e);
            this.props.closeDialogUploadFile();
            this.props.setPublication('image', e);
          }
        } else if (e.length > 1 && e.length <= 10) {
          this.props.InsertComponentCarousel(e);
          this.props.closeDialogUploadFile();
          this.props.setPublication('carousel', e);
        } else {
          this.setState({ erro: 'O limite de imagens permitida é de 10' });
          setTimeout(
            function() {
              this.setState({ erro: undefined });
            }.bind(this),
            3000
          );
        }
      } catch (error) {}
    }
  }

  saveLink(values) {
    this.props.addLink(values.name_button, values.link_button);
    this.props.setPublication('link', values);
    this.props.InsertComponentLink(values);
    this.props.closeDialogUploadFile();
  }

  saveContentEmbededVideoLink(values) {
    this.props.addEmbededVideoLink();
    this.props.setPublication('embeded_video_link', values);
    this.props.InsertComponentVideoEmbeded(values);
    this.props.closeDialogUploadFile();
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.props.alert.kind === undefined) {
      return null;
    }
    if (this.props.alert.kind !== 'link' && this.props.alert.kind !== 'video') {
      return (
        <Modal visible={this.props.alert.visible} width="50%" height="50%">
          <div
            onClick={this.props.closeDialogUploadFile}
            className="body-row-exit"
          >
            <img
              style={{ height: '12px', width: '12px' }}
              src={IcoExit}
              alt="fechar"
            />
          </div>
          <div
            style={{ width: '100%', height: '90%' }}
            className="dropzone-class"
          >
            {this.state.erro === undefined ? (
              <Dropzone
                className="dropzone-class-border"
                onDrop={this.handleChange}
              >
                {this.props.alert.kind === 'image' ? (
                  <div className="dropzone-class">
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={ImageIcon}
                      alt="ícone de imagem"
                    />
                    <div className="label-dialog-main">
                      <label className="label-dialog-main-select">
                        Clique aqui
                      </label>{' '}
                      ou arraste uma imagem para adicionar um conteúdo Imagem
                    </div>
                  </div>
                ) : (
                  undefined
                )}

                {this.props.alert.kind === 'carousel' ? (
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                      <img
                        style={{ width: '50px', height: '50px' }}
                        src={CarouselIcon}
                        alt="ícone de carrossel"
                      />
                    </div>
                    <div style={{ marginTop: '10px' }} className="col-md-8">
                      <div className="label-dialog-main">
                        <label className="label-dialog-main-select">
                          Clique aqui
                        </label>{' '}
                        ou arraste ao menos duas e até dez imagens para criar um
                        carousel
                      </div>
                    </div>
                  </div>
                ) : (
                  undefined
                )}
              </Dropzone>
            ) : (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="erro-images">
                      Limite de imagens excedido, máximo de dez imagens
                      permitidas
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      );
    }
    if (this.props.alert.kind === 'link') {
      return (
        <Modal visible={true} width="50%" height="29%">
          <form onSubmit={handleSubmit(this.saveLink)}>
            <div
              onClick={this.props.closeDialogUploadFile}
              className="body-row-exit"
            >
              <img
                style={{ height: '12px', width: '12px' }}
                src={IcoExit}
                alt="fechar"
              />
            </div>
            <div className="form-link-class">
              <Field
                name="name_button"
                rows={1}
                validate={[required]}
                id="name"
                label="Texto do botão*"
                component={TextFieldDefault}
                type="text"
              />

              <Field
                style={{ width: '70%' }}
                name="link_button"
                rowsMax={1}
                rows={1}
                validate={[required]}
                id="name"
                label="https://*"
                component={LongTextLink}
                type="text"
              />
              <div className="form-link-btn">
                <button className="img-button-click">
                  {' '}
                  <img src={IcoLinkConfirm} alt="confirmar" />{' '}
                </button>
              </div>
            </div>
          </form>
        </Modal>
      );
    }
    if (this.props.alert.kind === 'video') {
      return (
        <Modal width="550px" height="350px" visible={this.props.alert.visible}>
          <div className="modal_container">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <h4 className="modal_title">
                Selecione como você irá inserir o vídeo
              </h4>

              <div className="modal_container_select">
                <div className="modal_select">
                  <input
                    type="radio"
                    name="Upload de arquivo"
                    value="link"
                    onChange={this.onOptionChange}
                    checked={this.state.videoKind === 'link'}
                  />
                  <label className="modal_select_label">
                    Upload de arquivo
                  </label>
                </div>

                <div className="modal_select">
                  <input
                    type="radio"
                    name="Link do Youtube"
                    value="embeded_video_link"
                    onChange={this.onOptionChange}
                    checked={this.state.videoKind === 'embeded_video_link'}
                  />
                  <label className="modal_select_label">Link do Youtube</label>
                </div>
              </div>
            </div>

            {this.state.videoKind === 'embeded_video_link' ? (
              <input
                className="modal_link_input"
                type="text"
                value={this.state.inputValue}
                placeholder="http://www..."
                onChange={(e) => {
                  this.setState({ inputValue: e.target.value });
                  this.setState({ videoFile: [] });
                }}
              />
            ) : (
              <div className="dropzone_container">
                {this.state.videoFile[0] && this.state.videoFile.length > 0 ? (
                  <div className="dropzone_content">
                    <IconVideoUploaded />
                    <p className="dropzone_text">
                      {this.state.videoFile[0].name}
                    </p>
                  </div>
                ) : (
                  <Dropzone
                    className="dropzone_content"
                    onDrop={this.onDrop}
                    accept=".mp4"
                  >
                    <IconUpload />
                    <p className="dropzone_text">
                      <span className="dropzone_mark_text">Clique</span> ou
                      arraste um vídeo
                    </p>
                  </Dropzone>
                )}
              </div>
            )}

            <div className="modal_container_buttons">
              <DashboardButton
                withoutMargin
                label={'Cancelar'}
                onClick={this.onClear}
                style={{ width: '100px' }}
              />
              <ToolsButton
                disabled={
                  !this.state.videoFile[0] && this.state.inputValue === ''
                }
                isDisabled={
                  !this.state.videoFile[0] && this.state.inputValue === ''
                }
                label="Continuar"
                onClick={this.onUploadVideo}
                style={{ width: '100px' }}
              />
            </div>
          </div>
        </Modal>
      );
    }
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'dialogmidia'
})(ModalCanvasMidia);

const mapDispatchToProps = (dispatch) => {
  return {
    sendFiles: (files) => {
      dispatch({ type: 'UPLOAD_FILES', files: files });
    },
    addLink: (link, name) => {
      dispatch({ type: 'ADD_LINK', link: { name: name, link: link } });
    },
    addEmbededVideoLink: (link, name) => {
      dispatch({
        type: 'ADD_EMBEDED_VIDEO_LINK',
        link: { name: name, link: link }
      });
    },
    setPublication: (kind, value) =>
      dispatch({ type: 'formPost', kind: kind, value: value }),
    closeDialogUploadFile: () => {
      dispatch({ type: 'CLOSE_DIALOG_FILE', alert: { visible: false } });
    }
  };
};
InitializeFromStateForm = connect(
  (state) => ({}),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
