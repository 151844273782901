import React, { Component } from 'react';
import TextIcon from '../../img/ic-text-small.png';
import ImageIcon from '../../img/ic-imagem-small.png';
import VideoIcon from '../../img/ic-video-small.png';
import CarouselIcon from '../../img/ic-carousel-small.png';
import LinkIcon from '../../img/ic-link-small.png';

import Zoom from 'react-reveal/Zoom';
import ReactTooltip from 'react-tooltip';

require('./SelectComponent.css');

export default class SelectComponent extends Component {
  render() {
    return this.props.fixedMenu ? (
      <div>
        <div
          style={{ marginTop: '10x' }}
          onMouseLeave={this.props.onMouseLeave}
          className="col-md-3 select-component-fixed-topo"
        >
          <div className="row">
            <Zoom>
              <div
                onClick={this.props.InsertComponentText}
                className="col-md-2 body-select-component"
              >
                <div className="row">
                  <div
                    data-tip="Inserir componente de texto"
                    className="col-md-12"
                  >
                    <img
                      style={{ marginLeft: '5px' }}
                      className="image-title-component"
                      src={TextIcon}
                      alt="ícone de texto"
                    />
                  </div>
                </div>
              </div>
              <div
                onClick={this.props.showDialogUploadFile.bind(this, 'image')}
                className="col-md-3 body-select-component"
              >
                <div className="row">
                  <div data-tip="Inserir uma imagem" className="col-md-12">
                    <img className="image-title-component" src={ImageIcon} alt="ícone de imagem"/>
                  </div>
                </div>
              </div>
              <div
                onClick={this.props.showDialogUploadFile.bind(this, 'video')}
                className="col-md-2 body-select-component"
              >
                <div className="row">
                  <div data-tip="Inserir um video" className="col-md-12">
                    <img className="image-title-component" src={VideoIcon} alt="ícone de vídeo"/>
                  </div>
                </div>
              </div>
              <div
                onClick={this.props.showDialogUploadFile.bind(this, 'carousel')}
                className="col-md-3 body-select-component"
              >
                <div className="row">
                  <div data-tip="Inserir várias imagens" className="col-md-12">
                    <img className="image-title-component" src={CarouselIcon} alt="ícone de carrossel"/>
                  </div>
                </div>
              </div>
              <div
                onClick={this.props.showDialogUploadFile.bind(this, 'link')}
                className="col-md-2 body-select-component"
              >
                <div className="row">
                  <div data-tip="Inserir um link" className="col-md-12">
                    <img
                      style={{ marginLeft: '5px' }}
                      className="image-title-component"
                      src={LinkIcon}
                      alt="ícone de link"
                    />
                  </div>
                </div>
              </div>
            </Zoom>
          </div>
          <ReactTooltip />
        </div>
      </div>
    ) : (
      <div
        style={{ marginTop: '20x' }}
        onMouseLeave={this.props.onMouseLeave}
        className="col-md-4 select-component-body"
      >
        <div style={{ marginTop: '20x' }} className="row">
          <div className="col-md-2 body-select-component">
            <div onClick={this.props.InsertComponentText} className="row">
              <div data-tip="Inserir componente de texto" className="col-md-12">
                <img
                  style={{ marginLeft: '5px' }}
                  className="image-title-component"
                  src={TextIcon}
                  alt="ícone de texto"
                />
              </div>
              <ReactTooltip />
            </div>
          </div>
          <div
            onClick={this.props.showDialogUploadFile.bind(this, 'image')}
            className="col-md-3 body-select-component"
          >
            <div className="row">
              <div data-tip="Inserir uma imagem" className="col-md-12">
                <img className="image-title-component" src={ImageIcon} alt="ícone de imagem"/>
              </div>
            </div>
          </div>
          <div
            onClick={this.props.showDialogUploadFile.bind(this, 'video')}
            className="col-md-2 body-select-component"
          >
            <div className="row">
              <div data-tip="Inserir um video " className="col-md-12">
                <img className="image-title-component" src={VideoIcon} alt="ícone de vídeo"/>
              </div>
            </div>
          </div>
          <div
            onClick={this.props.showDialogUploadFile.bind(this, 'carousel')}
            className="col-md-3 body-select-component"
          >
            <div className="row">
              <div data-tip="Inserir várias imagens " className="col-md-12">
                <img className="image-title-component" src={CarouselIcon} alt="ícone de carrossel"/>
              </div>
            </div>
          </div>
          <div
            onClick={this.props.showDialogUploadFile.bind(this, 'link')}
            className="col-md-2 body-select-component"
          >
            <div className="row">
              <div data-tip="Inserir um link " className="col-md-12">
                <img
                  style={{ marginLeft: '5px' }}
                  className="image-title-component"
                  src={LinkIcon}
                  alt="ícone de link"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
