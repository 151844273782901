import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ButtoDefault from '../../utils/FormsComponents';
import { LabelLink } from '../../utils/utils_styled';

require('./headercreate.css');

class HeaderCalendar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="row-header-calendar">
        <label className="label-header-calendar">{this.props.name}</label>
        <div className="actions-header">
          <LabelLink onClick={this.props.cancel}>Cancelar</LabelLink>
          <ButtoDefault onClick={this.props.onClick} label="Salvar" />
        </div>
      </div>
    );
  }
}
HeaderCalendar = reduxForm({ form: 'new_event_header' })(HeaderCalendar);
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const LoginListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderCalendar);

export default LoginListContainer;
