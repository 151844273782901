import styled from 'styled-components';

export const ContainerDialogPontuationUsers = styled.div`
    /* background-color: red; */
    display: flex;
    flex-direction: column;

    height: 100%;
`;

export const ContainerTitle = styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0 1.5rem;

    h1 {
        font-family: 'Rubik';
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 2.8rem;
        color: #4b4e5c;
    }
`;

export const CloseButton = styled.img`
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 3rem;
    right: 5rem;
    cursor: pointer;
`;

export const Section = styled.div`
    width: 80%;
    margin: 0 auto;
    padding: 0 0 2rem;

    h3 {
        color: #4b4e5c;
        font-family: 'Rubik';
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding: 0 0 1rem;

        position: relative;
        z-index: 3;
    }

    .drop-zone-input {
        background-color: transparent;
        border: none;

        button {
        padding: 0;
        margin: 0;
        }
    }
`;

export const FieldSection = styled.div`
    max-height: 65px;

    &:nth-child(2){
        margin-top: -1rem;
    }

`

export const Info = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;

    color: #808080;
    font-family: 'Rubik';
    font-size: 1rem;
    line-height: 3rem;

    margin-top: 1.5rem;
    z-index: 3;
`

export const ContainerSpreadsheet = styled.div`
    width: 100%;
    height: 3.5rem;

    margin: 1.5rem 0 0 0;

    background: #F4F6FB;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    span{
        font-family: 'Rubik';
        font-size: 1.5rem;
        line-height: 2rem;
        color:#868C9B;
    }
`

export const ContainerButton = styled.div`
    padding: 1rem 0;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const ContainerMessage = styled.div`
    width: 75%;
    text-align: center;

    margin: 3.5rem auto;

    color: #4B4E5C;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 1.5rem;
`
