import API from '../componentes/restservice/api.instance';
import Config from '../componentes/restservice/Utils';
import { browserHistory } from 'react-router';

export default class SectorApi {
  static generateReport(values) {
    return (dispatch) => {
      var url = '';
      if (values.report_all_users) {
        url = `reports/?all_users=${values.report_all_users}&format_report=.xls`;
      } else {
        url = `reports/?tags=${values.tags_dialog_report.value}&sector=${values.sector}&format_report=.xls`;
      }
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(url, Config.headers())
        .then(
          function(response) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            window.open(response.data.report.url, '_blank');
          }.bind(this)
        )
        .catch(
          function(err) {
            if (err.message === 'Request failed with status code 401') {
              browserHistory.push('./');
            } else {
            }
            dispatch({
              type: 'OPEN_ERROR',
              alert: { visible: true, message: err.message }
            });
          }.bind(this)
        );
    };
  }
}
