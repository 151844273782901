import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field, reduxForm, isPristine } from 'redux-form';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import ComponentHeader from '../../../utils/ComponenteHeader';
import { required, maxLength190} from '../../../validation/FormValidation';
import { TextFieldTitle } from '../../../utils/FormsComponents';
import HeaderCanvasEdit from '../header/HeaderCanvasEdit';
import EmojiPicker from '../../../utils/EmojiPicker/EmojiPicker';
import PreviewCreateCanvas from '../previews/CreatePreview';
import PostApi from '../../../../Logicas/PostApi';
import * as FormApiAsync from '../../../../Logicas/FormApiAsync';
import * as PostApiAsync from '../../../../Logicas/PostApiAsync';
import TextModule from './Modules/TextModule';
import ImageModule from './Modules/ImageModule';
import VideoModule from './Modules/VideoModule';
import CarouselModule from './Modules/CarouselModule';
import SurveyModule from './Modules/SurveyModule';
import QuizModule from './Modules/QuizModule';
import FormComponentsButtons from './CanvasComponents/FormComponentsButtons';
import DrawModal from './CanvasComponents/DrawModal/DrawModal';
import ToolTipLink from '../toolstips/TooltipLink';
import TagApi from '../../../../Logicas/TagApi';
import PubSub from 'pubsub-js';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { browserHistory } from 'react-router';
import SelectComponentInitial from './../select components/SelectComponentInitial';
import DialogProgressCircular from '../../../utils/DialogProgressCircular';
import Sweepstakes from './Sweepstakes';
import { bind, debounce } from 'lodash';
import { style } from '../../../../style';
import editForm from './Services/editForm';
import OpenQuestionModule from './Modules/OpenQuestionModule';
import ScheduleModal from '../../../utils/dialogs/Components/ScheduleModal/ScheduleModal';
import ProgressToast from '../../../_shared/ProgressToast/ProgressToast';

require('../css/newcanvasform.css');

const DragHandle = sortableHandle(() => (
  <svg
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
      fill="#BDBDBD"
    />
  </svg>
));

const SortableContainer = sortableContainer(({ children }) => {
  return (
    <ul style={{marginTop:"10px"}}>
      {children}
    </ul>
  )
});

class CanvasEdit extends Component {
  constructor() {
    super();
    this.state = {
      elements: [],
      items: [],
      timer: 0,
      fixedMenu: false,
      showinitialcomponente: false,
      detailsPostDraw: 'Post',
      showBtnCarousel: false,
      selectionStart: 0,
      showToolTipText: false
    };
    this.InsertComponentText = this.InsertComponentText.bind(this);
    this.setComponentsToState = this.setComponentsToState.bind(this);
    this.InsertComponentImage = this.InsertComponentImage.bind(this);
    this.InsertComponentVideo = this.InsertComponentVideo.bind(this);
    this.InsertComponentCarousel = this.InsertComponentCarousel.bind(this);
    this.InsertComponentLink = this.InsertComponentLink.bind(this);
    this.InsertContentQuestion = this.InsertContentQuestion.bind(this);
    this.timer = this.timer.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.getPublications = this.getPublications.bind(this);
    this.showBtnCarousel = this.showBtnCarousel.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.contaQuestions = this.contaQuestions.bind(this);
    this.contaOptions = this.contaOptions.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.arrayMoveMutate = this.arrayMoveMutate.bind(this);
    this.arrayMove = this.arrayMove.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  componentWillMount() {
    const { getTags, editpostform } = this.props;
    getTags();
    this.setState({ elements: editpostform.elements });

    if (
      /post\/form\/edit/.test(window.location.href) ||
      /post\/form\/create/.test(window.location.href)
    ) {
      editForm(this.props);
    }
  }

  timer(id, event, target) {
    const elements = [...this.state.elements];

    const currentText = elements.find((element) => element.id === id);
    currentText.text = target.getData();
    const currentTextValue = currentText.text;

    const currentTextIndex = elements.findIndex((element) => element.id === id);
    elements[currentTextIndex].text = currentTextValue;

    this.setState({ elements });
    this.delayedQuery(currentText, currentTextValue);
  }

  delayedQuery = debounce((currentText, currentTextValue) => {
    this.props.saveContentEdit(
      this.props.editpostform.id,
      currentTextValue,
      currentText.id
    );
  }, 1000);

  contaQuestions = debounce((idContent, value, idPatch, typeQuestion) => {
    this.props.saveContentEditQuestions(
      idContent,
      value,
      idPatch,
      typeQuestion
    );
  }, 1000);

  contaOptions = debounce((value, idContent, idQuestion, type) => {
    if (this.state.timer === 0) {
      this.setState({ timer: 1 });

      this.props.saveContentEditOptions(
        this.props.editpostform.id,
        value,
        idContent,
        idQuestion,
        type
      );
    }
    this.setState({ timer: 0 });
  }, 1000);

  contaOptionsV2 = (value, idContent, idQuestion, type)=>{
    let aux = this.state.elements;
      if (value.length <= 255) {

          const element = aux.find(element => element.id === idContent)
          const content = element.content_survey.find(content => content.id === idQuestion);
          aux[aux.indexOf(element)].content_survey[element.content_survey.indexOf(content)].title = value;
          
        if (value && value.length > 0) {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.props.saveContentEditOptions(
              this.props.editpostform.id,
              value,
              idContent,
              idQuestion,
              type
            );
          }, 1500);
        }
  
        this.setState({
          elements: aux
        });
      }
  }

  InsertContentQuestion = async (type) => {
    await this.props.saveContentQuestion(
      this.props.editpostform.id,
      this.props.editpostform,
      true,
      type
    );

    switch (type) {
      case 'survey':
      case 'quiz':
        const idPublish = this.props.editpostform.id;
        const idContent = this.props.editpostform.elements.slice(-1)[0].id;

        await this.props.saveOptionQuestion(idPublish, idContent);

        setTimeout(async () => {
          const idContent = this.props.editpostform.elements.slice(-1)[0].id;

          await this.props.saveOptionQuestion(idPublish, idContent);
        }, 300);
        break;

      case 'open_question':
        setTimeout(async () => {
          const idContent = this.props.editpostform.elements.slice(-1)[0].id;
          const idPublish = this.props.editpostform.id;

          await this.props.saveOptionQuestion(idPublish, idContent);
        }, 300);
        break;
    }

    this.setState({ elements: this.props.editpostform.elements });

    setTimeout(
      function() {
        this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }.bind(this),
      300
    );
  };

  InsertContentAnswer = async () => {};

  async InsertComponentText(initial) {
    const elements = [...this.state.elements];

    await this.props.saveContentText(this.props.editpostform.id, '', true);

    const newElement = this.props.editpostform.elements.slice(-1)[0];
    elements.push(newElement);

    this.setState({ elements, showinitialcomponente: false });

    setTimeout(
      function() {
        this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }.bind(this),
      900
    );
  }

  async InsertComponentImage(e) {
    const elements = [...this.state.elements];

    const response = await this.props.saveContentImage(this.props.editpostform.id, e, 'image');

    if(response){
      const newElement = this.props.editpostform.elements.slice(-1)[0];
      elements.push(newElement);
  
      this.setState({ elements, showinitialcomponente: false });
  
      setTimeout(
        function() {
          this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }.bind(this),
        900
      );
    }

  }

  async InsertComponentVideo(e) {
    const elements = [...this.state.elements];

    const response = await this.props.saveContentVideoV2(this.props.editpostform.id, e, style.teamName.name);

    if(response){
      const newElement = this.props.editpostform.elements.slice(-1)[0];
      elements.push(newElement);

      this.setState({ elements, showinitialcomponente: false });

      setTimeout(
        function() {
          this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }.bind(this),
        900
      );
    }
  }

  async InsertComponentCarousel(e) {
    const elements = [...this.state.elements];

    const response = await this.props.saveContentCarousel(
      this.props.editpostform.id,
      e,
      'carousel'
    );

    if(response){
      const newElement = this.props.editpostform.elements.slice(-1)[0];
      elements.push(newElement);
  
      this.setState({ elements, showinitialcomponente: false });
  
      setTimeout(
        function() {
          this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }.bind(this),
        900
      );
    }

  }

  async InsertComponentLink(e) {
    const elements = [...this.state.elements];

    await this.props.saveContentLink(this.props.editpostform.id, e, 'link');

    const newElement = this.props.editpostform.elements.slice(-1)[0];
    elements.push(newElement);

    this.setState({ elements, showinitialcomponente: false });

    setTimeout(
      function() {
        this.body.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }.bind(this),
      900
    );
  }

  componentDidMount() {
    window.onscroll = () => this.handleScroll();
    PubSub.subscribe(
      're-render-canvas',
      function(render) {
        this.setState({ elements_preview: [] });
      }.bind(this)
    );
    if (this.props.editpostform.elements !== undefined) {
      if (this.props.editpostform.elements.length > 0) {
        this.setState({ showinitialcomponente: false });
      }
    }
  }


  componentDidUpdate() {
    if(this.props.editpostform.elements !== this.state.elements){
      this.setState({ elements: this.props.editpostform.elements });
    }
  }

  handleScroll() {
    if (document.documentElement.scrollTop > 250) {
      this.setState({ fixedMenu: true });
    } else {
      this.setState({ fixedMenu: false, showComponents: true });
    }
  }

  getPublications() {
    browserHistory.push('/post/publication');
  }

  showBtnCarousel() {
    this.setState({
      showBtnCarousel: true
    });
  }

  preserveCursor() {
    this.setState({
      selectionStart: document.querySelector('textarea#name').selectionStart
    });
  }

  setCursor() {
    document
      .querySelector('textarea#name')
      .setSelectionRange(this.state.selectionStart, this.state.selectionStart);
  }

  handleOnBlur() {
    this.preserveCursor();
  }

  setComponentsToState(elements) {
    // TODO: Status update is getting redundant on deleting items - Remove after passing a validated time
    // this.setState({ elements });
  }

  removeQuestion = async (idContent, idOption, type, length) => {
    switch (type) {
      case 'survey':
      case 'quiz':
        if (length > 2) {
          await this.props.deleteOptionQuestion(idContent, idOption, type);
        } else {
          const elements = [...this.state.elements];
          const newElements = elements.filter(
            (element) => element.id !== idContent
          );

          this.setState({ elements: newElements });

          await this.props.deleteQuestionContent(
            this.props.editpostform.id,
            idContent,
            type
          );
        }
        break;
      case 'open_question':
        const elements = [...this.state.elements];
        const newElements = elements.filter(
          (element) => element.id !== idOption
        );

        this.setState({ elements: newElements });

        await this.props.deleteQuestionContent(idContent, idOption, type);
    }
  };

  SortableItem = sortableElement(({ element, index }) => {
    switch (element.kind) {
      case 'text':
        return (
          <div style={{display:'flex', marginTop:'50px'}}>
            <div style={{height:'25px',cursor:'pointer'}}>
              <DragHandle/>
            </div>
            <TextModule
              key={element.id}
              element={element}
              timer={this.timer}
              id_canvas={this.props.editpostform.id}
              elements={this.state.elements}
              setComponentsToState={this.setComponentsToState}
            />
          </div>
        );
      case 'image':
        return (
          <div style={{display:'flex', marginTop:'50px'}}>
            <div style={{height:'25px',cursor:'pointer'}}>
              <DragHandle/>
            </div>
            <ImageModule
              key={element.id}
              element={element}
              id_canvas={this.props.editpostform.id}
              elements={this.state.elements}
              setComponentsToState={this.setComponentsToState}
            />
          </div>
        );
      case 'video':
        if (element.canvas_content_videos.length > 0) {
          return (
            <div style={{display:'flex', marginTop:'50px'}}>
              <div style={{height:'25px',cursor:'pointer'}}>
                <DragHandle/>
              </div>
              <VideoModule
                key={element.id}
                element={element}
                id_canvas={this.props.editpostform.id}
                elements={this.state.elements}
                setComponentsToState={this.setComponentsToState}
              />
            </div>
          );
        }
      case 'carousel':
        if (element.canvas_content_images.length > 0) {
          return (
            <div style={{display:'flex', marginTop:'50px'}}>
              <div style={{height:'25px',cursor:'pointer'}}>
                <DragHandle/>
              </div>
              <CarouselModule
                key={element.id}
                element={element}
                elements={this.state.elements}
                id_canvas={this.props.editpostform.id}
                link={element.link}
                setComponentsToState={this.setComponentsToState}
              />
            </div>
          );
        }
      case 'link':
        const show = {};
        const hide = {};
        show['showbtnlink' + element.id] = true;
        hide['showbtnlink' + element.id] = false;
        return (
          <div style={{display:'flex', marginTop:'50px'}}>
            <div style={{height:'25px',cursor:'pointer'}}>
              <DragHandle/>
            </div>
            <div
              onMouseLeave={() => this.setState(hide)}
              style={{marginLeft: '15px',minWidth:'510px',display:'flex',alignItems:'center',justifyContent:'center'}}
              className="row"
              key={element.id}
            >
              <div className="col-md-4 btn-add-component"
                style={{marginLeft: '5px'}}
              >
                <a
                  onMouseOver={() => this.setState(show)}
                  target="_blank"
                  href={element.link}
                >
                  {' '}
                  <div className="btn-add-component-label">
                    {' '}
                    {element.title.length > 30
                      ? element.title.slice(0, 30) + '...'
                      : element.title}
                  </div>
                </a>
    
                <ToolTipLink
                  showbtn={this.state['showbtnlink' + element.id]}
                  index={this.state.index}
                  id_canvas={this.props.editpostform.id}
                  id={'link_' + element.id}
                  link={element.link}
                  elements={this.state.elements}
                  setComponentsToState={this.setComponentsToState}
                />
              </div>
            </div>
          </div> 
        );
      case 'survey':
        return (
          <div style={{display:'flex', marginTop:'50px'}}>
            <div style={{height:'25px',cursor:'pointer'}}>
              <DragHandle/>
            </div>
            <SurveyModule
              key={element.id}
              element={element}
              elements={this.state.elements}
              editPostFormId={this.props.editpostform.id}
              style={style}
              contaOptions={this.contaOptionsV2}
              contaQuestions={this.contaQuestions}
              removeQuestion={this.removeQuestion}
              saveOptionQuestion={this.props.saveOptionQuestion}
              setComponentsToState={this.setComponentsToState}
            />
          </div>
        );
      case 'quiz':
        return (
          <div style={{display:'flex', marginTop:'50px'}}>
            <div style={{height:'25px',cursor:'pointer'}}>
              <DragHandle/>
            </div>
            <QuizModule
              key={element.id}
              element={element}
              editPostFormId={this.props.editpostform.id}
              style={style}
              contaOptions={this.contaOptionsV2}
              contaQuestions={this.contaQuestions}
              removeQuestion={this.removeQuestion}
              saveOptionQuestion={this.props.saveOptionQuestion}
            />
          </div>
        );
      case 'open_question':
        return (
          <div style={{display:'flex', marginTop:'50px'}}>
            <div style={{height:'25px',cursor:'pointer'}}>
              <DragHandle/>
            </div>
            <OpenQuestionModule
              key={element.id}
              element={element}
              editPostFormId={this.props.editpostform.id}
              style={style}
              contaQuestions={this.contaQuestions}
              removeQuestion={this.removeQuestion}
            />
          </div>
        );
      default:
        return (
          <div style={{display:'flex', marginTop:'0'}}>
            <div style={{height:'0',cursor:'pointer', visibility:'hidden'}}>
              <DragHandle/>
            </div>
          </div>
        )
    }
  });

  arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };

  arrayMove = (array, from, to) => {
    array = array.slice();
    this.arrayMoveMutate(array, from, to);

    return array;
  };

  onSortEnd = ({oldIndex,newIndex}) => {

    const array_move = this.arrayMove(
      this.state.elements,
      oldIndex,
      newIndex
    );

    this.setState({elements:array_move});

    const contents_position = [];
    let queue = 0;
    array_move.map(content => {
      contents_position.push({canvas_content_id: content.id, queue})
      queue++;
    })
    const contents_position_object = {
      contents_position
    }
    this.props.sortContent(this.props.editpostform.id,contents_position_object);
    
  };

  render() {
    const {
      editpostform: { showScheduleModal },
      title
    } = this.props;
    if (this.props.editpostform.elements !== undefined) {
      return (
        <MuiThemeProvider>
          <ComponentHeader
            enableNotification
            enablesubmit={( title === "" || (title !== undefined && title.length > 190)) || this.props.progressIndeterminate}
            description={this.props.load_status.message}
            onClick={this.props.canvasPublish.bind(
              this,
              this.props.editpostform.id,
              this.props.send_push
            )}
            tooltip={
              <p>
                Atualizar publicação
                <br />
              </p>
            }
            actions={[
              {
                name: 'Agendar publicação',
                action: this.props.showScheduleModalForTrue
              }
            ]}
            name="Publicações"
            nameaction="Publicar"
          />
          <div className="container body-canvas-edit ">
            {this.state.showinitialcomponente === false ? (
              <div>
                {this.props.isDrawType && (
                  <div className="container-draw">
                    <ul className="draw-details-list">
                      <li>
                        <div
                          className={'menu-item-details active'}
                          onClick={() => this.props.openDrawModal()}
                        >
                          Configurações do sorteio
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {this.state.detailsPostDraw === 'Post' && (
                  <div>
                    <HeaderCanvasEdit
                      showComponents={this.state.showComponents}
                      fixedMenu={this.state.fixedMenu}
                      createpost={this.props.editpostform}
                      tags={this.props.tags}
                      InsertComponentLink={this.InsertComponentLink}
                      InsertComponentCarousel={this.InsertComponentCarousel}
                      InsertComponentVideo={this.InsertComponentVideo}
                      InsertComponentText={this.InsertComponentText}
                      InsertComponentImage={this.InsertComponentImage}
                      alert={this.props.alert}
                      showDialogUploadFile={this.props.showDialogUploadFile}
                      closeDialogUploadFile={this.props.closeDialogUploadFile}
                      isDrawType={this.props.isDrawType}
                    />

                    <div className="row form-edit-canvas">
                      <div className="col-md-7">
                          <Field
                            onChange={(event) =>
                              this.props.editName(this.props.editpostform.id, {
                                title: event.target.value
                              })
                            }
                            name="title"
                            rows={1}
                            validate={[required, maxLength190]}
                            hintText="Editar titulo"
                            id="name"
                            label="Dê um título para a publicação"
                            component={TextFieldTitle}
                            type="text"
                            onBlur={this.handleOnBlur}
                          />
                          <p className='view-characters-description'>
                            {this.props.title &&
                              this.props.title.length > 0 ? 
                                this.props.title.length <=190 ?
                                  (`${190 -
                                    this.props.title.length} caracteres restantes.`)
                                  : (
                                    <span className='text-red'>
                                      Quantidade de caracteres acima do permitido.
                                    </span> 
                                  )
                              :(
                                <span className='text-red'>
                                    O título não pode ser vazio.
                                </span>
                              )}
                          </p>
                      </div>
                      <div className="col-md-1">
                        {!showScheduleModal && (
                          <EmojiPicker
                            element="textarea#name"
                            propToChange="title"
                            parentProps={this.props}
                            cursorPosition={this.state.selectionStart}
                          />
                        )}
                      </div>
                      <PreviewCreateCanvas
                        creatpost={this.props.creatpost}
                        elements={this.state.elements}
                        title={this.props.title}
                      />
                    </div>
                    {/post\/form\/edit/.test(window.location.href) ||
                    /post\/form\/create/.test(window.location.href) ? (
                      <FormComponentsButtons
                        InsertContentQuestion={this.InsertContentQuestion}
                      />
                    ) : null}
                  </div>
                )}
              </div>
            ) : (
              undefined
            )}
            <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
              {this.state.elements.map((element, index) => (
                <this.SortableItem index={index} element={element} />
              ))}
            </SortableContainer>
              {/* {this.state.elements.map((element) => {
                
              })} */}
            {this.state.showinitialcomponente === true ? (
              <SelectComponentInitial
                InsertComponentLink={this.InsertComponentLink}
                InsertComponentCarousel={this.InsertComponentCarousel}
                closeDialogUploadFile={this.props.closeDialogUploadFile}
                showDialogUploadFile={this.props.showDialogUploadFile}
                alert={this.props.alert}
                InsertComponentVideo={this.InsertComponentVideo}
                InsertComponentImage={this.InsertComponentImage}
                ShowComponentText={this.InsertComponentText}
              />
            ) : (
              undefined
            )}
            {this.state.detailsPostDraw === 'Configurações' && (
              <Sweepstakes idPublish={this.props.editpostform.id} />
            )}
          </div>
          <div ref={(ref) => (this.body = ref)} />
          <DialogProgressCircular />
          {showScheduleModal && <ScheduleModal />}
          <DrawModal />
          <ProgressToast progress={this.props.patchingPost} />
        </MuiThemeProvider>
      );
    } else {
      browserHistory.push('/post/publication');
      return null;
    }
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'editcanvas'
})(CanvasEdit);

const mapDispatchToProps = (dispatch) => {
  return {
    editPost: (id) => {
      dispatch(PostApi.editPost(id));
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    showDialogUploadFile: (kind) => {
      dispatch({
        type: 'SHOW_DIALOG_FILE',
        alert: { visible: true, kind: kind }
      });
    },
    closeDialogUploadFile: () => {
      dispatch({ type: 'CLOSE_DIALOG_FILE', alert: { visible: false } });
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    setPublication: (kind, value) =>
      dispatch({ type: 'formPost', kind: kind, value: value }),
    saveContentText: async (id, value) => {
      await dispatch(PostApiAsync.saveContentText(id, value));
    },
    saveContentEdit: (id, values, idpatch) => {
      dispatch(PostApi.saveContentEdit(id, values, idpatch));
    },
    saveContentImage: async (id, values, kind) => {
      const response = await dispatch(PostApiAsync.saveContentImage(id, values, kind));
      return response;
    },
    saveContentVideo: async (id, value) => {
      const response = await dispatch(PostApiAsync.saveContentVideo(id, value));
      return response;
    },
    saveContentVideoV2: async (id, value, teamName) => {
      const response = await dispatch(PostApiAsync.saveContentVideoV2(id, value, teamName));
      return response;
    },
    saveContentCarousel: async (id, value) => {
      await dispatch(PostApiAsync.saveContentCarousel(id, value));
    },
    saveContentLink: async (id, link, title) => {
      await dispatch(PostApiAsync.saveContentLink(id, link, title));
    },
    saveContentEditOptions: async (id, value, idContent, IdOption, type) => {
      await dispatch(
        FormApiAsync.saveContentEditOptions(
          id,
          value,
          idContent,
          IdOption,
          type
        )
      );
    },
    saveContentEditQuestions: async (id, values, idpatch, type) => {
      await dispatch(
        FormApiAsync.saveContentEditQuestions(id, values, idpatch, type)
      );
    },
    saveContentQuestion: async (id, value, edit, type) => {
      await dispatch(FormApiAsync.saveContentQuestion(id, value, edit, type));
    },
    saveOptionQuestion: async (idPublish, idContent) => {
      await dispatch(PostApi.saveOptionQuestion(idPublish, idContent));
    },
    deleteOptionQuestion: (idcontent, idOption, type) => {
      dispatch(PostApi.deleteOptionQuestion(idcontent, idOption, type));
    },
    deleteQuestionContent: (idPublish, idContent, type) => {
      dispatch(PostApi.deleteQuestionContent(idPublish, idContent, type));
    },
    canvasPublish: (id, send_push) => {
      dispatch(PostApi.canvasPublish(id, send_push));
    },
    editName: (id, value) => {
      dispatch(PostApi.canvasUpdate(id, value));
    },
    openDrawModal: () => {
      dispatch({ type: 'DRAW_MODAL_OPEN' });
    },
    showScheduleModalForTrue: () => {
      dispatch({ type: 'SCHEDULE_MODAL_SHOW' });
    },
    sortContent: (id_post,contents_position)=>{
      dispatch(PostApi.sortContent(id_post,contents_position))
    }
  };
};
const selector = formValueSelector('editcanvas');
InitializeFromStateForm = connect(
  (state) => ({
    alert: state.dialog,
    title: selector(state, 'title'),
    text: selector(state, 'text'),
    question_short: selector(state, 'question_short'),
    only: selector(state, 'only'),
    tags: state.tagsparsed,
    creatpost: state.createpost,
    edit_post: state.editpost,
    initialValues: state.editpostform,
    isPristine: isPristine('editcanvas')(state),
    editpostform: state.editpostform,
    load_status: state.loadstatus,
    showModal: state.show_modal_birthday,
    isDrawType: state.draw.isDrawType,
    progressIndeterminate: state.progress_indeterminate,
    patchingPost: state.editpostform.patchingPost,
    send_push: state.editpostform.send_push
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
