import React, { Component } from 'react';
import UserListDash from './UserListDash';
import UserSpeaker from './UserSpeaker';
import UserDevice from './UserDevice';
import HeaderUser from '../HeaderInteraction/HeaderUser';
import teamSettings from '../../../utils/TeamsSettings/generalSettings';
require('./userspeaker.css');

const { hasUsersEngagement } = teamSettings();

class WarpUserDash extends Component {
  render() {
    return (
      <div>
        <HeaderUser users_value={this.props.users_value} />
        {hasUsersEngagement && (
          <div className="display-row-home-dash">
            <UserListDash
              label="Usuários mais engajados"
              user_list={this.props.user_with_interaction}
            />
            <UserListDash
              label="Usuários menos engajados"
              user_list={this.props.user_no_interaction}
            />
          </div>
        )}
        <div className="display-row-home-dash">
          {this.props.user_speaker && (
            <UserSpeaker user={this.props.user_speaker} />
          )}
          <UserDevice users_devices={this.props.users_devices} />
        </div>
      </div>
    );
  }
}
export default WarpUserDash;
