import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { style } from '../../../style';
import HistoricalApi from '../../../Logicas/HistoricalApi';

import { BodyTable } from '../../utils/BodyTable';
import { FooterPagination } from '../../utils/Pagination';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableData,
  TableHeadRow
} from '../../utils/Table';

import IcoPontos from '../../utils/img/ic-pontos.svg';
import MoreIcon from '../../utils/img/more-icon.svg';
import not_img from '../../utils/img/ico-external.png';

class ComponentListPublish extends Component {
  redirectUserDetails = (id, publishTitle, publishImage) => {
    browserHistory.push('/historical/publishDetails');
    this.props.getPublishDetailsHistory(id, publishTitle, publishImage);
  };
  render() {
    const teamName = style.CurrencyName.name.split('/');
    return (
      <BodyTable>
        <Table>
          <TableHead>
            <TableHeadRow>Publicação</TableHeadRow>
            <TableHeadRow>{teamName[1]} / Curtidas</TableHeadRow>
            <TableHeadRow>{teamName[1]} / Comentários</TableHeadRow>
            <TableHeadRow>{teamName[1]} / Visualização</TableHeadRow>
            <TableHeadRow>{teamName[1]} (Totais)</TableHeadRow>
            <TableHeadRow></TableHeadRow>
          </TableHead>

          <TableBody>
            {this.props.listPublish.canvas_publications &&
              this.props.listPublish.canvas_publications.map(
                (listPublish, index) => {
                  return (
                    <TableRow className="content-list-history" key={index}>
                      <TableData>
                        <img
                          id="img-history-publish"
                          src={listPublish.image ? listPublish.image : not_img}
                          alt="Capa da Publicação"
                        />
                        {listPublish.title && listPublish.title.length > 40
                          ? `${listPublish.title.slice(0, 40)}...`
                          : listPublish.title}
                      </TableData>
                      <TableData>
                        <div className="text-color-historical">
                          {listPublish.score_by_likes.toLocaleString("pt-BR")}
                        </div>
                      </TableData>
                      <TableData>
                        <div className="text-color-historical">
                          {listPublish.score_by_comments.toLocaleString("pt-BR")}
                        </div>
                      </TableData>
                      <TableData>
                        <div className="text-color-historical">
                          {listPublish.score_by_visualizations.toLocaleString("pt-BR")}
                        </div>
                      </TableData>
                      <TableData>
                        <div className="text-color-historical text-edit-historical-img">
                          <strong>{listPublish.total_score.toLocaleString("pt-BR")}</strong>
                          <img src={IcoPontos} alt="moeda de pontos" />
                        </div>
                      </TableData>
                      <TableData>
                        <div className="text-color-historical">
                          <img
                            src={MoreIcon}
                            alt="ver historico dessa publicação"
                            onClick={() =>
                              this.redirectUserDetails(
                                listPublish.id,
                                listPublish.title,
                                listPublish.image
                              )
                            }
                          />
                        </div>
                      </TableData>
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
        {this.props.listPublish.size &&
          this.props.listPublish.size[1] > 0 &&
          this.props.listPublish.canvas_publications && (
            <FooterPagination
              activePage={this.props.pageActive}
              totalItemsCount={this.props.listPublish.size[0]}
              handlePageChange={this.props.getListPublishHistory.bind(
                this,
                this.props.filterTitle,
                this.props.startDate,
                this.props.endDate
              )}
            />
          )}
      </BodyTable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageActive: state.historical_points.pageActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListPublishHistory: (title, startDate, endDate, page) => {
      dispatch(
        HistoricalApi.getListPublishHistory(page, title, startDate, endDate)
      );
    },
    getPublishDetailsHistory: (id, publishTitle, publishImage) => {
      dispatch(
        HistoricalApi.getPublishDetailsHistory(id, publishTitle, publishImage)
      );
    }
  };
};

const ComponentListPublishContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentListPublish);

export default ComponentListPublishContainer;
