import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Jump from 'react-reveal/Jump';
import { DialogAlert } from '../utils/DailogComponentes';
import { Field, reduxForm } from 'redux-form';
import { TextFieldDefault, ButtonLogin } from '../utils/FormsComponents';
import { required } from '../validation/FormValidation';
import UserApi from '../../Logicas/UserApi';
import { connect } from 'react-redux';
import DialogApi from '../../Logicas/DialogApi';

require('./login.css');

class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {};
    this.loginUser = this.loginUser.bind(this);
  }

  loginUser(values) {
    this.setState({ open: true, errorequest: 'Enviando.....' });
    this.props.loginUser(values);
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <Jump>
        <body style={{ background: '#ffffff' }} className="container">
          <MuiThemeProvider>
            <form onSubmit={handleSubmit(this.loginUser)}>
              <div className="row">
                <div className="col-md-3 "></div>
                <div className="col-md-offset-6 col-md-5 form-group form-login">
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-4">
                      <img
                        style={{ width: '170px', height: '190px' }}
                        src="http://appfactorbr.com/images/Logotipo-vertical-preto.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <Field
                        name="email_user"
                        validate={[required]}
                        id="nameuser"
                        label="E-mail"
                        component={TextFieldDefault}
                        type="text"
                      />
                    </div>
                    <div className="col-md-1"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <ButtonLogin label="Enviar" />
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                </div>
              </div>
            </form>
            {this.props.erroalert != undefined ? (
              <DialogAlert
                closeDialog={this.props.closeDialogErro.bind(this)}
                message={this.props.erroalert.message}
                visible={this.props.erroalert.visible}
              />
            ) : (
              undefined
            )}
          </MuiThemeProvider>
        </body>
      </Jump>
    );
  }
}
ForgetPassword = reduxForm({
  form: 'user_session' // a unique name for this form
})(ForgetPassword);

const mapStateToProps = (state) => {
  return {
    user_login: state.crudUser,
    erroalert: state.erroalert
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (value) => {
      dispatch(UserApi.sendEmailNewPassword(value));
    },
    closeDialogErro: () => {
      dispatch(DialogApi.closeDialogErro());
    }
  };
};
const ForgetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPassword);

export default ForgetPasswordContainer;
