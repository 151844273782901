import styled from 'styled-components';
import { style as profile } from '../../style';

export const BodyPreviewEvent = styled.div`
  margin-top: 4%;
  height: 500px;
  width: 30%;
  border-top: 5px solid ${profile.inpuStyle.colorInput};
  border-radius: 8px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.17);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
  overflow-y: scroll;
`;
