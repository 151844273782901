export function alert_erro_geral(
  state = {
    visible: false,
    message: '',
    submessage: '',
    okFunction: null,
    fileList: false
  },
  action
) {
  if (action.type === 'OPEN_ERROR') {
    return action.alert;
  }
  if (action.type === 'CLOSE_ERROR') {
    return action.alert;
  }
  if (action.type === 'OPEN_SUCCESS') {
    return action.alert;
  }

  return state;
}
