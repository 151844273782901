import React, { Component } from 'react';

import IconCheck from '../../utils/img/ic-check.png';
import IconClock from '../../utils/img/ic-clock.png'

import * as S from './ProgressToast.styles';

export class ProgressToast extends Component {
  render() {
    const { progress, statusRequest } = this.props;

    const returnImageToast = {
      'Salvando...': IconClock,
      'Alterações salvas em RASCUNHOS': IconCheck,
      'Alterações salvas': IconCheck,
    }

    return (
      <S.ToastBody progress={progress || statusRequest}>
        {statusRequest ?
          <>
            <img 
              src={returnImageToast[statusRequest]}
              style={{marginRight: '10px', marginBottom: '3px'}}
            />
            <S.ToastMessage>
              {statusRequest}
            </S.ToastMessage>
          </>
          : <S.ToastMessage>Salvando alterações...</S.ToastMessage>
        }
      </S.ToastBody>
    );
  }
}

export default ProgressToast;
