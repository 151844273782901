import React, { Component } from 'react';
import { DialogProgressIndeterminated } from '../../../../utils/DailogComponentes';
// import { Field, reduxForm } from 'redux-form';
// import { connect } from 'react-redux';
// import { required } from '../../../../validation/FormValidation';
// import {
//   LongTextLink,
//   TextFieldDefault
// } from '../../../../utils/FormsComponents';

import IcoRemove from '../../../../utils/img/ic-excluir.svg';
import CardContentLink from '../../../../utils/img/card-content-link.svg';

import '../CourseLongStyles.css';

export class LinkModule extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      title: "",
      linkcontent:"",
    };

    this.changeLink = this.changeLink.bind(this);
    this.changeTitle = this.changeTitle.bind(this);
    this.saveLinkContent = this.saveLinkContent.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: this.props.loading.loadingCourse, linkcontent:this.props.content.linkcontent, title: this.props.content.title});
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      loading: nextProps.loading.loadingCourse
    });
  }
  
  changeTitle(value){
    this.setState({title:value});
  }
  changeLink(value){
    this.setState({linkcontent:value});
  }

  saveLinkContent(title,linkcontent){
    this.props.patchLinkContent(this.props.index, this.props.indexContent, title,linkcontent);
  }
  render() {
    return (
      <div className="container-content-submodule">
        <div className="loading-content">
          {this.props.loading.loadingCourse &&
            this.props.loading.indexContent === this.props.content.id && (
              <DialogProgressIndeterminated />
            )}
        </div>
        <div className="container-content-submodule-quiz">
            <div className="header-content-submodule">
                <div className="header-title-img">
                    <img src={CardContentLink} alt="Componente de Link" />
                    <div className="header-content-description">
                        <p>CARD LINK</p>
                    </div>
                </div>
                <div className="removed-submodule">
                    <img
                        src={IcoRemove}
                        alt="Remover Conteudo"
                        onClick={() =>
                        this.props.removedSubModuleAndContent(
                            this.props.content.id,
                            'content'
                        )
                        }
                    />
                </div>
            </div>
            <div className='content-submodule-link'>
              <div className="row">
                <span className="description-link">
                  {this.state.title !== null && this.state.title.length > 0 ? 'Botão' : ''}
                </span>
                <div className="col-md-12">
                  <input
                    name="title"
                    id="title"
                    className="input-titleLink"
                    placeholder='Botão'
                    type="text"
                    style={{width:"100%"}}
                    required
                    value={this.state.title}
                    onChange={(e)=>{
                      this.changeTitle(e.target.value);
                      this.saveLinkContent(e.target.value,this.state.linkcontent);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <span className="description-link">
                  {this.state.linkcontent !== null && this.state.linkcontent.length > 0 ? 'Link' : ''}
                </span>
                <div className="col-md-12">
                  <input
                    name="linkcontent"
                    id="linkcontent"
                    className="input-linkContent"
                    placeholder='Link'
                    type="text"
                    style={{ width: '100%' }}
                    required
                    value={this.state.linkcontent}
                    onChange={(e)=>{
                      this.changeLink(e.target.value);
                      this.saveLinkContent(this.state.title,e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

// let InitializeFromStateForm = reduxForm({
//   form: `linkModule/${Math.random()}`
// })(LinkModule);

// InitializeFromStateForm = connect(
//   (state, props) => ({
//     initialValues: props.content,
//   })
// )(InitializeFromStateForm);

// export default InitializeFromStateForm;
export default LinkModule;
