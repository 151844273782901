import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { style } from '../../../style';
import HistoricalApi from '../../../Logicas/HistoricalApi';

import { BodyTable } from '../../utils/BodyTable';
import { FooterPagination } from '../../utils/Pagination';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableData,
  TableHeadRow
} from '../../utils/Table';

import not_img from '../../utils/img/ico-external.png';

class ComponentListMerit extends Component {
  render() {
    const teamName = style.CurrencyName.name.split('/');
    return (
      <BodyTable>
        <Table>
          <TableHead>
            <TableHeadRow>Nome</TableHeadRow>
            <TableHeadRow>Motivo do merit</TableHeadRow>
            <TableHeadRow>{teamName[1]}</TableHeadRow>
            <TableHeadRow>Data de pontuação</TableHeadRow>
          </TableHead>

          <TableBody>
            {this.props.listMerit.manual_scoring &&
              this.props.listMerit.manual_scoring.map((listMerit, index) => (
                <TableRow className="content-list-history" key={index}>
                  <TableData>
                    <img
                      src={listMerit.image.url ? listMerit.image.url : not_img}
                      style={{
                        maxWidth:'35px',
                        maxHeight:'35px',
                      }}
                    />
                    {listMerit.name}
                  </TableData>
                  <TableData>
                    {listMerit.justification &&
                    listMerit.justification.length > 90
                      ? `${listMerit.justification.slice(0, 90)}...`
                      : listMerit.justification}
                  </TableData>
                  <TableData>
                    <div className="text-color-historical">
                      {listMerit.score.toLocaleString("pt-BR")}
                    </div>
                  </TableData>
                  <TableData>
                    {moment
                      .tz(listMerit.created_at, 'Europe/Berlin')
                      .format('DD/MM/YYYY')}
                  </TableData>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {this.props.listMerit.size &&
          this.props.listMerit.size[1] > 0 &&
          this.props.listMerit.manual_scoring && (
            <FooterPagination
              activePage={this.props.pageActive}
              totalItemsCount={this.props.listMerit.size[0]}
              handlePageChange={this.props.getListMeritHistory.bind(
                this,
                this.props.filterName,
                this.props.startDate,
                this.props.endDate
              )}
            />
          )}
      </BodyTable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageActive: state.historical_points.pageActive
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListMeritHistory: (name, startDate, endDate, page) => {
      dispatch(
        HistoricalApi.getListMeritHistory(page, startDate, endDate, name)
      );
    }
  };
};

const ComponentListMeritContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentListMerit);

export default ComponentListMeritContainer;
