import React, { Component } from 'react';
import ButtonDefault, {
  LongTextField,
  DropZoneImage
} from '../../utils/FormsComponents';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../validation/FormValidation';
import { MuiThemeProvider } from 'material-ui/styles';
import ContentApi from '../../../Logicas/ContentApi';
import PreviewContentImage from '../../previews/PreviewContentImage';
import { BodyContent } from '../../utils/BodyContent';

class EditContentImage extends Component {
  constructor() {
    super();
    this.state = {};
    this.saveContentImage = this.saveContentImage.bind(this);
    this.showDragArea = this.showDragArea.bind(this);
    this.closeDragArea = this.closeDragArea.bind(this);
  }

  componentDidMount() {
    this.props.enableBtn();
  }

  saveContentText(values) {
    this.props.saveContent(values, this.props.idmodule);
  }
  showDragArea() {
    this.setState({ drag_area: true });
    setTimeout(() => {
      this.setState({ drag_area: false });
    }, 2000);
  }

  closeDragArea() {
    this.setState({ drag_area: false });
  }
  saveContentImage(values) {
    this.props.saveContent(values, this.props.idmodule);
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    if (this.state.drag_area !== true) {
      return (
        <MuiThemeProvider>
          <form
            onDragOver={this.showDragArea}
            onDragOver={this.showDragArea}
            onMouseMove={this.show}
            onSubmit={handleSubmit(this.saveContentImage)}
          >
            <BodyContent drag_area={this.state.drag_area}>
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="title"
                    rows={1}
                    validate={[required]}
                    id="name"
                    label="Titulo*"
                    component={LongTextField}
                    type="text"
                  />
                </div>
                <div className="col-md-4">
                  <PreviewContentImage
                    image={this.props.initialValues.image}
                    title={this.props.initialValues.title}
                  />
                </div>
              </div>
            </BodyContent>
            <ButtonDefault label="Salvar" />
          </form>
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider>
          <form
            onDragOver={this.showDragArea}
            onDragOver={this.showDragArea}
            onMouseMove={this.show}
            onSubmit={handleSubmit(this.saveContentImage)}
          >
            <Field
              name="image"
              validate={[required]}
              id="name"
              label="Titulo da publicação*"
              component={DropZoneImage}
              type="text"
            />
          </form>
        </MuiThemeProvider>
      );
    }
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'dsdsds'
})(EditContentImage);

const mapDispatchToProps = (dispatch) => {
  return {
    saveContent: (values, idmodule) => {
      dispatch(ContentApi.saveContentImage(values, idmodule));
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    }
  };
};

InitializeFromStateForm = connect(
  (state, props) => ({
    alert: state.dialog,
    initialValues: props.content,
    form: 'form' + '/' + props.content.id
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
