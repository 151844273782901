import xlsx from 'xlsx';
import moment from 'moment';

export const generateUserReport = (userDetails) => {
  const { DetailsHistory, name } = userDetails;

  let detailsHistoryTranslated = [];
  DetailsHistory.forEach((item) => {
    item.registered_at = moment(item.registered_at).format('L [às] LT');

    const {
      title: titulo,
      score: pontos,
      justification: motivo,
      registered_at: data
    } = item;

    const newItem = Object.assign({}, { titulo, pontos, motivo, data });
    detailsHistoryTranslated.push(newItem);
  });

  const content = xlsx.utils.json_to_sheet(detailsHistoryTranslated);
  const workBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workBook, content, 'historico de nozes');

  xlsx.writeFile(
    workBook,
    `historico-nozes__${name.replace(/ /g, '-')}__${moment().format('L')}.xlsx`
  );
};

export const generatePublishReport = (publishDetails) => {
  const { interactions } = publishDetails;

  let publishDetailsTranslated = [];
  interactions.forEach((item) => {
    item.created_at = moment(item.created_at).format('L [às] LT');
    const {
      name: nome,
      score: pontos,
      kind: nozes_premiadas,
      created_at: data
    } = item;

    const newItem = Object.assign({}, { nome, pontos, nozes_premiadas, data });
    publishDetailsTranslated.push(newItem);
  });

  const content = xlsx.utils.json_to_sheet(publishDetailsTranslated);
  const workBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workBook, content, 'historico de nozes');

  xlsx.writeFile(workBook, `historico-nozes__${moment().format('L')}.xlsx`);
};

export const generatePublicationsSpreadsheet = (publicationsDetails) => {
  const parsedPublicationsDetails = publicationsDetails.map((item) => ({
    Título: item.title,
    Autor: item.user.name,
    Visualizações: item.visualization,
    Curtidas: item.likes,
    Comentários: item.size_comments,
    'Data de publicação':
      item.published_at !== null
        ? moment(item.published_at).format('L [às] LT')
        : 'Publicação em rascunho',
    Tags: item.tags.map((tag) => tag.name).join()
  }));

  const content = xlsx.utils.json_to_sheet(parsedPublicationsDetails);
  const workBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workBook, content, 'historico de publicacoes');

  xlsx.writeFile(
    workBook,
    `historico-publicacoes__${moment().format('L')}.xlsx`
  );
};

export const generateStoreRequestsSpreadsheet = (requestList) => {
  const productNotDespatched = requestList.productNotDespatched.map((item) => ({
    'Nome do produto': item.product.name,
    'Usuário solicitante': item.user.name,
    'Data da solicitação': moment(item.created_at).format('L [às] LT')
  }));

  const despatchedProduct = requestList.despatchedProduct.map((item) => ({
    'Nome do produto': item.product.name,
    'Usuário solicitante': item.user.name,
    'Data da solicitação': moment(item.created_at).format('L [às] LT')
  }));

  const productNotDespatchedSheet = xlsx.utils.json_to_sheet(
    productNotDespatched
  );
  const despatchedProductSheet = xlsx.utils.json_to_sheet(despatchedProduct);

  const workBook = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(
    workBook,
    productNotDespatchedSheet,
    'Pedidos aguardando confirmação'
  );
  xlsx.utils.book_append_sheet(
    workBook,
    despatchedProductSheet,
    'Pedidos confirmados'
  );

  xlsx.writeFile(
    workBook,
    `historico-solicitacoes__${moment().format('L')}.xlsx`
  );
};

export const generateCourseProgressReport = (courseDetails) => {
  const {
    users_not_finish,
    users_finished,
    users_not_started,
    title: courseTitle,
    module_courses
  } = courseDetails;

  const usersFinished = users_finished.map((userData) => ({
    Usuário: userData.user.name,
    'Data de conclusão': moment(userData.conclusion_date).format('L')
  }));

  const usersInProgress = users_not_finish.map((userData) => ({
    Usuário: userData.user.name,
    'Módulos concluídos': `${userData.finished_modules.length} de ${module_courses.length}`
  }));

  const userNotStarted = users_not_started.map((userData) => ({
    Usuário: userData.user.name
  }));

  const usersFinishedSheet = xlsx.utils.json_to_sheet(usersFinished);
  const usersInProgressSheet = xlsx.utils.json_to_sheet(usersInProgress);
  const userNotStartedSheet = xlsx.utils.json_to_sheet(userNotStarted);

  const workBook = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(
    workBook,
    usersFinishedSheet,
    'Concluíram o curso'
  );
  xlsx.utils.book_append_sheet(
    workBook,
    usersInProgressSheet,
    'Fazendo o curso'
  );
  xlsx.utils.book_append_sheet(
    workBook,
    userNotStartedSheet,
    'Não começaram o curso'
  );

  xlsx.writeFile(
    workBook,
    `${courseTitle
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/ /g, '-')}__progresso-usuarios.xlsx`
  );
};
