import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { reduxForm, change, formValueSelector, Field } from 'redux-form';
import Modal from 'react-awesome-modal';

import { TextFieldForm } from '../../../utils_form/FormComponents';
import { TextFieldDefault } from '../../../utils/FormsComponents';
import {
  required,
  email,
  minValue0,
  maxLength190
} from '../../../validation/FormValidation';

import HistoricalApi from '../../../../Logicas/HistoricalApi';

import DashboardButton from '../../../dashbord/dashboard_components/PublicationDash/Components/DashboardButton';
import ToolsButton from '../../../Tools/ToolsList/Components/ToolsButton/ToolsButton';

import IconDelete from '../../../utils/img/ic-delete.png';
import IcoExit from '../../../utils/img/exit-dialog.png';

import * as S from './DialogPontuationUsers.styles';
import { style } from '../../../../style';
import { getMuiTheme, MuiThemeProvider } from 'material-ui/styles';
import UserApi from '../../../../Logicas/UserApi';

const muiTheme = getMuiTheme({
  datePicker: {
    selectColor: style.inpuStyle.colorInput,
    headerColor: style.inpuStyle.colorInput
  },
  flatButton: { primaryTextColor: style.inpuStyle.colorInput }
});

export class DialogPontuationUsers extends Component {
  constructor() {
    super();
    this.state = {
      file: null,
      minDate: new Date('2018-01-01T00:00:00'),
      startDateUsageReport: null,
      endDateUsageReport: null
    };
    this.sendReport = this.sendReport.bind(this);
  }

  validFile(file) {
    switch (file.type) {
      case '.xls':
        return true;

      case '.xlsx':
        return true;

      case 'application/vnd.ms-excel':
        return true;

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return true;

      case 'application/vnd.oasis.opendocument.spreadsheet':
        return true;

      case 'application/vnd.ms-excel.sheet.macroenabled.12':
        return true;

      case '.application/vnd.ms-excel.sheet.macroenabled.12':
        return true;

      default:
        return false;
    }
  }

  userCanBePontuated = () => {
    if (
      !this.state.file ||
      !this.props.score ||
      !this.props.justification ||
      !this.props.email
    )
      return false;
    return true;
  };

  sendReport() {
    const { sendScoreViaSpreadsheet, justification, score, email } = this.props;
    const { file } = this.state;

    sendScoreViaSpreadsheet(
      file,
      score.replaceAll('.', ''),
      justification,
      email
    );
  }

  zeroScore = (target) => {
    if (target.nodeName === 'IMG') {
      this.props.dispatch(
        change('dialogPontuationUsersForm', 'score_users', '')
      );
    }
  };

  zeroJustification = (target) => {
    if (target.nodeName === 'IMG') {
      this.props.dispatch(
        change('dialogPontuationUsersForm', 'justification', '')
      );
    }
  };

  zeroEmail = (target) => {
    if (target.nodeName === 'IMG') {
      this.props.dispatch(change('dialogPontuationUsersForm', 'email', ''));
    }
  };

  clearOnCloseDialogButton = () => {
    this.props.dispatch(change('dialogPontuationUsersForm', 'score_users', ''));
    this.props.dispatch(
      change('dialogPontuationUsersForm', 'justification', '')
    );
    this.props.dispatch(change('dialogPontuationUsersForm', 'email', ''));
    this.setState({ file: null });
  };

  render() {
    const { showDialog, closeDialog, apiMessage, isLoading } = this.props;
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div
          className="react-awesome-modal"
          style={{
            visibility: showDialog ? 'visible' : 'hidden'
          }}
        >
          {!!apiMessage ? (
            <Modal
              effect="fadeInUp"
              width="420px"
              height="300px"
              visible={showDialog}
            >
              <S.ContainerDialogPontuationUsers>
                <S.ContainerTitle style={{ padding: '4rem 0 0' }}>
                  <h1>Pontuação concluída</h1>
                  <S.CloseButton
                    style={{ top: '5rem' }}
                    src={IcoExit}
                    onClick={() => {
                      this.clearOnCloseDialogButton();
                      closeDialog();
                    }}
                  />
                </S.ContainerTitle>

                <S.ContainerMessage>{apiMessage}</S.ContainerMessage>

                <S.ContainerButton>
                  <ToolsButton
                    label="Voltar"
                    onClick={() => {
                      this.clearOnCloseDialogButton();
                      closeDialog();
                    }}
                  />
                </S.ContainerButton>
              </S.ContainerDialogPontuationUsers>
            </Modal>
          ) : (
            <Modal
              effect="fadeInUp"
              width="500px"
              height="620px"
              visible={showDialog}
            >
              <S.ContainerDialogPontuationUsers>
                <S.ContainerTitle>
                  <h1>Pontuar usuários</h1>
                  <S.CloseButton
                    src={IcoExit}
                    onClick={() => {
                      this.clearOnCloseDialogButton();
                      closeDialog();
                    }}
                  />
                </S.ContainerTitle>

                <S.Section>
                  <h3>Planilha *</h3>

                  <Dropzone
                    onDrop={(value) => {
                      if (this.validFile(value[0])) {
                        this.setState({ file: value[0] });
                      } else {
                        this.props.dispatchFormatError();
                      }
                    }}
                    className="drop-zone-input"
                  >
                    <DashboardButton label="Importar" onClick={() => {}} />
                  </Dropzone>
                  <S.ContainerSpreadsheet>
                    <span>
                      {this.state.file && this.state.file.name
                        ? this.state.file.name
                        : 'Nenhum arquivo selecionado'}
                    </span>
                    <S.CloseButton
                      style={{ position: 'initial' }}
                      src={IcoExit}
                      onClick={() => this.setState({ file: null })}
                    />
                  </S.ContainerSpreadsheet>
                </S.Section>

                <S.Section>
                  <h3 style={{ paddingBottom: '0' }}>Dados da pontuação</h3>

                  <S.FieldSection>
                    <div className="cancel-edit" style={{ width: '100%' }}>
                      {this.props.score && this.props.score !== '0' ? (
                        <img
                          style={{
                            marginLeft: '72%',
                            bottom: '5px',
                            zIndex: '3'
                          }}
                          src={IconDelete}
                          alt=""
                          onClick={(e) => this.zeroScore(e.target)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <TextFieldForm
                      name="score_users"
                      rows={1}
                      validate={[required, minValue0]}
                      floatingLabelText="Pontuação*"
                      id="name"
                      type="text"
                      style={{ width: '100%' }}
                      isFormattedNumber
                    />
                  </S.FieldSection>

                  <S.FieldSection>
                    <div className="cancel-edit" style={{ width: '100%' }}>
                      {this.props.justification &&
                      this.props.justification !== '' ? (
                        <img
                          style={{
                            marginLeft: '72%',
                            bottom: '5px',
                            zIndex: '3'
                          }}
                          src={IconDelete}
                          alt=""
                          onClick={(e) => this.zeroJustification(e.target)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <TextFieldForm
                      name="justification"
                      rows={1}
                      validate={[maxLength190]}
                      floatingLabelText="Justificativa*"
                      type="text"
                      style={{ width: '100%' }}
                    />
                  </S.FieldSection>

                  <S.FieldSection>
                    <Field
                      name="responsible"
                      label="Responsável"
                      type="text"
                      style={{ width: '100%' }}
                      component={TextFieldDefault}
                      disabled={true}
                    />
                  </S.FieldSection>
                </S.Section>

                <S.Section style={{ paddingBottom: '0.5rem' }}>
                  <h3 style={{ paddingBottom: '0' }}>Informações</h3>

                  <S.FieldSection>
                    <div className="cancel-edit" style={{ width: '100%' }}>
                      {this.props.email && this.props.email !== '' ? (
                        <img
                          style={{
                            marginLeft: '72%',
                            bottom: '5px',
                            zIndex: '3'
                          }}
                          src={IconDelete}
                          alt=""
                          onClick={(e) => this.zeroEmail(e.target)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <TextFieldForm
                      name="email"
                      validations={[email]}
                      floatingLabelText="E-mail*"
                      type="email"
                      style={{ width: '100%' }}
                    />
                  </S.FieldSection>
                </S.Section>

                <S.Info>
                  Informe um e-mail válido para envio do relatório com
                  informações sobre a pontuação.
                </S.Info>

                <S.ContainerButton>
                  <ToolsButton
                    disabled={!this.userCanBePontuated() || isLoading}
                    isDisabled={!this.userCanBePontuated() || isLoading}
                    label="Pontuar"
                    onClick={!isLoading ? this.sendReport : () => {}}
                  />
                </S.ContainerButton>
              </S.ContainerDialogPontuationUsers>
            </Modal>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

DialogPontuationUsers = reduxForm({
  form: 'dialogPontuationUsersForm',
  enableReinitialize: true
})(DialogPontuationUsers);

const selector = formValueSelector('dialogPontuationUsersForm');

const mapStateToProps = (state) => ({
  showDialog: state.dialog_pontuation_users.visible,
  apiMessage: state.dialog_pontuation_users.apiMessage,
  isLoading: state.dialog_pontuation_users.isLoading,
  score: selector(state, 'score_users'),
  justification: selector(state, 'justification'),
  responsible: selector(state, 'responsible'),
  initialValues: { responsible: localStorage.getItem('UserName') },
  email: selector(state, 'email'),
  startDateUsageReport: selector(state, 'startDateUsageReport'),
  endDateUsageReport: selector(state, 'endDateUsageReport')
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () =>
      dispatch({
        type: 'CLOSE_DIALOG_PONTUATION_USERS'
      }),
    openDialog: () =>
      dispatch({
        type: 'OPEN_DIALOG_PONTUATION_USERS'
      }),
    sendScoreViaSpreadsheet: (file, score, justification, email) =>
      dispatch(
        UserApi.scorePointsToUsersViaSpreadsheet(
          file,
          score,
          justification,
          email
        )
      ),
    dispatchFormatError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: 'Formato de arquivo enviado inválido.'
        }
      });
    }
  };
};

const DialogPontuationUsersForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogPontuationUsers);

export default DialogPontuationUsersForm;
