import React, { Component } from 'react';
import { debounce } from 'lodash';
import ComponentHeader from '../utils/ComponenteHeader';
import { Body } from '../utils/BodyComponente';
import { reduxForm } from 'redux-form';
import { required } from '../validation/FormValidation';
import { connect } from 'react-redux';
import TagApi from '../../Logicas/TagApi';
import RepositorieApi from '../../Logicas/RepositoryApi';
import {
  LongTextFieldTitle,
  AutoCompleteForm
} from '../utils_form/FormComponents';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import { TextField } from 'redux-form-material-ui';

class NewFolder extends Component {
  constructor() {
    super();
    this.state = { tags: [] };
    this.saveFolder = this.saveFolder.bind(this);
    this.searchTagsDebounced = this.searchTagsDebounced.bind(this);
  }

  saveFolder(values) {
    this.props.saveFolder({
      ...values,
      ...(this.props.location.state && {
        parent_id: this.props.location.state.parent_id
      })
    });
  }

  canSearch(page, search) {
    if (
      (page === this.props.paginationTags.current_page &&
        search === this.props.searchTags) ||
      this.props.isLoadingTags
    ) {
      return false;
    }

    return true;
  }

  searchTagsDebounced = debounce((page, search) => {
    this.props.getTags(page, search);
  }, 1000);

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit(this.saveFolder)}>
        <ComponentHeader
          disabled={submitting}
          actions={[]}
          name="Criar pasta"
          nameaction="Salvar"
          onClick={handleSubmit(this.saveFolder)}
        />
        <Body>
          <div className="row">
            <div className="col-md-6">
              <LongTextFieldTitle
                name="name_folder"
                validations={[required]}
                id="name"
                label="Nome da pasta"
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <AutoCompleteForm
                name="tags_folder"
                isLoading={this.props.isLoadingTags}
                validations={[required]}
                label="Tags permitidas*"
                getTagAutoComplete={() => {}}
                infiniteScroll={{
                  searchByRequest: true,
                  loadMore: (page, search) => this.props.getTags(page, search)
                }}
                tags={this.props.listTagName}
              />
            </div>
          </div>
        </Body>
      </form>
    );
  }
}

const NewFolderWithRouter = withRouter(NewFolder);

let InitializeFromStateForm = reduxForm({
  form: 'newfolder'
})(NewFolderWithRouter);

const mapDispatchToProps = (dispatch) => {
  return {
    saveFolder: (values) => {
      dispatch(RepositorieApi.saveFolder(values));
    },
    getTags: async (page, search) => {
      const res = await dispatch(TagApi.getTagsUserV2(page, search));
      return res;
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name))
  };
};
const selector = formValueSelector('newfolder');

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.repositories.data,
    alert: state.dialog,
    tags: state.tagsparsed,
    tags_selected: selector(state, 'tags_folder'),
    listTagName: state.tagsWithPagination.tags,
    paginationTags: state.tagsWithPagination.pagination,
    searchTags: state.tagsWithPagination.search,
    isLoadingTags: state.tagsWithPagination.isLoading
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
