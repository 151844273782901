import styled from 'styled-components';
import { style as teamStyle } from '../../../../style';

const fontFamily = { fontFamily: 'Rubik, sans-serif' };

const defaultTransition = {
  transition: 'all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)'
};

export const ToolsComponentHeader = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
`;

export const ToolsComponentHeaderTitle = styled.div``;

export const ToolsComponentHeaderTitleValue = styled.h1`
  ${fontFamily};
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
  padding: 20px;
`;

export const ToolsComponentHeaderButtons = styled.div`
  .btn-click:hover {
    font-size: inherit;
  }
`;

export const FormContainer = styled.div`
  max-width: 1140px;
  margin: 100px auto 0;
  padding: 48px;
  display: flex;
  flex-direction: column;
`;

export const ToolsEditForm = styled.form``;

export const DropZone = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  ${defaultTransition}

  &::after {
    content: '';
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    ${defaultTransition}
  }

  &:hover {
    transform: scale(1.05, 1.05);
  }

  &:hover::after {
    opacity: 1;
  }

  & .dropZoneImage {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const DropZoneField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & > div {
    border-color: ${teamStyle.inpuStyle.colorInput} !important;
  }
`;

export const DropZoneActions = styled.div`
  margin: 16px 0 8px;
  padding: 0 8px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const DropZoneIcon = styled.img`
  width: 15px;
`;

export const DropZoneText = styled.h5`
  margin: 32px 0 16px 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  color: ${teamStyle.inpuStyle.colorInput};
  text-transform: lowercase;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;
