import React, { Component } from 'react';
import { DropDownSingle } from '../../utils/FormsComponents';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { required } from '../../validation/FormValidation';
import { MuiThemeProvider } from 'material-ui/styles';
import CreateContentText from '../contents/NewContentText';
import CreateContentImage from '../contents/NewContentImage';
import CreateContentVideo from '../contents/NewContentVideo';
import CreateContentQuiz from '../contents/NewContentQuiz';

class CreateNewContent extends Component {
  constructor() {
    super();

    this.saveContent = this.saveContent.bind(this);
  }

  componentDidMount() {
    this.props.enableBtn();
  }

  saveContent(values) {}

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <MuiThemeProvider>
        <form onSubmit={handleSubmit(this.saveContent)}>
          <div className="row">
            <div className="col-md-4">
              <Field
                name="kindcontent"
                array={['Quiz', 'Imagem', 'Texto', 'Video']}
                validate={[required]}
                id="name"
                label="Tipo do conteúdo"
                component={DropDownSingle}
              />
            </div>
          </div>
          {this.props.kind === 'Texto' ? (
            <CreateContentText idmodule={this.props.idmodule} />
          ) : (
            undefined
          )}

          {this.props.kind === 'Imagem' ? (
            <CreateContentImage idmodule={this.props.idmodule} />
          ) : (
            undefined
          )}

          {this.props.kind === 'Video' ? (
            <CreateContentVideo idmodule={this.props.idmodule} />
          ) : (
            undefined
          )}
          {this.props.kind === 'Quiz' ? (
            <CreateContentQuiz idmodule={this.props.idmodule} />
          ) : (
            undefined
          )}
        </form>
      </MuiThemeProvider>
    );
  }
}
let InitializeFromStateForm = reduxForm({
  form: 'createcontent'
})(CreateNewContent);

const mapDispatchToProps = (dispatch) => {
  return {
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    }
  };
};
const selector = formValueSelector('createcontent');

InitializeFromStateForm = connect(
  (state) => ({
    alert: state.dialog,
    kind: selector(state, 'kindcontent')
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
