import styled from "styled-components";

import { style } from '../../style';

export const TableRow = styled.tr`
    font-family: Rubik;
    height: 8rem;
    font-weight: 400;

    &:hover{
        background-color: #F4F6FB;
        cursor: pointer;
    }

    > td.date > div {
        background-color: #F4F6FB;
    }
`

export const TableCol = styled.td`
    font-size:14px;
    font-weight: 400;
    color: #4B4E5C;

    >svg{
        cursor: pointer;
        position: relative;
        left: 1rem;
    }

    &.date{
        padding-right: 15rem;
    }
`

export const DateView = styled.div`
    max-width: 24.5rem;
    padding: 0.5rem 1rem;
    border-radius: 32px;
    margin: 1rem 0 1rem 1rem;

    span {
        font-size: 20px;
    }
`

export const OptionSurveysContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 2.5rem;
`

export const OptionSurvey = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    >img{
        width: 3.5rem;
        height: 3.5rem;
    }

    >span.option-survey{
        color: ${style.inpuStyle.colorInput};
        text-align: center;
        width: 8rem;
    }

    >span{
        width: 50px;
        text-align: center;
    }
`

export const TabContainer = styled.div`
    display: flex;
    align-items: center;
    font-family: Rubik;

    gap: 3rem;
    height: 6rem;
    width: 80%;
    margin: 0 auto -1rem;
`

export const Tab = styled.div`
    color: ${(props) => props.isActive ? style.inpuStyle.colorInput : '#808080'};
    font-weight: 500;
    font-size: 18px;
    border-bottom: 2px solid ${(props) => props.isActive ? style.inpuStyle.colorInput : 'transparent'};
    cursor: ${(props) => props.withoutAction ? 'default' : 'pointer' };

    &:hover{
        border-color: ${(props) => props.withoutAction ? 'transparent' : style.inpuStyle.colorInput };
        color: ${(props) => props.withoutAction ? '#808080' : style.inpuStyle.colorInput };
    }


`
