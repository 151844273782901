import styled from 'styled-components';

export const ContainerDialog = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  height: 100%;
  border-top: 8px solid #2e63fb;
  border-radius: 5px;
`;

export const ContainerTitle = styled.header`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 auto;
  padding: 35px 0 30px;
  gap: 50px;

  h1 {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 2.8rem;
    color: #4b4e5c;
    margin: 0;
  }
`;

export const CloseButton = styled.img`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

export const Section = styled.div`
  width: 80%;
  margin: 0 auto;

  h3 {
    color: #4b4e5c;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 0 0 1rem;

    position: relative;
    z-index: 3;
  }

  .drop-zone-input {
    background-color: transparent;
    border: none;

    button {
      padding: 0;
      margin: 0;
    }
  }
`;

export const ContainerSpreadsheet = styled.div`
  width: 100%;
  height: 3.5rem;

  margin: 25px 0;

  background: #f4f6fb;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  span {
    font-family: 'Rubik';
    font-size: 1.5rem;
    line-height: 2rem;
    color: #868c9b;
  }
`;

export const FieldSection = styled.div`
  &:nth-child(2) {
    margin-top: -1rem;
  }

  .tag_form {
    width: 100%;
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #808080;
  font-family: 'Rubik';
  font-size: 1rem;
  line-height: 3rem;

  z-index: 3;
`;

export const ContainerButton = styled.div`
  padding: 3rem 0 1rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerMessage = styled.div`
  width: 75%;
  text-align: center;

  margin: 15px auto;

  color: #4b4e5c;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 1.5rem;
`;
