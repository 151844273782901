const initialState = {
  visible: false,
  requesting_resend: false,
  users_count: 0,
  resend_success: false
};

export function dialog_resend_welcome(state = initialState, action) {
  if (action.type === 'SHOW_RESEND_WELCOME') {
    return { ...state, visible: true };
  }
  if (action.type === 'UPDATE_USER_COUNT') {
    return { ...state, users_count: action.count };
  }
  if (action.type === 'RESEND_WELCOME_START') {
    return { ...state, requesting_resend: true };
  }
  if (action.type === 'RESEND_WELCOME_SUCCESS') {
    return { ...state, requesting_resend: false, resend_success: true };
  }
  if (action.type === 'CLOSE_RESEND_WELCOME') {
    return {
      ...state,
      visible: false,
      requesting_resend: false,
      resend_success: false
    };
  }
  return state;
}
