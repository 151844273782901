import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  required,
  minValue0,
  maxLength190
} from '../../validation/FormValidation';
import { Field, reduxForm } from 'redux-form';
import { DropZoneImage } from '../../utils/FormsComponents';
import {
  SelectForm,
  TextArea,
  LongTextFieldTitle,
  TextFieldForm,
  AutoCompleteForm
} from '../../utils_form/FormComponents';
import { DialogAlert } from '../../utils/DailogComponentes';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Body } from '../../utils/BodyComponente';
import TagApi from '../../../Logicas/TagApi';
import { formValueSelector } from 'redux-form';
import ComponentHeader from '../../utils/ComponenteHeader';
import CoursePreview from '../../previews/CoursePreview';
import CourseApi from '../../../Logicas/CourseApi';

class EditCourse extends Component {
  constructor() {
    super();
    this.state = { drag_area: false, tags: [] };
    this.showDragArea = this.showDragArea.bind(this);
    this.closeDragArea = this.closeDragArea.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
  }

  componentWillMount() {
    this.props.reloadedCourse(localStorage.getItem('IdCourseEdit'));
    this.props.getTags();
  }
  showDragArea() {
    this.setState({ drag_area: true });
    setTimeout(() => {
      this.setState({ drag_area: false });
    }, 2000);
  }

  closeDragArea() {
    this.setState({ drag_area: false });
  }

  saveCourse(values) {
    this.props.patchCourse(values);
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    if (this.state.drag_area !== true) {
      return (
        <MuiThemeProvider>
          <form
            onDragOver={this.showDragArea}
            onSubmit={handleSubmit(this.saveCourse)}
          >
            <ComponentHeader
              disabled={submitting}
              tooltip={
                <p>
                  Atualizar Course
                  <br />
                </p>
              }
              actions={[]}
              name="Curso"
              nameaction="Salvar"
            />
            <Body drag_area={this.state.drag_area}>
              <div className="row">
                <div className="col-md-8">
                  <LongTextFieldTitle
                    name="title"
                    validations={[required]}
                    id="name"
                    label="Titulo do curso*"
                    type="text"
                  />
                </div>
                <div className="col-md-3">
                  <CoursePreview
                    estimativa={this.props.course_duration}
                    text={this.props.course_description}
                    title={this.props.course_title}
                    img={this.props.img_course}
                    tags={this.props.tags_course}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextArea
                    labelsecondari="Descreva o curso"
                    name="description"
                    rows={3}
                    validations={[required, maxLength190]}
                    id="name"
                    label="Descrição*"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <AutoCompleteForm
                    name="tags_course_form"
                    isLoading={this.props.isLoadingTags}
                    validations={[required]}
                    label="Tags"
                    getTagAutoComplete={this.props.getTagAutoComplete}
                    getAuto={this.props.getTagAutoComplete}
                    tags={this.props.tags}
                  />
                </div>
                <div className="col-md-4">
                  <TextFieldForm
                    name="score"
                    disabled={false}
                    validations={[minValue0, required]}
                    id="nameuser"
                    floatingLabelText="Pontuação *"
                    type="number"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <SelectForm
                    name="level"
                    validations={[required]}
                    multiple={false}
                    id="nameuser"
                    elements_selected={this.props.level_selected}
                    elements={['Fácil', 'Intermediario', 'Avançado']}
                    label="Dificuldade *"
                    type="text"
                  />
                </div>
                <div className="col-md-4">
                  <TextFieldForm
                    name="duration"
                    validations={[required]}
                    id="nameuser"
                    floatingLabelText="Duração *"
                    type="text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <TextFieldForm
                    type="number"
                    name="score_for_certificate"
                    validations={[required, minValue0]}
                    floatingLabelText="Porcentagem minima"
                  />
                </div>
              </div>
            </Body>
          </form>
          <DialogAlert
            onClick={this.props.closeDialog}
            visible={this.props.alert.visible}
            message={this.props.alert.message}
          />
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider>
          <form
            onDragOver={this.showDragArea}
            onDragOver={this.showDragArea}
            onMouseMove={this.show}
            onSubmit={handleSubmit(this.saveCourse)}
          >
            <Field
              name="image"
              validate={[required]}
              id="name"
              label="Titulo da publicação*"
              component={DropZoneImage}
              type="text"
            />
          </form>
        </MuiThemeProvider>
      );
    }
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'editcourse'
})(EditCourse);

const mapDispatchToProps = (dispatch) => {
  return {
    patchCourse: (values) => {
      dispatch(CourseApi.patchCourse(values));
    },
    getTags: () => {
      dispatch(TagApi.getTagsUser());
    },
    getTagAutoComplete: (name) => dispatch(TagApi.getTagAutoComplete(name)),
    reloadedCourse: (id) => {
      dispatch(CourseApi.reloadedCourse(id));
    }
  };
};
const selector = formValueSelector('editcourse');
InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.course.id && state.course,
    alert: state.dialog,
    course_title: selector(state, 'title'),
    isLoadingTags: state.isLoadingtags,
    course_description: selector(state, 'description'),
    couser_score: selector(state, 'score'),
    course_duration: selector(state, 'duration'),
    img_course: selector(state, 'image'),
    tags: state.tagsparsed
  }),
  mapDispatchToProps
)(InitializeFromStateForm);
export default InitializeFromStateForm;
