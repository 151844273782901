import styled from "styled-components";
import { style } from '../../../style';

export const ContainerOutPreviewSearch = styled.div`
  position: absolute;
  margin-top: -25px;
  left: 24.5%;

  @media (max-width: 1250px){
    left: 23.3%;
  }

  @media (min-width: 1500px){
    left: 25.3%;
  }

  @media (min-width: 1700px){
    left: 26.3%;
  }

  @media (min-width: 1900px){
    left: 27.3%;
  }

  @media (min-width: 2100){
    left: 29%;
  }
`

export const ContainerInnerPreviewSearch = styled.div`
  position: relative;
  z-index: 21;

  width: 400px;
  min-height: 100px;
  /* bottom: 10px; */

  background: white;

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`

export const ContainerType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .header-title{
    margin-left: 10px;
    margin-top: 10px;
    /* margin-bottom: 10px; */

    display: flex;
    align-items: center;

    span{
      margin-left: 7px;
      font-family: Rubik;
      font-size: 12px;
      color: #808693;
    }
  }

  .results-not-found{
    font-family: Rubik;
    padding-left: 15px;
    font-size: 12px;
    color: #808693;
  }
  
`

export const MiniCardCourse = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  cursor: pointer;

  margin-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover{
    background-color: #E4E4E5;
  }
`

export const ContainerImageMiniCard = styled.div`
  width:25%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  >.image-not-active{
    filter: grayscale(100%);
  }
`

export const ImageMiniCard = styled.img`
  object-fit: cover;
  max-height: 80%;
  max-width: 100%;
`

export const ContainerTexts = styled.div`
  width: 75%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-left: 10px;
  font-family: Rubik;

  span{
    font-size: 11px;
    color: #808080;
  }
`

export const ContainerGoToMoreResults = styled.div`
  width: 100%;
  height: 45px;

  display: flex;
`

export const GoToMoreResults = styled.span`
  margin-top: 10px;
  margin-left: auto;
  margin-right: 15px;
  height: 18px;

  font-family: Rubik;
  font-size: 14px;
  color: #808693;

  cursor: ${(props) => props.isDisabled ? 'default' : 'pointer'};

  &:hover{
    color: ${(props) => props.isDisabled ? '#808693' : style.inpuStyle.colorInput};
    border-bottom: ${(props) => props.isDisabled ? 'none' : `1px solid ${style.inpuStyle.colorInput}`};
  }
`