import API, { API_RANDOM_UPLOAD_VIDEO, API_V2 } from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import PubSub from 'pubsub-js';
import errorHandler from '../componentes/utils/errorHandler';
import { browserHistory } from 'react-router';
import { style } from '../style';
import { getVideoCover } from '../componentes/utils/getVideoCover';

const isClientCipa = style.teamName.name === 'Cipa';

export default class GalleryApi {
  static getGalleries(page) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      API.get(
        `galleries?page=${page}&per_page=9&source=admin`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_LIST_GALLERIES',
              galleries: response.data
            });

            browserHistory.push('/galleries');

            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static getMoreGalleries = (page) => {
    return (dispatch) => {
      dispatch({ type: 'LOADING_GALLERIES_START' });

      API.get(
        `galleries?page=${page}&per_page=9&source=admin`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_MORE_GALLERIES',
              galleries: response.data
            });

            browserHistory.push('/galleries');

            dispatch({ type: 'LOADING_GALLERIES_FINISH' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'LOADING_GALLERIES_FINISH' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  };

  static refreshGallery(galleryId) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      API.get(`galleries/${galleryId}/gallery_contents/`, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'GALLERY_SELECT',
              content: response.data,
              galleryId
            });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static showGallery(galleryId) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      API.get(
        `galleries/${galleryId}/gallery_contents/?page=1`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GALLERY_SELECT',
              content: response.data,
              galleryId
            });

            browserHistory.push('/gallery');

            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static getMoreGalleryPhotos = (galleryId, page) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data } = await API.get(
          `galleries/${galleryId}/gallery_contents/?page=${page}`,
          Config.headers()
        );

        dispatch({
          type: 'GET_MORE_PHOTOS',
          content: data,
          galleryId
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  };

  static sendFileGallery(file, id, kind, send_notification) {
    return (dispatch) => {
      const CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };

      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const formdata = new FormData();
      formdata.append('name', file.name);
      formdata.append('kind', kind);
      formdata.append(kind, file);
      if (send_notification) {
        formdata.append('send_notification', send_notification);
      }

      API.post(`galleries/${id}/gallery_contents/`, formdata, CONFI)
        .then(
          function(response) {
            dispatch({
              type: 'SHOW_DIALOG_GALLERY',
              visible: false
            });
            dispatch({
              type: 'END_LOAD'
            });

            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });

            dispatch(this.showGallery(id));
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'END_LOAD'
            });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static sendFileGalleryVideo(file, id) {
    return (dispatch) => {
      const CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };

      const formdata = new FormData();
      formdata.append('video', file[0]);
      API.post(`galleries/${id}/gallery_videos`, formdata, CONFI)
        .then(
          function(response) {
            dispatch({
              type: 'SHOW_DIALOG_GALLERY',
              visible: false
            });
            dispatch({
              type: 'END_LOAD'
            });
            dispatch(this.showGallery(id));
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static deleteFileGallery(idgallery, idfile) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const CONFI = Config.headers();

      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(
        `galleries/${idgallery}/gallery_contents/${idfile}`,
        CONFI
      )
        .then(
          function() {
            dispatch(this.refreshGallery(idgallery));
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static saveGallery(galleryName, tags) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const form = new FormData();
      form.append('name', galleryName);
      tags.forEach((tag) => {
        form.append('tags[]', tag.value);
      });
      API.post(`galleries/`, form, Config.headers())
        .then(
          function(response) {
            const { data: newGallery } = response;
            newGallery.cover = null;

            dispatch({
              type: 'ADD_GALLERY',
              newGallery
            });
            PubSub.publish('close_dialog_gallery');

            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }

  static editGallery = (galleryId, galleryName, tags) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const form = new FormData();
        form.append('name', galleryName);
        tags.forEach((tag) => {
          form.append('tags[]', tag.value);
        });

        await API.patch(`galleries/${galleryId}`, form, Config.headers()).then(
          (response) => {
            const { data: editGallery } = response;

            dispatch({
              type: 'EDIT_GALLERY',
              editGallery,
              galleryId
            });

            dispatch(this.getGalleries(1));
          }
        );

        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      } catch (error) {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static destroyGallery(galleryId) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(`galleries/${galleryId}`, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'DELETE_GALLERY',
              galleryId
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }
}

export const sendFileGalleryV2 = (file, id, kind, send_notification, lastItem = false) =>
  async (dispatch) => {

    let responseSendFileGalleryV2 = false;

    dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

    //Video Validations
    if(kind === "video"){
      if (
        (isClientCipa && file.size / (1024 * 1024).toFixed(2) > 500) ||
        (!isClientCipa && file.size / (1024 * 1024).toFixed(2) > 200)
      ) {
        // size validation --> 200mb || 500mb
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: `Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo com no máximo ${
              isClientCipa ? '500mb' : '200mb'
            }.`
          }
        });

        if(lastItem) {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'END_LOAD'
          });
        }

        return;

      } else if (file.type !== 'video/mp4') {
        // type validation --> .mp4
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message:
              'Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo no formato .mp4.'
          }
        });
        if(lastItem) {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'END_LOAD'
          });
        }

        return;
      }
    }

    var CONFI = {
      headers: {
        'content-type': `${file.type}`,
        'content-length': `${file.size}`
      }
    };
    CONFI.onUploadProgress = (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      dispatch({
        type: 'START_LOAD',
        percent: percentCompleted,
        visible: true
      });
    };


    const divisor = file.name.split(".");

    const response = await fetch(
      `https://pfi6nr3akgr76vbbo2lyu273ui0qnpmf.lambda-url.us-east-1.on.aws?team=${style.teamName.name}&contentType=${file.type}&typeForKey=${divisor[divisor.length - 1]}`,
      {
        method: 'GET'
      }
    ).then((response) => {
      return response.json();
    });

    const filename = response.filename;

    const result = await API_RANDOM_UPLOAD_VIDEO.put(
      response.url,
      file,
      CONFI
    );

    let result_upload_image = {
      status: 400
    };

    //Video Cover
    if(kind === 'video'){

      const cover = await getVideoCover(file, 0.1);

      var CONFI_IMAGE = {
        headers: {
          'content-type': `${cover.type}`,
          'content-length': `${cover.size}`
        }
      };

      result_upload_image = await API_RANDOM_UPLOAD_VIDEO.put(
        response.url_image,
        cover,
        CONFI_IMAGE
      );
    }

    const uploadIsDone = kind === 'image' ? result.status === 200 : result.status === 200 && result_upload_image.status === 200;

    //Preparing to send the information to the backend

    const formdata = new FormData();
    formdata.append('key', filename);
    formdata.append('name', file.name);
    formdata.append('kind', kind);
    if (send_notification) {
      formdata.append('send_notification', send_notification);
    }

    if (uploadIsDone) {
      await API_V2.post(`galleries/${id}/gallery_contents/`, formdata, Config.headers())
      .then(
        function(response) {
          if(lastItem) {
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'END_LOAD'
            });
            dispatch(GalleryApi.showGallery(id));
          }else{
            dispatch({
              type: 'START_LOAD',
              percent: 0,
              visible: true
            });
          }

          responseSendFileGalleryV2 = true;
        }.bind(this)
      )
      .catch(
        function(err) {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'END_LOAD'
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        }.bind(this)
      );
    } else {
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: `Houve um erro no upload ${kind === 'image' ? 'da imagem' : 'do vídeo' }.`
        }
      });
    }

    return responseSendFileGalleryV2;

  };

