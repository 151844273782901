import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { style } from '../../../style';
import Modal from 'react-awesome-modal';
import { ProgressBar } from 'react-bootstrap';

import CourseApi from '../../../Logicas/CourseApi';
import NotificationApi from '../../../Logicas/NotificationApi';

import ButtonDefault from '../../utils/FormsComponents';
import { FooterPagination } from '../../utils/Pagination';

import IcoExit from '../../utils/img/exit-dialog.png';
import ImgPlaceHolder from '../../utils/img/place_holder_user.png';

require('./TableCourseDetails.css');

const colorPrimary = style.inpuStyle.colorInput;

class TableCourseDetails extends Component {
  constructor() {
    super();
    this.state = { userResendCertificate: '' };
  }

  render() {
    const { headerName, elements, usersCourse } = this.props;
    return (
      <React.Fragment>
        <div className="container-details">
          <table className="table-select-details">
            <div className="header-list">
              <th className="labels-headers">
                <div className="select-item-header-course">Nome</div>
              </th>
              <th className="labels-headers">
                <div className="select-item-header-course">Login</div>
              </th>
              {headerName === 'Iniciados' ? (
                <React.Fragment>
                  <th className="labels-headers">
                    <div className="select-item-header-course">Progresso</div>
                  </th>
                </React.Fragment>
              ) : (
                headerName === 'Concluídos' && (
                  <React.Fragment>
                    <th className="labels-headers">
                      <div className="select-item-header-course">
                        Data de conclusão
                      </div>
                    </th>
                  </React.Fragment>
                )
              )}
            </div>
            <div>
              <body className="user-body">
                <ul className="section-users-course">
                  {Array.isArray(usersCourse.users) &&
                    usersCourse.users.map(
                      function (users) {
                        return (
                          <tr className="th-row-details">
                            <td className="th-name">
                              <img
                                className="avatar-user"
                                src={
                                  users.user.image.url
                                    ? users.user.image.url
                                    : ImgPlaceHolder
                                }
                                alt="foto do usuário"
                              />
                              {users.user.name &&
                                users.user.name.length > 17
                                ? `${users.user.name.slice(0, 17)}...`
                                : users.user.name}
                            </td>
                            <td
                              className="th-email"
                              style={{ color: colorPrimary }}
                            >
                              {users.user.email}
                            </td>
                            {headerName === 'Concluídos' && (
                              <td className="th-date-conclusion">
                                {users.conclusion_date
                                  ? moment
                                    .tz(
                                      users.conclusion_date,
                                      'Europe/Berlin'
                                    )
                                    .format('DD/MM/YYYY')
                                  : 'Data indisponivel'}
                              </td>
                            )}
                            {headerName === 'Iniciados' &&
                              <React.Fragment>
                                <td className="th-progress">
                                  <ProgressBar
                                    now={
                                      users.finished_modules === undefined ||
                                        isNaN(users.finished_modules.length /
                                          elements.module_courses.length)
                                        ? 0
                                        : (users.finished_modules.length /
                                          elements.module_courses.length)
                                        * 100
                                    }
                                  />
                                </td>
                                <td className="th-date-last-acess">
                                  {users.finished_modules === undefined || isNaN(users.finished_modules.length /
                                    elements.module_courses.length)
                                    ? '0.00%'
                                    : (
                                      `${(
                                        (users.finished_modules.length /
                                          elements.module_courses.length)
                                        * 100
                                      ).toFixed(2)}%`
                                    )
                                  }
                                </td>
                              </React.Fragment>
                            }
                            {(headerName == 'Concluídos') ?
                              (elements.has_certificate ?
                                (
                                  (users.is_entitled_to_certificate ? (
                                    <td className="th-certified">
                                      <button
                                        type="button"
                                        className="btn-certified"
                                        style={{ color: colorPrimary }}
                                        onClick={() => {
                                          this.props.downloadUserCertificate(
                                            elements.id,
                                            users.user.id,
                                            elements.title
                                          );
                                        }}
                                      >
                                        BAIXAR CERTIFICADO
                                      </button>
                                      <button
                                        type="button"
                                        className="btn-certified"
                                        style={{ color: colorPrimary }}
                                        onClick={() =>
                                          this.setState(
                                            {
                                              userResendCertificate:
                                                users.user.email
                                            },
                                            () => {
                                              this.props.resendCertificate(
                                                elements.id,
                                                users.user.id
                                              );
                                            }
                                          )
                                        }
                                      >
                                        REENVIAR CERTIFICADO
                                      </button>
                                    </td>
                                  ) : (
                                    <td className="th-certified">
                                      <div className="th-not-certified">
                                        Usuário não obteve porcentagem mínima para
                                        certificado.
                                        <br />
                                        Para obter certificado, este usuário deve
                                        reiniciar o curso.
                                      </div>
                                    </td>
                                  ))
                                )
                                : (
                                  <td className="th-certified">
                                    <div className="th-not-certified">
                                      Esse curso não disponibiliza certificado.
                                    </div>
                                  </td>
                                )
                              )
                              : <></>
                            }
                          </tr>
                        );
                      }.bind(this)
                    )
                  }
                </ul>
              </body>
              {usersCourse.users && usersCourse.users.length > 0 && (
                <footer className="footer-pagination">
                  <FooterPagination
                    activePage={usersCourse.pagination.current_page}
                    totalItemsCount={usersCourse.pagination.total_count}
                    handlePageChange={(pageNumber) => {
                      const kind = headerName === 'Concluídos' ? 'finished' :
                        headerName === 'Iniciados' ? 'not_finished' : 'not_initiated'
                      this.props.getUsersCourse(elements.id, kind, pageNumber)
                    }
                    }
                  />
                </footer>
              )}
            </div>
          </table>
        </div>
        <Modal width="30%" height="30%" visible={this.props.showModal !== true}>
          <div className="send-notification-course">
            <div className="notification-img-close">
              <img
                onClick={() => this.props.closeDialogNotification()}
                className="btn-exit"
                src={IcoExit}
                alt="fechar"
              />
            </div>
            <header className="certificate-title-confirm">
              Certificado reenviado para{' '}
              <i style={{ color: colorPrimary }}>
                {this.state.userResendCertificate}
              </i>
              !
            </header>
            <div className="certificate-btn-ok">
              <ButtonDefault
                onClick={() => this.props.closeDialogNotification()}
                label="OK"
              />
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    headerName: state.getHeaderCourseDetails,
    showModal: state.show_modal_birthday
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resendCertificate: (idPublish, user) => {
      dispatch(NotificationApi.resendCertificate(idPublish, user));
    },
    downloadUserCertificate: (courseId, userId, courseTitle) => {
      dispatch(
        CourseApi.downloadUserCertificate(courseId, userId, courseTitle)
      );
    },
    getUsersCourse: (idCourse, kind, page, per_page) => {
      dispatch(CourseApi.getUsersCourse(idCourse, kind, page, per_page));
    },
    closeDialogNotification: () => {
      dispatch({ type: 'SHOW_MODAL_BIRTHDAY' });
    }
  };
};

const TableCourseDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TableCourseDetails);
export default TableCourseDetailsContainer;
