import React from 'react';
import { connect } from 'react-redux';
import DialogApi from '../../../../Logicas/DialogApi';
import { ReactComponent as IconText} from '../../../utils/img/textCurto.svg';
import { ReactComponent as IconUser} from '../icons/user.svg';

import * as S from './OpenAnswers.style';

function OpenAnswers(props) {

    const content = props.content;
    
    return(
        <S.OpenAnswerContainer>
            <S.TypeContainer>
                <IconText/>
                <span style={{marginLeft:"5px"}}>Respostas abertas</span>
            </S.TypeContainer>

            <S.QuestionContainer>
                <S.QuestionContent>
                    <span>Título:</span>
                    <h2>{content.text}</h2>
                </S.QuestionContent>
            </S.QuestionContainer>

            <S.AnswersContainer>
                <span>Respostas enviadas:</span>
                {content.content_open_question.open_responses.map(open_response => (
                    <S.AnswersContent>
                        <S.AnswerContainer>
                            <S.AnswerContent>
                                <span>{open_response.response}</span>
                            </S.AnswerContent>
                            <S.AnswerBottom>
                                <IconUser style={{marginRight: "5px"}}/>
                                {open_response.user.name}
                            </S.AnswerBottom>
                        </S.AnswerContainer>
                    </S.AnswersContent>
                ))}

            </S.AnswersContainer>
        </S.OpenAnswerContainer>
    )
}

const mapStateToProps = (state) => {
    return {
      valuesForm: state.getForm.formDetails,
      usersWhoVoted: state.getForm.modalContent.usersWhoVoted,
      answerTitle: state.getForm.modalContent.answerTitle
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      openDialog: () => {
        dispatch(DialogApi.openDialog());
      },
      setUsersWhoVoted: (answerTitle, usersWhoVoted, content_survey, survey, content) =>
        dispatch({
          type: 'GET_USERS_VOTERS',
          modalContent: { answerTitle, usersWhoVoted, content_survey, survey, content}
        })
    };
  };
  
  const OpenAnswersContainer = connect(
    mapStateToProps,
    mapDispatchToProps
  )(OpenAnswers);
  
  export default OpenAnswersContainer;