import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import { Field, reduxForm } from 'redux-form';
import { required, validurl } from '../../validation/FormValidation';
import ButtonDefault, {
  DropZoneTerm,
  LongTextLink
} from '../../utils/FormsComponents';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { formValueSelector } from 'redux-form';
import TermApi from '../../../Logicas/TermAPi';
import IcoExit from '../../utils/img/exit-dialog.png';

require('./dialogterm.css');

export class DialogTerm extends Component {
  constructor() {
    super();

    this.state = {
      errorText: '',
      drag: true
    };
    this.showDragArea = this.showDragArea.bind(this);
    this.term = this.term.bind(this);
  }
  showDragArea() {
    this.setState({
      drag: false
    });
    setTimeout(() => {
      this.setState({
        drag: true
      });
    }, 2000);
  }

  term(values) {
    if (values.img_term !== undefined && values.img_term[0] !== undefined) {
      if (values.img_term[0].type === 'application/pdf') {
        this.props.saveTerm(values);
      } else {
        this.props.closeDialog();
        this.props.openError();
      }
    } else {
      this.props.saveTerm(values);
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <Modal
        visible={this.props.visible}
        width="40%"
        height="40%"
        effect="fadeInUp"
      >
        {this.state.drag ? (
          <form
            onSubmit={handleSubmit(this.term)}
            onDragOver={this.showDragArea}
            onDragOver={this.showDragArea}
          >
            <MuiThemeProvider>
              <div onClick={this.props.closeDialog} className="body-row-exit">
                <img style={{ height: '12px', width: '12px' }} src={IcoExit} alt="fechar"/>
              </div>
              <div className="row-header-term">
                {this.props.img !== undefined ? (
                  <div className="term-header">Arquivo adicionado</div>
                ) : (
                  <div className="term-header">Termo de uso</div>
                )}
              </div>
              <div className="row-header-term">
                {this.props.img === undefined ? (
                  <Field
                    style={{ width: '70%' }}
                    name="link"
                    rowsMax={1}
                    rows={1}
                    validate={
                      this.props.img === undefined ? [required, validurl] : []
                    }
                    id="name"
                    label="Adicione o link ou Arraste o Arquivo"
                    component={LongTextLink}
                    type="text"
                  />
                ) : null}
              </div>
              <div style={{ marginTop: '5%' }} className="row-header-term">
                <ButtonDefault label="Salvar" />
              </div>
            </MuiThemeProvider>
          </form>
        ) : (
          <MuiThemeProvider>
            <form onDragOver={this.showDragArea} onDragOver={this.showDragArea}>
              <Field
                name="img_term"
                id="name"
                label="Titulo da publicação*"
                component={DropZoneTerm}
              />
            </form>
          </MuiThemeProvider>
        )}
      </Modal>
    );
  }
}
let DialogTermContainer = reduxForm({
  form: 'createterm',
  destroyOnUnmount: true
})(DialogTerm);
const selector = formValueSelector('createterm');

const mapDispatchToProps = (dispatch) => {
  return {
    saveTerm: (values) => {
      dispatch(TermApi.saveTerm(values));
    },
    closeDialog: () => {
      dispatch({ type: 'CLOSE_DIALOG_TERM', alert: { visible: false } });
    },
    openError: () => {
      dispatch({
        type: 'OPEN_ERROR',
        alert: { visible: true, message: 'Arquivo deve estar no formato PDF' }
      });
    }
  };
};
DialogTermContainer = connect(
  (state) => ({
    img: selector(state, 'img_term')
  }),
  mapDispatchToProps
)(DialogTermContainer);

export default DialogTermContainer;
