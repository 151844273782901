export function post_views(state = { data: [], pagination: {} }, action) {
  if (action.type === 'GET_VIEWS') {
    if (!action.reset) {
      if (action.data.length >= 1) {
        action.data.map(function (comments) {
          state.data.push(comments);
        });
        state.pagination = action.pagination;
      } else {
        state.data = [];
        state.pagination = {};
      }
    } else {
      state.data = action.data;
      state.pagination = action.pagination;
    }

    return {
      data: state.data,
      pagination: state.pagination,
      id_post: action.post_id,
      visible: action.visible
    };
  }
  return state;
}
