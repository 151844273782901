import React, { Component } from 'react';

import * as S from './TabLabel.styles';

export class TabLabel extends Component {
  render() {
    const { label, active, onClick, style } = this.props;
    return (
      <S.LabelBody active={active} onClick={onClick} style={style}>
        {label}
      </S.LabelBody>
    );
  }
}

export default TabLabel;
