import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import { Player } from 'video-react';
import ReactHtmlParser from 'react-html-parser';
import { Carousel } from 'react-responsive-carousel';

import checkbox_on from '../../../utils/img/checkbox_on.png';
import checkbox_off from '../../../utils/img/checkbox_off.png';
import radiobutton_on from '../../../utils/img/radiobutton_on.png';
import radiobutton_off from '../../../utils/img/radiobutton_off.png';

require('./createpreview.css');

export default class PreviewForm extends Component {
  componentDidMount() {
    PubSub.subscribe(
      're-render-canvas',
      function(render) {
        this.forceUpdate();
      }.bind(this)
    );
  }
  render() {
    return (
      <div className="preview-container">
        <div className="body-container-form">
          {this.props.contents && this.props.contents.length > 0 && (
            <div className="img-form-result">
              {this.props.contents[0].kind === 'image' && (
                <img
                  className="img-form-result"
                  src={
                    this.props.contents[0].canvas_content_images[0].image.url
                  }
                  alt="imagem-formulario"
                />
              )}
              {this.props.contents[0].kind === 'video' && (
                <Player
                  style={{ width: '100%', height: 'auto' }}
                  src={
                    this.props.contents[0].canvas_content_videos[0].video.url
                  }
                />
              )}
              {this.props.contents[0].kind === 'carousel' && (
                <Carousel showArrows={true} swipeable={false}>
                  {this.props.contents[0].canvas_content_images.length > 0 &&
                    this.props.contents[0].canvas_content_images.map(function(
                      image
                    ) {
                      return (
                        <div>
                          <img
                            style={{
                              'object-fit': 'cover'
                            }}
                            src={image.image.url}
                            alt="imagem do carrossel"
                          />
                        </div>
                      );
                    })}
                </Carousel>
              )}
            </div>
          )}
          <div className="preview-title-container-fluid">
            {this.props.title ? (
              <div className="title-publication">{this.props.title}</div>
            ) : (
              <div className="title-publication-preview">
                Titulo da Publicação
              </div>
            )}
          </div>
          <div className="body-question-preview">
            {this.props.contents && this.props.contents.length > 0 &&
              this.props.contents.map(function(content, index) {
                switch (content.kind) {
                  case 'text':
                    return (
                      <div className="container-fluid-text">
                        <div className="text-details-form">
                          {ReactHtmlParser(content.text)}
                        </div>
                      </div>
                    );
                  case 'link':
                    return (
                      <div className=" btn-add-component">
                        <a
                          style={{ marginTop: '20px' }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={content.link}
                        >
                          {' '}
                          <div className="btn-add-component-label">
                            {' '}
                            {content.title.length > 30
                              ? content.title.slice(0, 30) + '...'
                              : content.title}
                          </div>
                        </a>
                      </div>
                    );
                  case 'image':
                    if (index > 0) {
                      return (
                        <img
                          className="img-form-result"
                          src={content.canvas_content_images[0].image.url}
                          alt="imagem-formulario"
                        />
                      );
                    }
                    break;
                  case 'carousel':
                    if (content.canvas_content_images.length > 1 && index > 0) {
                      return (
                        <Carousel showArrows={true} swipeable={false}>
                          {content.canvas_content_images.map(function(image) {
                            return (
                              <div>
                                <img
                                  style={{
                                    'object-fit': 'cover'
                                  }}
                                  src={image.image.url}
                                  alt="imagem do carrossel"
                                />
                              </div>
                            );
                          })}
                        </Carousel>
                      );
                    }
                    break;
                  case 'video':
                    if (content.canvas_content_videos.length > 0 && index > 0) {
                      return (
                        <Player
                          style={{ width: '100%', height: 'auto' }}
                          src={content.canvas_content_videos[0].video.url}
                        />
                      );
                    }
                    break;
                  case 'survey':
                    return (
                      <div className="container-fluid-answer">
                        <div className="title-preview-form">{content.text}</div>
                        <div className="preview-form">
                          <div>
                            {content.content_survey &&
                              content.content_survey.map(function(
                                survey,
                                index
                              ) {
                                return (
                                  <div className="details-survey segmentation-container">
                                    <img
                                      className="img-alteration"
                                      src={
                                        index === 1
                                          ? radiobutton_on
                                          : radiobutton_off
                                      }
                                      alt="checked-box"
                                    />
                                    <p className="text-details-question">
                                      {survey.title}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  case 'quiz':
                    return (
                      <div className="container-fluid-answer">
                        <div className="title-preview-form">{content.text}</div>
                        <div className="preview-form">
                          <div>
                            {content.content_survey &&
                              content.content_survey.map(function(
                                survey,
                                index
                              ) {
                                return (
                                  <div className="segmentation-container details-survey">
                                    <img
                                      className="img-alteration"
                                      src={
                                        index === 1 ? checkbox_on : checkbox_off
                                      }
                                      alt="checked-box"
                                    />
                                    <p className="text-details-question">
                                      {survey.title}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  case 'open_question':
                    return (
                      <div className="container-fluid-answer">
                        <div className="title-preview-form">
                          {content.content_open_question.question}
                        </div>
                        <div className="preview-form">
                          <input
                            type="text"
                            className="input-preview-question"
                            placeholder="Digite sua resposta"
                          />
                        </div>
                      </div>
                    );
                }
              })}
          </div>
        </div>
      </div>
    );
  }
}
