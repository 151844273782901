import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from 'material-ui/styles';
import { DropZoneImageForm, TextForm } from '../../utils_form/FormComponents';
import ContentApi from '../../../Logicas/ContentApi';
import DialogApi from '../../../Logicas/DialogApi';
import IconRemove from '../../utils/img/ic-excluir.svg';
import ReactLoading from 'react-loading';
import PubSub from 'pubsub-js';
require('./contentbody.css');

class CreateContentImage extends Component {
  constructor() {
    super();
    this.state = { edit: false, render: true };
    this.setImage = this.setImage.bind(this);
    this.resetEdit = this.resetEdit.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
  }

  componentDidMount() {
    this.setState({ img_name: this.props.img_name });
    this.setState({ image: this.props.img_file });
  }

  resetEdit() {
    if (this.props.id_edit === undefined) {
      PubSub.publish('clear_new_elements', true);
    } else {
      this.setState({
        img_name: this.props.img_name,
        image: this.props.img_file,
        edit: false
      });
    }
  }

  saveImage() {
    if (this.state.image !== undefined && this.state.img_name !== undefined) {
      this.setState({ edit: false });
      if (this.props.id_edit !== undefined) {
        this.props.patchContent(
          { title_card: this.state.img_name, img_file: this.state.image },
          this.props.module_id,
          this.props.id_edit,
          this.props.course_id
        );
      } else {
        this.props.saveContent(
          { title_card: this.state.img_name, img_file: this.state.image },
          this.props.module_id,
          this.props.course_id
        );
      }
      this.props.showLoad(this.props.id_edit);
    } else {
      this.props.showErroValidation(
        'Título da image e a imagem são obrigatórios'
      );
    }
  }

  deleteCard() {
    this.props.deleteContent(this.props.id_edit, this.props.course_id);
  }

  setImage(file) {
    this.setState({ image: file });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ image: nextProps.img_file });
    this.setState({ img_name: nextProps.img_name });
  }

  render() {
    if (this.state.render) {
      return (
        <MuiThemeProvider>
          <form className="content-body-display">
            {this.props.load_content_requisition.visible === false ||
            this.props.load_content_requisition.id !== this.props.id_edit ? (
              <React.Fragment>
                <div className="actions-card-course">
                  {this.state.edit === true ||
                  this.props.id_edit === undefined ? (
                    <React.Fragment>
                      <label onClick={this.saveImage} className="label-actions">
                        Salvar
                      </label>
                      <label onClick={this.resetEdit} className="label-actions">
                        Cancelar
                      </label>
                    </React.Fragment>
                  ) : (
                    <label
                      onClick={() => this.setState({ image: null, edit: true })}
                      className="label-actions"
                    >
                      Editar
                    </label>
                  )}
                  {this.state.edit === true ? (
                    <img
                      onClick={this.deleteCard}
                      className="img-card-course"
                      src={IconRemove}
                      alt="remover"
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <TextForm
                  onChange={(event) =>
                    this.setState({ img_name: event.target.value })
                  }
                  hintText="Título do card"
                  value={this.state.img_name}
                />
                <div style={{ marginTop: '20%' }}>
                  <DropZoneImageForm
                    image={this.state.image}
                    onDrop={this.setImage}
                  />
                </div>
              </React.Fragment>
            ) : (
              <div className="display-load">
                <div className="display-load">
                  <label className="save-label">Salvando</label>
                  <ReactLoading
                    type="balls"
                    color="#c3c3c3"
                    height={'10%'}
                    width={'10%'}
                  />
                </div>
              </div>
            )}
          </form>
        </MuiThemeProvider>
      );
    } else {
      return <div />;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveContent: (values, idmodule, idcourse) => {
      dispatch(ContentApi.saveContentImage(values, idmodule, idcourse));
    },
    patchContent: (values, idmodule, idcontent, idcourse) => {
      dispatch(
        ContentApi.patchContentImage(values, idmodule, idcontent, idcourse)
      );
    },
    enableBtn: () => {
      dispatch({ type: 'NO_SUBMITTING' });
    },
    deleteShowDialogUser: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    deleteContent: (values, idcourse) => {
      dispatch(ContentApi.deleteContent(values, idcourse));
    },
    showLoad: (id) => {
      dispatch({ type: 'SUBMITTING_CONTENT', id: id });
    },
    showErroValidation: (message) =>
      dispatch({
        type: 'OPEN_ERROR',
        alert: {
          visible: true,
          message: message
        }
      })
  };
};

const mapStateToProps = (state) => {
  return {
    load_content_requisition: state.load_content_requisition
  };
};

const ContentImageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateContentImage);

export default ContentImageContainer;
