import React, { Component } from 'react';
import HeaderProduct from '../HeaderInteraction/HeaderProduct';
import ProductList from '../StoreDash/ProductList';
import ProductListRight from '../StoreDash/ProductListRight';

class WrapStoreDash extends Component {
  render() {
    return (
      <div>
        <HeaderProduct
          products_rescued={this.props.products_rescued}
          products_without_stock_list={this.props.products_without_stock_list}
          new_products={this.props.new_products}
          delivered_products={this.props.delivered_products}
          products_without_stock={this.props.products_without_stock}
          product_length={this.props.product_length}
        />
        <div className="display-row-home-dash">
          <ProductList
            description="Solicitados neste periodo"
            label="Produtos solicitados"
            list={this.props.products_rescued}
          />
          <ProductListRight
            editProduct={this.props.editProduct}
            description="Adicionados recentemente"
            label="Produtos Adicionados recentemente"
            list={this.props.new_products}
          />
        </div>
        <div className="display-row-home-dash">
          <ProductList
            description="Entregues neste periodo"
            label="Produtos entregues"
            list={this.props.delivered_products}
          />
          <ProductListRight
            editProduct={this.props.editProduct}
            description="Produtos que não estão disponíveis para compra"
            label="Produtos sem estoque"
            list={this.props.products_without_stock}
          />
        </div>
      </div>
    );
  }
}
export default WrapStoreDash;
