import styled from 'styled-components';
import { style as profile } from '../../style';

export const dialog = styled.dialog`
  background-color: ${profile.inpuStyle.colorInput};
`;

export const BodyCardGallery = styled.div`
  width: 400px;
  height: 250px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

export const FooterCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20%;
`;

export const ImageCard = styled.img`
  width: 200px;
  height: 115px;
  border-radius: 10px 10px 0px 0px;
`;

export const LabelCard = styled.label`
  font-family: Rubik;
  font-size: 12.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
`;

export const ListItemsGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5%;
`;

export const VideoCard = styled.div`
  width: 200px;
  height: 150px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`;

export const BodyDialogGallery = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-evenly;
  padding: 20px;
  height: 300px;
  opacity: 0.6;
`;

export const LabelLoad = styled.span`
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
  position: absolute;
`;

export const FormDialogCreate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  flex-flow: column;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: -35px;
  top: -35px;
  width: 24px;
  height: 24px;
  opacity: 0.6;
  background-color: #ffffff;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 11px;
    top: 2px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
