import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import { CircularProgress } from 'material-ui';
import { MuiThemeProvider } from 'material-ui/styles';

require('./dialogvalidation.css');
export default class DialogValidation extends Component {
  showDragArea() {
    this.setState({ height: '20%', drag: true });
  }
  render() {
    return (
      <MuiThemeProvider>
        <Modal width="40%" height="40%" visible={this.props.visible}>
          <div className="modal-header-validation">
            <CircularProgress color="#E5156B" size={60} thickness={7} />
            <div className="main-label-import">Validando a planilha</div>
            <div className="secondary-label-import">
              Aguarde, os dados estao sendo validados e importados.
            </div>
          </div>
        </Modal>
      </MuiThemeProvider>
    );
  }
}
