import React, { Component } from 'react';

import * as S from './styles';

let observer;

export class Pagination extends Component {
  ref = React.createRef();

  registerObserver = () => {
    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio > 0) {
          this.props.getMorePublications(this.props.pagination);
        }
      },
      {
        root: null,
        rootMargin: '0px 0px 64px 0px',
        threshold: 1
      }
    );
  };

  componentDidMount() {
    this.registerObserver();

    if (this.ref.current) {
      observer.observe(this.ref.current);
    }
  }

  componentWillUnmount() {
    observer.disconnect();
  }

  render() {
    return (
      <S.Pagination
        getMorePublications={this.props.getMorePublications}
        ref={this.ref}
      >
        {this.props.children}
      </S.Pagination>
    );
  }
}

export default Pagination;
